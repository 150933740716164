import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions/data";
import * as interActions from "../../../../store/actions/interactions";
import * as appwideActions from "../../../../store/actions/appwide";
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import matchSorter from 'match-sorter'
import './TransactionStyle.css'
import {DateFormatInput} from 'material-ui-next-pickers'
import AddPlus from '../../../../Media/AddPlus.svg'
import {loadData, siwtchTransactionType, dateParser} from '../../../../helpers/helpers'
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { backendUrl } from '../../../../variables'

class TransactionOverview extends React.PureComponent {

    state = {

        transactionId: this.props.transactionId,
        date: null,
        transctionData: null,
    }

    toggleModal = (type, payload) => {
        this.props.toggleModal(
            {
                type: type, 
                payload: {
                    data: payload, 
                    transactionId: this.state.transactionId, 
                    getData: this.getData, 
                    setDetailTr: this.updateId,
                    getOverallData: this.props.getOverallData
                }, 
                open: true
            }
        )
    }

    toggleConfirm = (type, id) => {

        this.props.toggleModal(
            {
                type: type, 
                payload: {
                    transactionId: this.state.transactionId, 
                }, 
                open: true
            }
        )

    }

    componentDidMount() {

        this.getData(this.props.transactionId)
        //this.props.setCurrentView(5)
       //this.props.componentDataFetch('get', 5, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)

    } 

    updateId = (transaction_id) => {

        this.setState(state => ({...state, transactionId: transaction_id}))

    }

    getData = (transaction_id) => {
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/transaction_detail/${transaction_id}`

        const transactionDetail = loadData(url_call, headers)
        transactionDetail
            .then(json => {

                this.props.getFlatWallets(this.props.user_id)
                this.props.getAllPortfolioList(this.props.user_id)

                this.setState(state => ({...state, transctionData: json['data']}))
                console.log(json['data'])

            })

    }




    deleteData = () => {

        let headers = { "content-type": "application/json" };
        headers['x-user'] = this.props.user_id
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/transaction_detail/${this.state.transactionId}`

        axios.delete(url_call, {headers: headers})
            .then(json => {

                this.props.getOverallData()
                this.setState(state => ({...state, transctionData: null, transactionId: null}))
                this.props.selectTransaction(null)
                this.props.getUserLimits(this.props.user_id)
                //console.log(json['data'])

            })



    }



    componentDidUpdate() {

        if (this.props.match.params.portfolio !== this.props.portfolioId) {

            this.props.selectTransaction(null)

        }


    }
    
    toggleTxModal = () => {

        //this.props.toggleTransactionAddModal({open: true});

    }

    render() {

        const { transctionData }  = this.state;

        return (

            this.state.transctionData !== null ? 

           <div>
               <div
                style={{
                    boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                    borderRadius: 14, 
                    position: 'relative',
                    width: '100%',
                    paddingBottom: 24,
                }}>

               <div style={{width: '100%'}}>

                    <div style={{paddingLeft: 40,  width: '100%', paddingTop: 25, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', borderBottom: '1px solid #E1E5E9'}}>     
                        <div>
                        <h3>Transaction detail</h3>
                        </div>

                        { (this.props.roles[this.props.portfolioId] === 'admin' || ( this.props.roles[this.props.portfolioId] === 'manager' && this.state.transctionData['user_transaction']['id'] == this.props.user_id)) ?
                        <div style={{display: 'flex'}}>

                            <div className="basier-p2 opacity-hover" style={{fontWeight: 900, height: 40, backgroundColor: '#0078DB', width: 150, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, marginRight: 12, cursor: 'pointer'}} onClick={() => this.toggleModal('edit_transaction', this.state.transctionData)}>
                                Edit
                            </div>

                            <div className="basier-p2 opacity-hover" style={{fontWeight: 900, height: 40, backgroundColor: '#F9716B', width: 150, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, marginRight: 40, cursor: 'pointer'}} onClick={() => this.toggleConfirm('delete_transaction', this.state.transactionId)}>
                                Delete
                            </div>

                        </div>

                        :

                        null 
                        }
                    </div>
                    
                    <div style={{ marginBottom: 24, width: '100%', paddingTop: 25 }}>   

                        <div style={{display: 'flex', alignItems: 'center', marginTop: 12, paddingLeft: 40, }}>

                            <div style={{
                                fontWeight: 900, 
                                height: 40, 
                                padding: 12, 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                borderRadius: 20, 
                                textTransform: 'uppercase', 
                                fontSize: 22, color: 'white', 
                                backgroundColor: siwtchTransactionType(transctionData.transaction_description)[1]
                                }} 
                                className="basier">
                                    {siwtchTransactionType(transctionData.transaction_description)[0]}
                            </div>
                            
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 40, }}>
                            <div className="basier" style={{fontSize: 16, paddingLeft: 12}}>
                                { dateParser(transctionData.date) }
                            </div >
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', marginTop: 18, marginBottom: 18, height: 60, backgroundColor: 'rgba(204, 237, 254,0.3)', paddingLeft: 40,  }}>
                            <div style={{fontWeight: 900, textTransform: 'uppercase', fontSize: 14}} className="basier">Base currency </div>
                            <div style={{marginLeft: 6, fontSize: 16}} className="basier">{transctionData.base_currency.name}</div>
                            
                            { transctionData.quote_currency !== null && (transctionData.transaction_description === 'buy' || transctionData.transaction_description === 'ico' || transctionData.transaction_description === 'sell' ) ? 

                                <React.Fragment>
                                <div style={{marginLeft: 12, marginRight: 12, fontSize: 20}}>|</div>
                                <div style={{fontWeight: 900, textTransform: 'uppercase', fontSize: 14}} className="basier">Quote currency </div>
                                <div style={{marginLeft: 6, fontSize: 16}} className="basier" >{transctionData.quote_currency.name}</div>
                                </React.Fragment>
                            
                            :
                            
                                null 
                            
                            }
                            
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', paddingLeft: 40, height: 30, }}>
                            <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200, fontSize: 14}} className="basier">Amount </div>
                            <div style={{marginLeft: 6, fontSize: 16}} className="basier">
                                {transctionData.amount.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}
                            </div>
                        </div>
                        
                        { (transctionData.transaction_description === 'buy' || transctionData.transaction_description === 'ico' || transctionData.transaction_description === 'sell' ) ? 
                        
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 12, paddingLeft: 40, height: 30, }}>
                            <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200, fontSize: 14}} className="basier">Unit Price </div>
                            <div style={{marginLeft: 6, fontSize: 16}} className="basier">

                            {transctionData.unit_price >= 1 ? 
                                transctionData.unit_price.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                                :
                                transctionData.unit_price === 0 ?
                                null
                                :
                                transctionData.unit_price.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                            }

                            </div>
                        </div>

                        :

                        null 

                        }

                        <div style={{display: 'flex', alignItems: 'center', marginTop: 12, paddingLeft: 40, height: 30, }}>
                            <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200, fontSize: 14}} className="basier">Fee </div>
                            <div style={{marginLeft: 6, fontSize: 16}} className="basier">
                                
                                {
                                    transctionData.fee !== null && transctionData.fee !== undefined  ? 

                                        transctionData.fee >= 1 ? 
                                            transctionData.fee.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                                        :
                                            transctionData.fee === 0 ?
                                        null
                                        :
                                            transctionData.fee.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                                
                                    : 
                                
                                        null 
                                
                                }

                            </div>
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', marginTop: 12, paddingLeft: 40, height: 30,}}>
                            <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200, fontSize: 14}} className="basier">Fee currency </div>
                            <div style={{marginLeft: 6, fontSize: 16}} className="basier">

                                {
                                    transctionData.fee_currency !== null && transctionData.fee_currency !== undefined  ? 

                                        transctionData.fee_currency.name

                                    :

                                        null

                                }

                            </div>
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', marginTop: 12, paddingLeft: 40, height: 30,}}>
                            <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200, fontSize: 14}} className="basier">
                                {transctionData.transaction_description === 'transfer' ? 
                                'Transferred From' 
                                :
                                'Wallet'
                                }
                            </div>
                            <div style={{marginLeft: 6, fontSize: 16}} className="basier">

                                
                                {transctionData.account !== null ? 
                                
                                <div style={{display: 'flex'}}>
                                    <div>{transctionData.account.name}</div>
                                    
                                    { transctionData.account.exchange !== null && transctionData.account.exchange !== undefined ? 
                                        <div style={{marginLeft: 6}}>
                                            <span style={{fontStyle: 'italic'}}>({transctionData.account.exchange.name})</span>
                                        </div>
                                        :

                                        null
                                    }

                                    { transctionData.account.custom_exchange_check === true ? 
                                        <div style={{marginLeft: 6}}>
                                            <span style={{fontStyle: 'italic'}}>({transctionData.account.custom_exchange})</span>
                                        </div>
                                        :

                                        null
                                    }
                                    
                                </div>
                                    
                                : 
                                
                                transctionData.storage !== null ?

                                <div style={{display: 'flex'}}>
                                    <div>{transctionData.storage.name}</div>
                                    <div style={{marginLeft: 6}}>
                                            <span style={{fontStyle: 'italic'}}>(Storage wallet)</span>
                                    </div>
                                    
                                </div>

                                : 

                                <div style={{fontStyle: 'italic'}}>Not specified</div>
                                
                                }




                            </div>
                        </div>
                        
                        {transctionData.transaction_description === 'transfer' ? 
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 12, paddingLeft: 40, height: 30,}}>
                            <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200, fontSize: 14}} className="basier">
                                Transferred To
                            </div>
                            <div style={{marginLeft: 6, fontSize: 16}} className="basier">

                                
                                {transctionData.destination !== null && transctionData.destination !== undefined  ? 
                                
                                    transctionData.destination.wallet_type === 'exchange' && transctionData.destination.exchange !== null  ? 

                                
                                        <div style={{display: 'flex'}}>
                                            <div>{transctionData.destination.exchange.name}</div>
                                             
                                                <div style={{marginLeft: 6}}>
                                                    <span style={{fontStyle: 'italic'}}>
                                                        ({
                                                            transctionData.destination.exchange.custom_exchange_check === true ? 
                                                            
                                                            transctionData.destination.exchange.custom_exchange
                                                            :
                                                            
                                                            transctionData.destination.exchange.exchange.name
                                                        })
                                                    </span>
                                                </div>
                                        </div>
                                    :

                                        <div style={{display: 'flex'}}>
                                            <div>{transctionData.destination.name}</div>
                                            <div style={{marginLeft: 6}}>
                                                    <span style={{fontStyle: 'italic'}}>(Storage wallet)</span>
                                            </div>
                                    
                                        </div>
                                    
                                : 
                                
                                <div style={{fontStyle: 'italic'}}>Not specified</div>
                                
                                }

                            </div>
                        </div>
                        :
                        null
                        }



                        <div style={{display: 'flex', alignItems: 'center', marginTop: 12, paddingLeft: 40, height: 30,}}>
                            <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200, fontSize: 14}} className="basier">Comments </div>
                        </div>
                        <div style={{fontSize: 16, paddingLeft: 40}} className="basier">

                            {
                                transctionData.comments !== null && transctionData.comments !== undefined  ? 

                                    transctionData.comments

                                :

                                    null

                            }
                            
                        </div>




                    </div>



                </div>

               </div>

           </div>

           :

           null

        )
    }
  
};

const mapStateToProps = state => {
    return {
        //transactions: state.data.transactions, 
        inBitcoin: state.data.bitcoinPf, 
        token: state.auth.token,
        user_id: state.auth.user_id,
        roles: state.auth.roles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        getFlatWallets: (user_id) => 
            dispatch(appwideActions.getFlatWalletList(user_id)), 
        getAllPortfolioList: (user_id) => 
            dispatch(appwideActions.getAllPortfolioList(user_id)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TransactionOverview));