import React from 'react';
import { connect } from 'react-redux';
import TableBody from './TableBody'
import TableHeaders from './TransactionTableHeaders'

class TransactionTable extends React.PureComponent {

    state = {
        totalPages: Math.max(Math.ceil(this.props.total / 10, 1)),
        shownData: this.props.data, 
        pageProposed: this.props.page + 1,
    }
    
    componentDidUpdate(prevProps) {

        if (this.props.data !== prevProps.data && this.props.data !== null && this.props.data !== undefined) {
            this.setState(state => ({...state, shownData: this.props.data, totalPages: Math.max(Math.ceil(this.props.total / 10, 1)),}))
            //this.goToPage(1)
        }

    }

    movePage = (number) => {
        let newPage = parseInt(this.props.page + 1) + parseInt(number)
        console.log('proposed new page is', newPage)
        if (newPage > this.state.totalPages) {
            newPage = this.state.totalPages
        } else if (newPage < 1) {
            newPage = 1
        }

        this.setState(state => ({...state, pageProposed: newPage}))
        this.props.getTransactions(newPage - 1)
        window.scrollTo(0, 0)
    }

    changeInput = (e) => {
        e.persist();
        const newPage = e.target.value
        this.setState(state => ({...state, pageProposed: newPage}))
    }
    
    goToPage = (newPage) => {

        console.log('newpage', newPage)
        if (newPage > this.state.totalPages) {
            newPage = this.state.totalPages
        } else if (newPage < 1) {
            newPage = 1
        }

        this.props.getTransactions(newPage - 1)


    }


    commitPageChange = () => {
        if (this.state.pageProposed !==this.props.page) {
            let proposedDisplay = this.state.pageProposed
            if (this.state.pageProposed > this.state.totalPages) {
                proposedDisplay = this.state.totalPages
            } else if (this.state.pageProposed < 1) {
                proposedDisplay = 1
            }
            this.setState(state => ({...state, pageProposed: proposedDisplay}))
            this.props.getTransactions(proposedDisplay - 1)
        }
    }


    render () {

        return (

            <div style={{width: '100%', display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                {/* Table headers */}
                <div style={{width: '100%'}}>
                    <TableHeaders selectAll={this.props.selectAll} allSelected={this.props.allSelected} />
                </div>
                
                {/* Table body */}
                
                <div style={{width: '100%', minHeight: 800}}>
                    <TableBody getOverallData={this.props.getOverallData} selectTransaction={this.props.selectTransaction} data={this.state.shownData} selectAll={this.props.selectAll} selectSingle={this.props.selectSingle} selected={this.props.selected} allSelected={this.props.allSelected} />
                </div>

                {/* Table footer / pagination */}

                <div className="basier-p2" style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 48, zIndex: 90}}>
                {this.props.total > 0 ?                
                <div className="basier-p4-caps" style={{marginBottom: 18}}>
                    Records {(this.props.page) * 10 + 1} - {Math.min((this.props.page) * 10 + 10, this.props.total)} of {this.props.total}
                </div>
                :
                <div className="basier-p4-caps" style={{marginBottom: 18}}>No records</div>
                }

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div className="basier-p2 opacity-hover" style={{textTransform: 'uppercase', color:this.props.page == 1 ? 'gray' : '#0078DB', cursor:this.props.page == 1 ? 'not-allowed' : 'pointer' }} onClick={this.props.page == 1 ? null : () => this.movePage(-1)}>Prev</div>
                        
                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 12, marginRight: 12}}>
                            <div >
                                <input onChange={this.changeInput} onBlur={this.commitPageChange} className="light-input" type="text" value={this.state.pageProposed} style={{width: 60, textAlign: 'center', border: '1px solid #CCC', boxShadow: 'none', padding: '5px 10px'}} />
                            </div>
                            <div style={{marginRight: 12, marginLeft: 12}} >of</div>
                            <div>
                                {this.state.totalPages}
                            </div>
                        </div>
                        
                        <div className="basier-p2 opacity-hover" style={{textTransform: 'uppercase', color:this.props.page == this.state.totalPages ? 'gray' : '#0078DB', cursor:this.props.page == this.state.totalPages ? 'not-allowed' : 'pointer' }} onClick={this.state.currentPage == this.state.totalPages ? null : () => this.movePage(1)}>Next</div>

                    </div>

                </div>

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(TransactionTable)
