import React from 'react'; 
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

const Summary = props => {

    const downArrow = 
    (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3333 8.35083L6.68421 13.9999L1 8.35083" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M6.68457 14V0" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
    )

    const upArrow = 
    (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.999933 7.50329L6.64905 1.85418L12.3333 7.5033" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M6.64868 1.85414L6.64868 15.8541" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
    )
    

    return (

        props.data[props.portfolioId] !== undefined ? 

            <Grid container 
            spacing={0}
            justify="space-between"
            alignItems="center">

            <Grid item>
                <p className="basier-p2" style={{marginBottom: 6, color: 'black'}}>Total Profit to date</p>
                <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                    {props.currency_symbol}&thinsp;
                    {props.data[props.portfolioId].net_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}
                </h2>
                <p className={`basier-p2 ${props.data[props.portfolioId].net_profit > 0 ? 'growth' : 'decline'}`} style={{marginBottom: 6, marginTop:12}}>
                    { ((props.data[props.portfolioId].percent_change) * 100).toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2}) }% in {props.currency_name}
                </p>

            </Grid>

            <Grid item>
                <p className="basier-p2" style={{marginBottom: 6, color: 'black'}}>Unrealized profit to date</p>
                <div style={{display: 'flex', alignItems: 'center'}}>
            
                <span style={{display: 'inline-block'}}>
                    <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                        <span>{props.currency_symbol}&thinsp;</span>
                        <span>{props.data[props.portfolioId].total_active_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span>
                    </h2>
                </span>
                </div>
                
                
            </Grid>



            <Grid item>
                <p className="basier-p2" style={{marginBottom: 6, color: 'black'}}>Realized profit to date</p>
                <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                    <span>{props.currency_symbol}&thinsp;</span>
                    <span>{props.data[props.portfolioId].total_realized_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span>
                </h2>
            </Grid>
                
            </Grid>

        : 

            <div style={{height: 112}}></div> 
        

    )
}


const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
    };
};

export default connect(
    mapStateToProps,
    null
)(Summary);