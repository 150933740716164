import React from 'react';
import { connect } from 'react-redux';
import BackIcon from "../../../../../Media/BackIcon.svg";
import MobileAudit from '../../../../MobileOnly/MobileAuditTrail';
import * as interActions from "../../../../../store/actions/interactions";

class SmallGeneral extends React.PureComponent {

    state = {
        nameEdit: false,
        data: null
    }

    componentDidMount() {
        const data = this.props.portfoliosData[this.props.portfolioId]
        console.log('thedata', data) 
        if (data !== undefined) {
            this.setState(state => ({...state, data: data}))
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.portfoliosData !== prevProps.portfoliosData) {
            const data = this.props.portfoliosData[this.props.portfolioId]
            if (data !== undefined) {
                console.log('thedata2', data) 
                this.setState(state => ({...state, data: data}))    
            }
        }
    }

    editName = () => {
        this.setState(state => ({...state, nameEdit: true})) 
    }

    goBackAction = () => {
        this.props.goBack()
    }

    componentWillUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
        this.props.setInteraction({label: 'pin', value: true});
    }

    render () {
        

        return (
            this.state.data === null ? 
            null :

                <React.Fragment>
                    
                            
                    <div style={{position: 'absolute', top: 0, zIndex: 9999999, width: '100%'}}>

                        <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed', justifyContent: 'space-between'}} id="responsive-dialog-title">

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div onClick={() => this.goBackAction()}>
                                        <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                                    </div>
                                </div>

                                <div className="basier-p2" style={{color: 'black', fontWeight: 900, textTransform: 'uppercase'}}>Audit Trail</div>

                                <div>
                                    <div className="basier"  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '1.2rem', width: 35}}>
                             
                                    </div> 
                                </div>

                        </div>

                        <div style={{position: 'fixed', top: 75, height: 200, width: '100%', zIndex: 1200, backgroundColor: 'white'}} />




                            <div style={{position: 'absolute', top: 75, zIndex: 120000, backgroundColor: 'white', minHeight: 100, width: '100%'}}>
                                 
                               <MobileAudit scope="portfolio" portfolioId={this.props.portfolioId} data={this.state.data} />

                            </div>

                    </div>

                </React.Fragment>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setInteraction: (data) => 
            dispatch(interActions.setInteraction(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SmallGeneral)



