import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { backendUrl } from '../../variables';
import Hidden from '@material-ui/core/Hidden';
import * as dataActions from "../../store/actions/data";
import {paymentLogos} from '../../helpers/helpers'
import PaymentComponent from '../AppWide/Payments/PaymentWrapper';
import * as appwideActions from "../../store/actions/appwide";
import loadingSpinner from '../../Media/loadingSpinner.gif';
import {loadData} from '../../helpers/helpers';
import InvoiceTable from './InvoiceTable';
import pdfIcon from '../../Media/pdfIcon.svg';
import currencyDict from '../../helpers/currency.json'

const styles = theme => ({
    root: {
        '& label.Mui-focused': {
          color: 'green',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'red',
          },
          '&:hover fieldset': {
            borderColor: 'yellow',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'green',
          },
        },
      },

      paymentWrap: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: 600,
                
        }

      },

      inputSmall: {
        width: 200,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 12,
        },
        [theme.breakpoints.up('md')]: {
            width: 200,
            marginRight: 12
        }
    },
    inputLarge: {
        width: 412,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 12,
        },
        [theme.breakpoints.up('md')]: {
            width: 412,
            marginRight: 12
        }
    },
    inputsWrapper: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }, 
    buttonWidth: {
        [theme.breakpoints.down('sm')]: {
            width: 130,
        },
    }, 
    buttonWrapper: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: 24
        } 
    }, 
    paddingSmall: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 12, paddingRight: 12
        },  
    }
  });

class ProfileContainer extends React.PureComponent {
    
    state = {
        edit: false,
        loading: false,
        loadingMessage: null, 
        errorMessage: null,
        invoiceData: null,
    }

    getInvoices = (customer_id) => {
        
        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const data_fetch = loadData(`${backendUrl}/v1/user_invoices/?u=${this.props.user_id}&id=${customer_id}`, headers)
        data_fetch
            .then(json => {

                this.setState(state => ({...state, invoiceData: json['data']}))
                
            })

    }

    componentDidMount() {
        
        this.props.setCurrentView(999);
        if (this.props.plan !== undefined && this.props.plan !== null) {
            this.props.getPay(this.props.user_id, this.props.plan.plan_data[0]['customer'])
            this.getInvoices(this.props.plan.plan_data[0]['customer'])
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.plan !== undefined && this.props.plan !== null && prevProps.plan !== this.props.plan) {
            this.props.getPay(this.props.user_id, this.props.plan.plan_data[0]['customer'])
            this.getInvoices(this.props.plan.plan_data[0]['customer'])
         }
    }

    changeMethod = () => {
        this.setState(state => ({...state, edit: !state.edit}))

    }

    submitToServer = (p, b, c) => {

        console.log('calling now for change')
        let paymentData = null;
        let oldPayment = null;
            
        paymentData = p
        try {oldPayment = this.props.payment['methods'][0]['id']} catch(e) {oldPayment=null}

        const url_call = `${backendUrl}/v1/change_payment/`
        const sendData = {
            payment_id: paymentData, 
            old_payment: oldPayment, 
            customer_id: this.props.plan.plan_data[0]['customer'], 
            user_id: this.props.user_id,
        }

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        axios.post(url_call, sendData, {headers: headers})
            .then(json => {

                this.props.getPay(this.props.user_id, this.props.plan.plan_data[0]['customer']);
                this.setState(state => ({...state, loading: false, edit: false}))

            })
            .catch( e => {

                let errorMessage = 'An unknown error occured, please try again.'
                this.setError(errorMessage)
                this.setState(state => ({...state, loading: false, edit: false}))

                
            })

        
    }

    setLoading = (l, m) => {
        this.setState(state => ({...state, loading: l, loadingMessage: m}))
    }

    setError = (m) => {
        this.setState(state => ({...state, errorMessage: m}))
    }
   
    render () {

        const { classes } = this.props;

        return (



                <div style={{marginTop: 0, minHeight: 120, maxWidth: '100vw'}}>


                    <div className="basier-p4-caps" style={{marginBottom: 12, marginTop: 12}}>
                        Payment method
                    </div>

                    {this.props.payment !== undefined && this.props.payment !== null ? 

                        this.props.payment.number === 0 || this.state.edit === true ? 

                            <div className={classes.paymentWrap}>
                                {this.state.loading && 
                                <div style={{width: 600, zIndex: 90000, height: 200, backgroundColor: 'white', opacity: 0.9, position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                    <img style={{height: 64, with: 64}} src={loadingSpinner} alt=""/>
                                    <div className="basier-p3" style={{marginTop: 12}}>{this.state.loadingMessage}</div>
                                </div>}

                            
                                <PaymentComponent 
                                    customer_id={this.props.plan.plan_data[0]['customer']} 
                                    submit={(p) => this.submitToServer(p, true, true)} 
                                    fullName={`${this.props.user_first_name} ${this.props.user_last_name}`} 
                                    user_id={this.props.user_id}
                                    token={this.props.token}
                                    currency={this.props.plan.plan_data[0]['plan']['currency']}
                                    setError={(m) => this.setError(m)}
                                    setLoading={(l, m) => this.setLoading(l, m)}
                                />
                                {this.state.errorMessage !== null ? 

                                    <div style={{padding: 12, color: 'red'}} className="basier-p4">
                                        {this.state.errorMessage}
                                    </div>

                                    :

                                    null

                                }
                            </div>

                        :

                        <div>
                            {
                                this.props.payment.number >= 1 ? 

                                <div>
                                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                                        <div style={{paddingLeft: 12}}>
                                            {paymentLogos(this.props.payment['methods'][0]['brand'])}
                                        </div>
                                        <div className="basier-p3" style={{color: 'black', textTransform: 'uppercase'}}>
                                            {`Card ending in ${this.props.payment['methods'][0]['last4']} expiry ${String(this.props.payment['methods'][0]['month']).padStart(2,0)}/${this.props.payment['methods'][0]['year']}`}
                                        </div>
                                    </div>

                                    <div onClick={this.changeMethod} style={{marginTop: 12, paddingLeft: 12, cursor: 'pointer', color: '#0078DB' }} className="basier-p4-caps opacity-hover">
                                        Change payment method
                                    </div>
                                </div>

                                :

                                null
                            }
                        </div>
                    :
                        null }

                    
                    <div className="basier-p4-caps" style={{marginBottom: 12, marginTop: 24}}>
                        Invoices
                    </div>
                    

                    {
                    this.props.subs === undefined ?
                    null 
                    :
                    this.props.subs.plan.plan_id === 'free' || this.props.subs.plan.plan_id === 'unlimited' ? 

                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', fontStyle: 'italic', marginTop: 24}}>You do not have invoices as you are not subscribed to a paying plan. If you switch to a paying plan, we will show your invoices.</div>
                    :
                    
                    this.state.invoiceData !== null && this.state.invoiceData !== undefined ? 
                        <React.Fragment>
                            <Hidden smDown>
                                <InvoiceTable data={this.state.invoiceData} />
                            </Hidden>
                            <Hidden mdUp>

                                {
                                this.state.invoiceData.map((val, key) => {

                                    const language = navigator.language || 'en'
                                    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                                    const parseDate = new Date(val['created_at']);
                                    const showDate = parseDate.toLocaleDateString(language,options)

                                    return (

                                        <div style={{
                                            height: 50, borderBottom: '1px solid #ddd', display: 'flex', 
                                            alignItems: 'center', justifyContent: 'space-between'
                                        }}>

                                            <div>
                                                <div className="basier-p3">
                                                    {showDate}
                                                </div>
                                                <div className="basier-p4-caps" style={{marginTop: 4}}>
                                                    {`${currencyDict[val.currency.toUpperCase()]['symbol']}${(val['total']/100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}
                                                </div>
                                            </div>
                                            <div>
                                                <a href={val['pdf']}>
                                                    <div className="touchableOpacity" style={{display: 'flex', alignItems: 'center', marginRight: 12}}>
                                                        <img src={pdfIcon} alt="" style={{width: 24, height: 24, marginRight: 6}} />
                                                        <div>PDF</div>
                                                    </div>
                                                </a>
                                            </div>

                                        </div>

                                    )
                                })
                                }


                            </Hidden>
                        </React.Fragment>
                    :
                        <div style={{width: '100%', height: 200}}>
                            <img style={{height: 64, with: 64}} src={loadingSpinner} alt=""/>
                        </div>
                    }
                    
                    <div>
                        
                    </div>
            
                </div>

                       
        )
    }


}

const mapStateToProps = state => {
    return {
        user_first_name: state.auth.user_first_name, 
        user_last_name: state.auth.user_last_name, 
        token: state.auth.token,
        user_id: state.auth.user_id, 
        payment: state.appwide.payMethods, 
        plan: state.appwide.plan,
        subs: state.auth.subscription
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(dataActions.setCurrentView(view)),
        getPay: (user, cust_id) => dispatch(appwideActions.getPay(user, cust_id)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileContainer));
