import React from 'react';
import { connect } from 'react-redux';
import SmallGeneral from './Small/SmallGeneral';
import SmallCollab from './Small/SmallCollab';
import NewCollab from './Small/NewCollab';
import SmallAudit from './Small/SmallAudit';
import SmallUpload from './Small/SmallUploads';
import {withRouter} from 'react-router-dom'; /* MAY NEED TO REMOVE */

class SmallSettings extends React.PureComponent {

    state = {
        settings: null, 
        selectedView: 'overview',
    }

    componentDidMount() {
        this.initSettings()
        console.log('mounting small')
    }

    initSettings = () => {

        let settings = []
        if (this.props.portfolioType === 'individual') {
            settings = [['general', 'General'], ['audit', 'Audit Trail'], ['uploads', 'Uploads']]
        } else {
            settings = [['general', 'General'], ['collaborators', 'Collaborators'], ['audit', 'Audit Trail'], ['uploads', 'Uploads']]
        }
        this.setState(state => ({...state, settings: settings}))
    }

    handleSelect = (v) => {
        //this.props.history.push(`${this.props.location.pathname}/${v}`)
        this.setState(state => ({...state, selectedView: v}))

    }

    
    
    render () {

        return (
            this.state.settings !== null ?
            <div>

                {this.state.selectedView === 'overview' &&
                    <div>
                        { 
                            this.state.settings.map((val, key) => {

                                return (
                                    
                                    <div 
                                        className="coin-flat-list-item "
                                        key={key}
                                        onClick={() => this.handleSelect(val[0])}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center', 
                                            height: 84,
                                            width: '100%',
                                            marginBottom: '1px solid #E1E5E9',
                                            justifyContent: 'space-between',
                                            }}>
                                                <div style={{display: 'flex', alignItems: 'center', width: '70%'}} >
                                                    <div style={{width: 40, height: 40, marginLeft: -20, borderRadius: 100, backgroundColor: '#0078DB'}} />
                                                    <div style={{marginLeft: 12}} className="basier-p2">{val[1]}</div>
                                                </div>
                                                <div style={{marginRight: 24}}>
                                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                                                    </svg>
                                                </div>
                                    </div>
                                )
                            })
                            
                        }

                </div>
                }

                {this.state.selectedView === 'general' &&  
                    <SmallGeneral portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} history={this.props.history} goBack={() =>  this.handleSelect('overview')} />
                }
                {this.state.selectedView === 'collaborators' &&  
                    <SmallCollab portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} history={this.props.history} goBack={() =>  this.handleSelect('overview')} />
                }
                {this.state.selectedView === 'audit' &&  
                    <SmallAudit portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} history={this.props.history} goBack={() =>  this.handleSelect('overview')} />
                }
                {this.state.selectedView === 'uploads' &&  
                    <SmallUpload portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} history={this.props.history} goBack={() =>  this.handleSelect('overview')} />
                }

            </div>
            :
            null
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmallSettings))
