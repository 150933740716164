
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import currencyData from '../../helpers/currency.json'

const initialState = {
    
    currentView: null,
    portfolioList: [],
    menuList: {},
    bitcoinPf: 'fiat',
    totalGlobalValue: undefined, 
    totalPortValue: {},
    globalBalance: {
        period: '30d',
        start: null, 
        end: null, 
        inBitcoin: 'fiat',
        values: [],
        start_date: null, 
        current_value: null,
        last_updated: null,
    }, 
    portfolioBalance: {
        period: '30d',
        start: null, 
        end: null, 
        inBitcoin: 'fiat',
        data: {},
    },
    portfolioHoldings: {},
    portfoliosData: {},
    roiEvol: {},
    portfoliosHist: null,
    currency_used: {
        id: null, 
        symbol: null,
    },
    transactions: null,
    deposits: null,
    menu_selected: null,
    selectedMenuOption: null,
    globalAssetList: {},
    fiatList: [], 
    filteredFiat: [],
    scopedUsers: [],
    subscriptions: [],
    wallet_balance: undefined, 
    balanceRisk: undefined, 
    userRisk: undefined,
    importedTransactions: undefined

    };


const updateImportTr = (state, action) => {
    return updateObject(state, {
        importedTransactions: action.data
    })
}

const updatePortfolioTotal = (state, action) => {

    return updateObject(state, {
        totalPortValue: {
            ...state.totalPortValue, 
            [action.data.portfolio_id]: action.data.data
                }
            })
}

const updateGlobalTotal = (state, action) => {

    return updateObject(state, {
        totalGlobalValue: action.data
                })
}

const setSubs = (state, action) => {
    return updateObject(state, {subscriptions: action.data}) 
}

const setBalanceRisk = (state, action) => {
    return updateObject(state, {balanceRisk: action.data}) 
}

const setUserRisk = (state, action) => {
    return updateObject(state, {userRisk: action.data}) 
}


const setPfScope = (state, action) => {
    return updateObject(state, {bitcoinPf: action.data}) 
}

const setPortfolioHist = (state, action) => {
    return updateObject(state, {portfoliosHist: action.data})
}

const getGlobalData = (state, action) => {
    
    return updateObject(state, {
        globalBalance: {
            ...state.globalBalance, 
            ...action.data,
            }
    });

}

const setCurrentView = (state, action) => {
    return updateObject(state, {currentView: action.data}) 
}

const updateMenuSelected = (state, action) => {
    return updateObject(state, {menu_selected: action.data})
}

const updatescopedUsers = (state, action) => {
    return updateObject(state, {scopedUsers: action.data})
}

const updateMenuPath = (state, action) => {
    return updateObject(state, {selectedMenuOption: action.data})
}


const getGraphData = (state, action) => {
    
    return updateObject(state, {
        globalBalance: {
            ...state.globalBalance, 
            ...action.data, 
            loading: false
        }
    });

}

const getAllPortfoliosData = (state, actions) => {
    return updateObject(state, {
        portfoliosData: {
            ...state.portfoliosData, 
            ...actions.data
        }
    }
    );
}

const getPortfolioHoldings = (state, actions) => {
    return updateObject(state, {
        portfolioHoldings: {
            ...state.portfolioHoldings, 
            [actions.data.portfolio]: {...actions.data.balances}
        }
    }
    );
}

const getSinglePortfoliosData = (state, actions) => {
    return updateObject(state, {
        portfoliosData: {
            ...state.portfoliosData, 
            [actions.data.portfolio]: {...actions.data.data}
        }
    }
    );
}

const overWritePorts = (state, actions) => {
    return updateObject(state, {
        portfoliosData: actions.data 
    }
    );
}

const getSingleRoiEvol = (state, actions) => {
    return updateObject(state, {
        roiEvol: {
            ...state.roiEvol, 
            [actions.data.portfolio]: {...actions.data}
        }
    }
    );
}

const getAllBalancesData = (state, actions) => {
    return updateObject(state, {
        globalAssetList: {
            ...state.globalAssetList, 
            ...actions.data
        }
    }
    );
}

const setFiatList = (state, action) => {
    
    return updateObject(state, {
        fiatList: action.data, 
        filteredFiat: action.data, 
    });

}

const filterFiatList = (state, action) => {
    
    return updateObject(state, {
        filteredFiat: action.data, 
    });

}

const setGlobalTime = (state, action) => {

    return updateObject(state, {
        globalBalance: {

            ...state.globalBalance, 
            period: action.data
        }
    });
}

const setPortfolioTime = (state, action) => {

    return updateObject(state, {
        portfolioBalance: {

            ...state.portfolioBalance, 
            period: action.data
        }
    });
}

const updatePortfolioValues = (state, action) => {

    return updateObject(state, {
        portfolioBalance: {
            ...state.portfolioBalance, 
            data: {
                ...state.portfolioBalance.data, 
                [action.data.portfolio_id]: {
                    ...state.portfolioBalance.data[action.data.portfolio_id], 
                    ...action.data, 
                }
             }
            }
    });

}

const setPortfolioList = (state, action) => {
    
    return updateObject(state, {
        portfolioList: action.data, 
    });

}

const setGlobaltoBtc = (state, action) => {
    
    return updateObject(state, {
        globalBalance: {
            ...state.globalBalance, 
            inBitcoin: action.data
        }
    });

}

const setPFtoBtc = (state, action) => {
    
    return updateObject(state, {
        portfolioBalance: {
            ...state.portfolioBalance, 
            inBitcoin: action.data
        }
    });

}

const updateTransactions = (state, action) => {

    return updateObject(state, {
        transactions: {...action.data}
        })

}

const updateDeposits = (state, action) => {

    return updateObject(state, {
        deposits: {...action.data}
        })

}

const updateWalletBalance = (state, action) => {

    return updateObject(state, {
        wallet_balance: {...action.data}
        })

}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.UPDATE_GLOBALS:
                return getGlobalData(state, action);
        case actionTypes.UPDATE_GRAPH_SUCCESS:
                return getGraphData(state, action);
        case actionTypes.UPDATE_ALL_PORTFOLIOS_SUCCESS:
                return getAllPortfoliosData(state, action);
        case actionTypes.UPDATE_PORTFOLIO_SUCCESS:
                return getSinglePortfoliosData(state, action);
        case actionTypes.UPDATE_FIAT:
                return setFiatList(state, action);
        case actionTypes.SET_PF_SCOPE:
                return setPfScope(state, action);
        case actionTypes.FILTER_FIAT:
                return filterFiatList(state, action);
        case actionTypes.UPDATE_GLOBAL_TIME: 
                return setGlobalTime(state, action);
        case actionTypes.UPDATE_ALL_BALANCES_SUCCESS: 
                return getAllBalancesData(state, action);
        case actionTypes.UPDATE_MENU: 
            return updateMenuSelected(state, action);
        case actionTypes.UPDATE_MENU_PATH: 
            return updateMenuPath(state, action);
        case actionTypes.UPDATE_PORTFOLIO_GRAPH_SUCCESS: 
            return updatePortfolioValues(state, action);  
        case actionTypes.UPDATE_PORTFOLIO_TIME: 
                return setPortfolioTime(state, action);
        case actionTypes.SET_CURRENT_VIEW: 
                return setCurrentView(state, action);
        case actionTypes.UPDATE_PF_LIST: 
                return setPortfolioList(state, action);
        case actionTypes.GLOBAL_GRAPH_SCOPE: 
                return setGlobaltoBtc(state, action);
        case actionTypes.PF_GRAPH_SCOPE: 
                return setPFtoBtc(state, action);
        case actionTypes.UPDATE_PORTFOLIO_HIST_SUCCESS: 
                return setPortfolioHist(state, action);
        case actionTypes.GET_TRANSACTION_SUCCESS: 
                return updateTransactions(state, action);
        case actionTypes.UPDATE_SCOPED_USER: 
                return updatescopedUsers(state, action);
        case actionTypes.GET_DEPOSITS_SUCCESS: 
                return updateDeposits(state, action);
        case actionTypes.GET_WALLET_BALANCE_SUCCESS: 
                return updateWalletBalance(state, action);
        case actionTypes.GET_SUBS: 
                return setSubs(state, action);
        case actionTypes.ROI_EVOL_SUCCESS: 
                return getSingleRoiEvol(state, action);
        case actionTypes.BALANCE_RISK_SUCCESS: 
                return setBalanceRisk(state, action);
        case actionTypes.USER_RISK_SUCCESS: 
                return setUserRisk(state, action);
        case actionTypes.UPDATE_TOTAL_VALUE_SUCCESS: 
                return updateGlobalTotal(state, action);
        case actionTypes.UPDATE_TOTAL_PORTFOLIO_VALUE_SUCCESS: 
                return updatePortfolioTotal(state, action);
        case actionTypes.OVERWRITE_PORTS: 
                return overWritePorts(state, action);
        case actionTypes.PORT_HOLDINGS_SUCCESS: 
                return getPortfolioHoldings(state, action);
        case actionTypes.ACC_TR_IMPORT_SUCCESS: 
                return updateImportTr(state, action);
        default:
            return state;

    }
}


export default reducer;