import React from 'react';
import { connect } from 'react-redux';
import {
    CardElement,
    injectStripe, 
} from 'react-stripe-elements';
import axios from 'axios'; 
import { backendUrl } from '../../../variables';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const style = {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }, 
    checkBoxRoot: {
        padding: 0
    }
  };

class PaymentForm extends React.PureComponent {

    state = {
        checkedConsent: false, 
    }

    handleCheck = () => {
        this.setState(state => ({...state, checkedConsent: !state.checkedConsent}))
    }

    handleSubmit = (ev) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();

        if (this.state.checkedConsent) {

            if (this.props.setupOnly === true ) {

            } else {

                this.props.setError(null)
                this.props.setLoading(true, 'Authorizing card with your bank')
                
            }

            

            
            const sendData = {
                "user_id": this.props.user_id, 
                "currency": this.props.currency,
            }
            let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {

                    headers['Authorization'] = `Token ${this.props.token}`

                }

                const url_call = `${backendUrl}/v1/pm_intent/`
                
                axios.post(url_call, sendData, {headers: headers})
                    .then(json => {

                        const clientSecret = json['data']['data']
                        const cardElement = this.props.elements.getElement('card');

                        this.props.stripe.confirmCardSetup(
                            clientSecret,
                            {
                            payment_method: {
                                card: cardElement,
                            }
                        }
                        ).then((result) => {
                            if (result.error) {
                            // Show error to your customer
                            this.props.setError(result.error.message)
                            } else {
                                
                                if (this.props.setupOnly === true ) {

                                } else {

                                    this.props.setLoading(true, 'Finalizing')
                                    this.props.submit(result['setupIntent']['payment_method'])
                                    
                                }
                                
                            
                            }
                            
                            
                            
                        
                        })
                        .catch(e => {
                            console.log(e)
                            this.props.setError('An error occured with setting up the payment')
                        })



                    })
                    .catch( e => {
                        
                        console.log(e)
                        this.props.setError('An error occured with setting up the payment')

                    })
                    
        } else {
            this.props.setError('Please agree with the Terms and conditions and the payment consent.')
        }
    }

    render () {

        const { classes } = this.props;
        
        return (

                <form onSubmit={this.handleSubmit}>

                    <div style={{marginBottom: 12, textTransform: 'uppercase', color: 'black'}} className="basier-p3">
                        New card details
                    </div>
                    <CardElement className="MyCardElement" style={style} />
                
                {this.props.setupOnly === true ? 
                null 
                :
                <div>
                    <div style={{marginTop: 24, paddingLeft: 12, display: 'flex', alignItems: 'flex-start'}}>
                        <Checkbox onClick={this.handleCheck} checked={this.state.checkedConsent} color="primary" className={classes.checkBoxRoot} />

                        <div className="basier-p4" style={{paddingLeft: 12}}>I agree with the <a href="#">Terms and Conditions</a> of Wisly UK Limited, and to being charged for the amount of the subscription that I have selected and at the frequency that I have selected.</div>
                    </div>
                    <button className='button-blue opacity-hover' style={{height: 40, borderRadius: 35, marginTop: 24, cursor: 'pointer'}}>Submit</button>
                </div>  
                }
            </form>

            
        )
    }


}

const SplitForm = injectStripe(PaymentForm);

export default (withStyles(style)(SplitForm))
