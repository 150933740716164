import React from "react";
import Grid from '@material-ui/core/Grid';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TopBar from "./TopBar";
import Modal from '../Modals/ModalBase'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { CSSTransitionGroup } from 'react-transition-group';
import NotifContent from '../AppWide/NotificationContainer'
import MenuContent from '../AppWide/UserMenuCointainer'
import Footer from './LayoutComponents/Footer'
import Hidden from '@material-ui/core/Hidden';
import {PullToRefresh} from "react-js-pull-to-refresh";
import {PullDownContent, ReleaseContent} from "react-js-pull-to-refresh";
import TaskViewer from "../AppWide/TaskViewer";

const PullRefresh = (
  <div style={{height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 12}}>
      <div className="ui-loading" style={{height: 30, width: 30, marginRight: 12}}></div>
      <div className="basier-p3">Loading...</div>
  </div>
)

class CustomLayout extends React.Component {

  state = {
    openMenu: false, 
    notifs: false,
    bk: 'white'
  }

  componentDidMount() {
      
    if ( ( (this.props.currentView === 99) || (this.props.currentView === 1) )  && this.props.user_id !== null) {
      document.body.style.backgroundColor = '#F8F9FA'
    } else {
      document.body.style.backgroundColor = 'white'
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.currentView !== this.props.currentView) {
          if (( (this.props.currentView === 99) || (this.props.currentView === 1) ) && this.props.user_id !== null) {
            document.body.style.backgroundColor = '#F8F9FA'
          } else {
            document.body.style.backgroundColor = 'white'
          }
      }
  }

  goToSettings = () => {
    this.props.history.push('/settings')
  }

  toggleMenu = () => {
    const open = !this.state.openMenu
    this.setState(state => ({...state, openMenu: open}))
  }

  toClose = () => {
    this.setState(state => ({...state, openMenu: false}))
  }

  handleNotifToggle = () => {
    this.setState(state => ({ notifs: !state.notifs }))
  }

  handleNotifClose = () => {

    setTimeout(() => { this.setState(state => ({ notifs: false })) }, 10)

  };

  handleClose = () => {
    
    setTimeout(() => { this.toClose() }, 10)
    
  }

  

  styles = {
    'header': {
      'backgroundColor': 'white',
      'padding': '0 0px',
      'lineHeight': '0px',
    }
  }


  render() {
    return (
      <div>
        <Grid container spacing={0}>
          
          {[971, 972, 51].includes(parseInt(this.props.currentView)) ?
          null
          :
          <TopBar location={this.props.location.pathname} toggleMenu={this.toggleMenu} toggleNotif={this.handleNotifToggle} notif={this.state.notifs}/>
          }        
          
          <Hidden smDown>
          <CSSTransitionGroup
              transitionName="example"
              transitionEnterTimeout={100}
              transitionLeaveTimeout={100}>

              {
                this.state.openMenu ?          
                <ClickAwayListener onClickAway={() => this.handleClose()}>

                  <div className='menu-name' >
                    <div class="arrow-up">
                    </div>
                    <MenuContent handleClose={this.handleClose} />

                  </div>

                </ClickAwayListener>
              
                :

                null 

              }

              {
                this.state.notifs ?          
                <ClickAwayListener onClickAway={() => this.handleNotifClose()}>

                  <div className='menu-notif' >
                    <div class="arrow-up">
                    </div>
                    <NotifContent handleClose={this.handleNotifClose} />

                  </div>

                </ClickAwayListener>
              
                :

                null 

              }

            </CSSTransitionGroup>
            </Hidden>

           
        </Grid>
          
        <Hidden mdUp>
          {[1,2,3,4,6,7,97].includes(parseInt(this.props.currentView)) ? 
                    <PullToRefresh
                    pullDownContent={<PullDownContent />}
                    releaseContent={<ReleaseContent />}
                    refreshContent={PullRefresh}
                    pullDownThreshold={75}
                    onRefresh={this.props.onRefresh}
                    triggerHeight={250}
                    backgroundColor='white'
                    startInvisible={true}
                    >

                      <Grid container spacing={0} className="main-content">
                          
                        <Grid style={{width: '100%'}} item> 
                            {this.props.children}
                          <Hidden smDown>
                            <Footer bk={this.state.bk} />
                          </Hidden>

                          {
                              
                                (this.props.active == null || this.props.active == undefined) ? 

                                null 

                                : 

                                this.props.active.subscription_status == 0 && (this.props.active.plan.plan_id !== 'free' &&  this.props.active.plan.plan_id !== 'unlimited') ?

                                <div onClick={this.goToSettings} style={{width: '100%', display: 'flex', position: 'fixed', bottom: 0, alignItems: 'center', height: 66, marginTop: 12, color: 'black', backgroundColor: '#f5b1ae', zIndex: 12000, paddingLeft: 12, paddingRight: 12}}>
                                  There was an issue with your account payment or your plan is expired. Please go to settings or contact support if you think it is a mistake. 
                                </div>
                                
                                :

                                (this.props.limits !== undefined && this.props.limits !== null) ? 
                                
                                (this.props.limits.investment_limit != -1 && this.props.limits.investment>this.props.limits.investment_limit) ||
                                (this.props.limits.transaction_limit != -1 && this.props.limits.transactions>this.props.limits.transaction_limit) ||
                                (this.props.limits.portfolio_limit != -1 && this.props.limits.portfolios>this.props.limits.portfolio_limit) ? 
                                  <div onClick={this.goToSettings} style={{width: '100%', display: 'flex', position: 'fixed', bottom: 0, alignItems: 'center', height: 66, marginTop: 12, color: 'black', backgroundColor: '#f5b1ae', zIndex: 12000, paddingLeft: 12, paddingRight: 12}}>
                                  Your account limits have been exceeded. This can lead to incorrect data and results. We recommend you upgrade your plan 
                                  </div>
                                :null
                                :null
                          }


                         
                        </Grid>
                        
                      </Grid>
                      
                    </PullToRefresh>

                    :

                    <Grid container spacing={0} className="main-content">
              
                      <Grid style={{width: '100%'}} item> 
                          {this.props.children}
                        <Hidden smDown>
                          <Footer bk={this.state.bk} />
                        </Hidden>
                      </Grid>
                      
                    </Grid>
            }
          </Hidden>

          <Hidden smDown>

              <Grid container spacing={0} className="main-content">
              
              <Grid style={{width: '100%'}} item> 
                  {this.props.children}
                  <TaskViewer />
                  <Footer bk={this.state.bk} />
              </Grid>
            </Grid>
          </Hidden>

        

        <Modal />
      </div>
    );
  }
}

export default withRouter(CustomLayout);