import React from 'react';
import { connect } from 'react-redux';
import TransactionWrapper from './TransactionWrapper'
class TransactionSelect extends React.PureComponent {

    state = {
        currentView: 'transaction'
    }
    render () {

        return (

            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>

                <div style={{width: '100%', minWidth: 960, maxWidth: 1440, paddingRight: 24, paddingLeft: 24}}>

                    <div className="basier-p4-caps" style={{width: '100', height: 20, display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        {

                        ['transaction'].map((val, key) => {
                            return (
                                <React.Fragment>
                                <div onClick={() => this.setState({currentView: val})} style={{color: this.state.currentView == val ? '#0078DB' : '#6A7E93', fontWeight: this.state.currentView == val ? 900 : 400, cursor: 'pointer'}}>
                                    {`${val} View`}
                                </div>
                                {key !== 0 && <div style={{marginLeft: 12, marginRight: 12}}>|</div>}
                                </React.Fragment>
                                
                            )
                        })

                        }
                    </div>

                    {this.state.currentView === 'transaction' && <div><TransactionWrapper portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} /></div> }

                     
                </div>

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(TransactionSelect)
