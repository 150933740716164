import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  root: {
    //width: 400,
    backgroundColor: 'white',
    display: 'flex', 
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  tabsRoot: {
    //width: 400,
    backgroundColor: 'white',
    display: 'flex', 
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 0,
    marginBottom: 12, 
  },
  tabsIndicator: {
    display: 'none',
  },
  tabRoot: {
    color: '#6A7E93',
    textTransform: 'initial',
    minWidth: 100,
    fontSize: '1rem',
    fontWeight: 400,
    marginRight: 6,
    marginLeft: 6,
    padding: 0,
    fontFamily: [
      'Basier Circle',
    ].join(','),
    '&:hover': {
      color: '#0078DB',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#0078DB',
      fontWeight: 'bold',
    },
    '&:focus': {
      color: '#0078DB',
    },
  },
  tabCointainer: {
      padding: 0,

  },
  tabScroller: {
  },
  tabSelected: {},
  typography: {
    padding: 0,
  },
});

class CustomizedTabs extends React.Component {
  
  render() {
    const { classes, handleChange } = this.props;

    return (
      <div className={`${classes.root}`}>

            <Tabs
            value={this.props.value}
            onChange={handleChange}
            scrollButtons="auto"
            classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
            className="scrollable-tabs"
            >
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer  }}
                label="Asset List"
                value={0}
            />
            <div style={{display: 'flex', alignItems: 'center', fontSize: 16}}>|</div>
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
                label="Positions List"
                value={1}
            />
            </Tabs>
            

      </div>
    );
  }
}

CustomizedTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTabs);