import React from 'react';
import NoPortfolio from '../../../Media/NoPortfolio.svg'
import Hidden from '@material-ui/core/Hidden';


const noPortfolio = (props) => {

    return (
        <React.Fragment>
            <Hidden smDown>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 0, paddingTop: 90, paddingBottom: 90, backgroundColor: '#F8F9FA'}}>
                
                <div style={{marginRight: 48, marginTop: 48, width: 400}}>
                    <h1>Welcome!</h1>
                    <h3>It looks like you don't have a portfolio yet. Let's get you started.</h3>

                    <div className='button-next' style={{height: 60, width: 320, fontSize: 18, borderRadius: 35, marginTop: 48, marginLeft: 12, marginRight: 12, cursor: 'pointer'}} onClick={props.newPortModal}>
                    Create first portfolio
                    </div>

                </div>
                    <div style={{display: 'block'}}>
                        <img src={NoPortfolio} alt=""/>
                    </div>

            </div>
            </Hidden>

            <Hidden mdUp>
            <div style={{width: '100%', textAlign: 'center', padding: 24, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, backgroundColor: '#F8F9FA', overflowX: 'hidden'}}>

                    
                    <h2>Welcome!</h2>
                    <div className="basier-p2">It looks like you don't have a portfolio yet. Let's get you started.</div>

                    <div className='button-next' style={{height: 60, width: 200, fontSize: 18, borderRadius: 35, marginTop: 48, marginLeft: 12, marginRight: 12, cursor: 'pointer'}} onClick={props.newPortModal}>
                    Create first portfolio
                    </div>

                    <div style={{display: 'block', marginTop: 24,}}>
                        <img src={NoPortfolio} alt="" />
                    </div>


                

            </div>
            </Hidden>
        </React.Fragment>

    )

}

export default noPortfolio