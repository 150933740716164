import React from 'react';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CalendarImg from '../../../../Media/calendar.svg'
import MultiSelect from '../../../AppWide/CoinMultiSelect';
import MutiFilter from '../../../AppWide/SearchableFilter/SFWrapper';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";


class TransactionFilter extends React.PureComponent {

    state = {
        sort: 'desc', 
        dates: [{
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }], 
        showDates: false,
        datesFiltered: false, 
    }

    toggleDates = () => {

        this.setState(state => ({...state, showDates: !state.showDates}))

    }

    componentDidMount() {
        if (this.props.filterValues['startDate'] !== undefined && this.props.filterValues['endDate']) {
            this.setState(state => ({...state, dates: [{
                startDate: this.props.filterValues['startDate'],
                endDate:  new Date(),
                key: 'selection'
                }]
            }))
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.filterValues['startDate'] !== prevProps.filterValues['startDate'] && this.props.filterValues['startDate'] !== undefined && this.props.filterValues['endDate']) {
            //const daysRemove = Math.ceil(Math.abs(new Date() - this.props.filterValues['startDate']) / (1000 * 60 * 60 * 24))
            this.setState(state => ({...state, dates: [{
                startDate: this.props.filterValues['startDate'],
                endDate:  new Date(),
                key: 'selection'
                }]
            }))
        }
    }

    toClose = () => {

        this.setState(state => ({...state, showDates: false}))

    }

    handleClose = () => {
    
        setTimeout(() => { this.toClose() }, 10)
        
      }

    handleSortChange = (e) => {
        e.persist();
        const val = e.target.value
        this.setState(state => ({...state, sort: val}))
        this.props.sort(val)
    }

    setDate = (data) => {
        console.log(data)
        const start = data[0]['startDate']
        let end = data[0]['endDate']
        //end = addDays(start, 1)
        
        this.setState(state => ({...state, dates: data}))
        this.props.filterData('date', {start: start, end: end})
        this.props.resetPage()

        if (start != this.props.filterValues['startDate'] || end != this.props.filterValues['endDate'] ) { 
            this.setState(state => ({...state, datesFiltered: true}))
        } else {
            this.setState(state => ({...state, datesFiltered: false}))
        }
    }

    clearDates = () => {
        this.setState(state => ({...state, dates: [{
            startDate: this.props.filterValues['startDate'],
            endDate: new Date(),
            key: 'selection'
            }], datesFiltered: false,
        }))
        this.props.filterData('date', 'all')
        this.props.resetPage()

    }

    render () {
        const defaultStyle={
            height: 32, 
            width: 120,
            cursor: 'pointer',
            display: 'flex', 
            alignItems: 'flex-end',
            paddingBottom: 4, 
            paddingLeft: 3,
            justifyContent: 'space-between',
            backgroundColor: this.state.showDates ? this.props.background !== undefined ? this.props.background : '#E1E5E9' : this.props.openBackground !== undefined ? this.props.openBackground : 'transparent',
            borderBottom: '1px solid rgba(0,0,0, 0.4)',
        }

        return (

            <div style={{marginBottom: 12, padding: 24, backgroundColor: '#F8F9FA' }}>
                <div style={{width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>

                    <div style={{width: 120}}>
                        <div className="basier-p4-caps" style={{marginBottom: 6}}>Sort by</div>
                        <div >
                            <Select
                                value={this.state.sort}
                                onChange={this.handleSortChange}
                            >
                                <MenuItem value='desc'>Newest First</MenuItem>
                                <MenuItem value='asc'>Oldest first</MenuItem>
                            </Select>
                        </div>
                    </div>

                    <div style={{width: 120,}}>
                        <div className="basier-p4-caps" style={{marginBottom: 6}}>Type</div>
                        <div style={{position: 'absolute'}} > 
                            <MutiFilter values={this.props.filterValues['transaction_description']} filters={this.props.filters['transaction_description']} resetPage={this.props.resetPage} filterData={(d) => this.props.filterData('transaction_description', d)} />
                        </div>
                    </div>


                    <div style={{width: 120}}>
                        <div className="basier-p4-caps" style={{marginBottom: 6}}>Base Coin</div>
                        <div style={{position: 'absolute'}}>
                            <MutiFilter values={this.props.filterValues['base_id']} filters={this.props.filters['base_id']} resetPage={this.props.resetPage} filterData={(d) => this.props.filterData('base_id', d)} />
                        </div>
                    </div>

                    <div style={{width: 120}}>
                        <div className="basier-p4-caps" style={{marginBottom: 6}}>Quote Coin</div>
                        <div style={{position: 'absolute'}}>
                            <MutiFilter values={this.props.filterValues['quote_id']} filters={this.props.filters['quote_id']} resetPage={this.props.resetPage} filterData={(d) => this.props.filterData('quote_id', d)} />
                        </div>
                    </div>

                    <div style={{width: 120}}>
                        <div className="basier-p4-caps" style={{marginBottom: 6}}>Dates</div>
                        <div style={{position: 'absolute'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img className="opacity-hover" onClick={this.toggleDates} src={CalendarImg} alt="" style={{height: 30, width: 30, marginRight: 12, cursor: 'pointer'}} />
                            {!this.state.datesFiltered ? 
                                <div>Filter Dates</div>
                            :
                                <div onClick={this.clearDates} style={{color: '#0078DB', cursor: 'pointer'}} className="opacity-hover">Clear Dates</div>
                            }
                        </div>
                            
                            {this.state.showDates &&    
                            <ClickAwayListener onClickAway={() => this.handleClose()}>
                            <div style={{backgroundColor: 'white', marginTop: 20, padding: 0, border: '1px solid #CCC', position: 'relative', left: '-70%'}}>
                                <DateRangePicker
                                    onChange={item => this.setDate([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    //months={2}
                                    ranges={this.state.dates}
                                    scroll={{enabled: true}}
                                    //direction="horizontal"
                                    shownDate={new Date()}
                                    />
                            </div>
                            </ClickAwayListener>
                            }
                            
                        </div>
                    </div>

                    <div style={{width: 120}}>
                        <div className="basier-p4-caps" style={{marginBottom: 6}}>User</div>
                        <div style={{position: 'absolute'}}>
                            <MutiFilter values={this.props.filterValues['user_id']} resetPage={this.props.resetPage} filters={this.props.filters['transaction_description']} rightOpen={true} filterData={(d) => this.props.filterData('user_id', d)} />
                        </div>
                    </div>



        
                </div>

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(TransactionFilter)
