import React from 'react'
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { connect } from 'react-redux';
import currencyDict from '../../../helpers/currency.json'


const GlobalAssetList = (props) => {
    
    const data = props.globalAssetList

    return (

        <div>

             {data !==  undefined ? 
                
                
                <div>
            
                    <Grid container
                        spacing={0}
                        alignItems="baseline"
                        style={{paddingBottom: 0}}>

                        <Grid item xl={4} lg={4} md={4} style={{textAlign: 'left'}}>
                            <h3 style={{paddingBottom: 0, marginBottom: 0}}>Watchlist</h3>
                        </Grid>


                        <Grid item xl={4} lg={4} md={4} style={{textAlign: 'right', paddingRight: 5}}>
                            <span className="basier-p4-caps">Price</span>
                        </Grid>


                        <Grid item xl={4} lg={4} md={4} style={{textAlign: 'right', paddingRight: 30}}>
                            <span className="basier-p4-caps">Change 24h</span>
                        </Grid>

                    </Grid>

                    { data.slice(0,5).map((coin, index) => 
                        
                    <Grid container
                        spacing={0}
                        alignItems="center"
                        key={index}
                        className='coin-list-container'>
                    
                    { coin.change > 0 ? <div className='green-container'></div> : <div className='red-container'></div> }
                    
                    <Grid item xl={4} lg={4} md={4} style={{textAlign: 'left', zIndex: 1000, display:'flex', alignItems: 'center'}}>

                        {   
                            coin.image_thumb ? 
                                <span style={{paddingLeft: 15, paddingRight: 15}}><img src={coin.image_thumb} alt="" style={{height: 30, width: 30, border: 'none'}} /></span>
                            :
                                <span style={{paddingLeft: 15, paddingRight: 15}}><div style={{height: 30, width: 30, border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24}}>
                                    {currencyDict[coin.coin_symbol] === undefined ? null : currencyDict[coin.coin_symbol]['symbol'].length > 1 ? null : currencyDict[coin.coin_symbol]['symbol'] }
                                    </div></span>
                        }
                        
                        <span>
                            <div className="basier-p3">
                                {coin.symbol}
                            </div >

                            <Hidden mdDown>
                            <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                                {coin.short_name}
                            </div>
                            </Hidden>
                            
                            </span>
                    </Grid>
                    
                    <Grid item xl={4} lg={4} md={4} style={{textAlign: 'right', paddingRight: 5, zIndex: 1000}}>
                        <span className="basier-p3">
                        {props.currency_symbol} &nbsp;
                        {coin.price > 1 ? coin.price.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2}) : coin.price.toLocaleString('en', {minimumFractionDigits:6, maximumFractionDigits: 6})}
                        </span>
                    </Grid>
                    

                    <Grid item xl={4} lg={4} md={4} style={{textAlign: 'right', paddingRight: 30, zIndex: 1000}}>
                        <span className="basier-p3">
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <div 
                                style={{
                                    color: 'white',
                                    padding: '6px 12px',
                                    fontWeight: 900, 
                                    backgroundColor: coin.change < 0 ? '#F9716B' : '#4CDAC1',
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center',
                                    borderRadius: 20,
                                    width: 72,
                                }}
                                >
                            {
                            coin.change.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                            }%
                            </div>
                            </div>
                        </span>
                    </Grid>
                
                </Grid>


                        )}

                </div>
                
                
                : null}
        </div>
    
       
    )
}


const mapStateToProps = state => {
    return {
        globalAssetList: state.data.subscriptions,
        currency_symbol: state.auth.currency.symbol,
    };
};

export default connect(
    mapStateToProps,
    null
)(GlobalAssetList);
