import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CoinSmallGraph from '../../../../AppWide/CoinSmallGraph' 
import worker from "../../../../../workers/assetWorker";
import WebWorker from "../../../../../workers/WebWorker";
import BackIcon from "../../../../../Media/BackIcon.svg"
import { backendUrl } from '../../../../../variables'
import * as actions from "../../../../../store/actions/data";

class assetModalContent extends React.PureComponent {

    state = {
        open: false,
        coin_in_scope: null, 
        graph_data: [], 
        graph_start: null,
    }

    componentDidMount() {

        this.props.setCurrentView(999)

        console.log(this.props.location.state)
        fetch(`${backendUrl}/v1/coin_history/?coin=${this.props.location.state.coinId}&period=3m`)
            .then(res => res.json())
            .then(json => {
                console.log(json['start_date'])
                this.setState(state => ({...state, 
                    graph_data: json['data'], 
                    graph_start: json['start_date']}))
            })
    
        this.worker = new WebWorker(worker);
    
        this.worker.addEventListener('message', event => {
            const coin_in_scope = event.data 
    
            this.setState(state => ({...state, 
                                    coin_in_scope: coin_in_scope,
                                    }));
    
        });
    
        this.worker.postMessage([this.props.location.state.portfoliosData[this.props.location.state.portfolioId].balances, this.props.location.state.coinId])
    
      }
      
    
    render () {

        return (

            this.state.coin_in_scope === null ? 
            null 
            : 

            <div style={{position: 'absolute', top: 0, zIndex: 999999, width: '100%'}}>

                <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed'}} id="responsive-dialog-title">
                <span onClick={() => this.props.history.goBack()}>
                    <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                </span>
                <span style={{marginRight: 12, marginLeft: 24}}>
                    <img src={this.state.coin_in_scope.image_large} style={{height: 48, width: 48}} alt=""/>
                </span>
                <span>
                    <span className="basier" style={{display: 'inline-flex', fontSize: '1.4rem'}}>
                    {this.state.coin_in_scope.coin_name}  
                    </span>
                </span>
                </div>
    
                <div style={{height: 550, marginTop: 75, backgroundColor: 'white', paddingTop: 4, width: '100%'}}>
    
                        <div style={{marginTop: 24, marginBottom: 24, paddingLeft: 24}}>
                            <div className="basier-p4-caps">Current Price</div>
                            <h2>
                            {this.state.currency_symbol}&nbsp;
                            {this.state.coin_in_scope.current_price > 1 ? 
                            this.state.coin_in_scope.current_price.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                            : 
                            this.state.coin_in_scope.current_price.toLocaleString('en', {minimumFractionDigits:6, maximumFractionDigits: 6}) }
                            </h2>
                        </div>
    
                        <div style={{height: 200, width:'100%', marginTop: 24, marginBottom: 24}}>
                            <CoinSmallGraph history_data={this.state.graph_data} start_date={this.state.graph_start} />
                        </div>
    
                        <Grid   container 
                                spacing={0}
                                alignItems="center"
                                style={{paddingLeft: 24, paddingRight: 24, textAlign: 'left'}}
                                >
                                
                            <Grid>
    
                                <div>   
                                    <div className="basier-p4-caps">Current Value</div>
                                    <h2>{this.state.currency_symbol} {this.state.coin_in_scope.total_value.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</h2>
                                </div>
    
                                <div>
                                    <div className="basier-p4-caps">Realized Profit</div>
                                    <h2>{this.state.currency_symbol} {this.state.coin_in_scope.realized_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</h2>
                                </div>
    
                                <div>   
                                    <div className="basier-p4-caps">Number of transactions</div>
                                    <h2>{this.state.coin_in_scope.transactions.toLocaleString('en', {minimumFractionDigits:0, maximumFractionDigits: 0})}</h2>
                                </div>
    
                                <div>
                                    <div className="basier-p4-caps">Unrealized Profit</div>
                                    <h2>{this.state.currency_symbol} {this.state.coin_in_scope.active_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</h2>
                                </div>

                                <div>   
                                    <div className="basier-p4-caps">Total Profit</div>
                                    <h2>{this.state.currency_symbol} {this.state.coin_in_scope.total_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</h2>
                                </div>
    
                                <div>
                                    <div className="basier-p4-caps">Breakeven Price</div>
                                    <h2>{this.state.currency_symbol} {this.state.coin_in_scope.purchase_price.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</h2>
                                </div>
                                
                            </Grid>
    
                        </Grid>
                            
                </div>
            </div>
    
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,

        
    };
};

const mapDispatchToProps = dispatch => {
    return {

        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),

    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(assetModalContent);
