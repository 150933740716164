import React from 'react';
import { connect } from 'react-redux';
import BackIcon from "../../../../Media/BackIcon.svg";
import axios from 'axios';
import * as appwideActions from "../../../../store/actions/appwide";
import { backendUrl } from '../../../../variables'
import fieldValidator from '../../../../helpers/validators'
import loadingSpinner from '../../../../Media/loadingSpinner.gif'
import successIcon from '../../../../Media/okIcon.svg'
import errorIcon from '../../../../Media/errorIcon.svg'
import * as dataActions from "../../../../store/actions/data";


class assetModalContent extends React.PureComponent {

    state = {
        open: false,
        coin_in_scope: null, 
        graph_data: [], 
        graph_start: null,
        edit: false,
        wallet_id: this.props.location.state.wallet_id,
        exchange_id: this.props.location.state.exchange_id,
        edit_id: this.props.location.state.data.id,
        data: this.props.location.state.data,
        source: this.props.location.state.source,
        editData: {},
        editNameError: false,
        loading: false, 
        error: false, 
        success: false, 
    }

    componentDidMount() {

        this.props.setCurrentView(972)
        if (this.props.wallets !== undefined && this.props.wallets !== null ) {
            if (this.state.source !== null && this.state.source !== undefined) {

                const idGet = this.props.location.state.wallet_id
                const ex_id = this.props.location.state.exchange_id
                let newData = this.props.location.state.data
                try { newData = this.props.wallets[this.props.user_id]['exchange'][ex_id][this.state.data.exchange_id]['wallets'][idGet] } catch(e) {console.log('error load')}
                this.setState(state => ({...state, data: newData}))


            } 
            else {
                const idGet = this.props.location.state.wallet_id
                let newData = this.props.location.state.data
                try { newData = this.props.wallets[this.props.user_id]['storage'][newData.wallet_coin].filter(v => v.id == idGet)[0] } catch(e) {console.log('error load')}
                this.setState(state => ({...state, data: newData}))
            }
        } 

        
      }

      componentDidUpdate(prevProps) {

        if (prevProps.wallets !== this.props.wallets && this.props.wallets !== undefined) {

            if (this.state.source !== null && this.state.source !== undefined) {

                const idGet = this.props.location.state.wallet_id
                const ex_id = this.props.location.state.exchange_id
                let newData = this.props.location.state.data
                try { newData = this.props.wallets[this.props.user_id]['exchange'][ex_id][this.state.data.exchange_id]['wallets'][idGet] } catch(e) {console.log('error load')}
                this.setState(state => ({...state, data: newData}))

                
            } 
            else {
                const idGet = this.props.location.state.wallet_id
                let newData = this.props.location.state.data
                try { newData = this.props.wallets[this.props.user_id]['storage'][newData.wallet_coin].filter(v => v.id == idGet)[0] } catch(e) {console.log('error load')}
                this.setState(state => ({...state, data: newData}))
            }

        }

        
      }
    

    getData = (transaction_id) => {
    
    }

    editHandler = () => {
        this.setState(state => ({...state, edit: true}))
    }

    deleteHandler = () => {
        if (window.confirm("Are you sure you want to delete this wallet")) {
            this.deleteTransaction()
          } 
    }

    deleteTransaction = () => {

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/wallets/${this.props.location.state.wallet_id}`

        axios.delete(url_call, {headers: headers})
            .then(json => {

                // load new wallet list
                this.props.getWallets(this.props.user_id)
                this.props.history.goBack()
                //console.log(json['data'])

            })


    }

    handleEditData = (e, label) => {
        e.persist();
        this.setState(state => ({...state, editData: {...state.editData, [label]: e.target.value}}))
    }

    cancelEdit = () => {
        this.setState(state => ({...state, edit: false, success: false, error: false, loading: false, editData: {}}))
    }
    
    backHandler = () => {
        this.props.backAction()
    }

    updateEditData = () => {

        if (Object.keys(this.state.editData).length > 0) {

            let wal_id = null;
            if (this.state.source !== null && this.state.source !== undefined) {
                wal_id = this.state.edit_id
            } else {
                wal_id = this.state.wallet_id
            }
            let editData = {
                ...this.state.editData, 
                user: this.props.user_id, 
                id: wal_id
            }
            
            const exchange_val = true

            if (exchange_val) {
                
                this.setState(state => ({...state, loading: true}))

                let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {

                    headers['Authorization'] = `Token ${this.props.token}`

                }

                const url_call = `${backendUrl}/v1/wallets/`

                axios.patch(url_call, editData, {headers: headers})
                    .then(json => {

                    this.props.getWallets(this.props.user_id)
                    this.setState(state => ({...state, loading: false, success: true}))
                        
                    setTimeout(() => {

                        this.cancelEdit()
                        
                    }, 600)
                        
                    })
                    .catch( e => {

                    this.setState(state => ({...state, loading: false, error: true}))

                    })
            
            }
        } else {
            this.cancelEdit()
        }

        
    
    }
    
    render () {
         
        const { item } = this.state;

        return ( 
         
            
            <div style={{position: 'absolute', top: 0, zIndex: 999999, width: '100%'}}>

                <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed', justifyContent: 'space-between'}} id="responsive-dialog-title">
                    
                        <div onClick={() => this.props.history.goBack()}>
                            <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                        </div>

                        <div>
                            <div className="touchableOpacity basier"  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '1.2rem'}}>

                            {this.state.edit ? 
                                
                                <React.Fragment>
                                    <div className="modalActionButton touchableOpacity"  onClick={this.updateEditData} style={{marginRight: 24, color: '#0078DB'}}>Save</div>
                                </React.Fragment>

                                :

                                    <React.Fragment>
                                    
                                    <div className="modalActionButton"  onClick={this.deleteHandler} style={{marginRight: 48, color: '#F9716B'}}>Delete</div>

                                    <div className="modalActionButton"  onClick={this.editHandler} style={{marginRight: 24, color: '#0078DB'}}>Edit</div>

                                 </React.Fragment>
                            }
                            </div> 
                        </div>

                    </div>



                <div style={{position: 'absolute', top: 75, zIndex: 120000, backgroundColor: 'white', minHeight: 100, width: '100%'}}>

                {this.state.edit ? 

                    this.state.loading ?

                    <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={loadingSpinner} alt="" style={{height: 50, width: 50, marginBottom: 12}} />
                        <div className="basier-p3">Editing Account...</div>
                    </div>

                    :

                    this.state.success ? 

                    <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={successIcon} alt="" style={{height: 50, width: 50, marginBottom: 12}} />
                        <div className="basier-p3">Successfully edited account!</div>
                    </div>

                    :

                    this.state.error ? 

                    <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={errorIcon} alt="" style={{height: 50, width: 50, marginBottom: 12}} />
                        <div className="basier-p3">Oops! An error occured, please try again.</div>

                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}} >
                                <div
                                    onClick={() => this.cancelEdit()}
                                    className='button-next'
                                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer', backgroundColor: '#F9716B', color: 'white'}}
                                    >
                                    Cancel
                                </div>

                                <div
                                    onClick={this.updateEditData}
                                    className='button-next-blue'
                                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                    >
                                    Retry
                                </div> 
                            </div>

                    </div>


                    :

                    <div style={{padding: 24}}>

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: 36, height: 36, marginRight: 12}}>
                                    { this.state.data.coin_image !== null && this.state.data.coin_image !== undefined ?
                                        <img src={this.state.data.coin_image} alt='' style={{width: 36, height: 36}} />
                                    :
                                        null
                                    }
                                </div>
                                <div>
                                    <h3 style={{margin:0}}>
                                        {this.state.data.coin_name}
                                    </h3>
                                    <div className="basier-p4-caps" style={{paddingLeft: 4}}>
                                        {this.state.source !== null && this.state.source !== undefined ? 
                                        
                                            this.state.source

                                        :
                                            null
                                        }
                                    </div>
                                </div>    
                            </div>
                        </div>
                        
                        {
                        this.state.source !== undefined && this.state.source !== null ?
                        
                        null : 

                        <React.Fragment>
                            <div style={{marginBottom: 12, marginTop: 24}} className="basier-p4-caps">Edit Wallet Name</div>
                            <div style={{marginTop: 6}} className="basier-p2">
                                <input value={this.state.editData.name || ""} onChange={(e) => this.handleEditData(e, 'name')} style={{width: '100%', textAlign: 'left',  height: 50, border: this.state.editNameError ? '2px solid red' : '1px solid #E1E5E9', paddingLeft: 12}} className="no-outline" />
                            </div>
                        </React.Fragment>

                        }

                        <div style={{marginBottom: 12, marginTop: 24}} className="basier-p4-caps">Edit Wallet Address</div>
                        <div style={{marginTop: 6}} className="basier-p2">
                            <input value={this.state.editData.address || ""} onChange={(e) => this.handleEditData(e, 'address')} style={{width: '100%', textAlign: 'left',  height: 50, border: '1px solid #E1E5E9', paddingLeft: 12}} className="no-outline" />
                        </div>

                    </div>

                    :
                    <div style={{padding: 24}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{width: 36, height: 36, marginRight: 12}}>
                                        { this.state.data.coin_image !== null && this.state.data.coin_image !== undefined ?
                                            <img src={this.state.data.coin_image} alt='' style={{width: 36, height: 36}} />
                                        :
                                            null
                                        }
                                    </div>
                                    <div>
                                        <h3 style={{margin:0}}>
                                            {this.state.data.coin_name}
                                        </h3>
                                        <div className="basier-p4-caps" style={{paddingLeft: 4}}>
                                            {this.state.source !== null && this.state.source !== undefined ? 
                                            
                                                this.state.source

                                            :
                                                
                                                null

                                            }
                                        </div>
                                    </div>    
                            </div>
                            
                            {
                            this.state.source !== undefined && this.state.source !== null ?
                            
                            null : 

                            <div style={{marginTop: 24}}>
                                <div className="basier-p4-caps" style={{marginBottom: 4}}>
                                    Wallet name 
                                </div>
                                <h3 style={{textTransform: 'capitalize', margin: 0}}>
                                    {this.state.data.name}
                                </h3>

                            </div>
                            }


                            <div style={{marginTop: 12}}>
                                <div className="basier-p4-caps" style={{marginBottom: 4}}>
                                    Wallet address 
                                </div>
                                <div className="basier-p2" style={{textTransform: 'capitalize', margin: 0}}>
                                    {this.state.data.address !== null && this.state.data.address !== undefined && this.state.data.address !== '' ? 
                                        this.state.data.address
                                    :
                                        'No address provided'
                                    }
                                </div>

                            </div>
                            
                    </div>
                }
                </div>
                

            </div>
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        currency_id: state.auth.currency.id,
        inBitcoin: state.data.bitcoinPf, 
        user_id: state.auth.user_id, 
        token: state.auth.token,
        wallets: state.appwide.wallets

        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        setCurrentView: () => dispatch(dataActions.setCurrentView())
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(assetModalContent);
