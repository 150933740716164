import React from 'react';
import { connect } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CSVLink, CSVDownload } from "react-csv";

const timeoutLength = 100
const headers = [
    { label: 'User', key: 'user' },
    { label: 'Date', key: 'date' },
    { label: 'Type', key: 'type' },
    { label: 'Base', key: 'base_coin' },
    { label: 'Amount', key: 'amount' },
    { label: 'Price', key: 'unit_price' },
    { label: 'Quote', key: 'quote_coin' },
    { label: 'Fee', key: 'fee' },
    { label: 'Fee_Currency', key: 'fee_curr' },
    { label: 'Comments', key: 'comments' },
    { label: 'Account', key: 'account' },
    { label: 'Destination_User', key: 'dest_user' },
    { label: 'Destination_Account', key: 'dest_account' },
  ];

class SelectAction extends React.PureComponent {

    state = {
        open: false, 
        onMenu: false, 
        exportData: 'no data', 
    }

    updateState = (data, done) => {
         this.setState({
           exportData: data
         },() => { done() })
       }

    exportData = (scope, done) => {
        this.handleClose();

        console.log('here')

            let data = null 
            
            if (scope === 'all') {
                console.log('all')
                data = [...this.props.allData]
            } else if (scope === 'filtered') {
                console.log('filter')

                data = [...this.props.filteredData]
            } else if (scope == 'selected') {
                console.log('select', Object.keys(this.props.selected))

                data = [...this.props.allData.filter(v => Object.keys(this.props.selected).includes(`${v.id}`))]
                console.log('select data', data)
            } else {
                console.log('lala')
                return 
            }
            let exportData = []
            data.map(v => {
                let tempData = {
                    "user": v.user_email, 
                    "date": v['date'], 
                    "base_coin": `${v['base']} (${v['base_ticker']})`, 
                    "quote_coin": ['deposit', 'withdraw', 'transfer'].includes(v.transaction_type) || (v.transfer_match !== null) ? '' : `${v['quote']} (${v['quote_ticker']})`, 
                    "type": v['transaction_description'], 
                    "amount": v.amount, 
                    "unit_price": v.unit_price,//['deposit', 'withdraw', 'transfer'].includes(v.transaction_type) ? '' : v.unit_price, 
                    "fee": v.fee, 
                    "fee_curr": v.fee_curr, 
                    "comments": v.comments, 
                    "account": v.account_exchange !== null ? v.account_exchange : v.storage_name,
                    "dest_user": v.destination_user, 
                    "dest_account": v.destination_type === 'storage' ? v.destination_name : v.destination_account
                }
                exportData.push(tempData)
            })

            console.log('exp', exportData)
            this.updateState(exportData, done)

        
        
    }

    handleHoverOn = () => {
        this.setState(state => ({ open: true })); 
      };
      
      emptyMenu = () => {
            this.props.setMenuPortfolio(null)
            this.props.setMenuPath(null)
            
        }
    
      goToLink = (destination) => {
    
        this.emptyMenu()
        this.props.history.push(`/${destination}`)
        this.handleClose()
        
      }
    
      handleHoverOff = () => {
        setTimeout(() => {
            this.setState(state => ({ open: false }));
           }, timeoutLength);
      };
    
      handleHoverMenuOn = () => {
        this.setState(state => ({ onMenu: true }));   
      }
    
      handleHoverMenuOff = () => {
        setTimeout(() => {
            this.setState(state => ({ onMenu: false }));
           }, timeoutLength);
      }
    
      handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
      };
    
      handleClose = event => {
        this.setState({ open: false });
      };
    


    render () {

        const open = this.state.open || this.state.onMenu
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <div >
                {this.props.selected !== null && this.props.selected !== undefined ? 

                    <div className="basier-p4-caps">
                        {Object.values(this.props.selected).filter(x => x === true).length} Selected
                    </div>
                
                : 

                null}
                </div>

                <div style={{marginRight: 12}}>
                <div>
                <Button
                    buttonRef={node => {
                    this.anchorEl = node;
                    }}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                    /* onMouseEnter={this.handleHoverOn}
                    onMouseLeave={this.handleHoverOff} */
                    style={{cursor: 'pointer'}}
                    >

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div className="basier-p4-caps" style={{marginRight: 6}}>Export</div>
                            <div style={{padding: 3, position: 'relative', top: -3}} className="light-arrow down" ></div>
                        </div>
                        
                </Button>

                    <Popper style={{zIndex: 90}} open={open} anchorEl={this.anchorEl} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                        <div style={{zIndex: 900}}>
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >   
                            <Paper>
                            <ClickAwayListener onClickAway={this.handleHoverOff}>
                                <MenuList
                                /* onMouseEnter={this.handleHoverMenuOn}
                                onMouseLeave={this.handleHoverMenuOff}  */
                                >
                                <CSVLink 
                                    data={this.state.exportData}
                                    headers={headers}
                                    filename="Wisly Full Export.csv"
                                    asyncOnClick={true}
                                    onClick={(event, done) => this.exportData('all', done)}
                                >
                                    <MenuItem className="basier-p4-caps" dense> 
                                    Export All
                                    </MenuItem>
                                </CSVLink>
                                {this.props.allData.length !== this.props.filteredData.length ? 
                                
                                <CSVLink 
                                        data={this.state.exportData}
                                        headers={headers}
                                        filename="Wisly Filtered Export.csv"
                                        asyncOnClick={true}
                                        onClick={(event, done) => this.exportData('filtered', done)}
                                    >
                                    <MenuItem className="basier-p4-caps" dense>
                                        Export Filtered
                                    </MenuItem>
                                </CSVLink>
                                :
                                <div style={{color: '#CCC', height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'default', padding: '8px 16px'}}>
                                    Export Filtered
                                </div>
                                }
                                {Object.keys(this.props.selected).length > 0 ?
                                <CSVLink 
                                    data={this.state.exportData}
                                    headers={headers}
                                    filename="Wisly Selected Export.csv"
                                    asyncOnClick={true}
                                    onClick={(event, done) => this.exportData('selected', done)}
                                    >
                                    <MenuItem className="basier-p4-caps" dense>
                                        Export selected
                                    </MenuItem>
                                </CSVLink>
                                :
                                <div style={{color: '#CCC', height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'default', padding: '8px 16px'}}>
                                    Export Selected
                                </div>
                                }
                                </MenuList>
                            </ClickAwayListener>
                            </Paper>
                        </Grow>
                        </div>
                        )}
                    </Popper>
                    </div>
                            
                </div>
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SelectAction)
