import React from "react";
import { connect } from 'react-redux';
import * as actions from "../../../store/actions/data";
import * as appwideActions from "../../../store/actions/appwide";
import currencyDict from '../../../helpers/currency.json'
import Hidden from '@material-ui/core/Hidden';
import Plus from '../../../Media/Plus.svg'
import okGreen from '../../../Media/okGreen.svg'
import cancelRed from '../../../Media/cancelRed.svg'
import { withRouter } from "react-router-dom";

// Import FilePond styles

class WalletOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            walletEdit: {}, 
            newAddress: {},
            loading: {},
        }

    }

    

    handleInit() {
    }

    handleSocket(data) {

        

    }

    editAddress = (wallet_id, e) => {
        e.persist();
        this.setState(state => ({...state, newAddress: {...state.newAddress, [wallet_id]: e.target.value} }))
    }
    
    showAddressEdit = (wallet_id, address) => {
        this.setState(state => ({...state, walletEdit: {...state.walletEdit, [wallet_id]: true}, newAddress: {...state.newAddress, [wallet_id]: address} }))
    }

    commitEditAddress = (wallet_id, action, acc, wallet) => {
        if (action === 'cancel') {
            this.setState(state => ({...state, walletEdit: {...state.walletEdit, [wallet_id]: false} }))
        } else if (action === 'ok') {
            this.setState(state => ({...state, loading: {...state.loading, [wallet_id]: true} }))
            const data = {
                id: wallet_id, 
                address: this.state.newAddress[wallet_id]
            }

            const newAdd = this.props.editWallet(data)
            newAdd
                .then(
                    data => {
                        let walletData = this.props.data[acc]['wallets'][wallet]

                        walletData['address'] = this.state.newAddress[wallet_id]


                        const payload = {

                            data: walletData, 
                            account: acc, 
                            coin: wallet, 
                            exchange: this.props.exchangeId,
                            scope: 'exchange', 
                            user_id: this.props.user_id,

                        }
                        this.props.updateSingleWallet(payload)
                        this.setState(state => ({...state, loading: {...state.loading, [wallet_id]: false} }))
                        

                    }
                )
                .catch( err => console.log('err', err))
            this.setState(state => ({...state, walletEdit: {...state.walletEdit, [wallet_id]: false} })) 


        }
        
    }

    onKeyDown = (e, wallet_id,  acc, wallet) => {

        switch (e.keyCode) {
            case 27: {
                this.commitEditAddress(wallet_id, 'cancel')
                break;
            }
            case 13: {
                this.commitEditAddress(wallet_id, 'ok',  acc, wallet)
                break;
            }

            default: {
                break;
            }
        }

    }

    goToDetail = (id, data, source) => {
        this.props.history.push({
            pathname: '/wallet_detail', 
            state: {wallet_id: id, data: data, source: source}
        })
    }

    componentDidMount() {

        if (this.props.portfolioId !== undefined) {

        }

        //this.props.setCurrentView(99);
        //this.props.setMenuPortfolio(null);
    }

    render() {
        //console.log(this.props.data)
        return (
            <div style={{marginTop: 0}}>
            {
            this.props.data.map((acc, key) => {

                return (
                    <div key={key} style={{marginBottom: 0}}>
                        
                        <div onClick={() => this.goToDetail(acc.id, acc, null)} className="exchange-flat-list-item" style={{paddingRight: 24, paddingLeft: 24, height: 65 }}>

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: 36, height: 36, marginRight: 12}}>
                                    { acc.coin_image !== null && acc.coin_image !== undefined ?
                                        <img src={acc.coin_image} alt='' style={{width: 36, height: 36}} />
                                    :
                                        null
                                    }
                                </div>

                                <div>
                                    <div className="basier-p3" style={{fontWeight: 900, color: 'black', textTransform: 'uppercase'}}>
                                        {acc.name}
                                    </div>
                                    <div className="basier-p4" style={{fontWeight: 400, fontStyle: 'italic', marginTop: 6}}>
                                        {acc.address}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                )
            })
            }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        updateSingleWallet: (payload) => 
            dispatch(appwideActions.updateSingleWallet(payload))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WalletOverview));
