import React, { type ElementConfig } from 'react';
import  Select, { components }  from 'react-select';
import { connect } from 'react-redux';





class MenuSelector extends React.Component {

  state = {
    selectedOption: null,
  }

  componentDidMount() {
    
    this.setState(state => ({...state, options: this.props.data, selectedOption: {value: "USD", label: "USD"}}))
    this.handleChange({value: "USD", label: "USD"})

  }

  handleClear = () => {

    this.setState(state => ({...state,  selectedOption: null }));
    this.props.handleChange(null)

  }
  handleChange = (selectedOption) => {

    this.setState(state => ({...state,  selectedOption: selectedOption }));
    this.props.handleChange(selectedOption.value)
    
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        onMenuOpen={() => this.handleClear()}
        options={this.state.options}
        isSearchable={true}
        styles={
          {
            control: styles => ({ ...styles, backgroundColor: 'transparent', width: '100%', border: `${!this.props.valid ? '2px solid red !important' : '0px'}`, fontSize: 16, color: 'black', dispay:'inline-block'}),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          
              return {
                ...styles,
                backgroundColor: isDisabled
                  ? null
                  : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
                color: isDisabled
                  ? '#F3F5F6'
                  : isSelected ? 'black' : 'black',
                opacity: isDisabled
                  ? '1'
                  : isSelected ? '1' : '0.7',
              };
            },
          
          }
        }
        maxMenuHeight={130}
        minMenuHeight={50}
        classNamePrefix="react-select-largefiat"
        placeholder="Select..."
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
