import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/auth";
import loadingSpinner from '../../Media/contentLoader.svg'
import {createLoadingSelector} from '../../store/reducers/selectors'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import currencyData from '../../helpers/currency.json'

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };

const BalanceGraph = (props) => {

    let received_date = new Date(props.data[props.portfolioId].start_date)
    let data_date = Date.UTC(received_date.getFullYear(), received_date.getMonth(), received_date.getDate() )
    let currencyNative = props.list_portfolios.filter(v => v.id == props.portfolioId)[0].currency
    let currencySymbol = currencyNative 
    let todayValue = props.overallData[props.portfolioId].net_profit
    let todayChange = props.overallData[props.portfolioId].percent_change * 100

    let allValues = [...props.data[props.portfolioId].values['values'], todayValue]
    let allPercents = [...props.data[props.portfolioId].values['percents'], todayChange]



    try { currencySymbol = currencyData[currencyNative]['symbol'] } catch(e) {}

    const { classes } = props;

    const arrowDownSvg = 
        `<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icons8-plus-math" transform="translate(0.000000, 6.000000)" fill-rule="nonzero">
                <path d="M17.2894737,1.10526316 L10.8947368,1.10526316 L7.10526316,1.10526316 L0.710526316,1.10526316 C0.448704711,1.10526316 0.236842105,1.31712576 0.236842105,1.57894737 L0.236842105,4.42105263 C0.236842105,4.68287424 0.448704711,4.89473684 0.710526316,4.89473684 L7.10526316,4.89473684 L10.8947368,4.89473684 L17.2894737,4.89473684 C17.5512953,4.89473684 17.7631579,4.68287424 17.7631579,4.42105263 L17.7631579,1.57894737 C17.7631579,1.31712576 17.5512953,1.10526316 17.2894737,1.10526316 Z" id="Path" fill="#F9716B"></path>
                <path d="M15.5131579,2.28947368 L13.1447368,2.28947368 C13.0790501,2.28947368 13.0263158,2.23673937 13.0263158,2.17105263 C13.0263158,2.10536589 13.0790501,2.05263158 13.1447368,2.05263158 L15.5131579,2.05263158 C15.5788446,2.05263158 15.6315789,2.10536589 15.6315789,2.17105263 C15.6315789,2.23673937 15.5788446,2.28947368 15.5131579,2.28947368 Z" id="Path" fill="#BC302A"></path>
                <path d="M16.6973684,2.28947368 L16.2236842,2.28947368 C16.1579975,2.28947368 16.1052632,2.23673937 16.1052632,2.17105263 C16.1052632,2.10536589 16.1579975,2.05263158 16.2236842,2.05263158 L16.6973684,2.05263158 C16.7630552,2.05263158 16.8157895,2.10536589 16.8157895,2.17105263 C16.8157895,2.23673937 16.7630552,2.28947368 16.6973684,2.28947368 Z" id="Path" fill="#BC302A"></path>
                <path d="M6.86842105,5.13157895 L0.710526316,5.13157895 C0.319181684,5.13157895 0,4.81239726 0,4.42105263 L0,1.57894737 C0,1.18760274 0.319181684,0.868421053 0.710526316,0.868421053 L6.86842105,0.868421053 L11.1315789,0.868421053 L17.2894737,0.868421053 C17.6808183,0.868421053 18,1.18760274 18,1.57894737 L18,4.42105263 C18,4.81239726 17.6808183,5.13157895 17.2894737,5.13157895 L11.1315789,5.13157895 L6.86842105,5.13157895 Z M0.710526316,1.34210526 C0.580078184,1.34210526 0.473684211,1.44849924 0.473684211,1.57894737 L0.473684211,4.42105263 C0.473684211,4.55150076 0.580078184,4.65789474 0.710526316,4.65789474 L7.34210526,4.65789474 L10.6578947,4.65789474 L17.2894737,4.65789474 C17.4199218,4.65789474 17.5263158,4.55150076 17.5263158,4.42105263 L17.5263158,1.57894737 C17.5263158,1.44849924 17.4199218,1.34210526 17.2894737,1.34210526 L10.6578947,1.34210526 L7.34210526,1.34210526 L0.710526316,1.34210526 Z" id="Shape" fill="#BC302A"></path>
                <path d="M12.4342105,2.28947368 L9.82894737,2.28947368 C9.76326063,2.28947368 9.71052632,2.23673937 9.71052632,2.17105263 L9.94736842,2.05263158 L12.4342105,2.05263158 C12.4998973,2.05263158 12.5526316,2.10536589 12.5526316,2.17105263 C12.5526316,2.23673937 12.4998973,2.28947368 12.4342105,2.28947368 Z" id="Path" fill="#BC302A"></path>
            </g>
        </g>
        </svg>`

    const arrowUp = 
    `<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icons8-plus-math" fill-rule="nonzero">
                <path d="M17.2894737,7.10526316 L10.8947368,7.10526316 L10.8947368,0.710526316 C10.8947368,0.448704711 10.6828742,0.236842105 10.4210526,0.236842105 L7.57894737,0.236842105 C7.31712576,0.236842105 7.10526316,0.448704711 7.10526316,0.710526316 L7.10526316,7.10526316 L0.710526316,7.10526316 C0.448704711,7.10526316 0.236842105,7.31712576 0.236842105,7.57894737 L0.236842105,10.4210526 C0.236842105,10.6828742 0.448704711,10.8947368 0.710526316,10.8947368 L7.10526316,10.8947368 L7.10526316,17.2894737 C7.10526316,17.5512953 7.31712576,17.7631579 7.57894737,17.7631579 L10.4210526,17.7631579 C10.6828742,17.7631579 10.8947368,17.5512953 10.8947368,17.2894737 L10.8947368,10.8947368 L17.2894737,10.8947368 C17.5512953,10.8947368 17.7631579,10.6828742 17.7631579,10.4210526 L17.7631579,7.57894737 C17.7631579,7.31712576 17.5512953,7.10526316 17.2894737,7.10526316 Z" id="Path" fill="#4CDAC1"></path>
                <path d="M15.5131579,8.28947368 L13.1447368,8.28947368 C13.0790501,8.28947368 13.0263158,8.23673937 13.0263158,8.17105263 C13.0263158,8.10536589 13.0790501,8.05263158 13.1447368,8.05263158 L15.5131579,8.05263158 C15.5788446,8.05263158 15.6315789,8.10536589 15.6315789,8.17105263 C15.6315789,8.23673937 15.5788446,8.28947368 15.5131579,8.28947368 Z" id="Path" fill="#1ABC9C"></path>
                <path d="M16.6973684,8.28947368 L16.2236842,8.28947368 C16.1579975,8.28947368 16.1052632,8.23673937 16.1052632,8.17105263 C16.1052632,8.10536589 16.1579975,8.05263158 16.2236842,8.05263158 L16.6973684,8.05263158 C16.7630552,8.05263158 16.8157895,8.10536589 16.8157895,8.17105263 C16.8157895,8.23673937 16.7630552,8.28947368 16.6973684,8.28947368 Z" id="Path" fill="#1ABC9C"></path>
                <path d="M10.4210526,18 L7.57894737,18 C7.18760274,18 6.86842105,17.6808183 6.86842105,17.2894737 L6.86842105,11.1315789 L0.710526316,11.1315789 C0.319181684,11.1315789 0,10.8123973 0,10.4210526 L0,7.57894737 C0,7.18760274 0.319181684,6.86842105 0.710526316,6.86842105 L6.86842105,6.86842105 L6.86842105,0.710526316 C6.86842105,0.319181684 7.18760274,0 7.57894737,0 L10.4210526,0 C10.8123973,0 11.1315789,0.319181684 11.1315789,0.710526316 L11.1315789,6.86842105 L17.2894737,6.86842105 C17.6808183,6.86842105 18,7.18760274 18,7.57894737 L18,10.4210526 C18,10.8123973 17.6808183,11.1315789 17.2894737,11.1315789 L11.1315789,11.1315789 L11.1315789,17.2894737 C11.1315789,17.6808183 10.8123973,18 10.4210526,18 Z M0.710526316,7.34210526 C0.580078184,7.34210526 0.473684211,7.44849924 0.473684211,7.57894737 L0.473684211,10.4210526 C0.473684211,10.5515008 0.580078184,10.6578947 0.710526316,10.6578947 L7.34210526,10.6578947 L7.34210526,17.2894737 C7.34210526,17.4199218 7.44849924,17.5263158 7.57894737,17.5263158 L10.4210526,17.5263158 C10.5515008,17.5263158 10.6578947,17.4199218 10.6578947,17.2894737 L10.6578947,10.6578947 L17.2894737,10.6578947 C17.4199218,10.6578947 17.5263158,10.5515008 17.5263158,10.4210526 L17.5263158,7.57894737 C17.5263158,7.44849924 17.4199218,7.34210526 17.2894737,7.34210526 L10.6578947,7.34210526 L10.6578947,0.710526316 C10.6578947,0.580078184 10.5515008,0.473684211 10.4210526,0.473684211 L7.57894737,0.473684211 C7.44849924,0.473684211 7.34210526,0.580078184 7.34210526,0.710526316 L7.34210526,7.34210526 L0.710526316,7.34210526 Z" id="Shape" fill="#1ABC9C"></path>
                <path d="M12.4342105,8.28947368 L9.82894737,8.28947368 C9.76326063,8.28947368 9.71052632,8.23673937 9.71052632,8.17105263 L9.71052632,1.30263158 C9.71052632,1.23694484 9.76326063,1.18421053 9.82894737,1.18421053 C9.89463411,1.18421053 9.94736842,1.23694484 9.94736842,1.30263158 L9.94736842,8.05263158 L12.4342105,8.05263158 C12.4998973,8.05263158 12.5526316,8.10536589 12.5526316,8.17105263 C12.5526316,8.23673937 12.4998973,8.28947368 12.4342105,8.28947368 Z" id="Path" fill="#1ABC9C"></path>
            </g>
        </g>
    </svg>`
    
    const options = {
        chart: {
            type: 'area', 
            backgroundColor: props.background,  
            animation: false,
        },
        credits: false,
        title: false,
        xAxis: {
            className: 'basier-p4-caps',    
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            type: 'datetime',
            minPadding:0,
            maxPadding:0,
            tickLength: 0,
            tickColor: 'black',
            labels: {
                padding: 30,
                style: {
                    fontSize: '12px',
                },
            }

        },
        yAxis: {
            className: 'basier-p4',
            title: {
                text: false,
            },
            align: 'left',
            minorGridLineWidth: 1,
            gridLineWidth: 1,
            gridLineColor: '#E1E5E9',
            opposite: false,
            labels: {
                style: {
                    fontSize: '12px',
                    textAlign: 'left',
                    position: 'relative',
                    display: 'inline-block'
                },
                formatter: function() {
                    
                    return this.value.toLocaleString('en', {minimumFractionDigits:0, maximumFractionDigits: 0})+"%"
                }
            }
            
        },
        series: [{
            name: 'Portfolio value in percent',
            data: allPercents,
            pointStart: data_date,
            pointInterval: 24 * 3600 * 1000,
            color: '#4CDAC1',
            negativeColor: '#F9716B',
            
            lineWidth: 1.5,
            marker: {
                fillColor: "#fff", 
                lineColor: null, 
                lineWidth: 2,
            }
            }, 
            {
            name: 'Portfolio value in USD',
            data: allValues,
            visible: false,
            pointStart: data_date,
            pointInterval: 24 * 3600 * 1000,
            color: '#4CDAC1',
            negativeColor: '#F9716B',
            
            lineWidth: 1.5,
            marker: {
                fillColor: "#fff", 
                lineColor: null, 
                lineWidth: 2,
            }
        }, 
        ],
        legend: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        
        plotOptions: {
            series: {
                animation: false, 
                marker: {
                    enabledThreshold: 3
                },
            },
            area: {
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1},
                    stops: [
                        [0, "rgba(76, 218, 193, 0.4)"],
                        [1, "rgba(76, 218, 193, 0)"]
                    ]
                },
                negativeFillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1},
                    stops: [
                        [0, "rgba(249, 113, 107, 0)"],
                        [1, "rgba(249, 113, 107, 0.4)"]
                    ]
                }
            },
        },
        responsive: {
            rules: [{
                condition: {
                    minWidth: 400
                },
                chartOptions: {
                    chart: {
                        height: 520
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    }
                }
            }]
        },
        tooltip: {
        
        backgroundColor: '#fff',
        height: 200,
        borderRadius: 14,
        borderWidth: 0,
        shadow: true,
        padding:12,
        shared: true, 
        style: {
            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
            height: '108px', 
            width: '186px',
        },
        useHTML: true,
        formatter: function () {
            const currentTime = this.x
            const indexOf = this.points[0].series.chart.series[1].xData.indexOf(currentTime)
            const percentChange = this.points[0].series.chart.series[1].yData[indexOf]
            const changePrevious = this.y;

            let changeVue = null;

            if (changePrevious < 0) {
                changeVue = arrowDownSvg
            } else {
                changeVue = arrowUp
            }

            return `
                    <span style='width: 100%; display: flex; flex-direction: column; align-items: center, padding-left: 12px; padding-right: 12px;'>
                        <div style='display: flex; flex-direction: column; align-items: flex-end;'>
                            <div style='display: flex;  align-items: baseline;'>
                                <span class='image-tooltip-profit'>  
                                    ${changeVue}
                                </span>
                                <h4 class='tooltip-title' style='margin-left: 4px'> 
                                   ${Math.abs(this.y).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%
                                </h4>
                            </div>
                            <div style='color: ${percentChange < 0 ? '#F9716B' : '#4CDAC1'}; margin-bottom: 8px;'>
                                <span class='basier-p2' style='font-weight: 900;'> ${(percentChange < 0 ? '-' : '') + currencySymbol+""+Math.abs(percentChange).toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span>
                            </div>
                        </div>
                        <span class='tooltip-divider'></span>
                        <br />
                        <span class='date-tooltip'>
                            <span class='basier-p4 date-text-span'>
                                ${Highcharts.dateFormat('%e %b %Y',new Date(this.x))}
                            </span>
                        </span>
                    </span>`
                }
        }
    }   


        return (

            <div>
          
                    <Grid container 
                        spacing={0}>
                        
                        <div style={{width: '100%', marginLeft: -10, paddingRight: 36, position: 'relative', height: 450}}>
                        { props.data[props.portfolioId] === undefined || props.data[props.portfolioId] === null ? 
                            
                            <div className="loader-graph"><img style={{height: 100, with: 100}} src={loadingSpinner} alt="Loading..."/></div>
                            
                            :

                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                oneToOne={true}
                            />
                        }

                        </div>

                    </Grid>

                </div>
            
            
        )


        
};

BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf, 
        list_portfolios: state.auth.list_portfolios

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setBtcSymbol: (s) =>
            dispatch(actions.setBtcSymbol(s)), 
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));


