import React from 'react';
import { connect } from 'react-redux';
import ExchangeCard from './SmallExchangeWallet'
import StorageCard from './SmallStorageWallet'
import Plus from '../../../Media/Plus.svg'
import * as actions from '../../../store/actions/interactions';
import { withRouter } from "react-router-dom";

class SmallWallets extends React.PureComponent {
    state = {
    }

    componentDidMount() {
        this.setState(state => ({...state, scope: 'exchange'}))
    }
    addNewExchange = () => {
        this.goToNew()
    }

    goToNew = () => {

        if( this.props.walletType === 'exchange') {
            this.props.history.push({
                pathname: '/new_ea', 
            })
        } else if (this.props.walletType === 'storage') {
            this.props.history.push({
                pathname: '/new_w', 
                state: {wallet_type: 'storage', exchange: null}
            })
        }
        

    }
    
    changeScope = (scope) => {
        this.props.setMobileWallet(scope);
    }
    render () {

        return (

            <div>

                <div style={{display: 'flex', marginTop: 12,}}>

                    <div style={{
                        width: '50%', height: 50, display: 'flex', 
                        alignItems: 'center', justifyContent: 'center', 
                        backgroundColor: `${this.props.walletType === 'exchange' ? '#0078DB' : '#E1E5E9' }`, 
                        color: `${this.props.walletType === 'exchange' ? 'white' : 'black'}`, 
                        fontWeight: `${this.props.walletType === 'exchange' ? 900 : 400 }`, 
                        textTransform: 'uppercase', marginRight: 6, marginLeft: 12, borderRadius: 10}} 
                        className="basier-p3"
                        onClick={() => this.changeScope('exchange')}>
                        Exchange
                    </div>
                    <div 
                        style={{width: '50%', height: 50, display: 'flex', 
                        alignItems: 'center', justifyContent: 'center', 
                        backgroundColor: `${this.props.walletType === 'storage' ? '#0078DB' : '#E1E5E9' }`, 
                        fontWeight: `${this.props.walletType === 'storage' ? 900 : 400 }`, 
                        textTransform: 'uppercase', marginRight: 12, marginLeft: 6, borderRadius: 10, 
                        color: `${this.props.walletType === 'storage' ? 'white' : 'black'}`, }}
                        onClick={() => this.changeScope('storage')}
                        className="basier-p3">
                        Storage
                    </div>
                </div>

                <div style={{marginTop: 36}}>

                    <div style={{marginBottom: 12, paddingRight: 24, paddingLeft: 12, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <h3 style={{margin: 0}}>{
                        this.props.walletType === 'exchange'?
                        'Accounts' : 'Wallets'
                        }</h3>
                        <div style={{paddingRight: 24, display: 'flex', alignItems: 'center'}} onClick={this.addNewExchange} className="opacity-active">
                            <img src={Plus} style={{height: 50, width: 50}} alt=""/>
                            <div className="basier" style={{fontSize: 16, textTransform: 'uppercase', marginLeft: 12}}>New</div>
                        </div>
                    </div>
                
                    <div style={{marginBottom: 80}}>
                    {
                    this.props.data !== undefined && this.props.data !== null && this.props.walletType === 'exchange' ? 
                        Object.keys(this.props.data[this.props.user_id]['exchange']).map((val, key) => {

                            return (
                                <ExchangeCard 
                                    setCurrentView={this.props.setCurrentView}
                                    key={key}
                                    openModal={(type, scope, add) => this.props.openModal(type, scope, add)} 
                                    user_id={this.props.user_id}
                                    data={this.props.data[this.props.user_id]['exchange'][val]} 
                                    editWallet={(data) => this.props.editWallet(data)}
                                    exchangeId={val}/>
                            )

                        })
                    :
                    null
                }
                {
                    this.props.data !== undefined && this.props.data !== null && this.props.walletType === 'storage' ? 
                        Object.keys(this.props.data[this.props.user_id]['storage']).map((val, key) => {

                            return (
                                <StorageCard 
                                    setCurrentView={this.props.setCurrentView}
                                    key={key}
                                    openModal={(type, scope, add) => this.props.openModal(type, scope, add)} 
                                    user_id={this.props.user_id}
                                    data={this.props.data[this.props.user_id]['storage'][val]} 
                                    editWallet={(data) => this.props.editWallet(data)}
                                    exchangeId={val}/>
                            )

                        })
                    :
                    null
                }
                </div>
            </div>

            </div>

           
            
        )
    }


}

const mapStateToProps = state => {
    return {
        walletType: state.interactions.mobileWalletType
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMobileWallet: (data) => dispatch(actions.setMobileWallet(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmallWallets));
