
export default () => {
	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		if (!e) return;

		const realized_profits = []
		const unrealized_profits = []
		const coins = []
		const total_profit = []

		const data = e.data

		data.map((balance, index) => {

			realized_profits.push(balance.realized_profit)
			unrealized_profits.push(balance.active_profit)
			coins.push(balance.coin_ticker)
			total_profit.push(balance.total_profit)

		})
		

		postMessage([realized_profits, unrealized_profits, coins, total_profit]);

	})
}
