import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import currencyDict from '../../../../../helpers/currency.json'
import Tooltip from '@material-ui/core/Tooltip';
import questionMark from '../../../../../Media/questionMark.svg'

class BalanceGraph extends React.PureComponent {

    renderApproxZero = (bal) => {
        return (

        <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{marginRight: 6}}>&lt; 1$</div>
            <div>
                <Tooltip title={`Actual balance: ${bal}`} placement="right">
                    <img style={{height: 20, width: 20, marginLeft: 0, marginRight: 12}} src={questionMark} alt=""/>
                </Tooltip>
            </div>
        </div>

        )
    }

    componentDidMount() {
        
    }
    
    render() {

        return (
            
            this.props.balances !== undefined && this.props.balances[this.props.user_id] !== undefined ?

            <div>

                { Object.keys(this.props.balances[this.props.user_id]['exchange']).length > 0 ? 
                    
                    Object.keys(this.props.balances[this.props.user_id]['exchange']).map((val, key) => {

                    return (
                        <div key={key} style={{minHeight: 300, width: '100%'}}>
                            <div style={{display: 'flex', alignItems: 'center', marginRight: 48, paddingBottom: 12, paddingTop: 12, paddingLeft: 12, backgroundColor: 'rgba(243, 245, 246, 0.5)' }}>
                                <div style={{marginRight: 12}}><img src={this.props.balances[this.props.user_id]['exchange'][val]['details']['image']} style={{height: 24, width: 24}} alt="exchange"/></div>
                                <h4 style={{margin: 0}}>{this.props.balances[this.props.user_id]['exchange'][val]['details']['name']}</h4>
                            </div>
                            
                            <div className="basier-p4-caps" style={{marginTop: 24, marginBottom: 6, display: 'flex', alignItems: 'center', marginLeft: 20}}>
                                <div style={{width: '25%'}}>Coin</div>
                                <div style={{width: '20%'}}>Balance</div>
                                <div style={{width: '35%'}}>Address</div>
                                <div style={{width: '20%'}}>Actions</div>
                            </div>

                            {
                                this.props.balances[this.props.user_id]['exchange'][val]['wallets'].map((val, key) => {

                                    return (
                                        <div key={key}>

                                            <div style={{marginTop: 24, marginBottom: 6, display: 'flex', alignItems: 'center', marginLeft: 20}}>
                                                <div style={{width: '25%', display: 'flex', alignItems: 'center'}}>

                                                    { val.coin_image !== null ? 
                                                        <div><img src={val.coin_image} alt="img" style={{height: 25, width: 25, marginRight: 12}} /></div>
                                                    :
                                                        <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
                                                            {currencyDict[val.coin_ticker] === undefined ? null : currencyDict[val.coin_ticker]['symbol'].length > 1 ? null : currencyDict[val.coin_ticker]['symbol'] }
                                                        </span>
                                                    }

                                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                                        <div className="basier-p3" style={{color: 'black'}}>
                                                            {val.coin_ticker}
                                                        </div>

                                                        <div className="basier-p4-caps">
                                                            {val.coin_name}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{width: '20%', color: 'black'}} className="basier-p3">
                                                    {
                                                        val.value < 1 && val.value > -1 ? 
                                                            this.renderApproxZero(val.balance.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8}))
                                                        :
                                                        val.balance > 1 ?
                                                        val.balance.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                                        :
                                                        val.balance.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8})
                                                    }
                                                </div>
                                                <div style={{width: '35%',  color: 'black'}} className="basier-p3">{val.wallet_address}</div>
                                                <div style={{width: '20%'}} className="basier-p3"></div>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    )
                })
            
                :
                
                <div style={{height: 50, fontStyle: 'italic'}}>You do not have any exchange balances yet. Add exchange data to your transactions to see your balances here.</div>
                
                }

                
            </div>

            :
            null
            
        )


    }   
};
  

const mapStateToProps = state => {
    return {
        
        balances: state.data.wallet_balance

    };
};

const mapDispatchToProps = dispatch => {
    return {
        
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BalanceGraph);


