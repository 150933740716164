import React from 'react';
import { connect } from 'react-redux';
import {siwtchTransactionType} from '../../../../../../helpers/helpers';
import CoinList from '../../../../../MobileOnly/MobileCurrency'
import TypeList from '../../../../../MobileOnly/MobileType'
import ExchangeList from '../../../../../MobileOnly/ExchangeDrawer'
import AccountList from '../../../../../MobileOnly/AccountDrawer'
import WalletList from '../../../../../MobileOnly/WalletDrawer'
import * as actions from "../../../../../../store/actions/interactions";
import * as dataActions from "../../../../../../store/actions/data";
import * as appwideActions from "../../../../../../store/actions/appwide";
import Switch from '../../../../../Assets/Switch';
import UserList from '../../../../../MobileOnly/UserDrawer';
import Step1 from './SmallStep1';
import Step2 from './SmallStep2';
import Step3 from './SmallStep3';
import MobileStepper from '@material-ui/core/MobileStepper';
import { withStyles } from '@material-ui/core/styles';
import BackIcon from "../../../../../../Media/BackIcon.svg";
import loadingSpinner from '../../../../../../Media/loadingSpinner.gif';
import okIcon from '../../../../../../Media/okIcon.svg';
import errorIcon from '../../../../../../Media/errorIcon.svg';
import { backendUrl } from '../../../../../../variables'


import axios from 'axios';

var moment = require('moment');
require('moment/locale/en-gb');

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }

const styles = theme => ({
 
    root: {
        maxWidth: '100%',
        flexGrow: 1,
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'white',
        marginTop: 12, 
    }

});


class TransactionSmallEditCreate extends React.PureComponent {


    constructor(props) {

        super(props)
        

          this.state = {
            loading: false, 
            success: false, 
            error: false, 
            open: false,
            coin_in_scope: null, 
            graph_data: [], 
            graph_start: null,
            coinModal: false, 
            typeModal: false,
            transaction_id: null,
            scopeCoin: null,
            transactionData: {}, 
            stateDate: null, 
            stateTime: null, 
            os: null,
            fee_check: false,
            userModal: false, 
            wallet_check: false, 
            source: null,
            step: 0,
            exchangeModal: false,
            showAccount: false, 
            storageWalletModal: false,
            passedData: this.props.passedData !== null && this.props.passedData !== undefined ? true : false 
          }
  
    }

    onFocus = () => {

        

    }
    
    getRef = async (ref) => {
        return ref;
    }

    handleReset = () => {

        this.setState(
            {
                loading: false, 
                success: false, 
                error: false, 
                open: false,
                coin_in_scope: null, 
                graph_data: [], 
                graph_start: null,
                coinModal: false, 
                typeModal: false,
                transaction_id: null,
                scopeCoin: null,
                transactionData: {}, 
                stateDate: null, 
                stateTime: null, 
                os: null,
                fee_check: false,
                userModal: false, 
                wallet_check: false, 
                source: null,
                step: 0,
                exchangeModal: false,
                showAccount: false, 
                storageWalletModal: false,
                priceDisplay: null,
                passedData: this.props.passedData !== null && this.props.passedData !== undefined ? true : false 
              }
        )


        // PORTFOLIO ID 
        this.props.callUserList(this.props.portfolioId)
        if (this.props.passedData !== undefined) {

        const os = getMobileOperatingSystem()
        this.setState(state => ({...state, os: os}))

        console.log(this.props.passedData)

        this.setState(state => ({...state, 
            
            transactionData: {
                base_currency: this.props.passedData.base_currency,
                quote_currency: this.props.passedData.quote_currency,
                fee_currency: this.props.passedData.fee_currency,
                transaction_description: this.props.passedData.transaction_description, 
                user_transaction: {value: this.props.passedData.user_transaction.id, label: `${this.props.passedData.user_transaction.first_name} ${this.props.passedData.user_transaction.last_name}`},
                amount: this.props.passedData.amount, 
                unit_price: this.props.passedData.unit_price, 
                fee: this.props.passedData.fee, 
            }
            
        }))

        if ( this.props.passedData.fee !== null &&  this.props.passedData.fee !== undefined && this.props.passedData.fee !== 0) {
            this.setState(state => ({...state, fee_check: true}))
        }

        const trDate = new Date(this.props.passedData.date)
        const moment_date = moment(trDate);

        if (os === "Android") {

            const stateDate = moment_date.local().format("YYYY-MM-DDTHH:mm")
            this.setState(state => ({...state, stateDate: stateDate}))

        } else {

            const stateDate = moment_date.local().format("YYYY-MM-DD")
            const stateTime = moment_date.local().format("HH:mm")
            this.setState(state => ({...state, stateDate: stateDate, stateTime: stateTime}))

        }

        const reduxDate = moment_date.utc().format();
        this.setState(state => ({...state, transactionData: {...state.transactionData, date: reduxDate}}));


        if (this.props.passedData.account !== undefined && this.props.passedData.account !== null) {
            this.setState(state => ({...state, 
                source: 'exchange',
                transactionData: {
                    ...state.transactionData,
                    account: this.props.passedData.account,
                    exchange: {id: this.props.passedData.account.exchange.id, name: this.props.passedData.account.exchange.name, image_thumb: this.props.passedData.account.exchange.image},
                  
                }
        }))

        }

        if (this.props.passedData.storage !== undefined && this.props.passedData.storage !== null) {
            this.setState(state => ({...state, 
                source: 'storage',
                transactionData: {
                    ...state.transactionData,
                    storage: this.props.passedData.storage,
                  
                }
            }))
        }

       




        } else {

        this.props.callUserList(this.props.portfolioId)
        this.setState(state => ({...state, transactionData: {...state.transactionData, portfolio: this.props.portfolioId, user_transaction: {value: this.props.user_id ,label: `${this.props.user_first_name} ${this.props.user_last_name}`}}}))

        const os = getMobileOperatingSystem()
        this.setState(state => ({...state, os: os}))

        const now = new Date()
        const moment_date = moment(now);
        const reduxDate = moment_date.utc().format();
        this.setState(state => ({...state, transactionData: {...state.transactionData, date: reduxDate}}))
       

        if (os === "Android") {

            const stateDate = moment_date.local().format("YYYY-MM-DDTHH:mm")
            this.setState(state => ({...state, stateDate: stateDate}))

        } else {

            const stateDate = moment_date.local().format("YYYY-MM-DD")
            const stateTime = moment_date.local().format("HH:mm")
            this.setState(state => ({...state, stateDate: stateDate, stateTime: stateTime}))

        }

        if (this.props.scope === 'edit') {

        } else {

        }

        const last_index = this.props.transaction.number
        const next_index = last_index + 1
        this.props.addTransaction({key: next_index})
        this.setState(state => ({...state, transaction_id: next_index}))
        
        }

    }


    submitTransaction = () => {

        this.setState(state => ({...state, loading: true}))

        let postData = this.state.transactionData;
        postData['base_currency'] = postData['base_currency']['id']
        if (postData['transaction_description'] === "buy" || postData['transaction_description'] === "ico" || postData['transaction_description'] === "sell") {
            postData['quote_currency'] = postData['quote_currency']['id']
        } else {
            delete postData["quote_currency"]
            delete postData["unit_price"]
        }

        if (postData['fee_currency'] !== undefined && postData['fee_currency'] !== null) {
            postData['fee_currency'] = postData['fee_currency']['id']
        } else {
            delete postData["fee"]
            delete postData["fee_currency"]
        }
        postData['user_transaction'] = postData['user_transaction']['value']

        if (postData['storage'] !== undefined && postData['storage'] !== null &&  postData['storage'] !== -1 ) {
            postData['storage'] = postData['storage']['id']
        }

        if (postData['exchange'] !== undefined && postData['exchange'] !== null) {


            if (postData['exchange'].custom === true) {
                postData['custom_exchange'] = {id: postData['exchange'].value, name:postData['exchange'].name}
                postData['exchange'] = null
              } else {
                try { postData['exchange'] = postData['exchange']['id'] } catch(e) {}
                postData['custom_exchange'] = null
              }

        }

        if (postData['account'] !== undefined && postData['account'] !== null && postData['account'] !== -1 ) {
            postData['account'] = postData['account']['id']
        }

        if (this.props.priceType === 'total') {
            postData['unit_price'] = postData['unit_price'] / postData['amount']
          }
        
        postData['user_id'] = this.props.user_id
        let headers = { 
            "content-type": "application/json",     
          };
      
          headers["Authorization"] = `Token ${this.props.token}`;
      
          
          
          if (this.state.passedData === true) {

            let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {

                headers['Authorization'] = `Token ${this.props.token}`

            }

            const url_call = `${backendUrl}/v1/transaction_detail/${this.props.passedData.id}`

                axios.patch(url_call, postData, {headers: headers})
                .then(json => {

                                
                    this.props.componentDataFetch('get', 5, this.props.currency_id, null, this.props.passedData.portfolio, null, null, this.props.inBitcoin)
                    this.props.getWallets(this.props.user_id)

                    this.props.getTrData(json['data']['data'])
                    
                    setTimeout(() => { this.props.cancelAdd() }, 400)
                    this.setState(state => ({...state, loading: false, success: true}))        

                })
                .catch( e => {

                    console.log(e)
                    this.setState(state => ({...state, loading: false, error: true}))

                })

          } else if (this.state.passedData === false) {

            console.log(this.state)

            const prod_url = `${backendUrl}`
            const preprod_url = 'http://localhost:8000'
      
            const url_use = prod_url

            axios
        
                .post(`${url_use}/v1/transactions/`,
                postData
                , { headers: headers } )
                .then(res => {
        
                    console.log('transaction success')
                    if (res.data['status'] === 'OK') {

                        this.props.componentDataFetch('get', 5, this.props.currency_id, null, this.props.portfolioId, null, null, this.props.inBitcoin)
                        this.setState(state => ({...state, loading: false, success: true}))
        
                    } else {

                        this.setState(state => ({...state, loading: false, error: true}))
                    
                    }
                })
                .catch( err => {

                    this.setState(state => ({...state, loading: false, error: true}))

                })
            
            } else {
                console.log(this.state)
            }


    }
    

    componentDidMount() {
        this.props.setCurrentView(51)
        // PORTFOLIO ID 
        this.props.callUserList(this.props.portfolioId)
        if (this.props.passedData !== undefined) {

        const os = getMobileOperatingSystem()
        this.setState(state => ({...state, os: os}))

        console.log(this.props.passedData)

        this.setState(state => ({...state, 
            
            transactionData: {
                base_currency: this.props.passedData.base_currency,
                quote_currency: this.props.passedData.quote_currency,
                fee_currency: this.props.passedData.fee_currency,
                transaction_description: this.props.passedData.transaction_description, 
                user_transaction: {value: this.props.passedData.user_transaction.id, label: `${this.props.passedData.user_transaction.first_name} ${this.props.passedData.user_transaction.last_name}`},
                amount: this.props.passedData.amount, 
                unit_price: this.props.passedData.unit_price, 
                fee: this.props.passedData.fee, 
            }
            
        }))

        if ( this.props.passedData.fee !== null &&  this.props.passedData.fee !== undefined && this.props.passedData.fee !== 0) {
            this.setState(state => ({...state, fee_check: true}))
        }

        const trDate = new Date(this.props.passedData.date)
        const moment_date = moment(trDate);

        if (os === "Android") {

            const stateDate = moment_date.local().format("YYYY-MM-DDTHH:mm")
            this.setState(state => ({...state, stateDate: stateDate}))

        } else {

            const stateDate = moment_date.local().format("YYYY-MM-DD")
            const stateTime = moment_date.local().format("HH:mm")
            this.setState(state => ({...state, stateDate: stateDate, stateTime: stateTime}))

        }

        const reduxDate = moment_date.utc().format();
        this.setState(state => ({...state, transactionData: {...state.transactionData, date: reduxDate}}));


        if (this.props.passedData.account !== undefined && this.props.passedData.account !== null) {
            
            let exchange_id = null; 
            let exchange_image = null; 
            let exchange_name = null; 
            let custom = null;

            if (this.props.passedData.account.custom_exchange_check === true) {

                exchange_id = this.props.passedData.account.custom_exchange.trim().toLowerCase().replace(" ", "_")
                exchange_name = this.props.passedData.account.custom_exchange; 
                custom = true

            } else {
                exchange_id = this.props.passedData.account.exchange.id; 
                exchange_name = this.props.passedData.account.exchange.name; 
                exchange_image = this.props.passedData.account.exchange.image; 
                custom = false

            }

            this.setState(state => ({...state, 
                source: 'exchange',
                transactionData: {
                    ...state.transactionData,
                    account: this.props.passedData.account,
                    exchange: {id: exchange_id, name: exchange_name, image_thumb: exchange_image, custom: custom},
                  
                }
        }))

        }

        if (this.props.passedData.storage !== undefined && this.props.passedData.storage !== null) {
            this.setState(state => ({...state, 
                source: 'storage',
                transactionData: {
                    ...state.transactionData,
                    storage: this.props.passedData.storage,
                  
                }
            }))
        }





        } else {
        
        console.log('no passed data')

        this.props.callUserList(this.props.portfolioId)
        this.setState(state => ({...state, transactionData: {...state.transactionData, portfolio: this.props.portfolioId, user_transaction: {value: this.props.user_id ,label: `${this.props.user_first_name} ${this.props.user_last_name}`}}}))

        const os = getMobileOperatingSystem()
        this.setState(state => ({...state, os: os}))

        const now = new Date()
        const moment_date = moment(now);
        const reduxDate = moment_date.utc().format();
        this.setState(state => ({...state, transactionData: {...state.transactionData, date: reduxDate}}))
       

        if (os === "Android") {

            const stateDate = moment_date.local().format("YYYY-MM-DDTHH:mm")
            this.setState(state => ({...state, stateDate: stateDate}))

        } else {

            const stateDate = moment_date.local().format("YYYY-MM-DD")
            const stateTime = moment_date.local().format("HH:mm")
            this.setState(state => ({...state, stateDate: stateDate, stateTime: stateTime}))

        }

        if (this.props.scope === 'edit') {

        } else {

        }

        const last_index = this.props.transaction.number
        const next_index = last_index + 1
        this.props.addTransaction({key: next_index})
        this.setState(state => ({...state, transaction_id: next_index}))
        
    }
}

    handleCoinSelect = (val, type) => { //

      this.setState(state => ({...state, 
        transactionData: {
            ...state.transactionData, 
            [type]: val, 
            fund_source: null,
        }}))
      this.props.updateTransaction({key: this.state.transaction_id, label: type, value: val})
      this.setState(state => ({...state, scopeCoin: null, coinModal: false }))
      if (type === "base_currency") {

        this.setState(state => ({...state, 
            source: null,
            transactionData: {
                ...state.transactionData, 
                transaction_description: undefined, 
                amount: null,
                exchange: null,
                unit_price: null,
                account: null, 
                quote_currency: null,
            }}))
      }

      else if (type === 'quote_currency') {

        let holding = 0
        try {
            holding = this.props.portfolioHoldings[this.props.portfolioId][val.id]
        } catch(e) {

        }

        if (holding > 0) {
            this.selectFundType('deduct')
        } else {
            this.selectFundType('add')
        }



      }

    }

    handleTypeSelect = (val) => { //
        this.setState(state => ({...state, source: null, transactionData: {...state.transactionData, transaction_description: val, exchange: null, account: null}}))
        if (val !== "buy" && val !== "ico" && val !== "sell") {
            this.setState(state => ({...state, 
                transactionData: {
                    ...state.transactionData, 
                    quote_currency: null,
                    amount: null, unit_price: null
                }}))  
        }
        this.typeModalClose()

    }

    handleExchangeSelect = (val) => { //

        this.setState(state => ({...state, source: 'exchange', transactionData: {...state.transactionData, exchange: val, storage: null, storage_name: null}}))
        const exchangeAccounts = this.props.wallets[this.props.user_id]['exchange']
        
        if (val['id'] in exchangeAccounts) {

            const selectedWallet = exchangeAccounts[val['id']][Object.keys(exchangeAccounts[val['id']])[0]]

            this.setState(state => ({...state, accounts: exchangeAccounts[val['id']], showAccount: true, transactionData: {...state.transactionData, account: selectedWallet}}))


        } else {

            this.setState(state => ({...state, transactionData: {...state.transactionData, account: -1}}))

        }
        this.exchangeModalClose()

    }

    selectType = (val) => { //

        if ( (val === "buy" || val === "sell" || val === "ico") || ( (val === "deposit" || val === "withdraw") && (this.state.transactionData['base_currency']['asset_type'] === 'fiat' ) ) )  {
            this.setState(state => ({...state, source: 'exchange'}))
        } else {
            this.setState(state => ({...state, source: null, amount: null, unit_price: null,}))
        }

        if (val !== "buy" && val !== "ico" && val !== "sell") {
            this.setState(state => ({...state, 
                transactionData: {
                    ...state.transactionData, 
                    quote_currency: null,
                    fund_source: null
                }}))  
        }
        this.setState(state => ({...state, transactionData: {...state.transactionData, transaction_description: val}})) 

    }

    selectAccount = (val) => { //

        this.setState(state => ({...state, transactionData: {...state.transactionData, account: val}})) 
        this.accountModalClose()

    }

    selectFundType = (val) => {

        this.setState(state => ({...state, transactionData: {...state.transactionData, fund_source: val}})) 

    }

    selectSource = (val) => { //

        this.setState(state => ({...state, source: val}))

    }

    coinModalClose = () => { //

        this.setState({ coinModal: false });

    };

    handleDate = (e) => { //

        e.persist();
        this.setState(state => ({...state, stateDate: e.target.value})) 
        
        if (this.state.os === "Android") {

            console.log(e.target.value)
            const date = new Date(e.target.value)
            const moment_date = moment(date);
            const reduxDate = moment_date.utc().format();
            this.setState(state => ({...state, transactionData: {...state.transactionData, date: reduxDate}}));

        } else {

            console.log(e)
            if (this.state.stateTime !== null && this.state.stateTime !== undefined) {

                const total_date = `${e.target.value}T${this.state.stateTime}`
                console.log(total_date)
                const date = new Date(total_date)
                console.log(date)
                const moment_date = moment(date);
                const reduxDate = moment_date.utc().format();
                this.setState(state => ({...state, transactionData: {...state.transactionData, date: reduxDate}}))

            } else {

                const total_date = `${e.target.value}T00:00`
                const date = new Date(total_date)
                const moment_date = moment(date);
                const reduxDate = moment_date.utc().format();
                this.setState(state => ({...state, transactionData: {...state.transactionData, date: reduxDate}}))

            }


        }


    }

    handleTime = (e) => { //
        e.persist();
        this.setState(state => ({...state, stateTime: e.target.value})) 

        if (this.state.stateDate !== null && this.state.stateDate !== undefined) {

            const total_date = `${this.state.stateDate}T${e.target.value}`
            const fake_utc_date = new Date(total_date)
            const timeOffset = new Date().getTimezoneOffset();
            const real_date = new Date(fake_utc_date.getTime() + (timeOffset*60*1000)) 
            const moment_date = moment(real_date);
            const reduxDate = moment_date.utc().format();
            this.setState(state => ({...state, transactionData: {...state.transactionData, date: reduxDate}}))

        } else {

            const now = new Date()
            const now_moment_date = moment(now);
            const stateDate = moment_date.local().format("YYYY-MM-DD")
            const total_date = `${stateDate}T${e.target.value}`
            const fake_utc_date = new Date(total_date)
            const timeOffset = new Date().getTimezoneOffset();
            const real_date = new Date(fake_utc_date.getTime() + (timeOffset*60*1000)) 
            const moment_date = moment(real_date);
            const reduxDate = moment_date.utc().format();
            this.setState(state => ({...state, transactionData: {...state.transactionData, date: reduxDate}}))

        }



    }

    coinModalOpen = (coin) => { //

        this.setState({ coinModal: true, scopeCoin: coin});

    };

    typeModalClose = () => { //

        this.setState({ typeModal: false });

    };

    typeModalOpen = () => { //

        this.setState({ typeModal: true});

    };


    exchangeModalClose = () => { //

        this.setState({ exchangeModal: false });

    };

    exchangeModalOpen = () => { //

        this.setState({ exchangeModal: true});

    };

    accountModalClose = () => { //

        this.setState({ accountModal: false });

    };

    accountModalOpen = () => { //

        this.setState({ accountModal: true});

    };


    storageWalletModalClose = () => { //

        this.setState({ storageWalletModal: false });

    };

    storageWalletModalOpen = () => { //

        this.setState({ storageWalletModal: true });

    };


    selectStorageWalletAccount = (val) => {

        this.setState(state => ({...state, transactionData: {...state.transactionData, storage: val, exchange: null, account: null}}));
        this.storageWalletModalClose();

    }

    


    userModalClose = () => { //

        this.setState({ userModal: false });

    };

    userModalOpen = () => { //

        this.setState({ userModal: true});

    };
    
    handleInput = (e, val) => { //
        e.persist();
        const received = e.target.value
        this.setState(state => ({...state, transactionData: {...state.transactionData, [val]: received}})) 

        if (val === 'unit_price') {
            let priceSend = null;
            if (this.props.priceType === 'unit') {
                priceSend = received * this.state.transactionData['amount']
            } else {
                priceSend = received / this.state.transactionData['amount']
            }
            this.setState(state => ({...state, priceDisplay: priceSend}))
        } else if (val === 'amount') {
            let priceSend = null;
            if (this.props.priceType === 'unit') {
                priceSend = this.state.transactionData['unit_price'] * received
            } else {
                priceSend = this.state.transactionData['unit_price'] / received 
            }
            this.setState(state => ({...state, priceDisplay: priceSend}))
        }

       

    }

    handleUser = (val) => { //

        this.setState(state => ({...state, transactionData: {...state.transactionData, user_transaction: val}}));
        this.userModalClose();

    }

    handleFeeToggle = () => { //

        this.setState(state => ({...state, fee_check: !state.fee_check, transactionData: {...state.transactionData, fee: null, fee_currency: null}})) 

    }
 
    handleWalletToggle = () => { //

        this.setState(state => ({...state, wallet_check: !state.wallet_check, transactionData: {...state.transactionData, account: null, exchange: null, account_name: null, storage: null, storage_name: null}})) 

    }

    newWallet = (name, address) => {
        this.setState(state => ({...state, transactionData: {...state.transactionData, storage: -1, storage_name: name, storage_address: address}})) 
    }

    handleNext = () => {
        const newStep = this.state.step + 1
        this.setState(state => ({...state, step: newStep}))
    }
    handlePrevious = () => {
        const newStep = this.state.step - 1
        this.setState(state => ({...state, step: newStep}))
    }

    updatePriceType = (v) => {
        this.setState(state => ({...state, priceDisplay: v}))
    }

    stepSwitch = (step) => {

        switch(step) {

            case 0: 
                return (

                    <Step1 
                        priceDisplay={this.state.priceDisplay}
                        passState={this.state}
                        selectFundType={this.selectFundType}
                        coinModalOpen={this.coinModalOpen}
                        handleTypeSelect = {this.handleTypeSelect}
                        selectType = {this.selectType}
                        selectSource={this.selectSource}
                        coinModalClose={this.coinModalClose}
                        updatePriceType={this.updatePriceType}
                        handleDate={this.handleDate}
                        handleTime={this.handleTime}
                        typeModalClose={this.typeModalClose}
                        typeModalOpen={this.typeModalOpen}
                        userModalClose={this.userModalClose}
                        userModalOpen={this.userModalOpen}
                        handleInput={this.handleInput}
                        handleUser={this.handleUser}
                        handleFeeToggle={this.handleFeeToggle}
                        handleWalletToggle={this.handleWalletToggle}
                        new={this.props.new}
                        />

                )
            
            case 1: 
                return (

                    <Step2 
                        passState={this.state}
                        coinModalOpen={this.coinModalOpen}
                        handleTypeSelect = {this.handleTypeSelect}
                        selectType = {this.selectType}
                        selectSource={this.selectSource}
                        coinModalClose={this.coinModalClose}
                        handleDate={this.handleDate}
                        handleTime={this.handleTime}
                        typeModalClose={this.typeModalClose}
                        typeModalOpen={this.typeModalOpen}
                        exchangeModalOpen={this.exchangeModalOpen}
                        userModalClose={this.userModalClose}
                        userModalOpen={this.userModalOpen}
                        handleInput={this.handleInput}
                        handleUser={this.handleUser}
                        handleFeeToggle={this.handleFeeToggle}
                        handleWalletToggle={this.handleWalletToggle}
                        />

                )
            
            case 2: 
                return (

                    <Step3
                        passState={this.state}
                        coinModalOpen={this.coinModalOpen}
                        handleTypeSelect = {this.handleTypeSelect}
                        selectType = {this.selectType}
                        selectSource={this.selectSource}
                        coinModalClose={this.coinModalClose}
                        handleDate={this.handleDate}
                        handleTime={this.handleTime}
                        typeModalClose={this.typeModalClose}
                        typeModalOpen={this.typeModalOpen}
                        exchangeModalOpen={this.exchangeModalOpen}
                        accountModalOpen={this.accountModalOpen}
                        userModalClose={this.userModalClose}
                        userModalOpen={this.userModalOpen}
                        handleInput={this.handleInput}
                        handleUser={this.handleUser}
                        handleFeeToggle={this.handleFeeToggle}
                        handleWalletToggle={this.handleWalletToggle}
                        storageWalletModalOpen={this.storageWalletModalOpen}
                        />

                )
            default: 
                return (<div></div>)
        }
    } 


    backHandler = () => {
        
        if (this.state.step === 0) {
            this.props.cancelAdd()
        } else {
            this.handlePrevious()
        }
    }
    
    render () {

        const { classes } = this.props; 

        return (   
            
                this.state.loading ? 

                    <div>

                        <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', top: 0}} id="responsive-dialog-title">

                        </div>
    
                        <div style={{marginTop: 75, marginBottom: 75, backgroundColor: 'white', paddingTop: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
                                
                                <img style={{ height: 90, width: 90, marginTop: 90}} src={loadingSpinner} alt=""/>

                        </div>
                        
                    </div>

                :


                this.state.success ? 
                    

                    <div>

                        <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', top: 0}} id="responsive-dialog-title">

                        </div>

                        <div style={{marginTop: 75, marginBottom: 12, backgroundColor: 'white', paddingTop: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
                                
                            <img style={{ height: 90, width: 90, marginTop: 90}} src={okIcon} alt=""/>
                            

                        </div>

                        { this.state.passedData ? 

                            <div className="basier" style={{textAlign: 'center', fontSize: 14}}>Succesfully edited transaction</div>

                        : 
                        
                            <React.Fragment>
                                <div className="basier" style={{textAlign: 'center', fontSize: 14}}>Succesfully added transaction</div>

                                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}} >
                                    <div
                                        disabled={this.state.step === 0}
                                        onClick={() => this.props.cancelAdd()}
                                        className='button-next'
                                        style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                        >
                                        Close
                                    </div>

                                    <div
                                        disabled={this.state.step === 0}
                                        onClick={this.handleReset}
                                        className='button-next-blue'
                                        style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                        >
                                        Add another
                                    </div> 
                                </div>
                            </React.Fragment>

                        }

                    </div>


                : 

                this.state.error ?

                <div>

                    <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', top: 0}} id="responsive-dialog-title">

                    </div>

                    <div style={{marginTop: 75, marginBottom: 75, backgroundColor: 'white', paddingTop: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
                            
                        <img style={{ height: 90, width: 90, marginTop: 90}} src={errorIcon} alt=""/>

                    </div>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}} >
                            <div
                                disabled={this.state.step === 0}
                                onClick={() => this.props.cancelAdd()}
                                className='button-next'
                                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer', backgroundColor: '#F9716B', color: 'white'}}
                                >
                                Cancel
                            </div>

                            <div
                                disabled={this.state.step === 0}
                                onClick={this.submitTransaction}
                                className='button-next-blue'
                                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                >
                                Retry
                            </div> 
                    </div>
                
                </div>

                :

                <div>

                    <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', top: 0}} id="responsive-dialog-title">
                    
                        <div onClick={this.backHandler}>
                            <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                        </div>

                        <div>
                            
                        </div>

                    </div>
    
                <div style={{marginTop: 75, marginBottom: 75, backgroundColor: 'white', paddingTop: 0, width: '100%'}}>

                        <div style={{marginTop: 12, marginBottom: 12, width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <MobileStepper
                                variant="progress"
                                steps={3}
                                position="static"
                                activeStep={this.state.step}
                                classes={{
                                    root: classes.root,
                                }}
                                />
                        </div>
                        

                        {this.stepSwitch(this.state.step)}

                        { 
                            this.state.step === 0 && 
                            this.state.transactionData['base_currency'] !== null && this.state.transactionData['base_currency'] !== undefined && 
                            this.state.transactionData['amount'] !== null && this.state.transactionData['amount'] !== undefined && 
                            ( (   
                                ( this.state.transactionData['transaction_description'] === "buy" || this.state.transactionData['transaction_description'] === "ico" ||this.state.transactionData['transaction_description'] === "sell") && 
                                ( this.state.transactionData['quote_currency'] !== null && this.state.transactionData['quote_currency'] !== undefined ) && 
                                ( this.state.transactionData['unit_price'] !== null && this.state.transactionData['unit_price'] !== undefined ) 
                            
                            ) || ( this.state.transactionData['transaction_description'] !== "buy" && 
                            this.state.transactionData['transaction_description'] !== "ico" && this.state.transactionData['transaction_description'] !== "sell") ) 

                            ?

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <div className='button-next-blue' onClick={this.handleNext} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                                    Next
                                </div>
                            </div>
                            
                            :

                            null
                            
                        }

                        { 
                            this.state.step === 1 ?

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}}>
                                <div
                                    onClick={this.handlePrevious}
                                    className='button-next'
                                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                >
                                    Back
                                </div>

                                {   
                                   this.state.transactionData['date'] !== null && this.state.transactionData['date'] !== undefined && 
                                   this.state.transactionData['user_transaction'] !== null && this.state.transactionData['user_transaction'] !== undefined ?
                                    
                                    <div className='button-next-blue' onClick={this.handleNext} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                                        Next
                                    </div>


                                   
                                   :

                                   null


                                }
                            </div>


                            
                            :

                            null
                            
                        }

                        { 
                            this.state.step === 2
                            ?

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}}>
                                <div
                                    onClick={this.handlePrevious}
                                    className='button-next'
                                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                >
                                    Back
                                </div>
                                
                                {   
                                   this.state.transactionData['user_transaction'] !== null && this.state.transactionData['user_transaction'] !== undefined ?
                                    
                                    <div className='button-next-blue' onClick={this.submitTransaction} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                                        Submit
                                    </div>


                                   
                                   :

                                   null


                                }
                            </div>


                            
                            :

                            null
                            
                        }
                        


                        <CoinList coins={this.props.coins} openModal={this.state.coinModal} closeModal={this.coinModalClose} handleSelect={(res) => this.handleCoinSelect(res, this.state.scopeCoin)}/>
                        
                        {this.state.transactionData['base_currency'] !== undefined && this.state.transactionData['base_currency'] !== null ? 
                            <TypeList scope={this.state.transactionData['base_currency']['asset_type']} openModal={this.state.typeModal} closeModal={this.typeModalClose} handleSelect={(res) => this.handleTypeSelect(res)}/>
                        :
                        null 
                        }

                        {this.props.portfolio_users !== [] && this.props.portfolio_users !== null && this.props.portfolio_users !== undefined ? 

                            <UserList users={this.props.portfolio_users} openModal={this.state.userModal} closeModal={this.userModalClose} handleSelect={(res) => this.handleUser(res)}/>
                        
                        : 
                        
                        null 
                        
                        }

                        {this.props.portfolio_users !== [] && this.props.portfolio_users !== null && this.props.portfolio_users !== undefined ? 

                            <ExchangeList exchanges={this.props.exchanges} openModal={this.state.exchangeModal} closeModal={this.exchangeModalClose} handleSelect={(res) => this.handleExchangeSelect(res)}/>
                        
                        : 
                        
                        null 
                        
                        }


                        {this.state.transactionData['exchange'] !== undefined && this.state.transactionData['exchange'] !== null ? 
                            <AccountList selected={this.state.transactionData['account']} accounts={this.state.accounts} openModal={this.state.accountModal} closeModal={this.accountModalClose} handleSelect={(res) => this.selectAccount(res)}/>
                        :
                        null 
                        }

                        {
                            this.state.transactionData['user_transaction'] !== undefined && this.state.transactionData['user_transaction'] !== null ? 

                            <WalletList wallets={this.props.wallets} user={this.state.transactionData['user_transaction']} base_currency={this.state.transactionData['base_currency']} newWallet={(name, address) => this.newWallet(name, address)} openModal={this.state.storageWalletModal} closeModal={this.storageWalletModalClose} handleSelect={(res) => this.selectStorageWalletAccount(res)}/>

                            :

                            null
                        }
                        



                    </div>
                </div>
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        currency_id: state.auth.currency.id,
        coins: state.appwide.all_coins,
        transaction: state.interactions.transactionAddData,
        portfolio_users: state.data.scopedUsers, 
        user_id: state.auth.user_id, 
        user_first_name: state.auth.user_first_name,
        user_last_name: state.auth.user_last_name,
        exchanges: state.appwide.exchanges, 
        wallets: state.appwide.wallets,
        inBitcoin: state.data.bitcoinPf, 
        token: state.auth.token,
        priceType: state.interactions.priceType, 
        portfolioHoldings: state.data.portfolioHoldings,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)),
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        callUserList: (portfolio) =>
          dispatch(dataActions.callUserList(portfolio)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        setCurrentView: (v) => dispatch(dataActions.setCurrentView(v))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)((withStyles(styles)(TransactionSmallEditCreate)));
