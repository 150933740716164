import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      border: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    border: '1px solid white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    display: 'block',
    padding: 6,
  },
}))(MuiExpansionPanelDetails);

const CustomizedExpansionPanels = props => {
  //const [expanded, setExpanded] = React.useState('panel1');

  return (

      <ExpansionPanel expanded={props.open}>
        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={!props.error ? <ExpandMoreIcon /> : null}>
        <div><span className="basier-p2" style={{ color: props.error ? '#F9716B' : null, fontWeight: props.error ? 900 : 400 }}>{props.title}</span> {!props.error && <span style={{marginLeft:4}} className="basier-p4">(click to toggle view)</span>}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {props.children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      
  );
}

export default CustomizedExpansionPanels