import React from 'react'
import PortfolioCard from './PortfolioCard'
import PortfolioAdd from './PortfolioAdd'
import { connect } from 'react-redux';
import {createLoadingSelector} from '../../../store/reducers/selectors'
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden';
import SwipeableViews from 'react-swipeable-views';
import Carousel from 'nuka-carousel';
import Slider from "react-slick";
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import PortfolioBK from '../../../Media/PortfolioBK.svg'
import contentLoader from '../../../Media/contentLoader.svg'



const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    
  },
  tabsRoot: {
    flexGrow: 1,
    backgroundColor: '#F8F9FA',
    overflow: 'hidden', 
    height: 500
  },
  tabsIndicator: {
    backgroundColor: '#0078DB',
    left: 0, 
    width: 160, 
    top: 48,
  },
  scrollable: {
    paddingBottom: 20,
  },
});

const options = [];

class PortfolioList extends React.Component {

  state = {
    portfolioList: []
  }
  
  componentDidMount() {

    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, {passive: false});

    //this.setState(state => ({...state, portfolioList: this.props.portData}))

    }

    componentDidUpdate(prevProps) {

    /*   if (this.props.portData !== prevProps.portData) {
    
        this.setState(state => ({...state, portfolioList: this.props.portData}))    

      } */
    
    }

    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, {passive: false});
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 15; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if(Math.abs(this.clientX) > minValue){ 
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }
    
    render() {

    //const number_port = (Object.keys(this.props.portData).length) + 1
    const to_show = Math.round(window.innerWidth / (280+36))

    const settings = {
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      variableWidth: true,
      speed: 500,
      initialSlide: 0,
      className: "slider-wrapper",
      adaptiveHeight: true,
      accessibility: true,
      slide:"span",
      touchThreshold: 7,
      responsive: [
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: true,
            infinite: true,
            adaptiveHeight: true,
          }
        },
      ]
    };
    const { classes } = this.props;

    return (
        <div>
            <div>

                    <Tabs
                      classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator, scroller: classes.scrollable}}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="portfolios"
                    >
                        { this.props.portList.map((k, index) => {

                            return (
                            
                              
                            <Link component={RouterLink} to={`/portfolio/${k['id']}`}>
                              {this.props.portData[k['id']] !== undefined ? 
                                <PortfolioCard key={index} data={this.props.portData[k['id']]}/>
                                :
                                <div style= {{
                                  height: 431, 
                                  width: 280, 
                                  borderRadius: 20, 
                                  boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                                  backgroundColor: '#fff',
                                  position: 'relative', 
                                  display: 'inline-block', 
                                  overflow: 'hidden',
                                  }}
                                  className="portfolio-card carousel-card hvr-grow-shadow-medium">
                                      <div style={{
                                          position: 'absolute',
                                          bottom: 0, 
                                          left: 0,
                                      }}>
                                      <img src={PortfolioBK} alt=""/>
                                      </div>
                                      <div style={{
                                          position: 'absolute',
                                          top: 27, 
                                          left: 15,
                                          color: 'black'
                                          }} 
                                          className="basier-p2">
                                          {k['name']}
                                      </div>

                                      <div style={{
                                          position: 'absolute', 
                                          left: 15, 
                                          top: 63}}>
                                          <img src={contentLoader} style={{width: 75, height: 75}} alt="" />
                                      </div>
                                    
                                </div>
                               }
                            </Link>
                                

                        )
                    }
                    
                    )}
                  </Tabs>
            

            </div>


        </div>
    )

    }
    
}


const graphPortfolioSelector = createLoadingSelector(['UPDATE_ALL_PORTFOLIOS']);

const mapStateToProps = state => {
    return {
        portData: state.data.portfoliosData,
        portList: state.auth.list_portfolios,
        loading_port: graphPortfolioSelector(state),
    };
};

export default connect(
    mapStateToProps,
    null,
)(withStyles(styles)(PortfolioList));