import React from 'react';
import { connect } from 'react-redux';

var _ = require('lodash/array');

class PendingCoins extends React.PureComponent {


    state = {
        new_coins: {},
        currentPage: 0,
        coinPerPage: 9, 
        maxPage: null,
        options: null
    }

    updateServer = () => {
        
        this.props.updateServer('exchange_confirm', null)

    }

    nextPage = () => {
        const calc = this.state.currentPage + 1
        const new_index = Math.min(this.state.maxPage - 1, calc)
        this.setState(state => ({...state, currentPage: new_index}))

    }

    previousPage = () => {
        const calc = this.state.currentPage - 1
        const new_index = Math.max(0, calc)
        this.setState(state => ({...state, currentPage: new_index}))

    }

    componentDidMount() {
        const max_per_page = this.props.data['data']['scope'].length > 9 ? 8 : 9
        const chunks = _.chunk(this.props.data['data']['scope'], max_per_page)
        const max_page = Math.ceil(this.props.data['data']['scope'].length / max_per_page)
        this.setState(state => ({...state, options: chunks, maxPage: max_page, coinPerPage: max_per_page}))
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.options !== this.props.option) {
            const max_per_page = this.props.data['data']['scope'].length > 9 ? 8 : 9
            const chunks = _.chunk(this.props.data['data']['scope'], max_per_page)
            const max_page = Math.ceil(this.props.data['data']['scope'].length / max_per_page)
            this.setState(state => ({...state, options: chunks, maxPage: max_page, coinPerPage: max_per_page}))
        }
    } 
 
    handleSelect = (old, new_ticker) => {

        this.setState(state => ({...state, new_coins: {...state.new_coins, [old]: new_ticker}}))

    }

    handleSubmit = () => {
        console.log(this.state.new_coins)
        this.updateServer()
    }


    render () {

        return (
            
            <div>
                <div style={{width: '100%', textAlign: 'center', }}>
                    <h5 style={{color: '#C174FD', fontWeight: 900}}>
                        Action required
                    </h5>

                    <div className="basier-p4">
                        The following users do not have a Wisly account currently. We can still add them to your portfolio, and we will send them an invitation to join Wisly and start collaborating with you! Do you wish to proceed?
                    </div>
                     
                </div>

                <div style={{marginTop: 12, marginBottom: 0, paddingRight: 12, paddingLeft: 12, marginRight: 12, height: this.props.data['data']['scope'].length > 9 ? 250 : 300 }}>
                    

                    {
                        this.props.data['data']['scope'] !== null && this.props.data['data']['scope'] !== undefined && this.state.options !== null && this.state.options !== undefined ?

                        this.state.options[this.state.currentPage].map ((val, key) => {
                            return (
                            <div key={key} style={{marginTop: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', textAlign: 'center'}}>
                                <div style={{color: 'black'}}>{val}</div>
                            </div>
                            )

                        })  

                        :

                        null

                    }


                </div>
                
                {this.props.data['data']['scope'].length > 9 ?

                <div style={{width: '100%', marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', height: 50}}>
                    <div className={`basier-p4-caps ${this.state.currentPage === 0 ? null : 'blue-hover'}`} onClick={this.previousPage} style={{marginRight: 16, cursor: `${this.state.currentPage === 0 ? 'not-allowed' : 'pointer'}`}}> 
                        &lt; Previous 
                    </div>

                    <div style={{color: 'black'}} className="basier-p4-caps">Page {this.state.currentPage + 1} of {this.state.maxPage}</div>

                    <div className={`basier-p4-caps ${this.state.currentPage === this.state.maxPage - 1 ? null : 'blue-hover'}`} onClick={this.nextPage} style={{marginLeft: 16, cursor: `${this.state.currentPage === this.state.maxPage - 1 ? 'not-allowed' : 'pointer'}`}}> 
                        Next &gt; 
                    </div>
                </div>

                :

                null}

                <div style={{width: '100%', marginTop: 12, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    <div className='basier' 
                        style={{height: 35, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 0, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        Cancel
                    </div>

                    <div className='button-next-blue' onClick={() => this.handleSubmit()} 
                        style={{height: 35, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 0, cursor: 'pointer'}}>
                        Submit
                    </div>

                </div> 

            </div>

        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PendingCoins)
