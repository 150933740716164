import React from "react";
import { FixedSizeList as List } from "react-window";
import CloseIcon from '../../Media/CloseIcon.svg'
import Grid from '@material-ui/core/Grid';



class CoinFlatList extends React.PureComponent {

    

    constructor(props) {

      super(props)
      this.inputRef = React.createRef();

      this.state = {
        initialItems: [], 
        items: [], 
      }

    }

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }

      getListSize = () => {
          return this.state.items.length
      }
      
      getSuggestions = (event) => {
        const value = event.target.value

        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
           
          this.setState(state => ({...state, items: this.state.initialItems}))
          return [];
        }
      
        const regex = new RegExp('\\b' + escapedValue, 'i');
        
        let filteredList = this.state.initialItems.filter(coin => regex.test(this.getSuggestionValue(coin)));

        if (filteredList.length === 0) {
          filteredList = [{name: value, id: value, custom: true}]
        }

        this.setState(state => ({...state, items: filteredList})) 

      }

      getSuggestionValue  = (suggestion) => {
        return `${suggestion.name}`;
      }

      selectCoin = (val) => {
        this.props.handleSelect(val)
      }

      getRef = async (ref) => {
        return ref;
    }
    
      componentDidMount() {

        const ref_needed = this.getRef(this.inputRef)

        ref_needed
            .then(res => {
                console.log(res)
                res.current.focus()
            })
        
        window.scrollTo(0, 0)

        this.setState(state => ( {...state, initialItems: this.props.exchanges, items: this.props.exchanges } ))

      }

    componentWillUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
      }

    componentWillUpdate() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
    }

      clearInput = () => {
          document.getElementById('coin-search').value = '';
          this.setState(state => ( {...state, items: this.state.initialItems}))
      }

      Row = ({ data, index, style }) => {
        // Data passed to List as "itemData" is available as props.data
        const item = data[index]
        return (
            <div style={{...style, 
                        paddingLeft: 24, 
                        paddingRight: 24, 
                        }} value={item.id}
                        className="coin-flat-list-item basier-p2"
                        onClick={() => this.selectCoin(item)}>
            <span><img src={item.image_thumb} style={{marginRight: 12, height: 30, width: 30}} alt=""/>{item.name}</span>
            <span>></span>
            </div>
        );
    };

      render() {
        
        const heightVitrual = window.screen.height - 75; 
        const widthVirtual = window.innerWidth;
        const itemHeight = 65

        return (
            <div className="filter-list" style={{height: '100%', position: 'fixed', top: 0}}>

            <div style={{backgroundColor: '#F8F9FA', 'boxShadow': 'none', height: 75, position: 'fixed', width: '100%'}}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                > 
        
                <Grid xs={2} sm={2} style={{zIndex: 1500}} className="over-all">
                  <img style={{marginLeft: '12px', paddingTop: '12px'}} src={CloseIcon} alt="" onClick={this.props.closeAction}/> 
                </Grid>
                <Grid xs={7} sm={7} style={{zIndex: 1500}} className="over-all">
                    <input 
                        id="coin-search"
                        autocorrect="off"
                        autocomplete="off"
                        ref={this.inputRef}
                        type="text" placeholder="Search" onChange={this.getSuggestions} className="mobile-coin-input"/>
                </Grid>
                <Grid xs={3} sm={3} style={{zIndex: 1500}} className="over-all">
                  <div style={{marginLeft: 24}} className="basier-p2" onClick={this.clearInput}>Clear</div>
                </Grid>

              </Grid>
            </div>

              {this.state.items !== undefined ? 
                <div style={{marginTop: 75}}>
                <List
                    height={heightVitrual}
                    itemCount={this.state.items.length}
                    itemData={this.state.items}
                    itemSize={itemHeight}
                    width={widthVirtual}
                    >
                    {this.Row}
                </List>
                </div>

              :

              null 

              }
              
          </div>
        )
      }

}

export default CoinFlatList