
export const updateLocal = (data) => {

    if (localStorage.hasOwnProperty("user")) {

        let user = JSON.parse(localStorage.getItem("user"));
        const newData = {
            ...user, 
            ...data
        }
        localStorage.setItem("user", JSON.stringify(newData));

        return true
    }
    return false

}