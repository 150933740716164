import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    
  },
  tabsRoot: {
    flexGrow: 1,
    backgroundColor: '#F8F9FA',
    overflow: 'hidden', 
    height: 50,
    
  },
  tabsIndicator: {
    backgroundColor: '#0078DB',
    left: 0, 
    width: 160, 
    top: 48,
  },
  scrollable: {
    paddingBottom: 20,
  },
  tabRoot: {
    color: '#6A7E93',
    textTransform: 'initial',
    minWidth: '72',
    fontSize: '1rem',
    fontWeight: 400,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      'Basier Circle',
    ].join(','),
    '&:hover': {
      color: 'black',
      opacity: 1,
    },
    '&$tabSelected': {
      color: 'black',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: 'black',
    },
  },
  tabCointainer: {
      paddingBottom: 0,
  },
  tabScroller: {
  },
  tabSelected: {},
  scrollButtons: {
    marginBottom: 15,
  },

  typography: {
    padding: theme.spacing.unit * 3,
  },
});

class CustomizedTabs extends React.Component {
  
  render() {
    const { classes, handleChange } = this.props;

    return (
      <div className={`${classes.root} tab-position`}>

        <Hidden mdDown>
            <Tabs
            value={this.props.value}
            centered
            onChange={handleChange}
            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator, scroller: classes.scrollable}}
            >
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer  }}
                label="Overview"
                value={0}
            />
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
                label="Profits"
                value={1}
            />
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
                label="Transaction"
                value={2}
            />
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
                label="Reports"
                value={3}
            />
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
                label={this.props.portfolioType === 'individual' ? 'Balances' : this.props.plan.plan_id === 'pro' || this.props.plan.plan_id === 'unlimited'  ? 'Collaborators' : 'Balances'}
                value={5}
            />

            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
                label="Settings"
                value={6}
            />

            </Tabs>
        </Hidden>

        <Hidden only={['xs', 'sm', 'lg', 'xl']}>
            <Tabs
            value={this.props.value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator, scroller: classes.scrollable, scrollButtons: classes.scrollButtons}}
            className="scrollable-tabs"
            >
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer  }}
                label="Overview"
                value={0}
            />
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
                label="Profits"
                value={1}
            />
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
                label="Transaction"
                value={2}
            />
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
                label="Reports"
                value={3}
            />
            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
                label={this.props.portfolioType === 'individual' ? 'Balances' : this.props.plan.plan_id === 'pro' || this.props.plan.plan_id === 'unlimited'  ? 'Collaborators' : 'Balances'}
                value={5}
            />

            <Tab
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
                label="Settings"
                value={6}
            />
            
            </Tabs>
            
        </Hidden>

      </div>
    );
  }
}

CustomizedTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTabs);