import React from 'react';
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import axios from 'axios';
import { backendUrl } from '../../../variables';
import SingleCollabList from '../../AppWide/SingleCollabList';
import RoleList from '../../Portfolio/PortfolioComponents/Settings/Large/RoleList';
import addCollabBK from "../../../Media/AddCollab.svg"
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import SelectUserDrawer from './ModalComps/SelectUserDrawer'
import {loadData} from '../../../helpers/helpers'

const styles = theme => ({
    container: {
        width: '100%', 
        padding: 48, 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 12, 
        },
    },
    handheld: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 13
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 15
        },
    },
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0, paddingRight: 0, 
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 48, paddingRight: 48, 
        },
       
    },
    firstContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 24
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 48
        },
    },
    smallMargin: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 12, marginRight: 12,
        }, 
    },
    sencondCointainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
    },
    firstWidth: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 324, justifyContent: 'space-between',
        },
        [theme.breakpoints.up('md')]: {
            display: 350, height: 350, justifyContent: 'space-between',
            paddingLeft: 24, paddingRight: 24, 
        },
    },
})
const generateUserRoles = (plan, numberManagers, limit) => {

    if (numberManagers == limit) {
        return [
            {value: "observer", label: "OBSERVER"},
        ]
    }
    console.log('the plan is', plan)
    switch(true) {
        case (plan === 'pro') || (plan === 'unlimited'): {
            return [
                {value: "admin", label: "ADMIN"}, 
                {value: "manager", label: "MANAGER"},
                {value: "observer", label: "OBSERVER"},
            ]
        }
        default: {
            return [
                {value: "manager", label: "MANAGER"},
                {value: "observer", label: "OBSERVER"},
            ]
        }
    }

}


class AddCollab extends React.PureComponent {

    state = {
        managers: this.props.data.users.filter(v => v.role !== 'observer').length,
        observers: this.props.data.users.filter(v => v.role === 'observer').length, 
        users: [],
        addedUser: null,
        userRole: null,
        userModal: false,
        }
    
    componentDidMount() {
        this.onMount()
    }

    updateData = (label, val) => {

        this.setState(state => ({...state, data: {...state.data, [label]: val}}) )

    }

    onMount = () => {
        this.setState(state => ({...state, userRole: this.state.managers === this.props.data.portfolio_limit ? 'observer' : 'manager'}))
    }

    changeRole = (v) => {
        this.setState(state => ({...state, userRole: v}))
    }

    userModalOpen = () => {
        this.setState(state => ({...state, userModal: true}))
    }

    userModalClose = () => {
        this.setState(state => ({...state, userModal: false}))
    }

    handleCreate = (inputValue) => {
        console.log('in create with', inputValue)
        this.setState({ isLoading: true });
    
        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const data_fetch = loadData(`${backendUrl}/v1/check_user/?email=${inputValue}`, headers)
        data_fetch
            .then(json => {

                let newOption = null;
                let proceed = true;
                if (json['exist'] === true) {

                    newOption = json['data']
                
                } else {

                    newOption = {"label": inputValue, "value": -1, plan: "free"}

                }

                if (proceed) {
                    //let newVals = [...this.state.value, newOption]
                    this.setState(state => ({...state,
                        isLoading: false,
                    }));
                    this.handleAdd(newOption)
                } else {
                    this.setState(state => ({...state,
                        isLoading: false}))
                }
                
            })
    
      }

    updateServer = () => {
       
 
        this.setState(state => ({...state, eAloading: true}))
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/portfolio/${this.props.data.portfolioId}/`
         
        const dataSend = {
            userToAdd: this.state.addedUser['id'],
            name: this.state.addedUser['name'],
            role: this.state.userRole,
            user_id: this.props.user_id,
            scope: 'addCollab'
        }

        axios.post(url_call, dataSend, {headers: headers})
            .then(json => {
                
                console.log('user added!')
                this.props.getData()
                
                setTimeout(() => {

                    this.handleClose()
    
                }, 300)
                
            })
            .catch( e => {

            this.setState(state => ({...state, eAloading: false, eAerror: true}))

            })


    }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
        document.body.ontouchmove = (e) => { 
            return true;
        }


      }
    
    
    handleModalAdd = (v) => {
        console.log('value is', v)
        if (v.value == -1) {
            this.handleCreate(v.label)
        } else if (v === null) {
            this.setState(state => ({...state, addedUser: null}))
            return;
        } else {
            this.handleAdd(v)
        }
        this.userModalClose()
    }

    handleAdd = (v) => {
        if (v === null) {
            this.setState(state => ({...state, addedUser: null}))
            return;
        }
        const userData = {
            "id": v.value, 
            "name": v.label, 
            "plan": v.plan,
        }
        this.setState(state => ({...state, addedUser: userData}))
    }

    handleDelete = () => {
        this.setState(state => ({...state, addedUser: null}))
    }

 
    render () {

        const { classes } = this.props;

        return (

            <div style={{zIndex: 99999999}}>

                <BaseModalTop forceSingle={true} title="Add user" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />
                <div className={classes.root} style={{paddingTop: 24, paddingBottom: 24}}>

                    <div className={classes.smallMargin} style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-around', height: 50, backgroundColor: "#F8F9FA", border: '1px solid #6A7E93', zIndex: 99999999}}>

                        <div>
                            <span className={`basier ${classes.responsiveText}`} style={{fontWeight: 400, color: 'black', textTransform: 'uppercase'}}>
                                Number of <span style={{color: '#0078DB'}}>managers</span> remaining: 
                            </span>
                            <span 
                                className={`basier ${classes.responsiveText}`} 
                                style={{
                                    marginLeft: 6,
                                    fontWeight: 900, 
                                    color: (this.props.data.portfolio_limit - this.state.managers) === 0 ? '#F9716B' : '#0078DB', 
                                    textTransform: 'uppercase'
                                    }}>
                                {this.props.data.portfolio_limit - this.state.managers}
                            </span>
                        </div>

                        <div>
                            <span className={`basier ${classes.responsiveText}`} style={{fontWeight: 400, color: 'black', textTransform: 'uppercase'}}>
                                Number of <span style={{color: '#C174FD'}}>observers</span> remaining: 
                            </span>
                            <span 
                                className={`basier ${classes.responsiveText}`} 
                                style={{
                                    marginLeft: 6,
                                    fontWeight: 900, 
                                    color: (10- this.state.observers) === 0 ? '#F9716B' : '#C174FD', 
                                    textTransform: 'uppercase'
                                    }}>
                                {10- this.state.observers}
                            </span>
                        </div>
                    </div>


                    <div className={classes.firstContainer} style={{display: 'flex'}}>
                        

                        <div 
                            className={classes.firstWidth}
                            style={{
                                paddingTop: 12, 
                                display: 'flex', flexDirection: 'column',
                                }}>
                            <div>
                                
                                <div style={{margiTop: 12}}>

                                    <Hidden mdUp>
                                        

                                        <div style={{marginTop: 0, display: 'flex', alignItems: 'center',}}>

                                            <div 
                                                    style={{
                                                    height: 60,
                                                    display: 'flex', 
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    paddingLeft: 24,
                                                    borderBottom: '1px solid #E1E5E9',
                                                    borderTop: '1px solid #E1E5E9',
                                                    width: '100%',
                                                    textTransform: 'uppercase',
                                                    fontWeight: 900
                                                }}
                                                    onClick={() => this.userModalOpen()}
                                                    className="basier-p3">

                                                    
                                                    <div style={{color: 'black', flex: 1, fontWeight: 900, paddingRight: 24}}>
                                                        User
                                                    </div>

                                                    <div style={{marginRight: 24, flex: 2, display: 'flex', alignItems: 'center'}}>

                                                        <div className="truncate"  style={{marginRight: 24, color: '#6A7E93'}}>
                                                            {
                                                                this.state.addedUser !== null ? 
                                                                
                                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                                    <div>{
                                                                        this.state.addedUser['name'].length > 18 ? 
                                                                        `${this.state.addedUser['name'].slice(0,15)}...`
                                                                        :
                                                                        this.state.addedUser['name']
                                                                        }</div>
                                                                </div>
                                                                
                                                                :
                                                                <div>select...</div>
                                                            }
                                                        </div>

                                                        <div>
                                                            >
                                                        </div>
                                                    </div>

                                                    

                                                </div>

                                        </div>

                                    </Hidden>

                                    <Hidden smDown>
                                        <div style={{marginBottom: 12}} className="basier-p4-caps">Select user or type email to add</div>
                                        <SingleCollabList users={this.props.data.users} orgId={this.props.data.org} handleAdd={(v) => this.handleAdd(v)} handleDelete={this.handleDelete} />
                                    </Hidden>
                                    
                                </div>

                                {this.state.addedUser !== null && 
                                
                                <React.Fragment>

                                    <Hidden mdUp>
                                        <div style={{display: 'flex', alignItems: 'center', marginTop: 48, marginLeft: 6, marginRight: 6}}>
                                            {   
                                                generateUserRoles(this.state.addedUser['plan'], this.state.managers, this.props.data.portfolio_limit).map((val, key) => {

                                                    return (
                                                        <div 
                                                            key={key}
                                                            className={`basier-p4-caps transaction-bubble`}
                                                            onClick={() => this.changeRole(val.value)}
                                                            style={{
                                                                marginBottom: 12, 
                                                                fontSize: 20,
                                                                fontWeight: 900, 
                                                                marginRight: 6,
                                                                marginLeft: 6,
                                                                padding: '20px 2px', 
                                                                minWidth: 80, 
                                                                flexGrow: 1,
                                                                height: 50, 
                                                                borderRadius: 10,
                                                                cursor: 'pointer',
                                                                color: this.state.userRole == val.value ? 'white' : 'black',
                                                                backgroundColor: this.state.userRole == val.value ? '#0078DB' : '#F8F9FA'
                                                                }}>
                                                                    {val.label}
                                                                </div>
                                                    )

                                                })
                                            }
                                        </div>
                                    </Hidden>

                                    <Hidden smDown>
                                        <div style={{marginTop: 24}}>
                                            <div style={{marginBottom: 12}} className="basier-p4-caps">User Role</div>
                                            <RoleList roleList={generateUserRoles(this.state.addedUser['plan'], this.state.managers, this.props.data.portfolio_limit)} selected={this.state.managers === this.props.data.portfolio_limit ? 'observer' : 'manager'} changeType={(r) => this.changeRole(r)} />
                                        </div>
                                    </Hidden>

                                </React.Fragment>

                                
                                }

                            </div>

                            <div>
                                <div className={`button-blue opacity-hover ${classes.smallMargin}`} onClick={() => this.updateServer()} 
                                    style={{height: 40, borderRadius: 35, marginBottom: 48, cursor: 'pointer'}}>
                                    Add User
                                </div>
                            </div>

                        </div>

                        <div className={classes.sencondCointainer} style={{width: 515, textAlign: 'center'}}>
                            
                            <img src={addCollabBK} style={{width: 400}} alt="" />


                        </div>
                        


                    </div>

                </div>
                
                <SelectUserDrawer openModal={this.state.userModal} closeModal={this.userModalClose} users={this.props.data.users} orgId={this.props.data.org} orgs={this.props.orgs} handleSelect={(v) => this.handleModalAdd(v)} />
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token,
        orgs: state.appwide.orgs
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddCollab))
