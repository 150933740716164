import React from 'react';
import * as actions from "../../../../../store/actions/interactions";
import { connect } from 'react-redux';
import CoinSearch from '../../../../AppWide/CoinSearch';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import OriginList from './QuestionBlocks/OriginList'
import Switch from '@material-ui/core/Switch';
import ExchangeQuestion from './QuestionBlocks/ExchangeQuestion'
import SelectSource from './QuestionBlocks/SelectSource'
import TransferSecondBlock from './QuestionBlocks/TransferSecondBlock'
import TransferFirstBlock from './QuestionBlocks/TransferFirstBlock'

var moment = require('moment');
require('moment/locale/en-gb');

class TransactionTypeQuote extends React.PureComponent {

    constructor(props) {

        super(props)
        
        this.firstFocus = React.createRef();
        this.secondFocusExchange = React.createRef();
        this.hiddenRef = React.createRef();
        this.dateRef = React.createRef();

        this.state = {
            done: false, 
            selected_quote: null, 
        }

        if (this.props.transaction['data'][this.props.transactionId]['date'] !== null && this.props.transaction['data'][this.props.transactionId]['date'] !== undefined) {
            

            const existing_date = this.props.transaction['data'][this.props.transactionId]['date']
            const stateDate = moment(existing_date).local().format("DD/MM/YYYY HH:mm")
            this.props.setTrTime(stateDate) 
                


        } else {

            const now = new Date()
            const moment_date = moment(now);
            const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")
            const reduxDate = moment_date.utc().format();
            this.props.setTrTime(stateDate) 
            this.props.selectDate(reduxDate)


        }
        

    }
    

    /* selectQuoteCoin = (data) => {
        this.props.updateTransaction({key: this.props.transactionId, label: "quote_currency", value: data.value})
        this.setState(state => ({...state, selected_quote: data}))
        //this.props.handleNext()
    } */

    componentDidMount() {

       /*  const ref_needed = this.getRef(this.firstFocus)
            ref_needed
            .then(res => {
                console.log('here in res')
                console.log(res)
                res.current.exchangeFocusRef.myInput.focus({preventScroll: true});
        }) */

    }

    getRef = async (ref) => {

        return ref;
    }

    changeExchange = () => {

        if (this.props.exchangeCheck === true) {

            this.props.setInteraction({label: 'exchangeCheck', value: false})
            this.props.updateTransaction({key: this.props.transactionId, label: "exchange", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "exchange_address", value: null})

        } else {

            

        }
        
    }

    getExchangeAddress = (ref) => {

        const ref_needed = this.getRef(this.secondFocusExchange)
        
        ref_needed
        .then(res => {

            try {res.current.focus({preventScroll: true})} catch(e) {}
            
            })

    }

    onKeyDownNext = (e, key, focus) => {

        if (e.keyCode === 13 || e.keyCode === 9) {

            e.preventDefault();
            this.props.updateTransaction({key: this.props.transactionId, label: key, value: e.target.value})
            this.props.handleNext()
            
        }
        
    }

    handleModalKeyDown = (e) => {
        if (e.keyCode === 13) {

            this.props.handleNext()

        }
    }

    typeFirstQuestion = (type, base, quote) => {

        switch(type) {

            case 'deposit': {
                return `Where you deposit the ${base} to?`
            }
            case 'withdraw': {
                return `Where did you withdraw the ${base} from?`                
            }
            case 'buy': {
                return `On which exchange did you buy ${base} vs. ${quote} ?`              
            }
            case 'sell': {
                return `On which exchange did you sell ${base} vs. ${quote} ?`                
            }
            case 'transfer': {
                return `Where did you transfer the ${base} from?`                
            }
            case 'mining': {
                return `To which wallet were the ${base} mining proceeds sent ?`                
            }
            case 'fee': {
                return `Where did you send the ${base} fees from ?`              
            }
            case 'dividend': {
                return `Where did you receive the ${base} the dividends ?`                
            }
            case 'income': {
                return `Where did you receive the ${base} the income ?`                
            }
            case 'gift': {
                return `Where did you receive the ${base} the gift ?`                
            }
            case 'airdrop': {
                return `Where did you receive the ${base} the airdrop ?`                
            }
            case 'fork': {
                return `Where did you receive the ${base} the fork proceeds ?`                
            }
            case 'purchase': {
                return `Where did you send the ${base} for the purchase from ?`                
            }
            case 'donation': {
                return `Where did you send the ${base} for the purchase from ?`                
            }
            case 'lost': {
                return `Where did you lose the ${base} from?`                
            }
            

            default: 
                return null;
        }
    }

    renderFirstQuestion = () => {

        const type = this.props.transaction['data'][this.props.transactionId]['transaction_description']
        const base = this.props.transaction['data'][this.props.transactionId]['base_currency']['name']
        const user_scope = this.props.transaction['data'][this.props.transactionId]['user_transaction']
        let quote = null 
        try { quote = this.props.transaction['data'][this.props.transactionId]['quote_currency']['name'] } catch(e) {}
        const exchangeQuestionsCrypto = ['buy', 'sell']
        const exchangeQuestionsFiat = ['deposit', 'withdraw']
        const walletQuestions = ['transfer']
        const sourceQuestions = ['deposit', 'withdraw', 'ico', 'mining', 'dividend', 'income', 'gift', 'airdrop', 'fork', 'purchase', 'donation', 'lost', 'fee']

        console.log('testing testing')
        if ( ( exchangeQuestionsCrypto.includes(type) && this.props.transaction['data'][this.props.transactionId]['base_currency']['type'] === 'crypto' ) || (exchangeQuestionsFiat.includes(type) && this.props.transaction['data'][this.props.transactionId]['base_currency']['type'] === 'fiat')  ) {
            console.log('correctly here')
            return (
            <ExchangeQuestion 
                ref={this.firstFocus}
                exchanges = {this.props.exchanges}
                transaction = {this.props.transaction}
                transactionId = {this.props.transactionId}
                firstFocusExchange = {this.firstFocusExchange}
                getExchangeAddress = {this.getExchangeAddress}
                updateTransaction = {this.props.updateTransaction}
                selectExchange = {this.props.selectExchange}
                onKeyDownNext = {this.props.onKeyDownNext}
                secondFocusExchange = {this.secondFocusExchange}
                selectedBase={this.props.selectedBase}
                selectedQuote={this.props.selectedQuote}
                question={this.typeFirstQuestion(type, base, quote)}
                firstFocus={true}
                wallets={this.props.wallets}
                user_id={user_scope}
                user_first={this.props.user_first}
                user_last={this.props.user_last}
                validation={this.props.validation}

            />
            )

        } else  if ( sourceQuestions.includes(type) ) {
            return (
                <SelectSource 
                    noAdd={false}
                    question={this.typeFirstQuestion(type, base, quote)} 
                    transactionId = {this.props.transactionId}
                    getExchangeAddress = {this.getExchangeAddress}
                    selectExchange = {this.props.selectExchange}
                    onKeyDownNext = {this.props.onKeyDownNext}
                    secondFocusExchange = {this.secondFocusExchange}
                    selectedBase={this.props.selectedBase}
                    selectedQuote={this.props.selectedQuote}
                    //wallets={this.props.wallets}
                    user_id={user_scope}
                    validation={this.props.validation}
                    user_first={this.props.user_first}
                    user_last={this.props.user_last}
                />
            )
        } else  if ( walletQuestions.includes(type) ) {

            return (
                <div>
                <TransferFirstBlock 
                    noAdd={true}
                    question={this.typeFirstQuestion(type, base, quote)} 
                    transactionId = {this.props.transactionId}
                    getExchangeAddress = {this.getExchangeAddress}
                    selectExchange = {this.props.selectExchange}
                    onKeyDownNext = {this.props.onKeyDownNext}
                    secondFocusExchange = {this.secondFocusExchange}
                    selectedBase={this.props.selectedBase}
                    selectedQuote={this.props.selectedQuote}
                    //wallets={this.props.wallets}
                    user_id={user_scope}
                    user_first={this.props.user_first}
                    user_last={this.props.user_last}
                    validation={this.props.validation}

                />

                {(this.props.transaction['data'][this.props.transactionId]['from_wallet'] !== null && 
                 this.props.transaction['data'][this.props.transactionId]['from_wallet'] !== undefined) ?
                
                 <TransferSecondBlock 
                    noAdd={true}
                    question={this.typeFirstQuestion(type, base, quote)} 
                    transactionId = {this.props.transactionId}
                    getExchangeAddress = {this.getExchangeAddress}
                    selectExchange = {this.props.selectExchange}
                    onKeyDownNext = {this.props.onKeyDownNext}
                    secondFocusExchange = {this.secondFocusExchange}
                    selectedBase={this.props.selectedBase}
                    selectedQuote={this.props.selectedQuote}
                    //wallets={this.props.wallets}
                    user_id={user_scope}
                    user_first={this.props.user_first}
                    user_last={this.props.user_last}
                    validation={this.props.validation}

                    />

                 :

                 null 
                }

                
                </div>
            )

        }


    }

    skipQuestion = () => {

        this.props.updateTransaction({key: this.props.transactionId, label: "exchange", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "account", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage_name", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "wallets", value: {} })
        this.props.updateTransaction({key: this.props.transactionId, label: "from_wallet", value: null })
        this.props.updateTransaction({key: this.props.transactionId, label: "to_wallet", value: null })
        this.props.handleNext()

    }


    render() {







    

        return (

            <div style={{marginTop: 24}}>
                
                {this.renderFirstQuestion()}

                { (this.props.transaction['data'][this.props.transactionId]['exchange'] === null ||
                  this.props.transaction['data'][this.props.transactionId]['exchange'] === undefined) &&
                  (this.props.transaction['data'][this.props.transactionId]['storage'] === null ||
                  this.props.transaction['data'][this.props.transactionId]['storage'] === undefined ) && 
                  (this.props.transaction['data'][this.props.transactionId]['from_wallet'] === undefined ||
                  this.props.transaction['data'][this.props.transactionId]['from_wallet'] === null ) ?


                    <div style={{display: 'flex', flexDirection: 'column', width: '80%', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: 150}}>
                        
                        <div className='basier' onClick={this.skipQuestion} 
                            style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'black', backgroundColor: '#CCEDFE', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            Skip
                        </div>


                        <div className="basier-p4" style={{fontStyle: 'italic', marginTop: 24, textAlign: 'center'}}>You can skip entering your exchanges and wallets, but we do not recommend it! By entering the sources of your transactions, you can better track your assets and year-end reporting will be much easier</div>
                    </div>

                :

                    null

                }

                <input type="text" style={{width: 0, height:0, opacity: 0,}} onKeyDown={this.handleModalKeyDown} ref={this.hiddenRef} />
            </div>  


        )
    }
}


const mapStateToProps = state => {
    return {
  
        modalPref: state.interactions.transactionAddModal,
        transaction: state.interactions.transactionAddData,
        tr_date: state.interactions.dateTransaction,
        exchangeCheck: state.interactions.exchangeCheck, 
        exchanges: state.appwide.exchanges, 
        wallets: state.appwide.wallets, 
        user_id: state.auth.user_id,
        user_first: state.auth.user_first_name,
        user_last: state.auth.user_last_name
        
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(TransactionTypeQuote);