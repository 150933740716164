import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/data";
import ExchangeBalance from './ExchangeBalance';
import StorageBalance from './StorageBalance';
import CollabSwipe from './SmallCollabSwipe';
import balanceIcon from '../../../../../Media/balanceIcon.svg';


class SmallCollaboratorOverview extends React.PureComponent {

    state = {
        walletType: 'exchange',
        selectedUser: this.props.user_id, 
    }

    componentDidMount() {
        this.props.setCurrentView(6)
        this.props.componentDataFetch('get', 6, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)
        this.setState(state => ({...state, selectedUser: this.props.user_id}))
    }

    componentDidUpdate(prevProps) {
        if (this.props.portfolioId !== prevProps.portfolioId) {
            this.setState(state => ({...state, selectedUser: this.props.user_id}))  
        }
    }

    changeScope = (val) => {
        this.setState(state => ({...state, walletType: val}))
    }

    setScopeUser = (val) => {

        this.setState(state => ({...state, selectedUser: val}))
        
    }

    render () {

        return (
            this.props.subscription !== undefined ? 

            <div>

                <div style={{paddingTop: 12}}>
                    
                    {this.props.subscription.plan.plan_id === 'unlimited' || this.props.subscription.plan.plan_id === 'pro' ? 
                    
                        <div style={{height: 'fit-content'}}>
                            <CollabSwipe firstScopeUser={this.state.selectedUser} setScopeUser={this.setScopeUser} deposits={this.props.deposits} />
                        </div>
                
                    :
                    
                    null
                    
                    }
                
                    
                </div>
                
                { this.props.portfoliosData[this.props.portfolioId] !== undefined ? 
                    this.props.portfoliosData[this.props.portfolioId].number_transactions > 0 ? 
                        <div style={{marginTop: 12, padding: 12}}>
                            <div className="basier-p4-caps">
                                Balances
                            </div>

                            <div className={`sticky-top-mobile ${this.props.pin ? 'menu-sticky' : ''}`} style={{display: 'flex', position: 'sticky', top: this.props.pin ? 135 : 0, paddingTop: 12, paddingBottom: 12, backgroundColor: 'white' }}>

                                <div style={{
                                    width: '50%', height: 40, display: 'flex', 
                                    alignItems: 'center', justifyContent: 'center', 
                                    backgroundColor: `${this.state.walletType === 'exchange' ? '#0078DB' : '#E1E5E9' }`, 
                                    color: `${this.state.walletType === 'exchange' ? 'white' : 'black'}`, 
                                    fontWeight: `${this.state.walletType === 'exchange' ? 900 : 400 }`, 
                                    textTransform: 'uppercase', marginRight: 6, marginLeft: 0, borderRadius: 10}} 
                                    className="basier-p3"
                                    onClick={() => this.changeScope('exchange')}>
                                    Exchange
                                </div>

                                <div 
                                    style={{width: '50%', height: 40, display: 'flex', 
                                    alignItems: 'center', justifyContent: 'center', 
                                    backgroundColor: `${this.state.walletType === 'storage' ? '#0078DB' : '#E1E5E9' }`, 
                                    fontWeight: `${this.state.walletType === 'storage' ? 900 : 400 }`, 
                                    textTransform: 'uppercase', marginRight: 0, marginLeft: 6, borderRadius: 10, 
                                    color: `${this.state.walletType === 'storage' ? 'white' : 'black'}`, }}
                                    onClick={() => this.changeScope('storage')}
                                    className="basier-p3">
                                    Storage
                                </div>
                                
                                </div>      
                                    
                                <div>
                                    {this.state.walletType === 'exchange' && <ExchangeBalance user_id={this.state.selectedUser} portfolioId={this.props.portfolioId} />}
                                </div>

                                <div>
                                    {this.state.walletType === 'storage' && <StorageBalance user_id={this.state.selectedUser} portfolioId={this.props.portfolioId} />}
                                </div>

                            </div>
                        :
                        
                            <div style={{height: 300, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 12}}>
                                <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 12, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                    <div>
                                        <img src={balanceIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any transactions in your portfolios yet. Once you add transactions you will your exchange and storage balances here.</div>
                                    </div>
                                </div>
                            </div>
                            
                    :
                    null
                } 
                </div>
            
            :
            null
            
        )
    }


}

const mapStateToProps = state => {
    return {
        subscription: state.auth.subscription, 
        pin: state.interactions.pin, 
        user_id: state.auth.user_id, 
        deposits: state.data.deposits, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SmallCollaboratorOverview)
