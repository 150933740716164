import React from 'react';
import { connect } from 'react-redux';


class AnalysisOverview extends React.PureComponent {

    state = {
        filter: null, 
        analysisList: null,
    }

    initAnalysisList = () => {

        const analysisList = [
            {id: 'balanceDate', name: 'Balance at Date', description: 'Generate your portfolio value and balances at any date, in any currency. Exportable into CSV.'}, 
            {id: 'periodSummary', name: 'Period Review', description: 'Generate a PDF report on your P&L, transactions and activity between two dates. Useful for year-end reporting.'}, 
        ]

        this.setState(state => ({...state, analysisList: analysisList, filterAnalysis: analysisList}))

    }

    componentDidMount = () => {
        this.initAnalysisList()
    }

    selectReport = (id) => {
        this.props.handleSelect(id)
    }

    changeFilter = (e) => {
        e.persist();
        const newVal = e.target.value
        console.log(newVal)
        this.setState(state => ({...state, filter: newVal}))

        if (newVal !== '' && newVal !== undefined && newVal !== null) {
            const newList = this.state.analysisList.filter(v => v.name.toLowerCase().includes(newVal.toLowerCase()))
            this.setState(state => ({...state, filterAnalysis: newList}))
        } else {
            this.setState(state => ({...state, filterAnalysis: this.state.analysisList}))
        }

    }

    renderReport = (data) => {

        return (
            <div 
                onClick={() => this.selectReport(data.id)}
                className="hvr-grow-shadow"
                style={{
                    width: 300, 
                    height: 200,
                    boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                    marginLeft: 12, marginRight: 12, 
                    border: '1px solid #E1E5E9', 
                    borderRadius: 10, 
                    padding: 24, 
                    cursor: 'pointer',
                }}>

                <h5 style={{margin: 0}}>
                    {data.name}
                </h5>
                <div className="basier-p3" style={{marginTop: 12}}>
                    {data.description}
                </div>

            </div>
        )

    }

    render () {

        
        return (

            <div>

                <h3>Reports</h3>

                <div style={{marginTop: 24}}>
                    <input type="text" onChange={this.changeFilter} value={this.state.filter} />
                </div>

                {this.state.analysisList !== null &&
                <div style={{marginTop: 36, display: 'flex'}}>
                    {this.state.filterAnalysis.map((val, key) => {
                        return (

                            <div key={key}>
                                {this.renderReport(val)}
                            </div>
                        
                        )
                    })}
                </div>
                }



            </div>
           
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AnalysisOverview)
