import React from 'react';
import { connect } from 'react-redux';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import FiatLarge from '../../../../AppWide/FiatLarge';
import {loadData} from '../../../../../helpers/helpers'
import { backendUrl } from '../../../../../variables'
import warningIcon from '../../../../../Media/warning.svg'
import currencyDict from '../../../../../helpers/currency.json'
import Tooltip from '@material-ui/core/Tooltip';
import Export from '../../../../../Media/Export.svg'
import Oops from '../../../../../Media/oops.svg'
import { saveAs } from 'file-saver';
import selectBackground from '../../../../../Media/selectBackground.svg';
import loadingSpinner from '../../../../../Media/contentLoader.svg';
import * as interActions from "../../../../../store/actions/interactions";
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const ColorLinearProgress = withStyles({
    root: {
        height: 3
    },
    colorPrimary: {
      backgroundColor: 'rgba(0, 120, 219, 0.2)',
    },
    barColorPrimary: {
      backgroundColor: '#0078DB',
    },
  })(LinearProgress);

var moment = require('moment');
require('moment/locale/en-gb');

class BalanceDate extends React.PureComponent {

    state = {

        currency: 'USD', 
        serverDate: null,
        validation: {
            date: true,
            currency: true,
        }, 
        futurDate: false,
        currenciesData: null, 
        balanceData: null,
        loading: false,
        downloading: false,

    }


    componentDidMount() {

        let totalFiat = null
        if (this.props.initialFiat !== undefined && this.props.initialFiat !== null && this.props.initialFiat !== []) {
            totalFiat = [{value: 'bitcoin', label: 'BTC', full_name: 'Bitcoin'}, ...this.props.initialFiat]
        } else {
            totalFiat = null
        }
        
        const now = new Date()
        const moment_date = moment(now);
        const serverDate = moment_date.local().format("YYYY-MM-DD")
        this.setState(state => ({...state, serverDate: serverDate, currenciesData: totalFiat}))

    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.initialFiat !== this.props.initialFiat) {
            let totalFiat = null
            if (this.props.initialFiat !== undefined && this.props.initialFiat !== null && this.props.initialFiat !== []) {
                totalFiat = [{value: 'BTC', label: 'BTC', full_name: 'Bitcoin'}, ...this.props.initialFiat]
            } else {
                totalFiat = null
            }
            this.setState(state => ({...state, currenciesData: totalFiat}))
        }

    }
    handleChange = (date) => {
        console.log(date)
        const testStateDate = moment( date, "YYYY-MM-DD", true).local()

        if (testStateDate.isValid()) {
            let dateRedux = moment(date);
            const serverDate = dateRedux.local().format("YYYY-MM-DD")
            this.setState(state => ({...state, serverDate: serverDate}))
            if (dateRedux.isValid()) {     
                this.setState(state => ({...state, validation: {...state.validation, date: true}}))         
                const serverDate = dateRedux.local().format("YYYY-MM-DD")
                this.setState(state => ({...state, serverDate: serverDate}))
                const now = new Date()
                if (date > now) {
                    this.setState(state => ({...state, futurDate: true}))    
                } else {
                    this.setState(state => ({...state, futurDate: false}))   
                }
                
            } else {

                this.setState(state => ({...state, validation: {...state.validation, date: false}}))    
            }

        } else {

            this.setState(state => ({...state, validation: {...state.validation, date: false}}))   

        }
    }


    
    updateData = (val) => {

        this.setState(state => ({...state, currency: val}))

    }

    generateReport = (csv) => {

        
        const dataSend = {
            date: this.state.serverDate, 
            currency: this.state.currency, 
            portfolio: this.props.portfolioId
        }

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        if (csv === '1') {
            this.setState(state => ({...state, downloading: true}))
            headers["content-type"] = 'text/csv'
            fetch(`${backendUrl}/v1/portfolio/balances/${dataSend.portfolio}/?c=${dataSend.currency}&date=${dataSend.date}&u=${this.props.user_id}&csv=${csv}`, {headers: headers})
            .then(response => response.blob())
            .then(blob => {
                if (blob.type === 'text/html') {
                    this.setState(state => ({...state, downloading: false}))
                    this.props.showSnack({message: 'Error in downloading file', variant: "error"})
                } else {
                saveAs(blob, `export_${this.state.serverDate}_${this.props.portfolioId}.csv`)
                this.setState(state => ({...state, downloading: false}))
                }
                })
            .catch(() => {
                this.setState(state => ({...state, downloading: false}))
                this.props.showSnack({message: 'Error in downloading file', variant: "error"})
            })


        } else {
            this.setState(state => ({...state, loading: true}))
            const data_fetch = loadData(`${backendUrl}/v1/portfolio/balances/${dataSend.portfolio}/?c=${dataSend.currency}&date=${dataSend.date}&u=${this.props.user_id}&csv=${csv}`, headers)
            data_fetch
                .then(json => {

                this.setState(state => ({...state, balanceData: json['data'], loading: false}))
                    
                })
                .catch(e => {

                    const errorDate = {
                        balances: null
                    }

                    this.setState(state => ({...state, balanceData: errorDate, loading: false}))

                })
        }
        

    }

    render () {

        return (

            <div style={{width: '100%', height: 'inherit', display: 'flex', alignItems: 'center'}}>

                <div style={{height: '90%', width: 200, borderRight: '1px solid #E1E5E9', paddingTop: 12, paddingBottom: 12, paddingLeft: 24, paddingRight: 24, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>

                    <div>
                    <h4>Parameters</h4>


                    <div style={{marginTop: 24}} className="basier-p4-caps">Currency</div>
                    <div style={{marginTop: 12}}>
                        {this.state.currenciesData !== null &&
                        <FiatLarge data={this.state.currenciesData} valid={this.state.validation.currency} handleChange={(val) => this.updateData(val)} />}
                    </div>


                    <div style={{marginTop: 24}} className="basier-p4-caps">Date</div>
                    <div style={{paddingRight: 24, width: 175, marginTop: 12}}>
                        <DateTimePicker 
                                onChange={(date) => this.handleChange(date)} 
                                value={this.state.serverDate} 
                                locale="en-gb"
                                closeOnSelect={true}
                                timeFormat={false}
                                strictParsing={true}
                                inputProps={{className: `select_datepicker-small basier-p3 ${!this.state.validation.date ? "error-input" : null }`, placeholder: 'YYYY-MM-DD'}}
                            />
                    </div>

                    </div>

                    <div>
                        <div className='button-next-blue' onClick={() => this.generateReport()} 
                            style={{height: 40, borderRadius: 35, marginTop: 0, cursor: 'pointer'}}>
                            Generate Report
                        </div>
                    </div>


                </div>

                <div style={{height: '95%', padding: 24, width: '100%', display: 'flex', flexDirection: 'column'}}>
                    
                    <h4>Output</h4>
                    
                    {   
                        this.state.loading ? 
                        <div style={{width: '100%', height: 'inherit', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                            <img style={{height: 120, with: 120}} src={loadingSpinner} alt="Loading..."/>
                            <div className="basier-p2" style={{marginTop: 6}}>Loading data</div>
                        </div>
                        :
                        this.state.balanceData == null ? 

                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
                            <h3>Please select parameters and generate analysis</h3>
                            <img src={selectBackground} alt="" style={{height: 400}} />
                        </div>

                        : 

                        this.state.balanceData['balances'] == null ? 

                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
                            <img style={{marginTop: 24, height: 150}} src={Oops} alt="" />
                            <h3>Unavailble data</h3>
                            <div className="basier-p2">
                                This data is currently unavailable. This might be a temporary issue as we update our data. Please try again in a few minutes.
                            </div>
                        </div>

                        :
                    
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column', flex: 2}}>

                                {
                                    this.state.balanceData !== null ? 
                                    
                                    <div style={{marginTop: 24, width: '100%', display: 'flex', flexDirection: 'column', flex: 2}}>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <div>
                                            <div style={{marginBottom: 6}} className="basier-p4-caps">Total Portfolio Value at Date</div>
                                            <h4>
                                                {`${this.state.balanceData['currency'] === 'bitcoin' ? 'BTC' : currencyDict[this.state.balanceData['currency']]['symbol']} ${
                                                    this.state.balanceData['total'] < 1 ? 
                                                    this.state.balanceData['total'].toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6})
                                                    :
                                                    this.state.balanceData['total'].toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                                }`}
                                            </h4>
                                        </div>


                                            {this.state.downloading ? 
                                            
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div className="basier-p4" style={{marginRight: 12}}>Downloading</div>
                                                <div style={{width: 50}}>
                                                    <ColorLinearProgress />
                                                </div>
                                            </div>

                                            :
                                    
                                            <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} className="opacity-hover" onClick={() => this.generateReport('1')}>
                                                <div><img src={Export} alt="export" style={{height: 20, width: 20}} /></div>
                                                <div style={{marginLeft: 12}} className="basier-p4-caps">Export balances</div>
                                            </div>
                                            }

                                        </div>

                                        <div style={{marginTop: 24, width: '100%', display: 'flex', flexDirection: 'column', flex: 2}}>
                                            <div
                                                style={{display: 'flex', alignItems: 'baseline', width: '100%', borderBottom: '1px solid rgb(225, 229, 233)', paddingBottom: 12}}
                                            >

                                                <div style={{width: '25%', minWidth: 150, textAlign: 'left', paddingLeft: 12}} className="basier-p4-caps">Asset</div>
                                                <div style={{width: '25%', minWidth: 135, textAlign: 'right'}} className="basier-p4-caps">Balance</div>
                                                <div style={{width: '25%', minWidth: 100, textAlign: 'right'}} className="basier-p4-caps">Price at date</div>
                                                <div style={{width: '25%', minWidth: 200, textAlign: 'right', paddingRight: 12}} className="basier-p4-caps">Total Value</div>

                                            </div>

                                            <div style={{flex: 2, backgroundColor: 'white', display: 'flex', flexDirection: 'column', overflow: 'scroll', maxHeight: 300, marginTop: 12}}>
                                                {   
                                                    this.state.balanceData['balances'].map((val, key) => {

                                                        return (
                                                            <div key={key} 
                                                                className="asset-line basier-p3"
                                                                style={{
                                                                    height: 60,
                                                                    paddingLeft: 12,
                                                                    marginTop: 8, 
                                                                    marginBottom: 8, 
                                                                    paddingRight: 12, 
                                                                    paddingTop: 6, 
                                                                    paddingBottom: 6,
                                                                    boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                                                                    borderRadius: 1035, 
                                                                    width: '100%',
                                                                    display: 'flex', 
                                                                    alignItems: 'center',
                                                                }}>
                                                                <div style={{width: '25%', minWidth: 150, textAlign: 'left', paddingLeft: 0}} className="basier-p4-caps">
                                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                                        <React.Fragment>
                                                                            {val.image !== null ? 
                                                                                <img src={val.image} alt="" style={{height: 25, width: 25, marginRight: 12}} />
                                                                            :
                                                                            <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
                                                                                {currencyDict[val.coin_symbol] === undefined ? null : currencyDict[val.coin_symbol]['symbol'].length > 1 ? null : currencyDict[val.coin_symbol]['symbol'] }
                                                                            </span> 
                                                                            }
                                                                            <div>
                                                                                <div className="basier-p3" style={{fontWeight: 400}}>{val.coin_symbol}</div>
                                                                                <div className="basier-p4-caps white-hover" style={{marginTop: 0, color: '#6A7E93'}}>{val.short_name}</div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    </div>
                                                                </div>
                                                                <div style={{width: '25%', minWidth: 135, textAlign: 'right'}} className="basier-p4-caps">
                                                                    
                                                                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                                                            <div style={{width: '100%'}}>
                                                                                <div style={{display: 'flex', alignItems: 'center', textAlign: 'right', justifyContent: 'flex-end'}}>
                                                                                {
                                                                                
                                                                                    val['error'] &&
                                                                                        <span>
                                                                                            <Tooltip title={`You had sold or used ${val['oversold'].toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})} more than you had at this date. This can indicate incorrect Transaction entry and can lead to an incorrect balance. Please review your transaction values and dates.`} placement="right">
                                                                                                <img src={warningIcon} alt="" style={{ width: 18, marginRight: 6 }} />
                                                                                            </Tooltip>
                                                                                        </span>
                                                                                    
                                                                                    
                                                                                    
                                                                                }
                                                                                { 
                                                                                (val['balance'] > 1 || val['balance'] === 0) ? 
                                                                                    <div style={{ textAlign: 'right', justifyContent: 'flex-end'}}>
                                                                                        <span>{val['balance'].toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
                                                                                    </div>
                                                                                :
                                                                                    <div style={{ textAlign: 'right', justifyContent: 'flex-end'}}>
                                                                                        <span>{val['balance'].toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6})}</span>
                                                                                    </div>

                                                                                }
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                </div>
                                                                <div style={{width: '25%', minWidth: 200, textAlign: 'right', paddingRight: 0}} className="basier-p4-caps">
                                                                    <span>{this.state.balanceData['currency'] === 'bitcoin' ? 'BTC' : currencyDict[this.state.balanceData['currency']]['symbol']}</span>&nbsp;
                                                                    <span>
                                                                    {
                                                                        val['current_price'] < 1 ? 
                                                                        val['current_price'].toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6})
                                                                        :
                                                                        val['current_price'].toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                                                    }
                                                                    </span>

                                                                </div>
                                                                <div style={{width: '25%', minWidth: 100, textAlign: 'right'}} className="basier-p4-caps">
                                                                    
                                                                        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                                                            <div style={{width: '100%'}}>
                                                                            {
                                                                            val['total_value'] < 0 ?
                                                                                <div style={{display: 'flex', alignItems: 'center', textAlign: 'right', justifyContent: 'flex-end'}}>

                                                                                    <span>
                                                                                        <Tooltip title={`The value of the coin is negative and equal to ${val['total_value'].toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}. This can indicate incorrect Transaction entry. Please review your transactions.`} placement="right">
                                                                                            <img src={warningIcon} alt="" style={{ width: 18 }} />
                                                                                        </Tooltip>
                                                                                    </span>
                                                                                    <span style={{marginLeft: 6}}>{this.state.balanceData['currency'] === 'bitcoin' ? 'BTC' : currencyDict[this.state.balanceData['currency']]['symbol']}</span>&nbsp;
                                                                                    <span>0.00</span>
                                                                                    
                                                                                </div>
                                                                            : 
                                                                            (val['total_value'] > 1 || val['total_value'] === 0) ? 
                                                                                <div style={{ textAlign: 'right', justifyContent: 'flex-end'}}>
                                                                                    <span>{this.state.balanceData['currency'] === 'bitcoin' ? 'BTC' : currencyDict[this.state.balanceData['currency']]['symbol']}</span>&nbsp;
                                                                                    <span>{val['total_value'].toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
                                                                                </div>
                                                                            :
                                                                                <div style={{ textAlign: 'right', justifyContent: 'flex-end'}}>
                                                                                    <span>{this.state.balanceData['currency'] === 'bitcoin' ? 'BTC' : currencyDict[this.state.balanceData['currency']]['symbol']}</span>&nbsp;
                                                                                    <span>{val['total_value'].toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6})}</span>
                                                                                </div>

                                                                            }
                                                                            </div>
                                                                        </div>

                                                                </div>
                                                                
                                                            
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    :

                                    null
                                }

                            </div>
                        }
                    
                </div>

            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        initialFiat: state.data.fiatList,
        user_id: state.auth.user_id, 
        token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(BalanceDate)
