import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Genral from './Large/General';
import Collabs from './Large/Collabs';
import AuditWrapper from './Large/Audit';
import Upload from './Large/Upload';

class LargeSettings extends React.PureComponent {

      constructor(props) {
         super(props);
         let firstSetting = 'general'

         if ( (this.props.selectedSetting !== undefined && this.props.selectedSetting !== null) && (['general', 'collaborators', 'uploads', 'audit'].includes(this.props.selectedSetting.toLowerCase()))) {
            firstSetting = this.props.selectedSetting
         }
         this.state = {
            selectedIndex: firstSetting
          }


      }
      

      changeMenu = (val) => {
         this.setState({ selectedIndex: val })
      }

      render () {

        return (

            <div style={{top: '130px', width: '100%', backgroundColor: 'white', padding: 48, paddingBottom: 12}}>
               
               <h3>Settings</h3>

               
               <div style={{height: 550, width: '100%', backgroundColor: 'white', borderRadius: 20, marginTop: 24, display: 'flex'}}>
                  
                   <div className="basier-p3" style={{width: 200, minWidth: 165, height: '100%', borderRight: '1px solid #E1E5E9', paddingTop: 24, textTransform: 'uppercase'}}>

                       <div 
                        onClick={() => this.changeMenu('general')}
                        className={`${this.state.selectedIndex === 'general' ? '' : 'blue-bk-hover opacity-hover'}`}
                        style={{height: 40, marginTop: 6, marginBottom: 6, display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                           <div 
                           className='identifyer'
                           style={{
                               height: '100%', 
                               width: 8, 
                               backgroundColor: this.state.selectedIndex === 'general' ? '#0078DB' : 'white'
                            }}>
                            </div>

                           <div 
                            className={`${this.state.selectedIndex === 'general' ? '' : 'blue-hover opacity-hover'}`}
                            style={{
                               paddingLeft: 12,
                               color: this.state.selectedIndex === 'general' ? '#0078DB' : '#6A7E93',
                               fontWeight: this.state.selectedIndex === 'general' ? 900 : 400
                            }}>General</div>
                            
                       </div>

                       <div 
                        onClick={() => this.changeMenu('collaborators')}
                        className={`${this.state.selectedIndex === 'collaborators' ? '' : 'blue-bk-hover opacity-hover'}`}
                        style={{height: 40, marginTop: 6, marginBottom: 6, display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                           <div 
                           className='identifyer'
                           style={{
                               height: '100%', 
                               width: 8, 
                               backgroundColor: this.state.selectedIndex === 'collaborators' ? '#0078DB' : 'white'
                            }}>
                            </div>

                           <div 
                            className={`$
                            {this.state.selectedIndex === 'collaborators' ? '' : 'blue-hover opacity-hover'}`}
                            style={{
                               paddingLeft: 12,
                               color: this.state.selectedIndex === 'collaborators' ? '#0078DB' : '#6A7E93',
                               fontWeight: this.state.selectedIndex === 'collaborators' ? 900 : 400
                            }}>Collaborators</div>
                            
                       </div>

                       <div 
                        onClick={() => this.changeMenu('audit')}
                        className={`${this.state.selectedIndex === 'audit' ? '' : 'blue-bk-hover opacity-hover'}`}
                        style={{height: 40, marginTop: 6, marginBottom: 6, display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                           <div 
                           className='identifyer'
                           style={{
                               height: '100%', 
                               width: 8, 
                               backgroundColor: this.state.selectedIndex === 'audit' ? '#0078DB' : 'white'
                            }}>
                            </div>

                           <div 
                            className={`${this.state.selectedIndex === 'audit' ? '' : 'blue-hover opacity-hover'}`}
                            style={{
                               width: '100%',
                               paddingLeft: 12,
                               color: this.state.selectedIndex === 'audit' ? '#0078DB' : '#6A7E93',
                               fontWeight: this.state.selectedIndex === 'audit' ? 900 : 400
                            }}>
                                Audit Trail
                            </div>
                            
                       </div>

                       <div 
                        onClick={() => this.changeMenu('uploads')}
                        className={`${this.state.selectedIndex === 'uploads' ? '' : 'blue-bk-hover opacity-hover'}`}
                        style={{height: 40, marginTop: 6, marginBottom: 6, display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                           <div 
                           className='identifyer'
                           style={{
                               height: '100%', 
                               width: 8, 
                               backgroundColor: this.state.selectedIndex === 'uploads' ? '#0078DB' : 'white'
                            }}>
                            </div>

                           <div 
                            className={`${this.state.selectedIndex === 'uploads' ? '' : 'blue-hover opacity-hover'}`}
                            style={{
                               width: '100%',
                               paddingLeft: 12,
                               color: this.state.selectedIndex === 'uploads' ? '#0078DB' : '#6A7E93',
                               fontWeight: this.state.selectedIndex === 3 ? 900 : 400
                            }}>
                                Uploads
                            </div>
                            
                       </div>

                   </div>

                   
                   <div style={{paddingLeft: 48, paddingRight: 48, paddingBottom: 36, paddingTop: 12, width: '100%'}}>
                    {this.state.selectedIndex === 'general' &&
                        <div style={{width: '100%'}}> 
                           <Genral portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                        </div>
                    }

                    {this.state.selectedIndex === 'collaborators' &&
                       <div style={{width: '100%'}}>
                          <Collabs portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                       </div>
                    }

                    {this.state.selectedIndex === 'audit' &&
                       <div style={{width: '100%'}}>
                          <AuditWrapper portfolioId={this.props.portfolioId} />
                       </div>
                    }

                    {this.state.selectedIndex === 'uploads' &&
                       <div style={{width: '100%'}}>
                          <Upload portfolioId={this.props.portfolioId} />
                       </div>
                    }

                    </div>


               </div>
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LargeSettings));
