import React from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import currencyDict from '../../../../helpers/currency.json'

class AssetTable extends React.PureComponent {


    render() {

        return (


           this.props.items.balances.map((item, index) => { 
            

            return (

                <Grid container style={{ 
                    height: 42,
                    paddingLeft: 24,
                    marginTop: 8, 
                    marginBottom: 8, 
                    paddingRight: 24, 
                    boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                    borderRadius: 1035, 
                    width: '100%',
                    }} 
                    value={item.coin}
                    index={index}
                    className="asset-line basier-p3">
        
            <Grid item style={{width: '25%' }} >

            <div style={{display: 'flex', alignItems: 'center'}}>

                {item.image !== "" && item.image !== null  ? 
                  <span><img style={{height: 25, width: 25, marginRight: 12}} src={item.image} alt=""/></span>
                  :
                  <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[item.coin_symbol] === undefined ? '16px' : currencyDict[item.coin_symbol]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                      {currencyDict[item.coin_symbol] === undefined ? item.symbol : currencyDict[item.coin_symbol]['symbol'].length > 1 ? currencyDict[item.coin_symbol]['symbol'] : currencyDict[item.coin_symbol]['symbol'] }
                  </span>
                }
            
            <span>{item.coin_name}</span>
            </div>
            
            </Grid> 

            <Grid item style={{width: '15%', textAlign: 'right'}} >
                {this.props.currency_symbol} {item.total_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
            </Grid> 


            <Grid item style={{width: '15%', textAlign: 'right' }} >{this.props.currency_symbol} {item.realized_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Grid> 
            <Grid item style={{width: '15%', textAlign: 'right' }} >{this.props.currency_symbol} {item.active_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Grid> 
            <Grid item style={{width: '15%', textAlign: 'right' }} >
                {this.props.currency_symbol}&nbsp;
                {item.current_price > 1 ? 
                    item.current_price.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                    : 
                    item.current_price.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }
            </Grid>

            <Grid item style={{width: '15%', textAlign: 'right' }} >
            
            {this.props.currency_symbol}&nbsp;
            {item.current_price > 1 ? 
                item.purchase_price.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                : 
                item.purchase_price.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }

            </Grid> 

        </Grid>

    )
    


            })


        )
    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
    };
};


export default connect(
    mapStateToProps,
    null
)(AssetTable);