import React from 'react';
import { connect } from 'react-redux';
import BackIcon from "../../../../Media/BackIcon.svg";
import axios from 'axios';
import * as appwideActions from "../../../../store/actions/appwide";
import * as dataActions from "../../../../store/actions/data";
import { backendUrl } from '../../../../variables'
import { withRouter } from "react-router-dom";
import Plus from '../../../../Media/Plus.svg'
import fieldValidator from '../../../../helpers/validators'
import loadingSpinner from '../../../../Media/loadingSpinner.gif'
import successIcon from '../../../../Media/okIcon.svg'
import errorIcon from '../../../../Media/errorIcon.svg'

class assetModalContent extends React.PureComponent {

    state = {
        open: false,
        coin_in_scope: null, 
        graph_data: [], 
        graph_start: null,
        edit: false,
        wallet_id: this.props.location.state.account_id,
        data: this.props.location.state.data, 
        editData: {},
        editNameError: false,
        loading: false, 
        error: false, 
        success: false, 
    }

    updateEditData = () => {

        let editData = {
            ...this.state.editData, 
            user: this.props.user_id, 
            id: this.state.wallet_id
        }

        
        const exchange_val = fieldValidator(this.state.editData['name'], ['isNotEmpty'])
        this.setState(state => ({...state, editNameError: !exchange_val}))

        if (exchange_val) {
            
            this.setState(state => ({...state, loading: true}))

            let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {

                headers['Authorization'] = `Token ${this.props.token}`

            }

            const url_call = `${backendUrl}/v1/exchange_accounts/`

            axios.patch(url_call, editData, {headers: headers})
                .then(json => {

                   this.props.getWallets(this.props.user_id)
                   this.setState(state => ({...state, loading: false, success: true}))
                    
                   setTimeout(() => {

                    this.cancelEdit()
                    
                  }, 600)
                    
                })
                .catch( e => {

                this.setState(state => ({...state, loading: false, error: true}))

                })
        
        }

        
    
    }


    componentDidMount() {

        this.props.setCurrentView(971)
        const idGet = this.props.location.state.account_id
        let newData = this.props.location.state.data
        try { newData = this.props.wallets[this.props.user_id]['exchange'][newData.exchange.id][idGet] } catch(e) {console.log('error load')}
        this.setState(state => ({...state, data: newData}))

    }

    componentDidUpdate(prevProps) {

        if (prevProps.wallets !== this.props.wallets) {

            const idGet = this.props.location.state.account_id
            let newData = this.props.location.state.data
            try { newData = this.props.wallets[this.props.user_id]['exchange'][newData.exchange.id][idGet] } catch(e) {console.log('error load')}
            this.setState(state => ({...state, data: newData}))

        }

    }

    editHandler = () => {
        this.setState(state => ({...state, edit: true}))
    }

    handleEditData = (e, label) => {
        e.persist();
        this.setState(state => ({...state, editData: {...state.editData, [label]: e.target.value}}))
    }

    deleteHandler = () => {
        if (window.confirm("Are you sure you want to delete this transction")) {
            this.deleteTransaction()
          } 
    }

    deleteTransaction = () => {

        let headers = { "content-type": "application/json" };
        headers['x-user'] = this.props.user_id
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = ''// `${backendUrl}/v1/transaction_detail/${this.props.location.state.item.id}`

        axios.delete(url_call, {headers: headers})
            .then(json => {

                // load new wallet list
                this.props.history.goBack()
                //console.log(json['data'])

            })


    }

    cancelEdit = () => {
        this.setState(state => ({...state, edit: false, success: false, error: false, loading: false, editData: {}}))
    }
    
    backHandler = () => {
        this.props.backAction()
    }

    goToWallet = (wall, data, source, globalData) => {
        let exchange_id = null 
        try {exchange_id = globalData.exchange.id } catch(e) {}
        this.props.history.push({
            pathname: '/wallet_detail', 
            state: {wallet_id: wall, data: data, source: source, exchange_id: exchange_id}
        })
    }   

    newWallet = () => {
        this.props.history.push({
            pathname: '/new_w', 
            state: {wallet_type: 'exchange', exchange: this.props.location.state.account_id, data: this.props.location.state.data}
        })

    }

    goBackAction = () => {
        if (this.state.edit) {
            this.cancelEdit()
        } else {
            this.props.history.goBack()
        }
    }
    
    render () {
         
        const { item } = this.state;

        return ( 
            
            <div style={{position: 'absolute', top: 0, zIndex: 9999999, width: '100%'}}>

                <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed', justifyContent: 'space-between'}} id="responsive-dialog-title">

                        
                        <div onClick={() => this.goBackAction()}>
                            <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                        </div>

                        <div>
                            <div className="basier"  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '1.2rem'}}>
                                
                                {this.state.edit ? 
                                
                                <React.Fragment>
                                    <div className="modalActionButton touchableOpacity"  onClick={this.updateEditData} style={{marginRight: 24, color: '#0078DB'}}>Save</div>
                                </React.Fragment>

                                :
                                
                                <React.Fragment>
                                    <div className="modalActionButton touchableOpacity"  onClick={this.deleteHandler} style={{marginRight: 48, color: '#F9716B'}}>Delete</div>

                                    <div className="modalActionButton touchableOpacity"  onClick={this.editHandler} style={{marginRight: 24, color: '#0078DB'}}>Edit</div>
                                 </React.Fragment>
                               
                                }
                            </div> 
                        </div>

                    </div>


                

                <div style={{position: 'absolute', top: 75, zIndex: 120000, backgroundColor: 'white', minHeight: 100, width: '100%'}}>

                {this.state.edit ? 

                    this.state.loading ?

                    <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={loadingSpinner} alt="" style={{height: 50, width: 50, marginBottom: 12}} />
                        <div className="basier-p3">Editing Account...</div>
                    </div>

                    :

                    this.state.success ? 

                    <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={successIcon} alt="" style={{height: 50, width: 50, marginBottom: 12}} />
                        <div className="basier-p3">Successfully edited account!</div>
                    </div>

                    :

                    this.state.error ? 

                    <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={errorIcon} alt="" style={{height: 50, width: 50, marginBottom: 12}} />
                        <div className="basier-p3">Oops! An error occured, please try again.</div>

                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}} >
                                <div
                                    onClick={() => this.cancelEdit()}
                                    className='button-next'
                                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer', backgroundColor: '#F9716B', color: 'white'}}
                                    >
                                    Cancel
                                </div>

                                <div
                                    onClick={this.updateEditData}
                                    className='button-next-blue'
                                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                    >
                                    Retry
                                </div> 
                            </div>

                    </div>


                :
            
                <div style={{padding: 24}}>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{width: 36, height: 36, marginRight: 12}}>
                                        { this.state.data.exchange !== null && this.state.data.exchange !== undefined ?
                                            <img src={this.state.data.exchange.image} alt='' style={{width: 36, height: 36}} />
                                        :
                                            null
                                        }
                                    </div>

                                    <div>
                                        <div className="basier-p2" style={{fontWeight: 900}}>
                                            {this.state.data.name}
                                        </div>
                                        <div className="basier-p4-caps">
                                            {this.state.data.custom_exchange_check ?

                                                this.state.data.custom_exchange

                                            :

                                                this.state.data.exchange.name
                                            
                                            }
                                        </div>
                                    </div>
                                </div>

                    <div style={{marginBottom: 12, marginTop: 24}} className="basier-p4-caps">Edit Account Name</div>
                    <div style={{marginTop: 6}} className="basier-p2">
                        <input value={this.state.editData.name || ""} onChange={(e) => this.handleEditData(e, 'name')} style={{width: '100%', textAlign: 'left',  height: 50, border: this.state.editNameError ? '2px solid red' : '1px solid #E1E5E9', paddingLeft: 12}} className="no-outline" />
                    </div>
                
                </div>

                :


                    <div style={{paddingLeft: 24, paddingTop: 24, paddingBottom: 24, paddingRight: 12}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{width: 36, height: 36, marginRight: 12}}>
                                        { this.state.data.exchange !== null && this.state.data.exchange !== undefined ?
                                            <img src={this.state.data.exchange.image} alt='' style={{width: 36, height: 36}} />
                                        :
                                            null
                                        }
                                    </div>

                                    <div>
                                        <div className="basier-p2" style={{fontWeight: 900}}>
                                            {this.state.data.name}
                                        </div>
                                        <div className="basier-p4-caps">
                                            {this.state.data.custom_exchange_check ?

                                                this.state.data.custom_exchange

                                            :

                                                this.state.data.exchange.name
                                            
                                            }
                                        </div>
                                    </div>
                                </div>

                                
                            </div>

                            <div style={{marginTop: 24}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div className="basier-p4-caps">
                                        wallets
                                    </div>
                                    <div onClick={this.newWallet} className="opacity-active">
                                        <div style={{display: 'flex', alignItems: 'center', width: 120}} onClick={this.addNewExchange}>
                                            <img src={Plus} style={{height: 40, width: 40}} alt=""/>
                                            <div className="basier-p4-caps" style={{fontSize: 16, textTransform: 'uppercase', marginLeft: 12}}>Add Wallet</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginTop: 24}}>
                                {Object.keys(this.state.data.wallets).map((val, key) => {
                                    const data = this.state.data.wallets[val]
                                    return (
                                        <div key={key} style={{height: 60}} className="exchange-flat-list-item" onClick={() => this.goToWallet(val, data, this.state.data.name, this.state.data)}>
                                            <div style={{display: 'flex'}}>
                                                <img src={data.coin_image} style={{height: 30, width: 30, marginRight: 12}} alt="" />
                                                <div>{data.coin_ticker}</div>
                                            </div>
                                            <div style={{paddingRight: 12}}>
                                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                                                </svg>
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>
                    </div>
                    }

                </div>
            
            </div>
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        currency_id: state.auth.currency.id,
        inBitcoin: state.data.bitcoinPf, 
        user_id: state.auth.user_id, 
        token: state.auth.token,
        wallets: state.appwide.wallets
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        setCurrentView: () => dispatch(dataActions.setCurrentView())
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(assetModalContent));
