import React, { type ElementConfig } from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/data';
import * as interactionActions from '../../../../../store/actions/interactions';



const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '250px', fontSize: 16, color: 'black', cursor: 'text', dispay:'inline-block'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#F3F5F6'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.4',
    };
  },

};

class MenuSelector extends React.Component {

  state = {
    selectedOption: this.props.initator ? this.props.initator : {value: this.props.user_id, label: `${this.props.first_name} ${this.props.last_name}`},
    users: undefined
  }

  componentDidMount() {


    if (this.props.prov === true) {

      this.setState(state => ({...state, users: this.props.userProvided})) 

    } else {

      this.props.callUserList(this.props.portfolioId)
      this.props.handleChange(this.props.user_id)

      if (this.props.userPass !== null && this.props.userPass !== undefined && this.props.users !== undefined && this.props.users !== null) {
        const selected = this.props.users.filter(v => v.value === this.props.userPass)
        if (selected.length > 0) {
          this.handleChange(selected[0])
        }

      }

    }

  }

  componentDidUpdate(prevProps) {

    if (this.props.userProvided !== null && this.props.userProvided !== undefined && this.props.userProvided !== prevProps.userProvided) {

      this.setState(state => ({...state, users: this.props.userProvided})) 
    }

    if (this.props.userPass !== null && this.props.userPass !== undefined && this.props.users !== undefined && this.props.users !== null && this.props.userPass !== prevProps.userPass && this.props.users !== prevProps.users) {

      const selected = this.props.users.filter(v => v.value === this.props.userPass)

      if (selected.length > 0) {
        this.handleChange(selected[0])
      }

    }


  }


  handleChange = (selectedOption) => {
    
    if (selectedOption === null) {
      console.log('here')
      this.setState({ selectedOption });
      if (this.props.propagate) {
        this.props.handleChange(selectedOption)
      }

    }
    else if (selectedOption !== this.state.selectedOption) {
      console.log('in the right one', selectedOption.value)
      this.setState({ selectedOption });

      this.props.handleChange(selectedOption.value)



    }
    
  }

  onFocus = () => {
    console.log('propagate', this.props.propagate)
    if (this.props.propagate === true) {
      this.handleChange(null)
    }
    

  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.state.users || this.props.users}
        isSearchable={true}
        styles={colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        onFocus={this.onFocus}
        onMenuOpen={this.onFocus}
        classNamePrefix="react-select-user"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {

      users: state.data.scopedUsers,
      currentPortfolio: state.data.menu_selected, 
      user_id: state.auth.user_id,
      first_name: state.auth.user_first_name,
      last_name: state.auth.user_last_name,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    callUserList: (portfolio) =>
          dispatch(actions.callUserList(portfolio)), 
    updateTransaction: (data) =>
          dispatch(interactionActions.updateTransaction(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
