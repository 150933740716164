import React, { type ElementConfig } from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';



const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '70px', height: 30, fontSize: 12, border: '0px', color: 'black', cursor: 'text', dispay:'inline-block'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#F3F5F6'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.4',
    };
  },

};

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <div style={{marginRight: '0px'}}>
          <svg width="8" height="8" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9136 1L7.95679 8L1 1" stroke="black" stroke-width="1.5" stroke-miterlimit="10"/>
          </svg>
        </div>
      </components.DropdownIndicator>
    );
  };

class MenuSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedOption: {value: 'above', label: 'Above'}
    }
  }
  
  

  componentDidMount() {


  }

  keyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
        e.persist()
        e.preventDefault()
    }
  }
  handleChange = (selectedOption) => {

    if (selectedOption.value !== this.state.selectedOption.value) {

      this.setState({ selectedOption });
      this.props.changeType(selectedOption.value)


    }
    
  }

    options = [{value: "above", label: "Above"}, {value: "below", label: "Below"}]

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.options}
        isSearchable={true}
        ref={this.props.innerRef}
        components={{DropdownIndicator}}
        onKeyDown={(e) => this.keyDown(e)}
        styles={colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        classNamePrefix="react-select-alertType"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

export default MenuSelector
