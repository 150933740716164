import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { siwtchTransactionType } from '../../helpers/helpers';

const styles = theme =>  ({
  root: {
    border: '1px solid #CCC', 
    height: 30, 
    minWidth: 30, 
    width: 30,
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
})

const timeoutLength = 100;

class MenuListComposition extends React.PureComponent {

constructor(props) {
    super(props);
    //this.anchorRef = React.createRef();
    this.state = {
        open: false, 
        subOpen: false,
        onMenu: false,
        anchorEl: null,
    } 

}

setOpen = () => {
    
}

getRef = async (ref) => {
    return ref;
}


handleToggle = () => {
    if (this.state.open) {
      this.setState(state => ({...state, open: false, subOpen: false, onMenu: false}))
    } else {
      this.setState(state => ({...state, open: true, subOpen: false, onMenu: false}))
    }
}



handleHoverOn = (e) => {
    this.setState(state => ({...state, subOpen: true, anchorEl: e.currentTarget  })); 
  };

handleClose = (event) => {
    setTimeout( () => {this.setState(state => ({...state, open: false, subOpen: false, onMenu: false}))} ,10)  
}

handleHoverOff = () => {
    setTimeout(() => {
        this.setState(state => ({ subOpen: false }));
       }, timeoutLength);
};

handleHoverMenuOn = () => {
    this.setState(state => ({ onMenu: true }));   
  }

  handleHoverMenuOff = () => {
    setTimeout(() => {
        this.setState(state => ({ onMenu: false }));
       }, timeoutLength);
  }

render () {

const { classes } = this.props;

  // return focus to the button when we transitioned from !open -> open

  return (
    <div>
      <div>
        <Button
          aria-controls={this.state.open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          className={classes.root}
          onClick={() => this.handleToggle()}
        >
          <div className="arrow down" ></div>
        </Button>
        <Popper open={this.state.open} role={undefined} transition disablePortal >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >   
                <ClickAwayListener onClickAway={() => this.handleClose()}>
                <div ref={node => {this.anchorEl = node}} className="basier-p4" style={{zIndex: 90, display: 'block', position: 'absolute', backgroundColor: 'white', }}>
                    <Paper>
                        <MenuList autoFocusItem={this.state.open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                            {this.props.menuItems.map((v, k) => {
                                return (
                                v[0] === 'Convert to' ?
                                <div>
                                    <MenuItem 
                                        key={k} className="basier-p4" onClick={v[1]}
                                        
                                        aria-owns={this.state.subOpen ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        //onClick={this.handleToggle}
                                        onMouseEnter={this.handleHoverOn}
                                        onMouseLeave={this.handleHoverOff}
                                        style={{cursor: 'pointer'}}
                                        >
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <div style={{marginRight: 12}}>{v[0]}</div>
                                            <div className="light-arrow right" ></div>
                                        </div>
                                        
                                    </MenuItem>
                                    <Popper open={this.state.subOpen || this.state.onMenu} anchorEl={this.anchorEl} placement='right-start' transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            id="menu-list-grow"
                                            //style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper>

                                                <MenuList
                                                    onMouseEnter={this.handleHoverMenuOn}
                                                    onMouseLeave={this.handleHoverMenuOff} 
                                                    >
                                                    {v[2].filter(v => v.toLowerCase() !== this.props.currentDescription.toLowerCase()).map((val, key) => {
                                                        return (
                                                            <MenuItem key={k} className="basier-p4" onClick={() => {this.handleToggle(); v[1](val);}}>
                                                                {siwtchTransactionType(val)[0]}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </MenuList>
                                                
                                            </Paper>
                                        </Grow>
                                        )}
                                    </Popper>
                                </div>
                                :
                                <MenuItem key={k} className="basier-p4" onClick={() => {this.handleToggle(); v[1](this.props.payload);}}>{v[0]}</MenuItem>
                                )
                            }
                            
                            
                            
                            )}
                             
                        </MenuList>
                    </Paper>
                </div>
                </ClickAwayListener>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}
}

export default withStyles(styles)(MenuListComposition)