import React from 'react';
import CoinSearch from '../../../../../AppWide/CoinSearch';
import OriginList from './OriginList'
import ExchangeQuestion from './SmallExchangeQuestion'
import * as actions from "../../../../../../store/actions/interactions";
import { connect } from 'react-redux';
import StorageQuestion from './SmallStorageWallet'


class SelectSource extends React.PureComponent {


    state = {
        originSelected: this.props.wallet !== null ? 'wallet' : this.props.account !== null ? 'exchange' : null, 
    }

    changeOrigin = (origin) => {

        this.setState(state => ({...state, originSelected: origin}))

        this.props.handleWalletSelect({key: this.props.transactionId, label: "exchange", value: null})
        this.props.handleWalletSelect({key: this.props.transactionId, label: "exchange_address", value: null})
        this.props.handleWalletSelect({key: this.props.transactionId, label: "account", value: null})
        this.props.handleWalletSelect({key: this.props.transactionId, label: "storage", value: null})
        this.props.handleWalletSelect({key: this.props.transactionId, label: "wallet", value: null})

    }

    render() {

        return (

            <div>
                
                <div style={{display: 'flex', alignItems: 'center' }}>
                    <div className='basier-p3' style={{marginTop: 12, paddingLeft: 36, width: 140, marginBottom: 6, zIndex: 7000, marginRight: 24}}>Source :</div>
                    <OriginList changeOrigin={this.changeOrigin} selection={this.state.originSelected} />
                </div>
                

                <div style={{marginTop: 24}}>

                    {
                        this.state.originSelected === 'exchange'? 
                        

                            <ExchangeQuestion 
                                data_filter={this.props.exchanges}
                                dataBind={this.props.dataBind}
                                exchange={this.props.exchange}
                                passRef={this.props.passRef}
                                focusIn={() => this.props.focusIn(this.firstFocusExchange)}
                                type="exchange"
                                allowOther={false}
                                clearRedux={["exchange"]}
                                clearWallets={false}
                                user_id={this.props.user_id}
                                transactionId={this.props.transactionId}
                                handleWalletSelect={this.props.handleWalletSelect}
                                //removeWallets={this.props.removeWallets}
                                updaterMethod={(ref, value) => this.props.updaterMethod(ref, value)}
                                handleSelect={(ref, value) => this.props.handleSelect('exchange', value)}
                                masterClass="transaction-coin-search"/>


                        : 

                        this.state.originSelected === 'wallet' ? 

                            this.props.wallets !== null && this.props.wallets !== undefined ? 

                            <div style={{display: 'flex', alignItems: 'center' }}>
                                <div className='basier-p3' style={{marginTop: 12, paddingLeft: 36, width: 140, marginBottom: 6, zIndex: 7000, marginRight: 24}}>Wallet :</div>
                                <StorageQuestion 
                                    accounts = {this.props.wallets[this.props.user_id]['storage']}
                                    coin = {this.props.coin}
                                    wallet={this.props.wallet}
                                    updateTransaction = {(data) => this.props.handleWalletSelect(data)}
                                    transactionId={this.props.transactionId}
                                    />
                            </div>

                                :
                            
                                null
                        

                        : 

                        null
                    }

                </div>

            </div>

        )
    }

}


const mapStateToProps = state => {
    return {
  
        modalPref: state.interactions.transactionAddModal,
        transaction: state.interactions.transactionAddData,
        tr_date: state.interactions.dateTransaction,
        exchangeCheck: state.interactions.exchangeCheck, 
        exchanges: state.appwide.exchanges,
        wallets: state.appwide.wallets,
        //user_id: state.auth.user_id
        
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectSource);
