import React from 'react';
import TransactionTypeRadios from './TransactionTypeRadios'
import * as actions from "../../../../../store/actions/interactions";
import { connect } from 'react-redux';
import CoinSearch from '../../../../AppWide/CoinSearch';
import { siwtchTransactionType } from '../../../../../helpers/helpers';
import { Input, Select} from 'antd';
import PriceType from './QuestionBlocks/SelectPriceType'
import TypeList from './TypeList';
const InputGroup = Input.Group;
const { Option } = Select;
class TransactionTypeQuote extends React.PureComponent {

    constructor(props) {

        super(props)
        this.inputQuoteRef = React.createRef();
        this.inputBaseRef = React.createRef();
        this.firstFocusRadio = React.createRef();
        this.firstFocusInputAmount = React.createRef();
        this.priceTypeRef = React.createRef();
        this.state = {
            done: false, 
            selected_quote: null, 
            portId: null,
        }

    }
    

    /* selectQuoteCoin = (data) => {
        this.props.updateTransaction({key: this.props.transactionId, label: "quote_currency", value: data.value})
        this.setState(state => ({...state, selected_quote: data}))
        //this.props.handleNext()
    } */

    componentDidMount() {

        if (this.props.transaction['data'][this.props.transactionId] !== undefined) {
            
            if (this.props.transaction['data'][this.props.transactionId]['base_currency'] === null) {

                const ref_needed = this.getRef(this.inputBaseRef)
                ref_needed
                .then(res => {
                    res.current.myInput.focus({preventScroll: true})

                })

            }
        
    }

    else 

    {
        const ref_needed = this.getRef(this.inputBaseRef)
        ref_needed
        .then(res => {
            res.current.myInput.focus({preventScroll: true})

        })

    }

    if (this.props.overridePort === true) {
        this.setState(state => ({...state, portId: this.props.overridePortId})) 
      } else {
        this.setState(state => ({...state, portId: this.props.currentPortfolio})) 
      }
       

    }

    getRef = async (ref) => {
        return ref;
    }

    handleChange = () => {
        this.props.selectType(null)
        this.props.updateTransaction({key: this.props.transactionId, label: "quote_currency", value: null})
        this.setState(state => ({...state, selected_quote: null}))
    }

    focusIn = (ref) => {

        try {ref.focus({preventScroll: true})} catch(e) {}

    }

    getChildRef = (ref) => {

        const ref_needed = this.getRef(this.firstFocusRadio)
        
        ref_needed
        .then(res => {

            try {res.current.myInput.focus({preventScroll: true})} catch(e) {}
            
            })

    }

    getQuoteRef = () => {
        

        const ref_needed = this.getRef(this.inputQuoteRef)
        
        ref_needed
        .then(res => {

            try { res.current.myInput.focus({preventScroll: true})} catch(e) {}
        })


    }

    getAmountRef = () => {

        const ref_needed = this.getRef(this.firstFocusInputAmount)
        
        ref_needed
        .then(res => {

            try{res.current.focus({preventScroll: true})} catch(e) {}
        })

    }

    getPriceTypeRef = () => {

        const ref_needed = this.getRef(this.priceTypeRef)
        
        ref_needed
        .then(res => {

            try{res.current.focus({preventScroll: true})} catch(e) {}
        })

    }

    forRadioRef = (type) => {

        if (type === 'sell' || type === 'buy' || type === 'ico') {
            this.getQuoteRef()
        }
        else 
        {   
            this.getAmountRef()
        }
        
    }

    handleAmountChange = (e) => {

        e.persist();
        this.props.updateTransaction({key: this.props.transactionId, label: 'amount', value: e.target.value})

        let priceSend = null;
        if (this.props.priceType === 'unit' && this.props.transaction['data'][this.props.transactionId]['unit_price'] !== null && this.props.transaction['data'][this.props.transactionId]['unit_price'] !== undefined) {
            priceSend = this.props.transaction['data'][this.props.transactionId]['unit_price'] * e.target.value
        } else {
            priceSend = this.props.transaction['data'][this.props.transactionId]['unit_price'] / e.target.value
        }

        this.setState(state => ({...state, priceDisplay: priceSend}))



    }

    ChangePriceType = (val) => {

        this.props.setInteraction({label: 'priceType', value: val})
        let priceSend = null;

        if(this.props.transaction['data'][this.props.transactionId]['unit_price'] !== null && this.props.transaction['data'][this.props.transactionId]['unit_price'] !== undefined && this.props.transaction['data'][this.props.transactionId]['amount'] !== null && this.props.transaction['data'][this.props.transactionId]['amount'] !== undefined && this.props.transaction['data'][this.props.transactionId]['amount'] !== 0 ) {

            if (val === 'unit') {
            
            priceSend = this.props.transaction['data'][this.props.transactionId]['unit_price'] * this.props.transaction['data'][this.props.transactionId]['amount']

            } else {
               
            
            priceSend = this.props.transaction['data'][this.props.transactionId]['unit_price'] / this.props.transaction['data'][this.props.transactionId]['amount']

            }
        }

        this.setState(state => ({...state, priceDisplay: priceSend}))
    }

    handlePriceChange = (e) => {
        e.persist();
        this.props.updateTransaction({key: this.props.transactionId, label: 'unit_price', value: e.target.value})
        let priceSend = null;
        if (this.props.priceType === 'unit') {
            priceSend = e.target.value * this.props.transaction['data'][this.props.transactionId]['amount']
        } else {
            priceSend = e.target.value / this.props.transaction['data'][this.props.transactionId]['amount']
        }

        this.setState(state => ({...state, priceDisplay: priceSend}))
    }

    onKeyDownInput = (e, key, focus, type) => {
        if (e.keyCode === 13 || e.keyCode === 9) {

            e.preventDefault();
            this.props.updateTransaction({key: this.props.transactionId, label: key, value: e.target.value})

            if (focus !== null && focus !== undefined && (type === 'buy' || type === 'ico' || type === 'sell')) {

                this.getPriceTypeRef()

            }
            if (type !== 'buy' && type !== 'ico' && type !== 'sell') {
                this.props.handleNext()
            }
        }
        
    }

    onKeyDownNext = (e, key, focus) => {
        if (e.keyCode === 13 || e.keyCode === 9) {

            e.preventDefault();
            this.props.updateTransaction({key: this.props.transactionId, label: key, value: e.target.value})
            this.props.handleNext()
            //this.getPriceTypeRef()
        }
        
    }

    onNextStepRef = (e, key,) => {
        if (e.keyCode === 13 || e.keyCode === 9) {
            console.log('hewo')
        }
    }
    

    render() {


        return (

            <div style={{marginTop: 24}}>

            {this.props.transactionId !== undefined && this.props.transactionId !== null ? 

                <div style={{marginTop: 0}}>
                <div style={{
                            display: 'flex', 
                            height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['base_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['base_currency']  !== undefined ? '60px' : '500px'}`, 
                            alignItems: 'baseline',
                            transition: 'height 0.3s', 
                            justifyContent: 'center'
                            }}>
                        <div className='basier-p3' style={{marginTop: 12, width: 500, marginBottom: 12, zIndex: 7000, marginRight: 24, fontWeight: 'bold'}}>Please select the transaction coin (Base Coin)</div>
                        <CoinSearch 
                                data_filter={this.props.all_coins}
                                dataBind={this.props.transaction['data'][this.props.transactionId]['base_currency']}
                                ref={this.inputBaseRef}
                                width={250}
                                keepInput={true}
                                overrideClass={true} 
                                focusIn={() => this.getChildRef()}
                                clearRedux='base_currency'
                                updaterMethod={(key, value) => this.props.updateTransaction({key: this.props.transactionId, label: key, value: value})}
                                handleSelect={(value) => this.props.selectBaseCoin(value)}
                                masterClass="transaction-coin-search"/>

                        
                    </div>

                    <div style={{
                                    marginTop: 48, 
                                    width: '100%',
                                    textAlign: 'center',
                                    display: `${this.props.transaction['data'][this.props.transactionId]['base_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['base_currency'] != undefined ? 'none' : 'block' }`
                                    }}>

                                        <div className="basier" style={{fontStyle: 'italic', fontSize: 14}}>The <span style={{color: '#0078DB'}}>Base Currency</span> is the asset that you are executing a transaction on. For example, if you buy Bitcoin, this will be the base currency.</div>

                    </div>

                    <div style={{
                            height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== null && this.props.transaction['data'][this.props.transactionId]['transaction_description']  !== undefined ? '60px' : '300px'}`, 
                            display: `
                                ${this.props.transaction['data'][this.props.transactionId]['base_currency'] !== null && 
                                this.props.transaction['data'][this.props.transactionId]['base_currency']  !== undefined ? 
                                this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== null && 
                                this.props.transaction['data'][this.props.transactionId]['transaction_description']  !== undefined ? 
                                'flex' : 'block' : 'none' }`, 
                            alignItems: 'baseline',
                            transition: 'height 0.3s',
                            justifyContent: 'center'

                            }}>
                    
                        <div className='basier-p3' style={{marginTop: 12, marginBottom: 12, width: 500, zIndex: 7000, marginRight: 24, fontWeight: 'bold'}}>Please select the transaction type</div>

                    {this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== null && this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== undefined ? 
                        <div style={{display: 'flex', alignItems: 'center', width: 250, border: '1px solid #CCCCCC', height: 40, borderRadius: 5}}>
                        <span className="basier-p3" style={{textTransform: 'uppercase', paddingLeft: 24, color: 'black'}}> 
                            {siwtchTransactionType(this.props.transaction['data'][this.props.transactionId]['transaction_description'])[0]}
                        </span>
                        <span className="basier-p4-caps link-hover"  style={{marginLeft: 12}} onClick={this.handleChange}>Change</span>
                        </div>
                    :   
                        <div style={{ width: '100%'}}>
                        <TransactionTypeRadios focusIn={this.forRadioRef} ref={this.firstFocusRadio} assetType={this.props.selectedBase} transactionId={this.props.transactionId} selectType={this.props.selectType} />
                        </div>
                    }
                   

                    </div>

                    {
                        this.props.transaction['data'][this.props.transactionId]['transaction_description'] === 'buy' || 
                        this.props.transaction['data'][this.props.transactionId]['transaction_description'] === 'ico' || this.props.transaction['data'][this.props.transactionId]['transaction_description'] === 'sell' ? 

                        this.props.selectedBase === null ?

                        null 

                        :
                        
                        <div>
                            <div style={{

                                    display: `
                                        ${this.props.transaction['data'][this.props.transactionId]['base_currency'] !== null && 
                                        this.props.transaction['data'][this.props.transactionId]['base_currency']  !== undefined && 
                                        this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== null && 
                                        this.props.transaction['data'][this.props.transactionId]['transaction_description']  !== undefined ? 
                                        'flex' : 'none' }`, 

                                    height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                                    alignItems: 'baseline',
                                    transition: 'height 0.3s',
                                    justifyContent: 'center'
                                    
                                    }}>
                                <div className='basier-p3' style={{marginTop: 12, marginBottom: 12, width: 500, zIndex: 7000, marginRight: 24, fontWeight: 'bold'}}>What coin did you {this.props.transaction['data'][this.props.transactionId]['transaction_description']} {this.props.selectedBase.coinName} against? (Quote Coin)</div>
                                <CoinSearch 
                                        data_filter={this.props.all_coins}
                                        width={250}
                                        dataBind={this.props.transaction['data'][this.props.transactionId]['quote_currency']}
                                        ref={this.inputQuoteRef}
                                        keepInput={true}
                                        focusIn={() => this.getAmountRef()}
                                        nextControl={this.firstFocusInputBase}     
                                        clearRedux='quote_currency'
                                        updaterMethod={(key, value) => this.props.updateTransaction({key: this.props.transactionId, label: key, value: value})}                             
                                        overrideClass={true} 
                                        handleSelect={(value) => this.props.selectQuoteCoin(value)}
                                        masterClass={`transaction-coin-search ${!this.props.validation.quote_currency === true ? 'error-input' : null}`}/>
                            </div>

                            <div style={{
                                        marginTop: 48, 
                                        width: '100%',
                                        textAlign: 'center',
                                        display: `
                                           ${(this.props.transaction['data'][this.props.transactionId]['base_currency'] === null ||
                                            this.props.transaction['data'][this.props.transactionId]['base_currency'] === undefined ) ||
                                            (this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null &&
                                            this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== undefined)
                                            
                                            ? 'none' : 'block' }`
                                        }}>

<div className="basier" style={{fontStyle: 'italic', fontSize: 14}}>The <span style={{color: '#0078DB'}}>Quote Currency</span> is the asset that you are executing a transaction against. For example, if you buy 1 Bitcoin for $6,000 USD, the quote currency will be USD.</div>

                            </div>

                        </div>

                        : null 

                    }

                    {this.props.selectedBase === null ?

                    null

                    : 
                    
                    <div style={{
                        display: `
                                ${
                                    this.props.transaction['data'][this.props.transactionId]['base_currency'] !== null && 
                                    this.props.transaction['data'][this.props.transactionId]['base_currency']  !== undefined && 
                                    this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== null && 
                                    this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== "buy" && 
                                    this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== "ico" && 
                                    this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== "sell" && 
                                    this.props.transaction['data'][this.props.transactionId]['transaction_description']  !== undefined ?
                                    'flex'
                                    :
                                    this.props.transaction['data'][this.props.transactionId]['base_currency'] !== null && 
                                    this.props.transaction['data'][this.props.transactionId]['base_currency']  !== undefined && 
                                    this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && 
                                    (this.props.transaction['data'][this.props.transactionId]['transaction_description'] === "buy" || 
                                    this.props.transaction['data'][this.props.transactionId]['transaction_description'] === "ico" || 
                                    this.props.transaction['data'][this.props.transactionId]['transaction_description'] === "sell") &&
                                    this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ?

                                    'flex' : 'none'}`,   

                        visibility: `${this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== null && this.props.transaction['data'][this.props.transactionId]['transaction_description']  !== undefined ? 'visible' : 'hidden'}`,   
                        height: 60, //height: `${this.props.transaction['data'][this.props.transactionId]['amount'] !== null && this.props.transaction['data'][this.props.transactionId]['amount']  !== undefined ? '60px' : '80px'}`, 
                        alignItems: 'baseline',
                        transition: 'height 0.3s'
                        }}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div className='basier-p3' style={{marginTop: 12, marginBottom: 12, width: 500, zIndex: 7000, marginRight: 24, fontWeight: 'bold'}}>{this.props.selectedBase.coinName} {this.props.transaction['data'][this.props.transactionId]['transaction_description']} quantity</div>
                    {!this.props.validation.amount && 
                        <div className="basier-p4" style={{marginTop: -8, fontStyle: 'italic', color: 'red'}}>
                            {`Quantity must be a positive number. Please use dots -> . <- for decimals`}
                        </div>
                    }
                    </div>
                        <input type="text" onChange={(e) => this.handleAmountChange(e)} ref={this.firstFocusInputAmount}  className={`transaction-coin-search ${!this.props.validation.amount === true ? 'error-input' : null}`} onKeyDown={(e) => this.onKeyDownInput(e, 'amount', this.priceTypeRef, this.props.transaction['data'][this.props.transactionId]['transaction_description'])} value={this.props.transaction['data'][this.props.transactionId]['amount'] || ""} />
                    </div>
                    }

                    {this.props.selectedBase !== null && (this.props.transaction['data'][this.props.transactionId]['transaction_description'] === 'buy' || this.props.transaction['data'][this.props.transactionId]['transaction_description'] === 'sell' || this.props.transaction['data'][this.props.transactionId]['transaction_description'] === 'ico') ?

                    <div style={{

                        display: `
                        ${
                            this.props.transaction['data'][this.props.transactionId]['base_currency'] !== null && 
                            this.props.transaction['data'][this.props.transactionId]['base_currency']  !== undefined && 
                            this.props.transaction['data'][this.props.transactionId]['amount']  !== null && 
                            this.props.transaction['data'][this.props.transactionId]['amount']  !== undefined && 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== null && 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== "buy" && 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== "sell" && 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== "ico" && 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description']  !== undefined ?
                            'flex'
                            :
                            this.props.transaction['data'][this.props.transactionId]['base_currency'] !== null && 
                            this.props.transaction['data'][this.props.transactionId]['base_currency']  !== undefined && 
                            this.props.transaction['data'][this.props.transactionId]['amount']  !== null && 
                            this.props.transaction['data'][this.props.transactionId]['amount']  !== undefined && 
                            this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && 
                            (this.props.transaction['data'][this.props.transactionId]['transaction_description'] === "buy" || 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] === "ico" || 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] === "sell") &&
                            this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ?

                            'flex' : 'none'}`,   


                        height: 60,
                        alignItems: 'baseline',
                        transition: 'height 0.3s'
                        }}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div className='basier-p3' style={{marginTop: 12, marginBottom: 2, width: 500, zIndex: 7000, marginRight: 24, fontWeight: 'bold'}}>At what price did you {this.props.transaction['data'][this.props.transactionId]['transaction_description'] === 'ico' ? 'do the ICO' : this.props.transaction['data'][this.props.transactionId]['transaction_description']} ?</div>
                        <div className='basier-p4' style={{fontStyle: 'italic', color: '#0078DB'}}>
                            {this.state.priceDisplay !== null && this.state.priceDisplay !== undefined ? 

                                this.props.priceType === 'unit' ? 


                                `You will spend a total of ${
                                    this.state.priceDisplay > 1 ? 
                                    this.state.priceDisplay.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                    :
                                    this.state.priceDisplay.toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})
                                } `

                                :

                                `The unit price will be ${
                                    this.state.priceDisplay > 1 ? 
                                    this.state.priceDisplay.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                    :
                                    this.state.priceDisplay.toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})
                                }`
                            
                            :
                            
                            null
                            }
                        </div>
                        {!this.props.validation.unit_price && 
                        <div className="basier-p4" style={{marginTop: 4, fontStyle: 'italic', color: 'red'}}>
                            {`Unit Price must be a positive number. Please use dots -> . <- for decimals`}
                        </div>
                        }
                    </div>

                        
                    <div style={{display: 'flex', alignItems: 'center'}}>

                        <PriceType innerRef={this.priceTypeRef} changeType={this.ChangePriceType} onKeyDown={(e) => this.onNextStepRef(e, 'price_type')} selection={this.props.priceType} nextRef={this.firstFocusInputPrice} />
                        <input 
                            onChange={(e) => this.handlePriceChange(e)} 
                            ref={(input) => { this.firstFocusInputPrice = input; }} 
                            className={`transaction-coin-search-price ${!this.props.validation.unit_price === true ? 'error-input' : null}`} onKeyDown={(e) => this.onKeyDownNext(e, 'unit_price', null)} 
                            value={this.props.transaction['data'][this.props.transactionId]['unit_price'] || ""} />
                    </div>
                    
                    
                    </div>

                    : 

                    null 


                    }

                    {this.props.selectedBase !== null && (this.props.transaction['data'][this.props.transactionId]['transaction_description'] === 'buy' || this.props.transaction['data'][this.props.transactionId]['transaction_description'] === 'sell' || this.props.transaction['data'][this.props.transactionId]['transaction_description'] === 'ico') && this.props.selectedQuote !== null ?

                    this.props.selectedQuote.coinType !== 'fiat' ? 

                    null 

                    :

                    <div style={{

                        display: `
                        ${
                            this.props.transaction['data'][this.props.transactionId]['base_currency'] !== null && 
                            this.props.transaction['data'][this.props.transactionId]['base_currency']  !== undefined && 
                            this.props.transaction['data'][this.props.transactionId]['amount']  !== null && 
                            this.props.transaction['data'][this.props.transactionId]['amount']  !== undefined && 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== null && 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== "buy" && 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== "sell" && 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] !== "ico" && 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description']  !== undefined ?
                            'flex'
                            :
                            this.props.transaction['data'][this.props.transactionId]['base_currency'] !== null && 
                            this.props.transaction['data'][this.props.transactionId]['base_currency']  !== undefined && 
                            this.props.transaction['data'][this.props.transactionId]['amount']  !== null && 
                            this.props.transaction['data'][this.props.transactionId]['amount']  !== undefined && 
                            this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && 
                            (this.props.transaction['data'][this.props.transactionId]['transaction_description'] === "buy" || 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] === "ico" || 
                            this.props.transaction['data'][this.props.transactionId]['transaction_description'] === "sell") &&
                            this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ?

                            'flex' : 'none'}`,   


                        height: 60,
                        alignItems: 'baseline',
                        transition: 'height 0.3s'
                        }}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div className='basier-p3' style={{marginTop: 12, marginBottom: 2, width: 500, zIndex: 7000, marginRight: 24, fontWeight: 'bold'}}>From where should we take the {this.props.selectedQuote.coinName} ?</div>
                    </div>

                        
                    <div style={{display: 'flex', alignItems: 'center'}}>

                        <div>
                        <TypeList handleSelect={this.props.selectFundSource} balances={this.props.portfolioBalance} portfolio={[this.state.portId]} coin={[this.props.selectedQuote.coinTicker]}  />
                        {
                        this.props.portfolioBalance !== undefined && this.props.portfolioBalance[this.state.portId] !== undefined && this.props.portfolioBalance[this.state.portId] !== null && this.props.transaction['data'][this.props.transactionId]['unit_price'] !== undefined && this.props.transaction['data'][this.props.transactionId]['amount'] !== undefined && this.props.transaction['data'][this.props.transactionId]['unit_price'] !== null && this.props.transaction['data'][this.props.transactionId]['amount'] !== null ?
                             this.props.transaction['data'][this.props.transactionId]['fund_source'] == 'deduct' && 
                             (
                                (  
                                this.props.priceType === 'unit' && 
                                (this.props.portfolioBalance[this.state.portId][this.props.selectedQuote.coinTicker] === undefined || (this.props.portfolioBalance[this.state.portId][this.props.selectedQuote.coinTicker] < (this.props.transaction['data'][this.props.transactionId]['amount'] * this.props.transaction['data'][this.props.transactionId]['unit_price'])))
                             ) || 
                             (
                                this.props.priceType === 'total' && 
                                (this.props.portfolioBalance[this.state.portId][this.props.selectedQuote.coinTicker] === undefined ||
                                this.props.portfolioBalance[this.state.portId][this.props.selectedQuote.coinTicker] < (this.props.transaction['data'][this.props.transactionId]['unit_price']))
                             ))
                             ? 
                                <div className="basier-p4" style={{color: 'red', marginTop: 4, paddingLeft: 4}}>WARNING: Insufficient balance to deduct.</div> 
                            :   
                                null
                                
                        :
                            null
                        }
                        </div>

                    </div>
                    
                    
                    </div>

                    : 

                    null 


                    }
                    

                    </div>

                    

            : 
            null
            }
            </div>  


        )
    }
}


const mapStateToProps = state => {
    return {
  
        modalPref: state.interactions.transactionAddModal,
        transaction: state.interactions.transactionAddData,
        all_coins: state.appwide.all_coins, 
        priceType: state.interactions.priceType,
        portfolioBalance: state.data.portfolioHoldings
        
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)), 
        setInteraction: (data) =>
            dispatch(actions.setInteraction(data)), 
        
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(TransactionTypeQuote);