import React, { type ElementConfig } from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';


const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '100%', height: 20, marginLeft: 12, fontSize: 16, border: '0px', color: 'black', cursor: 'text', dispay:'inline-block'}),
  menu: styles => ({ ...styles, backgroundColor: 'white', width: '100%', marginLeft: 12, zIndex: 900000}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#F3F5F6'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.7',
    };
  },

};


class MenuSelector extends React.Component {


    state = {
        selectedOption: null,
        data: null, 
    }

    componentDidMount() {
        console.log('SELECTED', this.props.selected)
        console.log('OPTIONS', this.props.data)
        let selectedOption = null;
        if (this.props.selected !== null && this.props.selected !== undefined) {
          selectedOption = this.props.data.filter(v => v['value'] == this.props.selected)

        }

        this.setState(state => ({...state, data: this.props.data, selectedOption: selectedOption}))
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState(state => ({...state, data: this.props.data, selectedOption: null}))
        }
    }

    handleChange = (val) => {
        this.setState({selectedOption: val});
        this.props.handleSelect(val);
    }
 
    render() {
        const { selectedOption } = this.state;

        return (

        <Select
            value={selectedOption}
            onChange={this.handleChange}
            options={this.props.data}
            onFocus={this.onFocus}
            onMenuOpen={this.onFocus}
            isSearchable={true}
            styles={colourStyles}
            alwaysOpen
            maxMenuHeight={120}
            minMenuHeight={50}
            classNamePrefix="react-select-user"
            theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
            ...theme.colors,
                primary25: '#F3F5F6',
                primary: '#F3F5F6',
            },
            })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    
  };
};

const mapDispatchToProps = dispatch => {
  return {
   
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
