import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CoinSmallGraph from '../../../../AppWide/CoinSmallGraph' 
import worker from "../../../../../workers/assetWorker";
import WebWorker from "../../../../../workers/WebWorker";
import {siwtchTransactionType} from '../../../../../helpers/helpers';
import TransactionView from './TransactionAddComponents/TransactionView';
import TransactionEdit from './TransactionAddComponents/TransactionEdit';
import * as actions from "../../../../../store/actions/data";

class assetModalContent extends React.PureComponent {

    constructor(props) {

        super(props)

        this.topRef = React.createRef();
        this.state = {
            edit: this.props.edit
        }
    }

    componentDidMount() {

        this.props.setCurrentView(51)

        document.ontouchmove = function(e){
            return true;
        }
      
      }
      
    
    render () {

        return (    

            <div style={{top: 0, zIndex: 999999, width: '100%', position: 'absolute', height: '100%'}}>
                    <div>

                        {!this.state.edit ? 
                            <TransactionView item={this.props.item} backAction={this.props.backAction}  />
                        :
                            <TransactionEdit new={this.props.new} topRef={this.topRef} portfolioId={this.props.portfolioId} cancelAdd={this.props.cancelAdd} passedData={this.props.passedData} getTrData={(id) => this.props.getTrData(id)} />
                        }
                            
                    </div>
                
            </div>

            
    
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,

        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(assetModalContent);
