import React from 'react';
import CoinSearch from '../../../../../AppWide/CoinSearch';
import ExchangeWallets from './ExchangeWallets'
import WalletCreation from './WalletCreation'
import * as actions from "../../../../../../store/actions/interactions";
import { connect } from 'react-redux';


class SmallExchangeQuestion extends React.PureComponent {


    state = {
        existing_wallet: null, 
        single_account: null, 
        multiple_account: null,
        exchangeId: this.props.exchange, 
        exchangeName: this.props.dataBind

    }

    componentDidMount() {

       /*  if (this.props.transaction['data'][this.props.transactionId]['exchange'] === undefined) {

            this.props.updateTransaction({key: this.props.transactionId, label: "exchange", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "account", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "wallets", value: {} })

        } */

    }

    

    handleSelect = (ref, value) => {

        let ex_id = null;
        if (typeof value === 'string' || value === null) {
            ex_id = value
        } else {
            ex_id = value['value']
        }
        this.setState(state => ({...state, exchangeName: value, exchangeId: ex_id}))
        //console.log(value)
        this.props.handleSelect(ref, value)
        /* this.props.selectExchange(value)
        const scope_exchange = value.value
        let scope_coin = null 
        try {scope_coin = this.props.transaction['data'][this.props.transactionId]['base_currency']['value']} catch(e) {}


        let exchange_accounts = null
        try { exchange_accounts = this.props.wallets[this.props.user_id]['exchange'][scope_exchange] } catch (e) {}
        console.log(exchange_accounts)
        if ( exchange_accounts === null ||  exchange_accounts === undefined ) {
            //this.setState(state => ({existing_wallet: false}))
        } else if (exchange_accounts.length === 1) {
            //this.setState(state => ({existing_wallet: true, single_account: true, multiple_account: false}))
        } else if (exchange_accounts.length > 1) {
            //this.setState(state => ({existing_wallet: true, multiple_account: true, single_account: false}))
        } */

    }

    render() {

        console.log('ex id', this.state.exchangeId)
        return (

        <div>

            <div style={{

                display: 'flex', 
                height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                alignItems: 'baseline',
                transition: 'height 0.3s'

            }}>
                    <div style={{marginLeft: 24, paddingLeft: 12, width: 140}} className='basier-p3'>
                        Exchange : 
                    </div>

                    <CoinSearch 
                        data_filter={this.props.exchanges}
                        dataBind={this.state.exchangeName}
                        ref={this.props.passRef}
                        width={250}
                        keepInput={true}
                        overrideClass={true} 
                        focusIn={this.props.focusIn}
                        type="exchange"
                        allowOther={false}
                        clearRedux="exchange"
                        clearWallets={false}
                        //removeWallets={this.props.removeWallets}
                        updaterMethod={(ref, value) => this.handleSelect(ref, value)}
                        handleSelect={(value) => this.handleSelect('exchange', value)}
                        masterClass="transaction-coin-search"/>

                    
                    



                </div>

            {
                this.state.exchangeId !== null && this.state.exchangeId !== undefined ?

                <div>

                    {

                        this.props.wallets[this.props.user_id]['exchange'][this.state.exchangeId] !== null &&
                        this.props.wallets[this.props.user_id]['exchange'][this.state.exchangeId] !== undefined ? 

                        
                        <div style={{

                            display: 'flex', 
                            height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                            alignItems: 'baseline',
                            transition: 'height 0.3s'
                            }}>

                            <div className='basier-p3' style={{paddingLeft: 12, marginLeft: 24, marginTop: 12, width: 140, marginBottom: 6, zIndex: 7000, fontWeight:  400}}>
                                Wallet: 
                            </div>

                            <ExchangeWallets 
                                accounts={this.props.wallets[this.props.user_id]['exchange']}
                                exchange={this.state.exchangeId}
                                straight={true}
                                updateTransaction={(value) => this.props.handleWalletSelect(value)}
                                transactionId={this.props.transactionId}
                            />

                        </div>

                            : 

                            null 


                        }
                
                </div>

                :

                null

            }

        </div>

        )
    }
}

const mapStateToProps = state => {
    return {     
        wallets: state.appwide.wallets, 
        exchanges: state.appwide.exchanges
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)), 
        removeWallets: () =>
            dispatch(actions.removeWallets()), 
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SmallExchangeQuestion);