
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import currencyData from '../../helpers/currency.json'

const initialState = {
    all_coins: undefined, 
    crypto: undefined, 
    exchanges: undefined, 
    wallets: {
        exchange: {},
        storage: [], 
        custom: {},
    },
    flat_wallets: undefined, 
    all_portfolios: undefined,
    orgs: undefined,
    notifs: undefined,
    invites: [],
    subscription: undefined,
    plan: undefined, 
    payMethods: undefined,
    limits: undefined,
    connectors: [],
    tasks: [],
    accountNames: [],
    contracts: null,
    };


const setContracts = (state, action) => {
    return updateObject(state, {contracts: action.data}) 
}

const setAccountNames = (state, action) => {
    return updateObject(state, {accountNames: action.data}) 
}

    
const setLimits = (state, action) => {
    return updateObject(state, {limits: action.data}) 
}

const setConnectors= (state, action) => {
    return updateObject(state, {connectors: action.data}) 
}
    
const setPlan = (state, action) => {
    return updateObject(state, {plan: action.data}) 
}

const setPayMethods = (state, action) => {
    return updateObject(state, {payMethods: action.data}) 
}

const setSubscription = (state, action) => {
    return updateObject(state, {subscription: action.data}) 
}

const setAllCoins = (state, action) => {
    return updateObject(state, {all_coins: action.data}) 
}

const setNotifs = (state, action) => {
    return updateObject(state, {notifs: action.data}) 
}

const setInvites = (state, action) => {
    return updateObject(state, {invites: action.data}) 
}

const setCrypto = (state, action) => {
    return updateObject(state, {crypto: action.data}) 
}

const setExchanges = (state, action) => {
    return updateObject(state, {exchanges: action.data}) 
}


const updateSingleWallet = (state, action) => {

    if (action.data.scope === "exchange") {
        return {
            ...state, 
            wallets: {
                ...state.wallets, [action.data.user_id]: {
                    ...state.wallets[action.data.user_id], 
                    [action.data.scope]: {
                        ...state.wallets[action.data.user_id][action.data.scope],
                        [action.data.exchange]: {
                            ...state.wallets[action.data.user_id][action.data.scope][action.data.exchange],
                            [action.data.account]: {
                                ...state.wallets[action.data.user_id][action.data.scope][action.data.exchange][action.data.account],
                                wallets: {
                                    ...state.wallets[action.data.user_id][action.data.scope][action.data.exchange][action.data.account]['wallets'],
                                    [action.data.coin]: action.data.data
                                        }
                                    }
                            
                                }
                            }
                        }
                
                
                    }
                }
    } else if (action.data.scope === 'storage') {
    
        return {
            ...state, 
            wallets: {
                ...state.wallets, [action.data.user_id]: {
                    ...state.wallets[action.data.user_id], 
                    [action.data.scope]: {
                        ...state.wallets[action.data.user_id][action.data.scope],
                        [action.data.coin]: action.data.data
                            }
                        }
                
                
                    }
                }


    }

}



const setWallets = (state, action) => {
    return updateObject(state, {wallets: action.data}) 
}

const setFlatWallet = (state, action) => {
    return updateObject(state, {flat_wallets: action.data}) 
}

const setAllPort = (state, action) => {
    return updateObject(state, {all_portfolios: action.data}) 
}

const setOrgs = (state, action) => {
    return updateObject(state, {orgs: action.data}) 
}

const incrementPort = (state, action) => {
    return {
        ...state, 
        limits: {...state.limits, portfolios: state.limits.portfolios + action.data}
    }
}

const incrementTr = (state, action) => {
    return {
        ...state, 
        limits: {...state.limits, transactions: state.limits.transactions + 1}
    }
}



const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.APPWIDE_ALL_COINS:
                return setAllCoins(state, action);
        case actionTypes.APPWIDE_CRYPTO:
                return setCrypto(state, action);
        case actionTypes.APPWIDE_EXCHANGES:
                return setExchanges(state, action);
        case actionTypes.APPWIDE_WALLETS:
                return setWallets(state, action);
        case actionTypes.APPWIDE_SINGLE_WALLET:
                return updateSingleWallet(state, action);
        case actionTypes.APPWIDE_FLAT_WALLETS:
                return setFlatWallet(state, action);
        case actionTypes.APPWIDE_ALL_PORTS:
                return setAllPort(state, action);
        case actionTypes.APPWIDE_ORG_LIST: 
                return setOrgs(state, action);
        case actionTypes.APPWIDE_NOTIFS: 
                return setNotifs(state, action);
        case actionTypes.APPWIDE_INVITES_SUCCESS: 
                return setInvites(state, action);
        case actionTypes.APPWIDE_SUB: 
                return setSubscription(state, action);
        case actionTypes.APPWIDE_PLAN: 
                return setPlan(state, action);
        case actionTypes.APPWIDE_PAY: 
                return setPayMethods(state, action);
        case actionTypes.APPWIDE_LIMITS: 
                return setLimits(state, action);
        case actionTypes.APPWIDE_CONNECTORS: 
                return setConnectors(state, action);
        case actionTypes.APPWIDE_ADD_PORT: 
                return incrementPort(state, action);
        case actionTypes.APPWIDE_ADD_TR: 
                return incrementTr(state, action);
        case actionTypes.APPWIDE_ACCOUNT_NAMES: 
                return setAccountNames(state, action);
        case actionTypes.APPWIDE_CONTRACTS: 
                return setContracts(state, action);
        default:
            return state;

    }
}


export default reducer;