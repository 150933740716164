import React from "react";
import { connect } from 'react-redux';
import * as actions from "../../../store/actions/data";
import * as interActions from "../../../store/actions/interactions";
import ExchangeCard from './WalletCard';
import StorageCard from './StorageCard';
import Plus from '../../../Media/Plus.svg'
import axios from 'axios';
import { backendUrl } from '../../../variables'
import Hidden from '@material-ui/core/Hidden';
import LargeWallet from './LargeWallet'
import SmallWallet from './SmallWallet'
// Import FilePond styles

class WalletOverview extends React.Component {

    state = {
        // Set initial files, type 'local' means this is a file
        // that has already been uploaded to the server (see docs)
        data: undefined, 
    }

    handleInit() {
    }

    handleSocket(data) {

        

    }

    addTask = () => {
        const currentTasks = this.props.tasks
        const newTask = currentTasks.length + 1

        const newTasks = [{description: `New Task ${newTask}`, sub: `Sub ${newTask}`, status: 'pending'}, ...currentTasks]

        this.props.setInteraction({label: "tasks", value: newTasks})
    }

    editWallet = (data) => new Promise ((resolve, reject) => {

        
        //console.log(this.state)
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const send_data = {
            ...data, 
            user_id: this.props.user_id
        }

        const url_call = `${backendUrl}/v1/wallets/`

        
            axios.patch(url_call, send_data, {headers: headers})
                .then(json => {

                    
                    resolve(json['data'])

                })
                .catch( e => {

                    reject('error')

                })
        
        
    })
    

    openModal = (type, scope, add,) => {

        this.props.toggleModal({type: type, open: true, payload: {scope: scope, context: add, data: null}})

    }

    componentDidMount() {


        if (this.props.portfolioId !== undefined) {

        } else {
            this.props.setCurrentView(97);
            this.props.setMenuPortfolio(null);
            this.setState(state => ({...state, data: this.props.wallets}))
        }
        //this.props.setCurrentView(99);
        //this.props.setMenuPortfolio(null);
    }

    componentDidUpdate(prevProps) {
        if (this.props.wallets !== prevProps.wallets) {
            this.setState(state => ({...state, data: this.props.wallets}))
        }
    }

    render() {

        return (

            this.state.data !== undefined && this.state.data !== null  ? 

            <div>
                <Hidden smDown>
                    <LargeWallet 
                        data={this.state.data}
                        openModal={this.openModal}
                        editWallet={this.editWallet}
                        user_id={this.props.user_id}
                    />
                </Hidden>

                <Hidden mdUp>
                    <SmallWallet 
                        setCurrentView={this.props.setCurrentView}
                        data={this.state.data}
                        editWallet={this.editWallet}
                        user_id={this.props.user_id}
                        openModal={this.openModal}


                    />
                </Hidden>

            </div>

            :

            null

        )
    }
}

const mapStateToProps = state => {
    return {
        wallets: state.appwide.wallets, 
        user_id: state.auth.user_id, 
        token: state.auth.token, 
        tasks: state.interactions.tasks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        setInteraction: (data) => dispatch(interActions.setInteraction(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(WalletOverview)
