import React from "react";
import { connect } from 'react-redux';
import * as actions from "../../../store/actions/data";
import okGreen from '../../../Media/okGreen.svg'
import cancelRed from '../../../Media/cancelRed.svg'
import Hidden from '@material-ui/core/Hidden';
import * as appwideActions from "../../../store/actions/appwide";

// Import FilePond styles

class WalletOverview extends React.Component {

    state = {
        walletEdit: {}, 
        newAddress: {},
        loading: {},
    }

    handleInit() {
    }

    handleSocket(data) {

        

    }

    viewDetail = (id, data, connect) => {
        if (connect !== null) {
            this.props.openModal(
                'connected_detail',
                data.id, 
                {data: data, scope: 'storage'}
            )
        }
        else {
            this.props.openModal('wallet_detail', id, {data: data, type: 'storage'})
        }
        
    }

    componentDidMount() {

        if (this.props.portfolioId !== undefined) {

        }

        //this.props.setCurrentView(99);
        //this.props.setMenuPortfolio(null);
    }

    onKeyDown = (e, wallet_id) => {

        switch (e.keyCode) {
            case 27: {
                this.commitEditAddress(wallet_id, 'cancel')
                break;
            }
            case 13: {
                this.commitEditAddress(wallet_id, 'ok')
                break;
            }

            default: {
                break;
            }
        }

    }

    editAddress = (wallet_id, e) => {
        e.persist();
        this.setState(state => ({...state, newAddress: {...state.newAddress, [wallet_id]: e.target.value} }))
    }
    
    showAddressEdit = (wallet_id, address) => {
        this.setState(state => ({...state, walletEdit: {...state.walletEdit, [wallet_id]: true}, newAddress: {...state.newAddress, [wallet_id]: address} }))
    }

    commitEditAddress = (wallet_id, action) => {
        if (action === 'cancel') {
            this.setState(state => ({...state, walletEdit: {...state.walletEdit, [wallet_id]: false} }))
        } else if (action === 'ok') {
            this.setState(state => ({...state, loading: {...state.loading, [wallet_id]: true} }))
            const data = {
                id: wallet_id, 
                address: this.state.newAddress[wallet_id]
            }

            const newAdd = this.props.editWallet(data)
            newAdd
                .then(
                    data => {
                        let walletIndex = this.props.data.findIndex(val => val.id === wallet_id)
                        
                        let walletData = this.props.data 


                        walletData[walletIndex]['address'] = this.state.newAddress[wallet_id]
                        


                        const payload = {

                            data: walletData, 
                            wallet: wallet_id, 
                            coin: this.props.coin, 
                            exchange: this.props.exchangeId,
                            scope: 'storage', 
                            user_id: this.props.user_id,

                        }
                        this.props.updateSingleWallet(payload)
                        this.setState(state => ({...state, loading: {...state.loading, [wallet_id]: false} }))
                        

                    }
                )
                .catch( err => console.log('err', err))
            this.setState(state => ({...state, walletEdit: {...state.walletEdit, [wallet_id]: false} })) 


        }
        
    }

    render() {

        return (

            <div style={{padding: 24, boxShadow: '1px 2px 18px rgba(225, 229, 233, 0.3)', minHeight: 150, borderRadius: 20, marginBottom: 24}}>
                
                <div>

                    <div style={{display: 'flex', alignItems: 'center', paddingBottom: 6, borderBottom: '1px solid #CCCCCC',}}>
                        <div style={{marginRight: 12}}><img src={this.props.data[Object.keys(this.props.data)[0]]['coin_image']} alt="" style={{height: 24, width: 24}} /></div>
                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                            <div className="basier-p2" style={{fontWeight: 900, color: 'black'}}>{this.props.data[Object.keys(this.props.data)[0]]['coin_ticker']}</div>
                            <div style={{marginLeft: 6, marginRight: 6, fontWeight: 400, }} className="basier-p2" >|</div>
                            <div className="basier-p4-caps" style={{textTransform: 'uppercase',}}>{this.props.data[Object.keys(this.props.data)[0]]['coin_name']}</div>
                        </div>
                    </div>
                    
                </div>

                {
                    this.props.data.map((val, key) => {

                        return (
                            <div key={key} style={{width: '100%'}}>
                               
                                <div className="light-hover" style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', minHeight: 48, borderBottom: `${this.props.data.length === key+1 ? 'none' : '1px solid rgb(225, 229, 233)'}`}}>

                                    <div style={{minWidth: 130, width: '45%', overflow: 'hidden', color: 'black', marginRight: 6}}>
                                        <div className="basier-p4-caps truncate"  style={{width: '100%'}}>
                                        { val.name } 
                                        </div>
                                        {val.connector !== null && 
                                        <div className="basier-p4" style={{fontStyle: 'italic', color: '#4CDAC1'}}>Connected</div>}
                                    </div>
                                
                                    <div className="basier-p4" style={{fontSize: 12, width: 'inherit', overflow: 'hidden'}}>

                                        { this.state.walletEdit[val['id']] ?
                                                                                                            
                                            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                                <input autoFocus={true} autoComplete="false" className="light-input basier-p4" style={{height: 30, width: 'inherit'}} value={this.state.newAddress[val['id']] || ""} onChange={(e) => this.editAddress(val['id'], e)} onKeyDown={(e) => this.onKeyDown(e, val['id'])}/>
                                                <div style={{width: 70, display: 'flex', alignItems: 'center'}}>
                                                    <div style={{marginLeft: 6, marginRight: 6}} onClick={() => this.commitEditAddress(val['id'], 'ok')}>
                                                        <img src={okGreen} style={{height: 24, width: 24}} alt="ok" />
                                                    </div>
                                                    <div style={{marginRight: 6}} onClick={() => this.commitEditAddress(val['id'], 'cancel')}>
                                                        <img src={cancelRed} style={{height: 24, width: 24}} alt="no" />
                                                    </div>
                                                </div>
                                            </div>
                                            

                                        :

                                            val.address !== null && val.address !== undefined && val.address !== "" ? 

                                                <div className="truncate" style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                                        {
                                                            this.state.loading[val['id']] ? 

                                                                <div style={{height: 20, width: '70%'}} className="pulse-skeleton"></div>

                                                            : 
                                                                        
                                                                    <div className="basier-p4 truncate" style={{width: 'fit-content'}}>
                                                                        {val.address}
                                                                    </div>

                                                        }
                                                        { 
                                                            this.state.loading[val['id']] ? 

                                                            null :

                                                            <div className="basier-p4 opacity-hover" style={{color: '#0078DB', cursor: 'pointer', width: 30, marginLeft: 8}} onClick={() => this.showAddressEdit(val['id'], val.address)}>
                                                                edit
                                                            </div>


                                                        }
                                                </div>

                                                :

                                                <div className="basier-p4-caps opacity-hover" style={{color: '#0078DB', cursor: 'pointer', width: 'fit-content'}} onClick={() => this.showAddressEdit(val['id'], val.address)}>+ add address</div>
                                            
                                        
                                        }
                                </div>

                                

                                <div className="basier-p4" style={{width: "10%", marginLeft: 8}}>
                                                         
                                    <div onClick={() => this.viewDetail(val['id'], val, val.connector)} className="basier-p4-caps opacity-hover" style={{color: '#0078DB', cursor: 'pointer'}}>
                                        View
                                    </div>
                                                                            
                                </div>
                            </div>
                        </div>
                        )
                    })

                    
                }
                
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        updateSingleWallet: (payload) => 
            dispatch(appwideActions.updateSingleWallet(payload))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(WalletOverview)
