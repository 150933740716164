import React from "react";
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as actions from "../../store/actions/appwide";
import * as authActions from "../../store/actions/auth";
import inviteIcon from '../../Media/invite.svg'
import userIcon from '../../Media/profile.svg'
import supportIcon from '../../Media/support.svg'
import settingsIcon from '../../Media/settings.svg'
import reconcileIcon from '../../Media/reconcileIcon.svg'
import { withRouter} from 'react-router-dom';


const styles = theme => ({
    popper: {
        zIndex: '99999999999999999999999999999 !important',
    }, 
})


class NotifsContainer extends React.PureComponent {

    componentDidMount() {

        this.props.getInvites(this.props.user_id)

    }

    state = {

    }

    goToSettings = () => {

        this.props.history.push('/settings')
        this.props.handleClose()

    }

    goToInvite = () => {

        this.props.history.push('/invitations')
        this.props.handleClose()

    }

    goToReconcile = () => {
        this.props.history.push('/reconcile')
        this.props.handleClose()
    }

    logout = () => {
        if (window.confirm('Are you sure you want to log out?')){
            this.props.logout()
        }
        
    }
    

    render() {

        const { classes } = this.props;
        let number_portfolios = 0
        try { number_portfolios = this.props.list_portfolios.length } catch(e) {}
        const fullName = `${this.props.first_name} ${this.props.last_name}`

        return (

            <div style={{height: '100%', width: '100%'}}>

               <div style={{height: 220, display: 'flex', flexDirection: 'column'}}>
                        
                   <div style={{height: 60, display: 'flex', alignItems: 'center', paddingTop: 4,  justifyContent: 'left', paddingLeft: 12,}}>
                       <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{fontWeight: 900, paddingRight: 6}} className="basier-p3">
                                    {
                                        fullName.length <= 19 ? 
                                        fullName : `${fullName.slice(0,19)}...`
                                    }
                            </div>
                            <div className="basier-p4-caps" style={{marginTop: 6}}>
                                {number_portfolios} portfolios
                            </div>
                        </div>
                   </div>

                   <div style={{height: 40, display: 'flex', alignItems: 'center', justifyContent: 'left', paddingLeft: 12, cursor: 'pointer', borderTop: '1px solid #CCCCCC',}} className="hover-notif" onClick={() => {this.goToInvite()}}>
                       <div>
                           <img src={inviteIcon} style={{height: 24, width: 24, marginRight: 12,}} alt="" />
                       </div>
                       <div>
                            Invitations
                       </div>
                       {this.props.invites.filter(v => v.status === 'pending').length > 0 ? 
                       <div style={{marginLeft: 12, width: 28, height: 16, borderRadius: 8, backgroundColor: '#F9716B', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', padding: 4, fontWeight: 900, fontSize: 10}} className="basier">
                           {this.props.invites.filter(v => v.status === 'pending').length}
                       </div>
                       :
                       null
                       }
                        
                   </div>

                   <div style={{height: 40, display: 'flex', alignItems: 'center', justifyContent: 'left', paddingLeft: 12, cursor: 'pointer', borderTop: '1px solid #CCCCCC',}} className="hover-notif" onClick={() => {this.goToReconcile()}}>
                       <div>
                           <img src={reconcileIcon} style={{height: 24, width: 24, marginRight: 12,}} alt="" />
                       </div>
                       <div>
                            Reconciliation
                       </div>
                       {this.props.transactions !== undefined && this.props.transactions.filter(v => (v.related_transaction === null && v.include === true) || (v.reviewed === false && v.base_currency !== null)).length > 0 ? 
                       <div style={{marginLeft: 12, width: 28, height: 16, borderRadius: 8, backgroundColor: '#F9716B', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', padding: 4, fontWeight: 900, fontSize: 10}} className="basier">
                           {this.props.transactions.filter(v => (v.related_transaction === null && v.include === true) || v.reviewed === false).length}
                       </div>
                       :
                       null
                       }
                        
                   </div>

                   <div style={{height: 40, display: 'flex', alignItems: 'center', justifyContent: 'left', paddingLeft: 12, borderTop: '1px solid #CCCCCC', cursor: 'pointer'}} className="hover-notif" onClick={() => this.goToSettings()}>
                        <div>
                           <img src={settingsIcon} style={{height: 24, width: 24, marginRight: 12}} alt="" />
                       </div>
                       <div>
                            Settings
                       </div>
                   </div>

                   <div style={{height: 40, display: 'flex', alignItems: 'center', justifyContent: 'left', paddingLeft: 12, borderTop: '1px solid #CCCCCC', cursor: 'pointer'}} className="hover-notif" onClick={() => window.open("https://support.wisly.io", "_blank")}>
                        <div>
                           <img src={supportIcon} style={{height: 24, width: 24, marginRight: 12}} alt="" />
                       </div>
                       <div>
                            Support
                       </div>
                   </div>

                   

               </div>

               <div style={{height: 30, backgroundColor: "#0078DB", borderRadius: '0px 0px 5px 5px', display: 'flex', alignItems:'center', justifyContent: 'center', fontWeight: 900, color: 'white', cursor: 'pointer' }} className="basier-p4-caps no-select opacity-hover" onClick={() => this.logout()}>
                    Logout
               </div>

            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        notifs: state.appwide.notifs, 
        user_id: state.auth.user_id, 
        token: state.auth.token,
        invites: state.appwide.invites, 
        first_name: state.auth.user_first_name,
        last_name: state.auth.user_last_name,
        list_portfolios: state.auth.list_portfolios, 
        transactions: state.data.importedTransactions
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(authActions.logout()), 
        getInvites: (data) => dispatch(actions.getInvites(data)),
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)((withRouter(NotifsContainer))));