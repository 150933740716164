import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/data";
import * as interActions from "../../../../../store/actions/interactions";
import SmallTransactionOverview from './SmallTransactionOverview'
import SmallTransactionEditDetail from './SmallTransactionEditDetail'


class TransactionWrapper extends React.PureComponent {

    state = {
        transactionDetail: null,
        add: false,
    }


    addNew = () => {
        this.setState(state => ({...state, add: true}))
    }

    cancelAdd = () => {
        this.setState(state => ({...state, add: false})) 
    }

    selectTransaction = (val) => {

        this.setState(state => ({...state, transactionDetail: val}))

    }


    componentDidMount() {

        
       //this.props.setCurrentView(5)
       this.props.componentDataFetch('get', 5, this.props.currency_id, null, this.props.portfolioId, null, null, this.props.inBitcoin)

    } 
    
    getOverallData = () => {
        this.props.componentDataFetch('get', 5, this.props.currency_id, null, this.props.portfolioId, null, null, this.props.inBitcoin)
    }

    toggleTxModal = () => {

        //this.props.toggleTransactionAddModal({open: true});

    }

    render() {

        return (

           <div>

               {this.state.add ? 
               
                <SmallTransactionEditDetail portfolioId={this.props.portfolioId} edit={true} new={true} cancelAdd={this.cancelAdd} />
            
                : 
                
                <SmallTransactionOverview setCurrentView={this.props.setCurrentView} transactions={this.props.transactions['transactions']} portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} selectTransaction={this.props.selectTransaction} addNew={this.addNew} />

                }
            </div>

        )
    }
  
};

const mapStateToProps = state => {
    return {
        transactions: state.data.transactions, 
        inBitcoin: state.data.bitcoinPf, 
        currency_id: state.auth.currency.id
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        toggleTransactionAddModal: (data) => 
            dispatch(interActions.toggleTransactionAddModal(data)),
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionWrapper);