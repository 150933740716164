import React, { type ElementConfig } from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';
import plusButton from '../../../../../../Media/Plus.svg'
import {noAddErc20} from '../../../../../../helpers/noERC20'


const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '250px', fontSize: 16, border: '0px', color: 'black', cursor: 'text', dispay:'inline-block'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#F3F5F6'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.7',
    };
  },

};

const optionDisplay = (wallet) => {
    return (
      <div style={{'textAlign': 'left'}}>
          {wallet.name} ({wallet.wallet_coin === 'ethereum' ? `${wallet.coin_ticker}/ERC-20` : `${wallet.coin_ticker}` })
      </div>
     
    )
}

const adderDisplay = (
    <div style={{'textAlign': 'left'}}>
      <span className="icon-container-menu">
          <img src={plusButton} alt=""/>
      </span>
      <span className="basier-p4-caps">add a new Wallet</span>
  </div>
  )

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <div style={{marginLeft: '12px'}}>
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9136 1L7.95679 8L1 1" stroke="#6A7E93" stroke-width="1.5" stroke-miterlimit="10"/>
        </svg>
      </div>
    </components.DropdownIndicator>
  );
};

const menuHeaderStyle = {
    padding: '8px 12px',
    background: 'white',
    color: 'black',
    fontWeight: 900,
  };

const MenuList = props => {
    return (
      <components.MenuList {...props}>
        <div className="basier-p4-caps" style={menuHeaderStyle}>Storage Wallets</div>
        {props.children}
      </components.MenuList>
    );
  };

let options = [];


class MenuSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null
    }
  }
  

  componentDidMount() {

    options = [];
    console.log(this.props.wallets)
    console.log(this.props.base_currency['value'])
    console.log(this.props.wallets[this.props.base_currency['value']])

    if ( this.props.wallets[this.props.base_currency['value']] !== undefined &&  this.props.wallets[this.props.base_currency['value']] !== null) {

        this.props.wallets[this.props.base_currency['value']].map( (wallet) => {

        let values = {id: JSON.stringify(wallet['id']), value: wallet['name'], label: optionDisplay(wallet)};
        if (this.props.wallets[this.props.base_currency['value']].length === 1) {
            this.setState(state => ({...state, selectedOption: values}))
          this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: values.id})
        } else if (values['id'] === this.props.transaction['data'][this.props.transactionId]['storage']) {
          this.setState(state => ({...state, selectedOption: values}))
          this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: values.id})
        }
        
        options.push(values)

        })

    }

    if ( !noAddErc20.includes(this.props.base_currency['value']) && this.props.wallets['ethereum'] !== undefined  ) {

        this.props.wallets['ethereum'].map( (wallet) => {
            let values = {id: JSON.stringify(wallet['id']), value: wallet['name'], label: optionDisplay(wallet)};
            
            if (values['id'] === this.props.transaction['data'][this.props.transactionId]['storage']) {
              this.setState(state => ({...state, selectedOption: values}))
              this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: values.id})

            }
            
            options.push(values)
      
          })

    }

    console.log(options)

    if (this.props.noAdd === false) {
      const adder = {id: "noAction", value: 'adder', label: adderDisplay}
      options.push(adder);
    }

    this.setState(state => ({...state, options: options}))

  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.wallets !== this.props.wallets) {

      options = [];
      this.setState(state => ({...state, selectedOption: null}))
      console.log(this.props.wallets)
      console.log(this.props.base_currency['value'])
      console.log(this.props.wallets[this.props.base_currency['value']])
  
      if ( this.props.wallets[this.props.base_currency['value']] !== undefined &&  this.props.wallets[this.props.base_currency['value']] !== null) {
  
          this.props.wallets[this.props.base_currency['value']].map( (wallet) => {
  
          let values = {id: JSON.stringify(wallet['id']), value: wallet['name'], label: optionDisplay(wallet)};
          if (this.props.wallets[this.props.base_currency['value']].length === 1) {
              this.setState(state => ({...state, selectedOption: values}))
            this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: values.id})
          } else if (values['id'] === this.props.transaction['data'][this.props.transactionId]['storage']) {
            this.setState(state => ({...state, selectedOption: values}))
            this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: values.id})
          }
          options.push(values)
  
          })
  
      }
  
      if ( !noAddErc20.includes(this.props.base_currency['value']) && this.props.wallets['ethereum'] !== undefined  ) {
  
          this.props.wallets['ethereum'].map( (wallet) => {
              let values = {id: JSON.stringify(wallet['id']), value: wallet['name'], label: optionDisplay(wallet)};
              
              if (values['id'] === this.props.transaction['data'][this.props.transactionId]['storage']) {
                this.setState(state => ({...state, selectedOption: values}))
                this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: values.id})

              }
              
              options.push(values)
        
            })
  
      }
  
      console.log(options)
  
      if (this.props.noAdd === false) {
        const adder = {id: "noAction", value: 'adder', label: adderDisplay}
        options.push(adder);
      }
  
      this.setState(state => ({...state, options: options}))
    
  }
}


  handleChange = (selectedOption) => {

    if (selectedOption.value !== 'adder') {
        this.setState(state => ({...state,  selectedOption: selectedOption }));
        this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: selectedOption.id})
        
    } else if (selectedOption.value === 'adder') {
        this.props.setNewWallet(false)
    }
    
  }

  onFocus = () => {

    this.setState(state => ({...state,  selectedOption: null }));
    this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: null})

  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.state.options}
        onFocus={this.onFocus}
        isSearchable={true}
        styles={colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        components={{ MenuList, DropdownIndicator }}
        classNamePrefix="react-select-user"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    
  };
};

const mapDispatchToProps = dispatch => {
  return {
   
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
