import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/auth";
import loadingSpinner from '../../Media/loadingSpinner.gif'
import {createLoadingSelector} from '../../store/reducers/selectors'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import currencyData from '../../helpers/currency.json'

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };

const BalanceGraph = (props) => {

    let today_value = null 
    try {today_value = props.data.today_value.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})} catch(e) { today_value = props.data.today_value }
    let data_render = props.data.values
    let received_date = new Date(props.data.start_date)
    let data_date = Date.UTC(received_date.getFullYear(), received_date.getMonth(), received_date.getDate() )

    const symbol_to_use = props.currencyScope === 'bitcoin' ? "₿" : props.currency_symbol
    const { classes } = props;

    const arrowDownSvg = 
        `<svg width="24" height="24" viewBox="12 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <rect x="10" y="0.00415039" width="24" height="24" rx="5" fill="white"/>
        </g>
        <path d="M26.2664 13.0848L21.7471 17.6041L17.1997 13.0848" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
        <path d="M21.7473 17.6042V6.40417" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
        <defs>
        <filter id="filter0_d" x="0" y="0.00415039" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="10"/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.882353 0 0 0 0 0.898039 0 0 0 0 0.913725 0 0 0 0.3 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>`

    const arrowUp = 
    `<svg width="24" height="24" viewBox="12 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
    <rect x="10" width="24" height="24" rx="5" fill="white"/>
    </g>
    <path d="M18 10.3684L22.4861 5.88234L27 10.3684" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M22.4854 5.88235L22.4854 17" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    <defs>
    <filter id="filter0_d" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
    <feOffset dy="10"/>
    <feGaussianBlur stdDeviation="5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.882353 0 0 0 0 0.898039 0 0 0 0 0.913725 0 0 0 0.3 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    </defs>
    </svg>`
    
    const options = {
        chart: {
            type: 'area', 
            backgroundColor: props.background,  
            animation: false,
        },
        credits: false,
        title: false,
        xAxis: {
            className: 'basier-p4-caps',    
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            type: 'datetime',
            minPadding:0,
            maxPadding:0,
            tickLength: 0,
            tickColor: 'black',
            labels: {
                padding: 30,
                style: {
                    fontSize: '12px',
                },
            }

        },
        yAxis: {
            className: 'basier-p4 graph-labels-x',
            title: {
                text: false,
            },
            align: 'right',
            minorGridLineWidth: 1,
            gridLineWidth: 1,
            gridLineColor: '#E1E5E9',
            opposite: true,
            labels: {
                style: {
                    fontSize: '12px',
                    textAlign: 'right',
                    position: 'relative',
                    display: 'inline-block'
                },
                formatter: function() {
                    return this.value.toLocaleString('en');
                }
            }
            
        },
        series: [{
            name: 'Portfolio value in USD',
            data: data_render,
            pointStart: data_date,
            pointInterval: 24 * 3600 * 1000,
            color: '#0078DB',
            lineWidth: 1.5,
            marker: {
                fillColor: "#fff", 
                lineColor: "#0078DB", 
                lineWidth: 2,
            }
        }],
        legend: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        
        plotOptions: {
            series: {
                animation: false, 
                marker: {
                    enabledThreshold: 3
                },
            },
            area: {
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1},
                    stops: [
                        [0, "rgba(0, 120, 219, 0.22)"],
                        [1, "rgba(0, 120, 219, 0)"]
                    ]
                },
            },
        },
        responsive: {
            rules: [{
                condition: {
                    minWidth: 400
                },
                chartOptions: {
                    chart: {
                        height: 450
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    }
                }
            }]
        },
        tooltip: {
        
        backgroundColor: '#fff',
        height: 200,
        borderRadius: 14,
        borderWidth: 0,
        shadow: true,
        padding:12,
        style: {
            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
            height: '108px', 
            width: '186px',
        },
        useHTML: true,
        formatter: function () {
            const changePrevious = this.y / this.series.yData[this.point.index - 1];
            let changeVue = null;

            if (changePrevious < 1) {
                changeVue = arrowDownSvg
            } else if (changePrevious > 1) {
                changeVue = arrowUp
            } else { 
                changeVue = arrowUp
            }

            return "<span class='tooltip-wrapper'><span class='tooltip-main-text'><span class='image-tooltip'>" + changeVue + "</span><h4 class='tooltip-title'>" + symbol_to_use + " " + parseFloat(this.y.toFixed(2)).toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2}) + "</h4></span><span class='tooltip-divider'></span><br /><span class='date-tooltip'><span class='basier-p4 date-text-span'>" + Highcharts.dateFormat('%e %b %Y',new Date(this.x)) + " 23:59 UTC</span></span>"
                }
        }
    }   

        const handleChangeTimeline = (e) => {
            const new_period = e.currentTarget.getAttribute('value')
            const globalData = {
                period: new_period,
                start: props.data.start,
                end: props.data.end, 
                scope: props.currencyScope
            }

            if (props.scope === "portfolio") {
                props.updateTime(new_period, props.portfolio)
                props.updateGraph('update', props.currentView, props.currency, null, props.portfolio, null, globalData, props.inBitcoin)
            } else {
                props.updateTime(new_period)
                props.updateGraph('update', props.currentView, props.currency, props.user, null, globalData, null, props.inBitcoin)
            }
            
        }

        const handleBTCChange = e => {

            
            const new_scope = e.target.value;
            //console.log(`new scope is ${new_scope}`)
            const globalData = {
                period: props.timePeriod,
                start: props.data.start,
                end: props.data.end, 
                scope: new_scope
            }
            
            props.setPfScope(new_scope)
            props.updateGraph('update', props.currentView, props.currency, props.user, props.portfolio, globalData, globalData, e.target.value)
            if (new_scope === 'bitcoin') {
                props.setBtcSymbol('₿')
            } else {
                let currency_symbol = null;
                try { currency_symbol = currencyData[props.currency_name]["symbol"]} catch (e) { currency_symbol = props.currency_name}
                props.setBtcSymbol(currency_symbol)
            }
            
        }


        return (

            <div>

                <Grid container 
                spacing={0}
                justify="space-between"
                alignItems="center"
            >
                    <Grid item style={{
                        marginLeft: 48,
                        display: 'flex', 
                        alignItems: 'center'
                    }}> 
                        <div style={{display: 'inline-block'}}>
                            <div 
                                className="basier-p4-caps"
                                style={{color: '#6A7E93',
                                        display: 'inline-block'}}
                                >
                                Total Portfolio Balance
                            </div><br />
                             <h2 style={{display: 'inline-block'}}>{symbol_to_use} {today_value}</h2>
                        </div>

                        <span style={{  marginLeft: 25, 
                                        width: 170,
                                        height: 42, 
                                        paddingLeft: 10,
                                        display: 'inline-flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center',
                                        }}
                            className="white-button"
                            >
                            
                            <div class="form-check form-check-inline sp-l-12">

                                <RadioGroup
                                    name="btcScope"
                                    value={props.inBitcoin}
                                    onChange={handleBTCChange}
                                    style={{display: 'inline-block'}}

                                >                              
                                    <FormControlLabel value="fiat" 
                                                      control={<Radio classes={{
                                                          root: classes.root,
                                                          checked: classes.checked
                                                      }}/>} 
                                                      label={props.currency_name} />
                                    <FormControlLabel value="bitcoin" 
                                                      control={<Radio classes={{
                                                          root: classes.root,
                                                          checked: classes.checked
                                                      }} />} 
                                                      label="BTC" />

                            
                                </RadioGroup>

						    </div>
                            </span>

                    </Grid>

                    <Grid item style={{
                        marginRight: 56,
                        display: 'flex'
                    }}>
                        {/* <span style={{  marginLeft: 25, 
                                        width: 290, 
                                        height: 42, 
                                        display: 'flex'}}
                            className="white-button"
                            >

                            <span className="white-button"
                                    style={{
                                        display: 'flex',
                                        marginLeft: 12,
                                        marginRight: 8,
                                        alignSelf: 'center',
                                        height: 21, 
                                        width: 81, 
                                        backgroundColor: 'rgba(243, 245, 246, 0.5)', 
                                        color: '#6A7E93'
                                    }}>
                                    01/01/1990
                            </span>

                            <span class="basier-p4" style={{alignSelf: 'center', color: '#6A7E93'}}>to</span> 

                            <span className="white-button"
                                    style={{
                                        display: 'flex',
                                        marginLeft: 8,
                                        marginRight: 12,
                                        alignSelf: 'center',
                                        height: 21, 
                                        width: 81, 
                                        backgroundColor: 'rgba(243, 245, 246, 0.5)', 
                                        color: '#6A7E93'
                                    }}>
                                    02/02/1999
                            </span>
                                
                            <span style={{alignSelf: 'center'}} className='basier-p2'>
                                    Custom
                            </span>

                            
                        </span> */}
                        
                        <span style={{marginLeft: 25, 
                                    alignSelf: 'center'}}
                               value='ytd'
                               className={`basier-p2 period-selector ${props.timePeriod === 'ytd' ? 'period-active' : null}`}
                               onClick={e => handleChangeTimeline(e)}>
                            YTD
                        </span>
                        <span style={{marginLeft: 25, 
                                    alignSelf: 'center'}}
                               value='12m'
                               className={`basier-p2 period-selector ${props.timePeriod === '12m' ? 'period-active' : null}`}
                               onClick={e => handleChangeTimeline(e)}>
                            1y
                        </span>
                        <span style={{marginLeft: 25, 
                                    alignSelf: 'center'}}
                               value='6m'
                               className={`basier-p2 period-selector ${props.timePeriod === '6m' ? 'period-active' : null}`}
                               onClick={e => handleChangeTimeline(e)}>
                            6m
                        </span>
                        <span style={{marginLeft: 25, 
                                    alignSelf: 'center'}}
                               value='3m'
                               className={`basier-p2 period-selector ${props.timePeriod === '3m' ? 'period-active' : null}`}
                               onClick={e => handleChangeTimeline(e)}>
                            3m
                        </span>
                        <span style={{marginLeft: 25, 
                                    alignSelf: 'center'}}
                              value='30d'
                              className={`basier-p2 period-selector ${props.timePeriod === '30d' ? 'period-active' : null}`}
                              onClick={e => handleChangeTimeline(e)}>
                            1m
                        </span>
                       
                    </Grid>
                    </Grid>
                    
                    <Grid container 
                        spacing={0}>

                        <div style={{width: '100%', marginLeft: -10, paddingRight: 36, position: 'relative', height: 450}}>
                        { ( props.loadedGraph === true) ?  <div className="loader-graph"><img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/></div> : null }
                            
                        

                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                oneToOne={true}
                            />

                        </div>

                    </Grid>

                </div>
            
            
        )


        
};

BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setBtcSymbol: (s) =>
            dispatch(actions.setBtcSymbol(s)), 
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));


