import React from 'react'; 
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import ProfitTable from './ProfitList';
import SmallAssetTable from '../Overview/Small/SmallAssetTable';
import Hidden from '@material-ui/core/Hidden';
import AssetModal from '../../PortfolioComponents/AssetModal';
import Plus from '../../../../Media/Plus.svg'
import * as actions from '../../../../store/actions/interactions'
import SmallProfitList from './Small/SmallProfitTable'

const toggleModal = (props) => {
    props.toggleModal({type: 'add_transaction', open: true, payload: {} })
}

const AssetList = props => {

    return (

        <div>
        <Hidden smDown>
        <div
        style={{
            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
            borderRadius: 14, 
            position: 'relative',
            width: '100%',
            paddingBottom: 24,
        }}>

            
            
            <div className='title-asset-sticky' style={{width: '100%'}}>
                <div  style={{paddingLeft: 40, paddingTop: 25, display: 'flex', alignItems: 'center'}}>
                    <h3 style={{marginBottom: 6}}>Profit details</h3>
                    {props.roles[props.portfolioId] === 'admin' || props.roles[props.portfolioId] === 'manager' ? 
                    <span onClick={() => toggleModal(props)} className="basier-p4-caps" style={{userSelect: 'none', color: 'black', marginLeft: 24, cursor: 'pointer'}}>
                        <span><img src={Plus} alt="" style={{marginRight: 12}}/></span>
                        <span>Add a Transaction</span>
                    </span> 
                    :
                    null }
                </div>
                
                <div style={{display: 'flex', alignItems: 'flex-end', backgroundColor: 'white', 'boxShadow': 'none', height: 30, paddingBottom: 12, marginTop: 30, marginBottom: 12, borderBottom: '1px solid #E1E5E9'}}>
               
                <Grid
                    container
                    direction="row"
                    alignItems="baseline"
                    style={{paddingLeft: 70, paddingRight: 70, color: '#6A7E93'}}
                    className="basier-p4-caps"
                > 
        
                <Grid item style={{width: '25%' }}>Asset</Grid> 
                <Grid item style={{width: '15%', textAlign: 'right'}}>Total profit</Grid> 
                <Grid item style={{width: '15%', textAlign: 'right'}}>Realized profit</Grid> 
                <Grid item style={{width: '15%', textAlign: 'right'}}>Unrealized profit</Grid> 
                <Grid item style={{width: '15%', textAlign: 'right' }}>Current price</Grid> 
                <Grid item style={{width: '15%', textAlign: 'right', paddingRight: 12}}>Price bought</Grid> 


              </Grid>
                </div>

            </div>

            <Grid item style={{width: '100%'}}>
                <div style={{marginLeft: 48, marginRight: 48, marginTop: 30}}>
                    
                    {props.portfoliosData[props.portfolioId] !== undefined ?
                    <ProfitTable items={props.portfoliosData[props.portfolioId]} />
                    : 
                    null 
                    }
                
                </div>
            </Grid>
            
            </div>
            </Hidden>

            <Hidden mdUp>

            <div
            style={{
                boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                borderRadius: 14, 
                position: 'relative',
                width: '100%',
                paddingBottom: 24,
            }}>

              <div className='title-asset-sticky-mobile' style={{width: '100%'}}>
                <div  style={{paddingLeft: 12, paddingTop: 25}}>
                    <h3>Profit details</h3>
                </div>
                
                <div style={{display: 'flex', alignItems: 'flex-end', backgroundColor: 'white', 'boxShadow': 'none', height: 30, paddingBottom: 12, marginTop: 30, borderBottom: '1px solid #E1E5E9'}}>
               
                <Grid
                    container
                    direction="row"
                    alignItems="baseline"
                    style={{color: '#6A7E93'}}
                    className="basier-p4-caps"
                > 
        
                <Grid item style={{width: '30%', paddingLeft: 12}}>Asset</Grid> 
                <Grid item style={{width: '65%', textAlign: 'right', paddingRight: 12}}>Profit</Grid> 
                <Grid item style={{width: '5%', textAlign: 'centre', paddingRight: 12}}></Grid> 


              </Grid>
                </div>

            </div>

            <Grid item style={{width: '100%'}}>
                <div style={{marginLeft: 0, marginRight: 0, marginTop: 0}}>

                {props.portfoliosData[props.portfolioId] !== undefined ?

                    <SmallProfitList items={props.portfoliosData[props.portfolioId]} />
                    : 
                    null 

                }

                </div>
            </Grid>

            </div>

            </Hidden>

             { props.renderModal && props.portfoliosData !== undefined ? <AssetModal portfolioId={props.portfolioId} coin_in_scope={props.scopeAvailable} /> : null }
            
            </div>

            
        
        

    )

}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        portfoliosData: state.data.portfoliosData,
        renderModal: state.interactions.assetModal.open,
        scopeAvailable: state.interactions.assetModal.coin_in_scope,
        roles: state.auth.roles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetList);

