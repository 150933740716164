import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import Logo from '../../Media/Logo.svg';
import MenuIcon from '../../Media/MenuIcon.svg';
import CloseIcon from '../../Media/CloseIcon.svg';
import LogoutIcon from '../../Media/LogoutIcon.svg';
import OverviewIcon from '../../Media/Icon-W-Blue.svg';
import PortfolioIcon from '../../Media/PortfolioOverview.svg';
import CoinIcon from '../../Media/CoinOverview.svg';
import Grid from '@material-ui/core/Grid';
import CoinSelect from '../MobileOnly/MobileCoinSelect'
import Headroom from 'react-headroom'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/interactions";
import * as authActions from "../../store/actions/auth";
import Badge from '@material-ui/core/Badge';
import Hidden from '@material-ui/core/Hidden';
import MobileTabs from "../Portfolio/PortfolioComponents/SmallTabs"
import { withRouter } from "react-router-dom";

const drawerWidth = '100%';
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const getter = (dict, key) => {

  if (dict !== undefined) {
      if (dict[key] !== undefined) {
          return dict[key]
      }
  } 
  return 0

}

const styles = theme => ({
  overall: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    flexGrow: 1,
},
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  badge: {
    marginTop: 24, 
    marginRight: 12,

  },  
  colorbadge: {
    background: '#F9716B',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  root: {
    backgroundColor: "#F8F9FA",
      "&:hover": {
          //you want this to be the same as the backgroundColor above
          backgroundColor: "#F8F9FA"
      }, 
    },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#F8F9FA',
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#F8F9FA"
  }, 
  },
  show: {
    transform: 'translateY(0)',
    transition: 'transform .5s',
  },
  hide: {
    transform: 'translateY(-110%)',
    transition: 'transform .5s',
  },
});

const switchInts = (tab) => {

  switch(tab) {
      case 0: 
          return 'overview'
      case 1: 
          return 'profit'
      case 2: 
          return 'transactions'
      case 3: 
          return 'report'
      case 5: 
          return 'balances'
      case 6: 
          return 'settings'
      default: 
          return 'overview'
  }

}


class SwipeableTemporaryDrawer extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false,
      menuName: 'Menu', 
      menuIcon: MenuIcon,
      openModal: false, 
      modalType: null,
    };

  }
  

  handleTabChange = (event, val) => {
    const newLocation = this.props.location.pathname.split('/').slice(0,-1).join('/') + `/${switchInts(val)}`

    this.props.history.push(newLocation)
    this.props.setPfTab(val);
  }

  handleModalOpen = (e, type) => {

    this.setState(state => ({...state, modalType: type}))
    this.setState({ openModal: true });
  };

  handleRedirect = (val) => {
    this.props.history.push('/notifications')
  }

  handleModalClose = () => {

    this.setState({ openModal: false });
    this.setState({
      left: false,
      menuName: "Menu", 
      menuIcon: MenuIcon,
    });
  };

  componentDidMount() {

    if (this.state.openModal === false && this.state.left === false) {
      document.body.ontouchmove = (e) => { 
        return true;
      }
    }
  }

  componentDidUpdate() {

    if (this.state.openModal === false && this.state.left === false) {
      document.body.ontouchmove = (e) => { 
        return true;
      }
    }
  }

  toggleDrawer = (side, open) => () => {

    document.body.ontouchmove = (e) => { 
      e.preventDefault(); 
      return false;
    }

    if (open === true) {
      document.body.ontouchmove = (e) => { 
        e.preventDefault(); 
        return false; };
    } else {
      document.body.ontouchmove = (e) => { 
      return true; };
    }

    this.setState({
      [side]: open,
    });

    if (this.state.menuName === "Menu") {
      this.setState(state => ({menuName: "Close", menuIcon: CloseIcon}))

    } else {
      this.setState(state => ({menuName: "Menu", menuIcon: MenuIcon})) 
    }

  };

  handleLogout = () => {

    if (window.confirm("Are you sure you want to log out?")) {
      this.props.logout()
    } 

  }

  goToSettings = () => {

  }

  onPin = () => {
    this.props.setInteraction({label: 'pin', value: true})
  }

  onUnpin = () => {
    this.props.setInteraction({label: 'pin', value: false})
  }



  render() {
    const { classes } = this.props;

    const fullList = (
      <div className={classes.fullList}>
        <div style={{backgroundColor: 'white', boxShadow: 'none'}}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{height: 80}}
          > 

          
          <Grid xs={2} sm={2}><div style={{width:'100%', textAlign:'center', marginLeft: '20px', marginTop: 8}}><img src={Logo} alt="" /></div></Grid>
          <Grid xs={5} sm={5} style={{zIndex: 1500, textAlign: 'right',  marginRight: '12px', marginTop: 8}} className="over-all">
            <span style={{color: 'black', marginTop: 4}}className="basier-p2">{this.state.menuName}</span>
            <img style={{marginLeft: '4px', paddingTop: '12px', marginTop: 4}} src={this.state.menuIcon} alt="" onClick={this.toggleDrawer('left', !this.state.left)}/> 
          </Grid>

          </Grid>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          > 

            <Grid xs={2} sm={2} style={{marginBottom: 24, marginTop: 12}}>
              <div style={{width:'100%', textAlign:'center'}}
                   onClick={() => this.props.history.push('/settings/profile')}
                ><img 
                  style={{height: 62, width: 62, marginLeft: 24}}
                  src={`https://ui-avatars.com/api/?rounded=true&size=128&background=6A7E93&color=ffffff&name=${this.props.first_name}+${this.props.last_name}`} 
                  alt="" />
              </div>
            </Grid>

            <Grid xs={8} sm={8} style={{marginBottom: 36, marginTop: 24}}>
              <div className="basier-p2" style={{color: 'black', marginLeft: 48}}>
                {this.props.first_name} {this.props.last_name}
              </div>
                <div style={{marginLeft: 48}} className="basier-p4-caps">{this.props.portfolios.length} portfolio{this.props.portfolios.length > 1 ? 's' : null}</div>
              
            </Grid>

            <Grid xs={2} sm={2} style={{marginBottom: 36, marginTop: 24}}>
              <span onClick={this.handleLogout}><img style={{height: 30, width: 30, marginRight: 12}} src={LogoutIcon} alt=""/></span>
            </Grid>

          </Grid>
          
        </div>
        <Divider />

        <div style={{backgroundColor: '#F8F9FA', marginTop: 12, marginBottom: 12, height: 54, display: 'flex', alignItems: 'center', justifyContent: 'center'}} 
             key="currency" value="currency" onClick={(e, coin) => this.handleModalOpen(e, 'currency')}>
             <div style={{width: '85%', 
                           height: 50, 
                           backgroundColor: 'white', 
                           display: 'flex', 
                           alignItems: 'center', 
                           justifyContent: 'space-between', 
                           boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                           borderRadius: 7}}>
                <div style={{marginLeft: 24, color: 'black'}} className="basier-p3">Set display currency</div>
                <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>
                  <div style={{marginRight: 24}}>{this.props.currency}</div>
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                  </svg>
                </div>
              </div>
        </div>
        
        <List>
        
        <div style={{color: 'black', marginLeft: 12, marginBottom: 12, fontWeight: 'bold'}} className="basier-p4-caps">Navigation</div>
        <Link component={RouterLink} to="/">
            <div style={{backgroundColor: '#F8F9FA', height: 54, display: 'flex', alignItems: 'center', marginLeft: 36}} key="Overview">
              <span style={{display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            height: 30, 
                            width: 30,
                            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', 
                            borderRadius: 5, 
                            backgroundColor: 'white'}}>
                <img style={{height: 20, width: 20}} src={OverviewIcon} alt="" />
              </span>
              <span style={{marginLeft: 12, color: 'black'}} className="basier-p2">Overview</span>
            </div>
        </Link>
        <Divider />
        <div style={{backgroundColor: '#F8F9FA', height: 54, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} 
             key="currency" value="portfolio" onClick={(e, coin) => this.handleModalOpen(e, 'portfolio')}>
             <span style={{marginLeft: 36, display: 'flex', alignItems: 'center', }}>
              <span style={{display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'center', 
                              height: 30, 
                              width: 30,
                              boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', 
                              borderRadius: 5, 
                              backgroundColor: 'white'}}>

                  <img style={{height: 20, width: 20}} src={PortfolioIcon} alt="" />

                </span>
                <span style={{marginLeft: 12, color: 'black'}} className="basier-p2">Portfolios</span>

              </span>
              <span style={{marginRight: 24}}>
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                </svg>
              </span>
        </div>
        <Divider />
        <div style={{backgroundColor: '#F8F9FA', height: 54, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} 
             key="currency" value="portfolio" onClick={(e, coin) => this.handleModalOpen(e, 'more')}>
            <div style={{backgroundColor: '#F8F9FA', height: 54, display: 'flex', alignItems: 'center', marginLeft: 36}} key="Overview">
              <div style={{display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            height: 30, 
                            width: 30,
                            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', 
                            borderRadius: 5, 
                            backgroundColor: 'white'}}>
                <div style={{fontSize: 24, lineHeight: '15px', height: 30}}>...</div>
              </div>
              <span style={{marginLeft: 12, color: 'black'}} className="basier-p2">More</span>
            </div>
            <span style={{marginRight: 24}}>
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                </svg>
              </span>
          </div>
        <Divider />
      
        </List>

        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <div style={{marginTop: 24, width: '85%', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>

          
          <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',}}>
                <div style={{width: '50%', paddingRight: 6}}>
                  <Link component={RouterLink} to="/notifications">
                  <div 
                        
                        className="basier-p4-caps"
                        style={{width: '100%', 
                                  height: 50, 
                                  paddingLeft: 12,
                                  paddingRight: 12,
                                  backgroundColor: 'white', 
                                  display: 'flex', 
                                  alignItems: 'center',
                                  justifyContent: 'space-between', 
                                  boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                                  borderRadius: 7}}>
                    <div style={{marginRight: 6}}>
                        Notifs
                    </div>
                    <div>
                      {getter(this.props.notifs, 'unread') > 0 ? 
                        <div style={{marginLeft: 12, width: 22, height: 22, borderRadius: 100, backgroundColor: '#F9716B', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', padding: 4, fontWeight: 900, fontSize: 10}} className="basier">
                            {getter(this.props.notifs, 'unread')}
                        </div>
                        :
                        null
                        } 
                    </div>
                  </div>
                  </Link>
                </div>
             
              
              
                <div style={{width: '50%', paddingLeft: 6}}>
                  <Link component={RouterLink} to="/settings">
                  <div 
                        className="basier-p4-caps"
                        style={{width: '100%', 
                                  height: 50, 
                                  paddingLeft: 12,
                                  backgroundColor: 'white', 
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  justifyContent: 'space-between', 
                                  boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                                  borderRadius: 7}}>
                    Settings
                  </div>
                  </Link>
                </div>
             
            </div>

          </div>
        </div>

      </div>
    );

    return (
    <div style={{marginBottom: this.props.location.pathname.slice(1,10) === "portfolio" ? 50 : 0}}>
      <Headroom 
        wrapperStyle={{backgroundColor: 'transparent', height: 200, maxHeight: 200}} 
        className="headroom-height"
        onPin={this.onPin}
        onUnpin={this.onUnpin}
        >
      <div style={{backgroundColor: '#F8F9FA', boxShadow: 'none', height: 75}}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{height: 80}}
        > 
        
        <Link component={RouterLink} to="/">
          <Grid xs={2} sm={2}><div style={{width:'100%', textAlign:'center', marginLeft: '20px', marginTop: 8}}><img src={Logo} alt="" /></div></Grid>
        </Link>

          <Grid xs={5} sm={5} style={{zIndex: 1500, textAlign: 'right',  marginRight: '12px', marginTop: 8}} className="over-all">
            <span style={{color: 'black', marginTop: 4}}className="basier-p2">{this.state.menuName}</span>
            <Badge classes={{
                    badge: classes.badge, 
                    colorPrimary: classes.colorbadge, 
                }} badgeContent={getter(this.props.notifs, 'unseen')} color="primary" invisible={getter(this.props.notifs, 'unseen') === 0 ? true : false}>
              <img style={{marginLeft: '4px', paddingTop: '12px', marginTop: 4}} src={this.state.menuIcon} alt="" onClick={this.toggleDrawer('left', !this.state.left)}/> 
            </Badge>
          </Grid>


        </Grid>
      </div>

      </Headroom>
      <Hidden mdUp>
          {this.props.location.pathname.slice(1,10) === "portfolio" ? 
          <div className={`sticky-top-menu ${this.props.pin ? 'menu-sticky-menu' : ''}`} style={{display: 'flex', position: 'fixed', top: this.props.pin ? 75 : -61, paddingTop: 0, paddingBottom: 0, backgroundColor: 'white', zIndex: 1200 }}>
          {
          this.props.subscription !== undefined ? 
              <MobileTabs value={this.props.tab} handleChange={this.handleTabChange} plan={this.props.subscription.plan} />
          :null

          }
          </div>
          :
          null
          }
      </Hidden>
       
        <SwipeableDrawer
          anchor="left"
          open={this.state.left}
          onClose={this.toggleDrawer('left', false)}
          onOpen={this.toggleDrawer('left', true)}
          disableBackdropTransition={!iOS} 
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
          hysteresis={0.1}
          classes={{
            paper: classes.drawerPaper,
            root: classes.root
          }}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            {fullList}
          </div>
        </SwipeableDrawer>
          
        <CoinSelect openModal={this.state.openModal} closeModal={this.handleModalClose} type={this.state.modalType}/>


    </div>

    );
  }
}

SwipeableTemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
      first_name: state.auth.user_first_name, 
      last_name: state.auth.user_last_name,
      tab: state.interactions.portfolioTab,
      currency: state.auth.currency.id,
      portfolios: state.auth.list_portfolios,
      notifs: state.appwide.notifs, 
      subscription: state.auth.subscription,
      pin: state.interactions.pin

  };
};

const mapDispatchToProps = dispatch => {
  return {
    setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
            
      setPfTab: (tab) => 
          dispatch(actions.setPfTab(tab)), 
      logout: () => 
          dispatch(authActions.logout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(SwipeableTemporaryDrawer)));