
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import * as interActions from "../../store/actions/interactions";
import React from "react";
import TextField from '@material-ui/core/TextField';
import whiteLoader from '../../Media/whiteloader.gif'
import axios from 'axios';
import {backendUrl} from '../../variables';

import {
    Form
} from 'antd';

class NormalLoginForm extends React.Component {

    state = {
        loadingReset: false,
        resetValid: false, 
        resetError: false,
        comfirmError: false,
        pass1: null, 
        pass2: null,
        passErrors: null,
    }

    handlePass1Change = (e) => {
        e.persist();
        this.setState(state => ({...state, pass1: e.target.value}))
        if (this.state.pass2 !== null && this.state.pass2 !== undefined && this.state.pass2 !== '' && e.target.value !== '' ) {
            this.compareToFirstPassword(e.target.value, this.state.pass2)
        }
    }

    handlePass2Change = (e) => {
        e.persist();
        this.setState(state => ({...state, pass2: e.target.value}))
        if (this.state.pass1 !== null && this.state.pass1 !== undefined && this.state.pass1 !== '' && e.target.value !== '' ) {
            this.compareToFirstPassword(this.state.pass1, e.target.value)
        }
    }


    handleForgotSend = (pass1, pass2) => {

        if (pass1 === pass2) {
        this.setState(state => ({...state, loadingReset: true}))
        const urlCall = `${backendUrl}/rest-auth/password/reset/confirm/`

        axios.post(
            urlCall, 
            {
                uid: this.props.uid, 
                token: this.props.token,
                new_password1: pass1, 
                new_password2: pass2,
            }
        ).then( () => {
            this.props.cancelReset();
            this.setState(state => ({...state, loadingReset: false, resetValid: true, resetError: false}))
        }
        ).catch( (err) => {
            const errorData = err.response.data
            let errorMessage = ['These passwords are not valid. Please choose a more secure password to continue.']
            try {
                errorMessage = errorData['new_password2']
            } catch(e) {}
            console.log(errorMessage)
            this.setState(state => ({...state, loadingReset: false, resetValid: false, resetError: true, passErrors: errorMessage}))
        })

        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.handleForgotSend(values.pass1, values.pass2);
            }
        });
    }

    compareToFirstPassword = (pass1, pass2) => {
        console.log('1', pass1)
        console.log('2', pass2)
        if (pass1 !== pass2) {
           this.setState(state => ({...state, comfirmError: true}))
        } else {
            this.setState(state => ({...state, comfirmError: false}))
        }
      };

    render() {

        const { getFieldDecorator } = this.props.form;

        return (

            <form onSubmit={this.handleSubmit}>

                {getFieldDecorator('pass1', {
                    rules: [{ required: true, message: 'Please input your new password!' }],
                })(
                    <TextField
                    id="outlined-password1-input"
                    label="New Password"
                    style={{ marginLeft: 5,
                            marginRight: 5,
                            width: '100%',
                            borderRadius: 7, 
                            backgroundColor: 'white',
                            }}
                    type="password"
                    name="pass1"
                    ref={this.email}
                    autoComplete="new-password"
                    margin="normal"
                    variant="outlined"
                    onChange={this.handlePass1Change}
                    onFocus={this.focusInput}
                />
                )}

                {getFieldDecorator('pass2', {
                    rules: [{ required: true, message: 'Please confirm your new Password!' }],
                })(
                    <TextField
                            error={this.state.comfirmError}
                            id="outlined-password2-input"
                            label="Confirm Password"
                            style={{ marginLeft: 5,
                                marginRight: 5,
                                width: '100%',
                                borderRadius: 7, 
                                backgroundColor: 'white',
                            }}
                            type="password"
                            ref={this.pw}
                            autoComplete="new-password"
                            margin="normal"
                            variant="outlined"
                            onChange={this.handlePass2Change}
                            onFocus={this.focusInput}
                            value

                        />
                )}

                { this.state.comfirmError &&
                <div style={{color: 'red', marginLeft: 12}} className="basier-p4">Passwords do not match</div>}

                <div
                    style={{display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            width: '100%', 
                            marginTop: 20,
                            marginLeft: 10, 
                            marginRight: 15,}}>
                
                        <div className="basier-p4-caps" style={{color: '#6A7E93', cursor: 'pointer'}} onClick={this.props.toggleForgot}></div>
                        {!this.loadingReset ? 
                        <input type="submit" value="Reset" className="basier-p2"
                            style={{backgroundColor: '#0078DB', 
                                    borderRadius: 20, 
                                    color: 'white', 
                                    border: 'none',
                                    boxShadow: 'none',
                                    height: 40, 
                                    width: 87,
                                    display: 'flex', 
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: 10,
                                    cursor: 'pointer',
                                    outline: 'none'
                                    
                                    }}/>
                        :

                        <div className="basier-p2"
                        style={{backgroundColor: '#0078DB', 
                                borderRadius: 20, 
                                color: 'white', 
                                border: 'none',
                                boxShadow: 'none',
                                height: 40, 
                                width: 87,
                                display: 'flex', 
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: 10,
                                cursor: 'pointer',
                                outline: 'none'
                                
                                }}>
                        <img src={whiteLoader} alt="Loading" style={{height: 20, width: 20}}/>
                        </div>

                        }
                            
                </div>
                {this.state.passErrors && 
                    <div style={{marginTop: 12, marginBottom: 6, color: 'red'}}>
                        <div className="basier-p4-caps" style={{fontWeight: 900}}>The following errors occured:</div>
                        {this.state.passErrors.map((val, key) => {
                            return (
                                <div key={key} style={{color: 'red', marginLeft: 12, marginTop: 4}} className="basier-p4">{val}</div>
                            )
                        })}
                    </div>
                }

            </form>
        );
    }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm);


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password, remember) =>
            dispatch(actions.authLogin(email, password, remember)),
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedNormalLoginForm);