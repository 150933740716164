import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import matchSorter from 'match-sorter'
import pdfIcon from '../../Media/pdfIcon.svg'
import currencyDict from '../../helpers/currency.json'

class TransactionOverview extends React.PureComponent {

    state = {
        date: null,
    }
    componentDidMount() {
        //this.props.setCurrentView(5)
        //this.props.componentDataFetch('get', 5, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)

    } 
    
    toggleTxModal = (data) => {

        this.props.toggleModal({type: 'audit_modal', open: true, payload: {data: data} })

    }

    render() {

      
        const columns=[
            
            { 
                id: 'created_at',
                accessor: 'created_at', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{paddingLeft: 12}}>Date</div>
                ),
                minWidth: 150,
                Cell: row => {
                    const language = navigator.language || 'en'
                    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                    const parseDate = new Date(row.value);
                    const showDate = parseDate.toLocaleDateString(language,options)

                    return (
                    <div>
                        <div className="basier-p4" style={{paddingLeft: 12}}>
                        {showDate}
                        </div>  
                    </div>
                )
                }
            },
            { 
                accessor: 'total', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{paddingLeft: 12}}>Amount</div>
                ),
                minWidth: 150,
                Cell: row => {
                    
                    
                    return (
                    <div>
                        <div className="basier-p4-caps" style={{paddingLeft: 12}}>
                            {`${currencyDict[row.original.currency.toUpperCase()]['symbol']}${(row.value/100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}
                        </div>  
                    </div>
                )
                }
            },
            { 
                id: 'period_start',
                accessor: 'created_at', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{paddingLeft: 12}}>Period</div>
                ),
                minWidth: 200,
                Cell: row => {
                    const language = navigator.language || 'en'
                    const options = {year: 'numeric', month: 'numeric'};
                    const parseStart = new Date(row.original.period_start);
                    const showStart = parseStart.toLocaleDateString(language,options)
                    const parseEnd = new Date(row.original.period_end);
                    const showEnd = parseEnd.toLocaleDateString(language,options)

                    return (
                    <div>
                        <div className="basier-p4" style={{paddingLeft: 12}}>
                        {`${showStart} to ${showEnd}`}
                        </div>  
                    </div>
                )
                }
            },
            { 
                accessor: 'pdf', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{textAlign: 'left', paddingLeft: 12}}>File</div>
                ),
                minWidth: 150,
                maxWidth: 150,
                Cell: row => {
                    return (

                        <div className="basier-p4-caps opacity-hover" style={{textAlign: 'left', paddingLeft: 12, display: 'flex', alignItems: 'center'}}>
                            <img src={pdfIcon} alt="" style={{width: 24, height: 24, marginRight: 6}} />
                            <a href={`${row.value}`} style={{color: 'black'}}>Downlaod</a>
                        </div>
                    
                    )
                }
            }
            ]

        
        return (

            <div style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>

                <ReactTable
                    
                    defaultPageSize={3}
                    resizable={false}
                    defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}
                    showPageSizeOptions={false}
                    data={this.props.data}
                    columns={columns}
                    getProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            border: '0px',
                          }
                        };
                      }}
                      getTrGroupProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            border: '0px',
                          }
                        };
                    }}
                    getTheadFilterProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            marginTop: 6,
                            border: 'none'
                          }
                        };
                      }}
                    getPaginationProps={(state, rowInfo, column) => {
                        return {
                          style: {
                           boxShadow: 'none',
                           border: 'none',
                          }
                        };
                      }}
                    getTheadProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            boxShadow: 'none', 
                          }
                        };
                      }}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            display: 'flex', 
                            alignItems: 'center',
                            marginBottom: 4,
                            marginTop: 4,
                            boxShadow: rowInfo === undefined ? 'none' : '0px 10px 10px rgba(225, 229, 233, 0.3)', 
                            borderRadius: rowInfo === undefined ? 0 : 35, 
                            height: 56,
                            border: '0px',
                            background: rowInfo === undefined ? 'white' : 
                            rowInfo.row.action === 'create' ? 
                                'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #4CDAC1'
                            : 
                            rowInfo.row.action === 'edit'
                            ? 
                                'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #ffc163'
                            :
                            rowInfo.row.action === 'delete'
                            ? 
                                'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #F9716B'
                            :
                            'white'
                          }
                        };
                      }}
                />
            
            </div>

        )
    }
  
};

const mapStateToProps = state => {
    return {
        inBitcoin: state.data.bitcoinPf
    };
};

const mapDispatchToProps = dispatch => {
    return {
       
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionOverview);