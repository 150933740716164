import React from 'react';
import LargeGraph from '../../../AppWide/LargeGraph';
import FlexibleLargeGraph from '../../../AppWide/FlexibleLargeGraph';
import SmallGraph from '../../../AppWide/FlexibleSmallGraph';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions/data";
import {createLoadingSelector} from '../../../../store/reducers/selectors';
import SummaryOverview from '../SummaryData';
import AssetList from './Large/OverviewAssetList'
import Grid from '@material-ui/core/Grid';
import { relative } from 'path';
import Hidden from '@material-ui/core/Hidden';
import DetailTabs from './DetailTabs';
import SmallSummary from './Small/SmallSummary'
import loadingSpinner from '../../../../Media/loadingSpinner.gif'
import StraightSummary from './Small/StraightSummaryOverview'
import chartIcon from '../../../../Media/chartIcon.svg';


class PortfolioOverview extends React.Component {

    state = {
        currentTab: 0, 
    }

    componentDidMount() {

        const request_data = {
            period: this.props.portfoliosBalance.period,
            start: this.props.portfoliosBalance.start,
            end: this.props.portfoliosBalance.end
        }

        this.props.setCurrentView(2)
        
        if (false) {

        //have a last updated and only call if it makes sense. Potentially put it in the update scope of the component data fetch. 
        this.props.componentDataFetch('get', 2, this.props.currency, this.props.user, this.props.portfolioId, null, request_data, this.props.inBitcoin)
        }

    }

    changeTab  = (event, value) => {
        this.setState({currentTab: value})
    }

    render() {

        

        return (
            
            <div style={{width: '100%'}}>

                <div style={{marginTop: 0}}>

                    {this.props.portfoliosBalance.data[this.props.portfolioId] !== undefined ? 

                        this.props.portfoliosBalance.data[this.props.portfolioId].values.length > 0 ? 
                        <div>

                            <Hidden smDown>
                                
                                <FlexibleLargeGraph 
                                            data={this.props.portfoliosBalance.data[this.props.portfolioId].values}
                                            start_date={this.props.portfoliosBalance.data[this.props.portfolioId].start_date}
                                            today_value={this.props.portfoliosBalance.data[this.props.portfolioId].today_value}
                                            currencyScope={this.props.portfoliosBalance.inBitcoin}
                                            user={this.props.user}
                                            currency={this.props.currency}
                                            setNewScope={this.props.setGraphScope}
                                            setPfScope={this.props.setPfScope}
                                            updateGraph={(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) => this.props.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)}
                                            updateTime={this.props.updateTime}
                                            timePeriod={this.props.timePeriod}
                                            loadedGraph={this.props.loadedGraph}
                                            portfolio={this.props.portfolioId}
                                            background='white'
                                            scope="portfolio" />
                            
                            </Hidden>
                        
                            <Hidden mdUp>

                                <SmallGraph 
                                           data={this.props.portfoliosBalance.data[this.props.portfolioId].values}
                                           user={this.props.user}
                                           start_date={this.props.portfoliosBalance.data[this.props.portfolioId].start_date}
                                           today_value={this.props.portfoliosBalance.data[this.props.portfolioId].today_value}
                                            currencyScope={this.props.portfoliosBalance.inBitcoin}
                                            currency={this.props.currency}
                                            setPfScope={this.props.setPfScope}
                                            setNewScope={this.props.setGraphScope}
                                            updateGraph={(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) => this.props.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)}
                                            updateTime={this.props.updateTime}
                                            timePeriod={this.props.timePeriod}
                                            loadedGraph={this.props.loadedGraph}
                                            portfolio={this.props.portfolioId}
                                            background='white'
                                            scope="portfolio" />

                            </Hidden>

                        </div>

                        :

                        <React.Fragment>
                            <Hidden smDown>
                                <div style={{height: 530, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 24}}>
                                    <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                        <div>
                                            <img src={chartIcon} alt="" style={{height: 80}} />
                                        </div>
                                        <div className="basier-p2" style={{marginTop: 24}}>
                                            <div>You do not have any transactions in your portfolios yet.</div>
                                            <div>Once you add transactions you can see your portfolio's historical evolution here.</div>
                                        </div>
                                    </div>
                                </div>
                            </Hidden>

                            <Hidden mdUp>
                                <div style={{height: 300, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 12}}>
                                    <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 24, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                        <div>
                                            <img src={chartIcon} alt="" style={{height: 80}} />
                                        </div>
                                        <div className="basier-p2" style={{marginTop: 24}}>
                                            <div>You do not have any transactions in your portfolios yet. Once you add transactions you can see your portfolio's historical evolution here.</div>
                                        </div>
                                    </div>
                                </div>
                            </Hidden>
                        </React.Fragment>


                        : 
                        
                        <div>

                            <Hidden smDown>
                                
                                <div style={{backgroundColor: 'white', height: 533, width: '100%'}}>

                                    <div style={{marginLeft: 48, height: 83, width: 450, display: 'flex', alignItems: 'center'}}>
                                        <div className="pulse-skeleton" style={{width: 83, height: 83, borderRadius: 100, }}></div>

                                        <div style={{marginLeft: 24, display: 'flex', flexDirection: 'column'}}>
                                            <div className="pulse-skeleton" style={{width: 325, height: 45, borderRadius: 10, marginRight: 0}}></div>
                                            <div className="pulse-skeleton" style={{width: 325, height: 26, marginTop: 12, borderRadius: 10, marginRight: 0}}></div>

                                        
                                        </div>
                                       

                                    </div>
                                    <div style={{marginLeft: 0, height: 450}}>
                                        <div style={{marginTop: 48}}></div>
                                        <div style={{width: '100%', height: 402}} className="pulse-skeleton"></div>
                                    </div>

                                </div>
                            
                            </Hidden>
                        
                            <Hidden mdUp>

                               <div style={{backgroundColor: 'white', height: 417, width: '100%'}}>

                                <div style={{marginLeft: 12, height: 83, width: '100%', display: 'flex', alignItems: 'center'}}>
                                        <div className="pulse-skeleton" style={{width: 75, height: 75, borderRadius: 100, }}></div>

                                        <div style={{marginLeft: 24, display: 'flex', flexDirection: 'column'}}>
                                            <div className="pulse-skeleton" style={{width: 200, height: 30, borderRadius: 10, marginRight: 0}}></div>
                                            <div className="pulse-skeleton" style={{width: 200, height: 18, marginTop: 12, borderRadius: 10, marginRight: 0}}></div>
                                        </div>
                                    </div>

                                    <div style={{marginLeft: 0, height: 334}}>
                                        <div style={{marginTop: 12}}></div>
                                        <div style={{width: '100%', height: 322}} className="pulse-skeleton"></div>
                                    </div>

                                </div>

                            </Hidden>

                        </div>
                        
                    }

                </div>

                    <Hidden smDown>

                        <div className="sticky-summary-header" style={{paddingTop: 78, marginLeft: 48, marginRight: 48}}>
                        <SummaryOverview data={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                        </div>
                    </Hidden>

                    <Hidden mdUp>
                        <div style={{paddingTop: 12, marginLeft: 0, marginRight: 0}}>
                        {window.innerWidth < 560 ? 
                        <SmallSummary loading={this.props.loadedPortData} data={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                        :
                        <StraightSummary data={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                        }
                        </div>
                    </Hidden>


                <div className="asset-wrapper-responsive" >

                        { this.props.portfoliosData === {} ? 
                            null 
                        :
                            <AssetList portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} value={this.state.currentTab} handleTabChange={this.changeTab} />
                        }

                </div>

                <div style={{marginTop: 78, marginLeft: 48, marginRight: 96}}>


                </div>

                
            </div>
        )

    }
        
    
}

const graphLoadingSelector = createLoadingSelector(['UPDATE_PORTFOLIO_GRAPH']);
const dataLoadingSelector = createLoadingSelector(['UPDATE_PORTFOLIO']);


const mapStateToProps = state => {
    return {
        portfoliosBalance: state.data.portfolioBalance,
        portfoliosData: state.data.portfoliosData,
        currency: state.auth.currency.id,
        user: state.auth.user_id, 
        timePeriod: state.data.portfolioBalance.period,
        loadedGraph: graphLoadingSelector(state),
        loadedPortData: dataLoadingSelector(state),
        inBitcoin: state.data.bitcoinPf,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        updateTime: (time, portfolio) => 
            dispatch(actions.setPortfolioTime(time, portfolio)), 
        setGraphScope: (currency, scope) => 
            dispatch(actions.setGraphScope(currency, scope)), 
        setPfScope: (currency) => 
            dispatch(actions.setPfScope(currency)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioOverview);
