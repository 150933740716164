import React from "react";
import { FixedSizeList as List } from "react-window";
import CloseIcon from '../../Media/CloseIcon.svg'
import Port1 from '../../Media/Portfolio-1.svg'
import Port2 from '../../Media/Portfolio-2.svg'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';


class PortflioFlatList extends React.PureComponent {


    Row = ({ data, index, style }) => {
        // Data passed to List as "itemData" is available as props.data
        const item = data[index]
    
        return (
            <div style={{...style, 
                        paddingLeft: 24, 
                        paddingRight: 24, 
                        }} value={item.id}
                        className="coin-flat-list-item basier-p2"
                        onClick={this.handlePortfolioChoose}>
            <span>
                <span style={{marginLeft: 12, height: 20, width: 20}}>
                    <img style={{marginRight: 12, height: 20, width: 20}} src={(index === 0 || !!(index && !(index%2))) ? Port1 : Port2 }/>
                </span>
                <span style={{color: 'black'}} className="basier-p2">{item.name}</span>
            </span>
            <span>

            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
            </svg>

            </span>

            </div>
        );
    };

    handlePortfolioChoose = (e) => {
        const data = e.currentTarget.getAttribute('value')
        this.props.history.push(`/portfolio/${data}`)
        this.props.closeAction()
    }

    componentDidMount() {
        document.body.ontouchmove = (e) => { 
          e.preventDefault(); 
          return false;
        }
    }

    componentDidUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
      }

    componentDidUpdate() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
    }


      render() {
        
        const heightVitrual = window.innerHeight - 75; 
        const widthVirtual = window.innerWidth;
        const itemHeight = 50

        return (
            <div className="filter-list">

            <div style={{backgroundColor: '#F8F9FA', 'boxShadow': 'none', height: 75}}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                > 
        
                <Grid  style={{zIndex: 1500}} className="over-all">
                  <img style={{marginLeft: '12px', paddingTop: '12px'}} src={CloseIcon} alt="" onClick={this.props.closeAction}/> 
                </Grid>
                <Grid  style={{zIndex: 1500, textAlign: 'centre', color: 'black', marginLeft: -24}} className="over-all basier-p2">
                    Select Portfolio
                </Grid>
                <Grid style={{zIndex: 1500}} className="over-all">
                  <div style={{marginLeft: 24}} className="basier-p2" onClick={this.clearInput}></div>
                </Grid>

              </Grid>

            </div>
            
                <List
                    height={heightVitrual}
                    itemCount={this.props.portfolio_list.length}
                    itemData={this.props.portfolio_list}
                    itemSize={itemHeight}
                    width={widthVirtual}
                    >
                    {this.Row}
                </List>
              
          </div>
        )
      }

}


const mapStateToProps = state => {
    return {
        portfolio_list: state.auth.list_portfolios
    };
  };
  
  
  export default connect(
    mapStateToProps,
    null
  )(withRouter(PortflioFlatList));
