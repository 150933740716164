import axios from "axios";
import * as actionTypes from "./actionTypes";
import { loadData  } from '../../helpers/helpers';
import { backendUrl } from '../../variables'



export const setViewCurrency = (data) => {
    return {
        type: actionTypes.SET_VIEW_FIAT, 
        data
    };
};

export const setPorts = (data) => {
    return {
        type: actionTypes.UPDATE_PORTS, 
        data
    };
};

export const loadApp = (data) => {
    return {
        type: actionTypes.LOAD_APP, 
        data
    };
};

export const setPrefs = (data) => {
    return {
        type: actionTypes.UPDATE_PREFS, 
        data
    };
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};
export const setBtcSymbol = (data) => {
    return {
        type: actionTypes.BTC_SYMBOL,
        data
    };
};

export const authSuccess = data => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        data
    };
};

export const updateRoles = (data) => {
    return {
        type: actionTypes.UPDATE_ROLES, 
        data
    };
};

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const resetUserPreferences = data => {
    return {
        type: actionTypes.USER_PREF,
        data
    };
}

export const fetchUserPreferences = user => {

    return dispatch => {

    let headers = { "Content-Type": "application/json" };
    const token = user.rahat_t
    const user_id = user.user_id
    headers["Authorization"] = `Token ${token}`;

    const data_fetch = loadData(`${backendUrl}/v1/user-init/?u=${user_id}`, headers)
    data_fetch
        .then(json => {
            dispatch(resetUserPreferences(json['data']));
            }

        )
    }
}




export const logout = () => {

    let headers = { "Content-Type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    
    axios
        .post(`${backendUrl}/rest-auth/logout/`, {
        }, { headers: headers } )
        .catch(err => {})

    localStorage.removeItem("user");

    
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = expirationTime => {
    return dispatch => {

        const now = new Date()
        const dif = expirationTime - now 

        if (dif <= 3600 * 5000) {

            setTimeout(() => {
                console.log('do something about logout')
                //dispatch(logout());
            }, dif);

        } else {
            console.log('too long to wait')
        }
        
    };
};



export const updateUser = data => {
    return {
        type: actionTypes.UPDATE_USER,
        data
    };
}

export const checkStart = data => {
    return {
        type: actionTypes.AUTH_CHECK,
        data
    };
}


export const checkUser = (bypass, givenToken, userGiven) => {

    return dispatch => {

        dispatch(checkStart());
        let user_id = null;
        let token = null;
        let userData = {}
        let headers = { "Content-Type": "application/json" };
        if (localStorage.hasOwnProperty("user")) {
            userData = JSON.parse(localStorage.getItem("user"));
            user_id =  userData.user_id
            token = userData.rahat_t

            
        } else {
            if (bypass === true) {
                
                user_id =  userGiven
                token = givenToken
                console.log('bypass')
                console.log('user', user_id)
                console.log('token', token)
            } else {
                console.log('not bypass')
            }
        }
        console.log('token is', token)
        headers["Authorization"] = `Token ${token}`;

        axios
            .get(`${backendUrl}/api/users/check/?u=${user_id}`, { headers: headers } )
            .then(json => {

                const updateData = json['data']['data']

                console.log(updateData)

                let portfolio_list = []
                let portfolio_id = null
                let preferred_currency = null 
                let subscription_id = null
                let plan_end = null
                let plan = null
                let subscription_status = null

                try { portfolio_id = updateData.default_portfolio } catch(e) { portfolio_id = null }
                try { portfolio_list = updateData.portfolios } catch(e) { portfolio_list = [] }
                try { preferred_currency = updateData.preferences.currency.id } catch(e) { preferred_currency = null }
                try { subscription_id = updateData.subscription_id } catch(e) { subscription_id = null }
                try { subscription_status = updateData.subscription_status } catch(e) { subscription_status = null }
                try { plan_end = updateData.plan_end } catch(e) { plan_end = null }
                try { plan = updateData.plan } catch(e) { plan = null }


                const stateData = {
                    ...userData,
                    rahat_t: token,
                    user_id: updateData.user.id, 
                    expirationDate: new Date(updateData.expiry),
                    email: updateData.user.email, 
                    user_first_name: updateData.user.first_name,
                    user_last_name: updateData.user.last_name,
                    default_portfolio: portfolio_id, 
                    user_preferences: updateData.preferences, 
                    list_portfolios: portfolio_list,
                    roles: updateData.roles,
                    subscription: {
                        id: subscription_id, 
                        plan: plan, 
                        end: new Date(plan_end),
                        subscription_status: subscription_status,
                    },
                    preferred_currency: preferred_currency, 

                }

                dispatch(authSuccess(stateData))
                dispatch(setViewCurrency(stateData));
                if (localStorage.hasOwnProperty("user")) {
                    
                    let newUser = {
                        ...userData, 
                        ...stateData
                    }

                    localStorage.setItem("user", JSON.stringify(newUser));
                } else {
                    let newUser = {
                        ...stateData
                    }

                    localStorage.setItem("user", JSON.stringify(newUser));
                }

                
            })
            .catch(err => dispatch(logout()))

            
        return {
            type: actionTypes.AUTH_LOGOUT
        };
    }
};


export const authLogin = (email, password, remember) => {
    let headers = { "content-type": "application/json" };
    return dispatch => {
        dispatch(authStart());
        axios
            .post(`${backendUrl}/auth/login/`, {
                email: email,
                password: password
            }, { headers: headers } )
            .then(res => {

                let portfolio_list = []
                let portfolio_id = null
                let preferred_currency = null 
                let subscription_id = null
                let plan_end = null
                let plan = null
                let subscription_status = null 

                try { portfolio_id = res.data.default_portfolio } catch(e) { portfolio_id = null }
                try { portfolio_list = res.data.portfolios } catch(e) { portfolio_list = [] }
                try { preferred_currency = res.data.preferences.currency.id } catch(e) { preferred_currency = null }
                try { subscription_id = res.data.subscription_id } catch(e) { subscription_id = null }
                try { subscription_status = res.data.subscription_status } catch(e) { subscription_status = null }
                console.log(res.data)
                try { plan_end = res.data.plan_end } catch(e) { plan_end = null }
                try { plan = res.data.plan } catch(e) { plan = null }


                const stateData = {
                    rahat_t: res.data.token,
                    user_id: res.data.user.id, 
                    email: res.data.user.email, 
                    user_first_name: res.data.user.first_name,
                    user_last_name: res.data.user.last_name,
                    user_preferences: res.data.preferences, 
                    default_portfolio: portfolio_id, 
                    list_portfolios: portfolio_list,
                    roles: res.data.roles,
                    subscription: {
                        id: subscription_id, 
                        plan: plan, 
                        end: new Date(plan_end),
                        subscription_status: subscription_status,
                    },
                    preferred_currency: preferred_currency, 
                    expirationDate: new Date(res.data.expiry),

                }
                
                localStorage.setItem("user", JSON.stringify(stateData));
                dispatch(authSuccess(stateData));
                dispatch(setViewCurrency(stateData));
                const expirationDate = new Date(res.data.expiry)
                dispatch(checkAuthTimeout(expirationDate));

                
            })
            .catch(err => {
                dispatch(authFail(err));

            });
    };
};


export const authCheckState = (bypass, token, userProvided) => {
    
    return (dispatch) => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user === undefined || user === null) {
            
            if (bypass === true) {
                
                dispatch(checkUser(true, token, userProvided));   
            } else {
                dispatch(logout());
            }   
        } else {
            const expirationDate = new Date(user.expirationDate);
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                
                //dispatch(authSuccess(user));
                //dispatch(setViewCurrency(user));
                //dispatch(fetchUserPreferences(user));
                dispatch(checkUser(false, null, null));
                
            }
        }
        
    };
};

