import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions/data";
import * as interActions from "../../../../store/actions/interactions";
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import matchSorter from 'match-sorter'
import './TransactionStyle.css'
import {DateFormatInput} from 'material-ui-next-pickers'
import AddPlus from '../../../../Media/AddPlus.svg'
import TransactionOverview from './TransactionOverviewLarge'
import SmallTransactionWrapper from './Small/SmallTransactionWrapper'
import TransactionDetail from './TransactionDetail'
import Hidden from '@material-ui/core/Hidden';
import {withRouter} from 'react-router-dom'; /* MAY NEED TO REMOVE */
import queryString from 'query-string';

class TransactionWrapper extends React.PureComponent {

    state = {
        transactionDetail: null,
    }


    selectTransaction = (val) => {

        if (val.matched !== true) {
            this.props.toggleModal({type: 'transaction_detail', open: true, payload: {transactionId: val.id, portfolioId: this.props.portfolioId, getOverallData: this.getOverallData }})
        } else {
            this.props.toggleModal({type: 'matched_detail', open: true, payload: {transactionId: val.transfer_match, portfolioId: this.props.portfolioId, getOverallData: this.getOverallData }})
        }
        //this.setState(state => ({...state, transactionDetail: val}))

    }


    getTr = (page) => {

        this.props.getTransaction(this.props.portfolioId, page, '1')

    }
    


    componentDidMount() {

        this.props.getTransaction(this.props.portfolioId, 0, '0')
        
       //this.props.setCurrentView(5)
       this.props.componentDataFetch('get', 5, this.props.currency_id, null, this.props.portfolioId, null, null, this.props.inBitcoin)
       let parsed = {} 
       try {
            parsed = queryString.parse(this.props.location.search)
        } catch(e){}

        let filtered = []
        Object.keys(parsed).map(val => {

            let temp = {id: val, value: parsed[val]}
            filtered.push(temp)

        })
        this.props.setTrFilter(filtered)
    
    } 

    componentWillUnmount() {
        this.props.setTrFilter({})
    }
    
    getOverallData = () => {
        console.log('geting ??? ')
        this.props.componentDataFetch('get', 5, this.props.currency_id, null, this.props.portfolioId, null, null, this.props.inBitcoin)
    }

    toggleTxModal = () => {

        //this.props.toggleTransactionAddModal({open: true});

    }

    render() {

        return (

           <div>

              

                <React.Fragment>
                    <Hidden smDown>

                        <TransactionOverview getOverallData={this.getOverallData} portfolioId={this.props.portfolioId} selectTransaction={this.selectTransaction} getTransaction={(page, filters) => this.props.getTransaction(this.props.portfolioId, page, '1', filters)} />
                    

                    </Hidden>

                    <Hidden mdUp>

                    {this.props.transactions !== null && this.props.transactions !== undefined && this.props.isLoading === false ? 
                        <SmallTransactionWrapper transactions={this.props.transactions['transactions']} portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} selectTransaction={this.selectTransaction} />

                    
                        :

                        null

                    }
                    </Hidden>
                </React.Fragment>
                

           </div>

        )
    }
  
};

const mapStateToProps = state => {
    return {
        transactions: state.data.transactions, 
        inBitcoin: state.data.bitcoinPf, 
        currency_id: state.auth.currency.id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTrFilter: (data) => 
            dispatch(interActions.setTrFilter(data)),
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        toggleTransactionAddModal: (data) => 
            dispatch(interActions.toggleTransactionAddModal(data)),
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        getTransaction: (pid, page, skp, filters) => dispatch(actions.getTransaction(pid, page, skp, filters))
        
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TransactionWrapper));