import React from 'react';
import { connect } from 'react-redux';
import loadingSpinner from '../../Media/loadingSpinner.gif'
import bell from '../../Media/bell.svg'
import bin from '../../Media/bin.svg'
import { ClickAwayListener } from '@material-ui/core';
import AlertType from './AlertType';

class LargeWatchList extends React.PureComponent {

    state = {
        alertModal: {}
    }

    changeType = () => {

    }

    closeAlert = () => {
        setTimeout(() => { this.setState({alertModal: {}}) }, 10)
        
    }
    
    showAlertModal = (id) => {
        this.setState(state => ({...state, alertModal: {[id]: !state.alertModal[id]}}))
    }

    deleteRecord = (sub) => {

        if (window.confirm("Are you sure you want to delete this coin from the watchlist?")) {
            this.props.deleteRecord(sub)
        } 

    }

    render () {

        return (

            <div style={{padding: 0}}>

                <div style={{display: 'flex', alignItems: 'space-around', flexWrap: 'wrap'}}>

                    {this.props.subs.map((val, key) => {

                        return (
                            <div 
                            className="watch-list-container"
                            style={{
                                height: 200, width: 200, 
                                padding: 24,
                                display: 'flex',
                                alignItems: 'flex-start', 
                                flexDirection: 'column', 
                                justifyContent: 'flex-start',
                                border: val.change > 0 ? '1px solid #4CDAC1' : '1px solid #F9716B'

                            }}
                        >   

                            <div style={{position: 'relative', width: '100%', height: '100%'}}>
                                <div style={{position: 'absolute', top: 130, right: -5, width: 20, height: 20, cursor: 'pointer'}} onClick={() => this.deleteRecord(val.id)}>
                                    <img style={{height: 30, with: 30}} src={bin} alt=""/>
                                </div>

                                {false ?
                                <ClickAwayListener onClickAway={this.closeAlert}>
                                    <div style={{position: 'relative'}}>
                                        <div class="arrow-up" style={{top: 160, right: -10}} />
                                        <div className="small-alert" style={{ position: 'absolute', zIndex: 100, top: 170, right: -60, width: 200, height: 100, padding: 12}}>
                                            <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase'}}>
                                                New price alert
                                            </div>
                                            <div style={{width: '100%', marginTop: 8, display: 'flex', alignItems: 'center'}}>
                                                <AlertType changeType={this.changeType} />
                                                <input autoFocus={true} type="text" className="light-input basier-p4" style={{width: 105, borderRadius: '0px 5px 5px 0px', padding: 8, height: 30}} />
                                            </div>

                                        </div>
                                    </div>
                                </ClickAwayListener> 

                                :

                                null 

                                }
                            </div>


                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={val.image_thumb} style={{marginRight: 12, height: 35, width: 35}} alt="" />
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                <h4 style={{margin: 0}}>{val.symbol}</h4>
                                <div className="basier-p4-caps truncate" style={{width: 110}}>{val.short_name}</div>
                                </div>
                            </div>
                            
                            <div style={{width: '100%', marginTop: 24, justifyContent: 'flex-start', textAlign: 'left', paddingLeft: 12}}>
                                <div className="basier-p4-caps">
                                Price
                                </div>
                                <h4>
                                    {val.price > 1 ? 
                                        `${val.price.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})} $`
                                    :
                                        `${val.price.toLocaleString('en', {minimumFractionDigits:6, maximumFractionDigits: 6})}
                                        $`
                                    }
                                </h4>
                                <div className="basier-p4-caps">
                                24h change
                                </div>
                                <div style={{
                                        fontWeight: 900,
                                        color: val.change > 0 ? '#4CDAC1' : '#F9716B'}}>
                                    {val.change !== null ? 
                                    val.change > 0 ? 

                                    `+${val.change.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}%`
                                    : 

                                    `${val.change.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}%`
                                    :
                                    null
                                    }
                                </div>
                            </div>
                        </div>
                        )
                    })}

                    {this.props.loading ? 
                        <div 
                            className="watch-list-container"
                            style={{
                                height: 200, width: 200, 
                                padding: 24,
                                display: 'flex',
                                alignItems: 'center', 
                                flexDirection: 'column', 
                                justifyContent: 'center',
                                border: '1px solid #cccccc'

                            }}
                        >   
                            
                            <img style={{height: 40, with: 40}} src={loadingSpinner} alt="Loading..."/>

                    </div>
                    :
                    null
                    }
            </div>
        
        </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LargeWatchList)
