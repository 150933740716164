import React from "react";
import { connect } from 'react-redux';
import * as actions from "../../../store/actions/data";
import * as interActions from "../../../store/actions/interactions";
import ExchangeCard from './WalletCard';
import StorageCard from './StorageCard';
import Plus from '../../../Media/Plus.svg'
import axios from 'axios';
import { backendUrl } from '../../../variables'

// Import FilePond styles

class WalletOverview extends React.Component {

    state = {
        // Set initial files, type 'local' means this is a file
        // that has already been uploaded to the server (see docs)
    }

    handleInit() {
    }

    handleSocket(data) {

        

    }


    render() {

        return (

            this.props.data !== undefined ? 

            <div style={{padding: 48}}>
                <div style={{paddingLeft: 24, paddingRight: 24, display: 'flex'}}>

                    <div style={{width: '50%', paddingRight: 12, }}>
                        
                        
                        <div style={{display: 'flex', flexDirection: 'column'}}>

                            <div className="basier-p4-caps">accounts</div>
                            
                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <h3 style={{margin: 0}}>Exchange</h3>
                                <div onClick={() => this.props.openModal('new_wallet', 'exchange', null)} className="basier-p4-caps opacity-hover" style={{userSelect: 'none', color: 'black', marginLeft: 18, cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                                    <div><img src={Plus} alt="" style={{marginRight: 12, height: 24, width: 24}}/></div>
                                    <div style={{paddingTop: 0}}>Add new exchange</div>
                                </div> 

                            </div>
                           

                        </div>

                        <div style={{marginTop: 24}}>
                        {   this.props.data[this.props.user_id] &&
                            Object.keys(this.props.data[this.props.user_id]['exchange']).map((val, key) => {

                                return (
                                   <ExchangeCard 
                                        key={key}
                                        openModal={(type, scope, add) => this.props.openModal(type, scope, add)} 
                                        user_id={this.props.user_id}
                                        data={this.props.data[this.props.user_id]['exchange'][val]} 
                                        editWallet={(data) => this.props.editWallet(data)}
                                        exchangeId={val}/>
                                )

                            })
                        }
                        </div>
                             
                        </div>


                    <div style={{width: '50%', paddingLeft: 12,}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            
                            <div className="basier-p4-caps">wallets</div>
                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <h3 style={{margin: 0}}>Storage</h3>
                                <div onClick={() => this.props.openModal('add_single_wallet', 'storage', null)} className="basier-p4-caps opacity-hover" style={{userSelect: 'none', color: 'black', marginLeft: 18, cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                                    <div><img src={Plus} alt="" style={{marginRight: 12, height: 24, width: 24}}/></div>
                                    <div style={{paddingTop: 0}}>Add storage wallet</div>
                                </div> 

                            </div>

                           

                        </div>

                        <div style={{marginTop: 24}}>
                        {   this.props.data[this.props.user_id] &&
                            Object.keys(this.props.data[this.props.user_id]['storage']).map((val, key) => {

                                return (
                                   <StorageCard 
                                        key={key} 
                                        user_id={this.props.user_id}
                                        openModal={(type, scope, add) => this.props.openModal(type, scope, add)} 
                                        data={this.props.data[this.props.user_id]['storage'][val]}
                                        coin={val}
                                        editWallet={(data) => this.props.editWallet(data)} />
                                )

                            })
                        }
                        </div>

                    </div>

                </div>
            </div>

            :

            null

        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(WalletOverview)