import React from "react";
import { FixedSizeList as List } from "react-window";
import CloseIcon from '../../Media/CloseIcon.svg'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import * as auth_actions from "../../store/actions/auth";
import { backendUrl } from '../../variables';


class FiatFlatList extends React.PureComponent {

    state = {
        initialItems: [], 
        items: []
    }

    Row = ({ data, index, style }) => {
        // Data passed to List as "itemData" is available as props.data
        const item = data[index]
        return (
            <div style={{...style, 
                        paddingLeft: 24, 
                        paddingRight: 24, 
                        }} value={item.value}
                        className="coin-flat-list-item basier-p2"
                        onClick={this.handleCurrencyChoose}>
            <span>{item.label}</span>
            <span>></span>
            </div>
        );
    };

    handleCurrencyChoose = (e) => {
        const data = e.currentTarget.getAttribute('value')
        const send_data = {"preferred_currency": data}
        this.props.updateCurrency(send_data)

        const graphDataRequest = {
          period: this.props.graphData.period,
          start: this.props.graphData.start,
          end: this.props.graphData.end
        }
    
        const portfolioGraphRequest = {
          period: this.props.portfolioGraphData.period,
          start: this.props.portfolioGraphData.start,
          end: this.props.portfolioGraphData.end
        }
        
       
        this.props.componentDataFetch('update', this.props.currentView, data, this.props.user, this.props.currentPortfolio, graphDataRequest, portfolioGraphRequest, this.props.inBitcoin)
        this.props.setGraphScope("fiat", "global");
        this.props.setGraphScope("fiat", "portfolio");

        this.props.closeAction()

    }

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }

      getListSize = () => {
          return this.state.items.length
      }
      
      getSuggestions = (event) => {
        const value = event.target.value

        const escapedValue = this.escapeRegexCharacters(value.trim());
        console.log('searching')
        if (escapedValue === '') {
           
          this.setState(state => ({...state, items: this.state.initialItems}))
          return [];
        }
      
        const regex = new RegExp('\\b' + escapedValue, 'i');
        
        const filteredList = this.state.initialItems.filter(coin => regex.test(this.getSuggestionValue(coin)));
        this.setState(state => ({...state, items: filteredList})) 

      }

      getSuggestionValue  = (suggestion) => {
        return `${suggestion.label}`;
      }

      selectCoin = (evt) => {

      }
    
      componentDidMount() {

        this.setState(state => ( {...state, initialItems: this.props.fiatList, items: this.props.fiatList } ))
        
  
      }

      clearInput = () => {
          document.getElementById('coin-search').value = '';
          this.setState(state => ( {...state, items: this.state.initialItems}))
      }

      render() {
        
        const heightVitrual = window.innerHeight - 75; 
        const widthVirtual = window.innerWidth;
        const itemHeight = heightVitrual/8

        return (
            <div className="filter-list">

            <div style={{backgroundColor: '#F8F9FA', 'boxShadow': 'none', height: 75}}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                > 
        
                <Grid xs={2} sm={2} style={{zIndex: 1500}} className="over-all">
                  <img style={{marginLeft: '12px', paddingTop: '12px'}} src={CloseIcon} alt="" onClick={this.props.closeAction}/> 
                </Grid>
                <Grid xs={7} sm={7} style={{zIndex: 1500}} className="over-all">
                    <input 
                        autoFocus
                        id="coin-search"
                        autocorrect="off"
                        autocomplete="off"
                        type="text" placeholder="Search" onChange={this.getSuggestions} className="mobile-coin-input"/>
                </Grid>
                <Grid xs={3} sm={3} style={{zIndex: 1500}} className="over-all">
                  <div style={{marginLeft: 24}} className="basier-p2" onClick={this.clearInput}>Clear</div>
                </Grid>

              </Grid>
            </div>
            
                <List
                    height={heightVitrual}
                    itemCount={this.state.items.length}
                    itemData={this.state.items}
                    itemSize={itemHeight}
                    width={widthVirtual}
                    >
                    {this.Row}
                </List>
              
          </div>
        )
      }

}


const mapStateToProps = state => {
    return {
  
        fiatList: state.data.fiatList,
        filteredFiat: state.data.fiatList,
        currency_used: state.auth.currency.id, 
        user: state.auth.user_id,
        portfolio: state.auth.default_portfolio_id,
        currentPortfolio: state.data.menu_selected,
        graphData: state.data.globalBalance,
        portfolioGraphData: state.data.portfolioBalance,
        currentView: state.data.currentView, 
        inBitcoin: state.data.bitcoinPf
  
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        filterFiatList: (data) => 
            dispatch(actions.filterFiatList(data)),
        updateCurrency: (data) => 
            dispatch(auth_actions.setViewCurrency(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        setGraphScope: (currency, scope) => 
            dispatch(actions.setGraphScope(currency, scope)), 
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(FiatFlatList);
