import React from 'react';
import { connect } from 'react-redux';
import CoinSearch from '../../../AppWide/CoinSearch';
import MultiPort from '../../../AppWide/MultiPortfolio';
import loadingSpinner from '../../../../Media/loadingSpinner.gif'
import okIcon from '../../../../Media/okIcon.svg'

class WalletAdd extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            existing: false,
        }

        this.inputAddress = React.createRef();
    }

    focusIn = () => {
        this.inputAddress.current.focus()
    }

    componentDidMount() {
        this.props.updateData('wallet_type', this.props.scope)
        this.props.updateData('user', this.props.user_id)
        if (this.props.scope === 'exchange') {
            this.props.updateData('exchange', this.props.contextData.account)
        }
    }

    checkAndUpdateCoins = (val) => {

        let existing = false
        if (this.props.scope === 'exchange') {

            const user = this.props.wallets[this.props.user_id]
            if (user) {
                let exchange = undefined
                try { exchange = this.props.wallets[this.props.user_id]['exchange'][this.props.contextData.exchange] } catch(e) {}
                if (exchange) {
                    let account = undefined
                    try { account = this.props.wallets[this.props.user_id]['exchange'][this.props.contextData.exchange][this.props.contextData.account] } catch(e) {}
                    if (account) {
                        let wallet = undefined
                        try { wallet = this.props.wallets[this.props.user_id]['exchange'][this.props.contextData.exchange][this.props.contextData.account]['wallets'][val.value] } catch(e) {}

                        if (wallet) {
                            existing = true
                            this.setState(state => ({...state, existing: true}))
                        } else {
                            existing = false
                            this.setState(state => ({...state, existing: false}))
                        }
                    }
                }
            }



        }

        if (!existing) {

            this.props.updateData('coin', val.value)
        } else {

        }

    }

    submit = () => {

        this.props.updateServer()
    }

    render () {

        return (

            this.props.loading ? 

            <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 0.6, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
                <img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/>
            </div>

            :

            this.props.success ? 

            <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
                <img style={{height: 64, with: 64}} src={okIcon} alt="OK!"/>
                <div style={{marginTop: 24}} className="basier-p2">
                  Wallet created!
                </div>
            </div>

            :

            <div style={{padding: 48}}>

                <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>

                    <div>
                        <div style={{paddingLeft: 12, color: 'black', marginBottom: 12, display: 'flex', alignItems: 'center'}}>
                            <div className="basier-p4-caps" style={{fontWeight: 900, }}>Coin</div>
                            <div style={{color: 'red', marginLeft: 6, fontStyle: 'italic'}} className="basier-p4">
                            {
                                !this.props.validation.exchange_account ? 
                                
                                'Required'

                                :

                                null
                            }
                            </div>
                        </div>
                        <div style={{marginLeft: 12,}}>

                            <CoinSearch 
                                data_filter={this.props.scope === 'exchange' ? this.props.all_coins : this.props.cryptos}
                                dataBind={null}
                                ref={this.props.passRef}
                                autoFocus={true}
                                width={250}
                                keepInput={true}
                                overrideClass={true} 
                                focusIn={this.focusIn}
                                //type="exchange"
                                allowOther={false}
                                clearWallets={false}
                                //removeWallets={this.props.removeWallets}
                                updaterMethod={(ref, value) => this.props.updateData(ref, value)}
                                handleSelect={(value) => this.checkAndUpdateCoins(value)}
                                masterClass={`${( !this.props.validation.coin || this.state.existing ) ? "transaction-coin-search error-input" : "transaction-coin-search"}`}/>

                        </div>

                    </div>

                    <div style={{marginLeft: 12,}}>
                        <div style={{paddingLeft: 12, color: 'black', marginBottom: 12, display: 'flex', alignItems: 'center'}}>
                            <div className="basier-p4-caps" style={{fontWeight: 900, }}>Blockchain Address</div>
                        </div>

                        <div>
                            <input ref={this.inputAddress} onChange={(e) => this.props.updateData('address', e.target.value)} className='light-input' autoComplete={false} value={this.props.data.address} placeholder="Optional"
                            style={{marginLeft: 12, paddingLeft: 24, width: 570}} />
                        </div>
                        
                    </div>

                </div>

                <div>

                    {this.state.existing ?
                        
                        <div style={{paddingLeft: 12, height: 36, color: 'red', fontStyle: 'italic', marginTop: 6}} className="basier-p4">
                            {`You already have a ${this.state.coinSelected} wallet for this account. You cannot add another one`}
                        </div>
                        : 

                        <div style={{height: 36}}>
                        </div> 
                    
                    }

                    </div>
                    
                    <div style={{display: 'flex', alignItems: 'baseline'}}> 
                        {
                            this.props.scope === 'storage' ?
                        
                        <div style={{width: 262, paddingLeft: 0, marginTop: 0}}>
                            <div style={{paddingLeft: 12, color: 'black', marginBottom: 12, display: 'flex', alignItems: 'center'}}>
                                <div className="basier-p4-caps" style={{fontWeight: 900, }}>Name</div>
                            </div>
                            <input ref={this.inputName} onChange={(e) => this.props.updateData('name', e.target.value)} className={`light-input ${(!this.props.validation.name || !this.props.validation.isName) ? 'error-input' : ''}`} autoComplete={false} value={this.props.data.name} placeholder="Input name"
                                style={{marginLeft: 12, paddingLeft: 24, width: 250}} />
                        </div>
                        

                        :

                        null 
                        }

                        <div style={{width: `${this.props.scope === 'storage' ? '570px' : '100%'}`, marginLeft: `${this.props.scope === 'storage' ? '24px' : '12px'}`, marginTop: 0}}>
                            <div style={{paddingLeft: 12, color: 'black', marginBottom: 12, display: 'flex', alignItems: 'center'}}>
                                <div className="basier-p4-caps" style={{fontWeight: 900, }}>Portfolio</div>
                            </div>
                            <MultiPort updateData={(vals) => this.props.updateData('portfolios', vals)} addAll={true} />
                        </div>

                    </div>

                    {!this.props.validation.name && 
                             <div style={{paddingLeft: 12, height: 36, color: 'red', fontStyle: 'italic', marginTop: 6}} className="basier-p4">
                                 You already have an account with that name. Please select a different name.
                            </div>
                    }

                <div style={{width: '100%', marginTop: 200, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>

                      <div className='basier' onClick={this.props.handleClose} 
                          style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          Cancel
                      </div>

                      <div className='button-next-blue' onClick={() => this.submit()} 
                          style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer'}}>
                          Submit
                      </div>
                </div> 
                
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        all_coins: state.appwide.all_coins,
        cryptos: state.appwide.crypto, 
        wallets: state.appwide.wallets,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(WalletAdd)
