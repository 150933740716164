import React from "react";
import { connect } from 'react-redux';
import * as actions from "../../../store/actions/data";
import * as appwideActions from "../../../store/actions/appwide";
import currencyDict from '../../../helpers/currency.json'
import Hidden from '@material-ui/core/Hidden';
import Plus from '../../../Media/Plus.svg'
import okGreen from '../../../Media/okGreen.svg'
import cancelRed from '../../../Media/cancelRed.svg'

// Import FilePond styles

class ExchangeCardLarge extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            walletEdit: {}, 
            newAddress: {},
            loading: {},
        }

    }

    

    handleInit() {
    }

    handleSocket(data) {

        

    }


    openManageModal = (data) => {
        
        this.props.openModal(
            'connected_detail',
            data.id, 
            {data: data, scope: 'exchange'}
        )
    }
    

    editAddress = (wallet_id, e) => {
        e.persist();
        this.setState(state => ({...state, newAddress: {...state.newAddress, [wallet_id]: e.target.value} }))
    }
    
    showAddressEdit = (wallet_id, address) => {
        this.setState(state => ({...state, walletEdit: {...state.walletEdit, [wallet_id]: true}, newAddress: {...state.newAddress, [wallet_id]: address} }))
    }

    commitEditAddress = (wallet_id, action, acc, wallet) => {
        if (action === 'cancel') {
            this.setState(state => ({...state, walletEdit: {...state.walletEdit, [wallet_id]: false} }))
        } else if (action === 'ok') {
            this.setState(state => ({...state, loading: {...state.loading, [wallet_id]: true} }))
            const data = {
                id: wallet_id, 
                address: this.state.newAddress[wallet_id]
            }

            const newAdd = this.props.editWallet(data)
            newAdd
                .then(
                    data => {
                        let walletData = this.props.data[acc]['wallets'][wallet]

                        walletData['address'] = this.state.newAddress[wallet_id]


                        const payload = {

                            data: walletData, 
                            account: acc, 
                            coin: wallet, 
                            exchange: this.props.exchangeId,
                            scope: 'exchange', 
                            user_id: this.props.user_id,

                        }
                        this.props.updateSingleWallet(payload)
                        this.setState(state => ({...state, loading: {...state.loading, [wallet_id]: false} }))
                        

                    }
                )
                .catch( err => console.log('err', err))
            this.setState(state => ({...state, walletEdit: {...state.walletEdit, [wallet_id]: false} })) 


        }
        
    }

    onKeyDown = (e, wallet_id,  acc, wallet) => {

        switch (e.keyCode) {
            case 27: {
                this.commitEditAddress(wallet_id, 'cancel')
                break;
            }
            case 13: {
                this.commitEditAddress(wallet_id, 'ok',  acc, wallet)
                break;
            }

            default: {
                break;
            }
        }

    }

    componentDidMount() {

        if (this.props.portfolioId !== undefined) {

        }

        //this.props.setCurrentView(99);
        //this.props.setMenuPortfolio(null);
    }

    viewDetail = (id, data, exchange, account, account_id) => {

        const exchangeName = exchange['exchange'] !== null ? exchange['exchange']['name'] : exchange['custom_exchange'] !== null ? exchange['custom_exchange'] : null
        const walletData = {
            ...data, 
            exchange: exchangeName,
            account: account, 
            account_id: account_id,

        }
        this.props.openModal('wallet_detail', id, {data: walletData, type: 'exchange'})
    }

    render() {
        //console.log(this.props.data)
        return (

            <div style={{padding: 24, boxShadow: '1px 2px 18px rgba(225, 229, 233, 0.3)', minHeight: 150, borderRadius: 20, marginBottom: 24}}>
                
                <div style={{marginBottom: 24}}>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        { this.props.data[Object.keys(this.props.data)[0]]['exchange'] !== null ?
                        <div style={{marginRight: 12}}><img src={this.props.data[Object.keys(this.props.data)[0]]['exchange']['image']} alt="" style={{height: 24, width: 24}} /></div>
                        :
                        null
                        }
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div className="basier-p2" style={{textTransform: 'uppercase', fontWeight: 900}}> {this.props.data[Object.keys(this.props.data)[0]]['exchange'] !== null ? 
                            
                                this.props.data[Object.keys(this.props.data)[0]]['exchange']['name']
                            :
                            
                            this.props.data[Object.keys(this.props.data)[0]]['custom_exchange'] !== null ? 

                                this.props.data[Object.keys(this.props.data)[0]]['custom_exchange']

                                :

                                null
                            
                            }
                            </div>
                        </div>
                    </div>
                    
                </div>

                {
                    Object.keys(this.props.data).map((acc, key) => {

                        return (
                            <div key={key} style={{paddingRight: 12, paddingLeft: 12, marginBottom: 24}}>

                                <div style={{borderBottom: '1px solid #CCCCCC', paddingBottom: 6, paddingTop: 6, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} >
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <div className="basier-p3" style={{color: 'black'}}>
                                        {this.props.data[acc].name}
                                        </div>

                                        <div style={{marginLeft: 12}}>
                                            |
                                        </div>
                                        { 
                                        this.props.data[acc].connected ? 

                                            <div style={{marginLeft: 12, color: '#4CDAC1', fontStyle: 'italic'}} className="basier-p3">
                                                <div style={{paddingTop: 2}}>Connected</div>
                                            </div>
                                        :
                                        
                                            <div onClick={() => this.props.openModal('add_single_wallet', 'exchange_wallet', {account: acc, exchange: this.props.exchangeId})} className="basier-p4-caps opacity-hover" style={{userSelect: 'none', color: 'black', marginLeft: 12, cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                                                <div><img src={Plus} alt="" style={{marginRight: 12, height: 16, width: 16}}/></div>
                                                <div style={{paddingTop: 2}}>Add a wallet</div>
                                            </div> 
                                        
                                        }
                                    </div>
                                    
                                    {this.props.data[acc].connected ? 
                                    <div onClick={() => this.openManageModal(this.props.data[acc])} style={{paddingRight: 12, color: '#0078DB', cursor: 'pointer'}} className="opacity-hover basier-p4-caps">
                                        Manage
                                    </div>
                                    :
                                    null}

                                </div>

                                {   this.props.data[acc].connected ? 
                                    
                                    <div style={{padding: 12, marginTop: 12, width: '100%', fontStyle: 'italic', textAlign: 'left', backgroundColor: 'rgba(162, 222, 253, 0.1)'}}>
                                        Wallets from connected accounts are managed automatically. Nothing for you to do!
                                    </div>

                                    : 

                                     Object.keys(this.props.data[acc].wallets).length > 0 ?

                                        Object.keys(this.props.data[acc].wallets).map((wallet, key2) => {

                                            const wallet_data = this.props.data[acc].wallets[wallet]
                                            return (
        
                                                <div key={key2}>
                                                    
                                                    <div className="light-hover" style={{display: 'flex', alignItems: 'center', height: 48, borderBottom: `${Object.keys(this.props.data[acc].wallets).length === key2+1 ? 'none' : '1px solid rgb(225, 229, 233)'}`}}>
                                                        <div className="basier-p4-caps" style={{width: '25%', display: 'flex', alignItems: 'center'}}>
                                                            { wallet_data.coin_image !== null ? 
                                                                <div><img src={wallet_data.coin_image} alt="img" style={{height: 20, width: 20, marginRight: 12}} /></div>
                                                            :
                                                                <span style={{height: 16, width: 16, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 16 }}>
                                                                    {currencyDict[wallet_data.coin_ticker] === undefined ? null : currencyDict[wallet_data.coin_ticker]['symbol'].length > 1 ? null : currencyDict[wallet_data.coin_ticker]['symbol'] }
                                                                </span>
                                                            }
                                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                                <div className="basier-p4-caps" style={{color: 'black'}}>
                                                                    {wallet_data.coin_ticker}
                                                                </div>
                                                            </div>
                                                        </div>

                                                            
                                                            <div className="basier-p4" style={{width: '65%', fontSize: 12,}}>
                                                                { 
                                                                    wallet_data['coin_type'] === 'fiat' ? 

                                                                    null 

                                                                    :


                                                                    this.state.walletEdit[wallet_data['id']] ?
                                                                    
                                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                                        <input autoFocus={true} autoComplete="false" className="light-input-back basier-p4" style={{width: '70%', height: 30, zIndex: 10}} value={this.state.newAddress[wallet_data['id']] || ""} onChange={(e) => this.editAddress(wallet_data['id'], e)} onKeyDown={(e) => this.onKeyDown(e, wallet_data['id'], acc, wallet)}/>
                                                                        <div style={{width: '30%', display: 'flex', alignItems: 'center'}}>
                                                                            <div style={{marginLeft: 6, marginRight: 6}} onClick={() => this.commitEditAddress(wallet_data['id'], 'ok',  acc, wallet)}>
                                                                                <img src={okGreen} style={{height: 24, width: 24}} alt="ok" />
                                                                            </div>
                                                                            <div style={{marginRight: 6}} onClick={() => this.commitEditAddress(wallet_data['id'], 'cancel')}>
                                                                                <img src={cancelRed} style={{height: 24, width: 24}} alt="no" />
                                                                            </div>
                                                                        </  div>
                                                                    </div>
                                                                        

                                                                    :

                                                                    wallet_data.address !== null && wallet_data.address !== undefined && wallet_data.address !== "" ? 

                                                                    <div style={{display: 'flex', alignItems: 'center'}}>

                                                                        {
                                                                            this.state.loading[wallet_data['id']] ? 

                                                                            <div style={{height: 20, width: '70%'}} className="pulse-skeleton"></div>

                                                                            : 


                                                                            wallet_data.address.length > 20 ? 

                                                                                <div>
                                                                                    <Hidden mdDown>
                                                                                        {wallet_data.address}
                                                                                    </Hidden>

                                                                                    <Hidden lgUp>
                                                                                        {`${wallet_data.address.slice(0,10)}.....${wallet_data.address.slice(-10)}`}
                                                                                    </Hidden>
                                                                                </div>

                                                                            :
                                                                            
                                                                            <div>
                                                                                {wallet_data.address}
                                                                            </div>
                                                                        }
                                                                        { 
                                                                            this.state.loading[wallet_data['id']] ? 

                                                                            null :

                                                                            <div className="basier-p4 opacity-hover" style={{color: '#0078DB', cursor: 'pointer', width: 'fit-content', marginLeft: 6}} onClick={() => this.showAddressEdit(wallet_data['id'], wallet_data.address)}>
                                                                                edit
                                                                            </div>


                                                                        }

                                                                        

                                                                    </div>



                                                                    :

                                                                    <div className="basier-p4-caps opacity-hover" style={{color: '#0078DB', cursor: 'pointer', width: 'fit-content'}} onClick={() => this.showAddressEdit(wallet_data['id'], wallet_data.address)}>+ add address</div>

                                                                }
                                                                
                                                            
                                                            </div>

                                                        <div className="basier-p4" style={{width: '10%', fontSize: 12,}}>
                                                            {
                                                                wallet_data['coin_type'] === 'fiat' ? 

                                                                    null 

                                                                    :
                                                                <div onClick={() => this.viewDetail(wallet_data['id'], wallet_data, this.props.data[Object.keys(this.props.data)[0]],this.props.data[acc].name, this.props.data[acc].id)} className="basier-p4-caps opacity-hover" style={{color: '#0078DB', cursor: 'pointer'}}>
                                                                    View
                                                                </div>
                                                           
                                                            }
                                                         </div>
                                                        
                                                    </div>


                                                </div>
                                            
                                            )
                                        })

                                        :

                                        <div style={{padding: 12, marginTop: 12, width: '100%', textAlign: 'center', backgroundColor: 'rgba(243, 245, 246, 0.5)'}}>
                                            No wallets yet
                                        </div>

                                }
                               
                            </div>
                        )
                    })
                
                
                
                }
                

            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        updateSingleWallet: (payload) => 
            dispatch(appwideActions.updateSingleWallet(payload))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ExchangeCardLarge)
