import * as actionTypes from "./actionTypes";

import { loadData } from '../../helpers/helpers';
import { backendUrl } from '../../variables'

export const setPfTab = (data) => {
    return {
        type: actionTypes.UPDATE_PF_TAB, 
        data
    };
}

export const showSnack = (data) => {
    return {
        type: actionTypes.SNACKBAR_SHOW, 
        data
    };
}

export const setTrFilter = (data) => {
    return {
        type: actionTypes.SET_TR_FILTER, 
        data
    };
}

export const setInteraction = (data) => {
    return {
        type: actionTypes.SET_INTERACTION, 
        data
    };
}

export const setTrTime = (data) => {
    return {
        type: actionTypes.SET_TR_TIME, 
        data
    };
}

export const hideSnack = () => {
    return {
        type: actionTypes.SNACKBAR_HIDE
    };
}

export const updateRequestTime = (data) => {
    return {
        type: actionTypes.SET_UPDATE_TIME, 
        data
    };
}


export const toggleAssetModal = (data) => {
    return {
        type: actionTypes.TOGGLE_ASSET_MODAL, 
        data
    };
}

export const toggleModal = (data) => {
    return {
        type: actionTypes.SET_MODAL, 
        data
    };
}

export const toggleTransactionAddModal = (data) => {
    return {
        type: actionTypes.TOGGLE_ADD_TRANSACTION_MODAL, 
        data
    };
}

export const addTransaction = (data) => {
    return {
        type: actionTypes.ADD_TRANSACTION_DATA, 
        data
    };
}

export const updateTransaction = (data) => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_DATA, 
        data
    };
}

export const removeTransaction = (data) => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_DATA, 
        data
    };
}

export const setMobileWallet = (data) => {
    return {
        type: actionTypes.CHANGE_MOBILE_WALLET, 
        data
    };
}


export const addTransfer = (data) => {
    return {
        type: actionTypes.ADD_TRANSFER_DATA, 
        data
    };
}

export const updateTransfer = (data) => {
    return {
        type: actionTypes.UPDATE_TRANSFER_DATA, 
        data
    };
}

export const removeTransfer = (data) => {
    return {
        type: actionTypes.REMOVE_TRANSFER_DATA, 
        data
    };
}

export const updateWallets = (data) => {
    return {
        type: actionTypes.UPDATE_WALLET_DATA, 
        data
    };
}

export const removeWallets = () => {
    return {
        type: actionTypes.REMOVE_WALLET_DATA, 
    };
}




export const updateTasks = (data) => {
    return {
        type: actionTypes.APPWIDE_TASKS, 
        data
    };
};


export const getTasks = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } else {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/user/tasks?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateTasks(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}