import React from 'react';
import worker from "../../../../workers/profitWorker";
import WebWorker from "../../../../workers/WebWorker";
import ProfitGraph from './ProfitGraph'
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions/data";
import ProfitSummary from './Small/SmallSummaryProfit';
import SummaryProfit from './SummaryProfit'
import StraightSummary from './Small/StraightSummary'
import ProfitList from './OverviewProfitList';
import Hidden from '@material-ui/core/Hidden';
import ROIGraph from '../../../AppWide/RoiGraph'
import ProfitGraphSmall from './Small/SmallProfitGraph';
import profitIcon from '../../../../Media/profitIcon.svg';

class PortfolioProfit extends React.Component {
    
    state = {
        realized_profit: [],
        unrealized_profit: [],
        coins: [],
        total_profit: [], 
        scope: 'bars',
    }
    
    switchScope = (val) => {
        this.setState(state => ({...state, scope: val}))
    }

    componentDidMount() {

        this.props.setCurrentView(4)

            this.worker = new WebWorker(worker);

            this.worker.addEventListener('message', event => {
                const data = event.data 
                const realized_profit = data[0]
                const unrealized_profit = data[1]
                const coins = data[2]
                const total_profit = data[3]

                this.setState(state => ({...state, 
                                        realized_profit: realized_profit, 
                                        unrealized_profit: unrealized_profit, 
                                        coins: coins, 
                                        total_profit: total_profit, 
                                        }))

            });

        if (this.props.portfoliosData[this.props.portfolioId] !== null && this.props.portfoliosData[this.props.portfolioId] !== undefined ) {
            this.worker.postMessage(this.props.portfoliosData[this.props.portfolioId].balances)
        }

    }

    componentDidUpdate(prevProps) {
        
        if (prevProps.portfoliosData[this.props.portfolioId] !== undefined && this.props.portfoliosData[this.props.portfolioId] !== null && this.props.portfoliosData[this.props.portfolioId] !== undefined ) {
            if (prevProps.portfoliosData[this.props.portfolioId].balances !== this.props.portfoliosData[this.props.portfolioId].balances) {
                this.worker.postMessage(this.props.portfoliosData[this.props.portfolioId].balances)
            }
        }
        

    }
    
    
    render() {

        return (
            this.props.portfoliosData[this.props.portfolioId] !== undefined ?
            
                this.props.portfoliosData[this.props.portfolioId].balances.length > 0 ? 
                    <div>
                    <Hidden smDown>
                    <div style={{marginTop: 48, marginRight: 24, marginLeft: 24, height: 600}}>
                        
                        <div style={{marginBottom: 12, paddingLeft: 12}}> 

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div className="blue-hover basier-p2" 
                                    onClick={() => this.switchScope('bars')}
                                    style={{marginRight: 12,
                                        color: this.state.scope === 'bars' ? "#0078DB" : "#6A7E93" ,
                                        fontWeight: this.state.scope === 'bars' ? 900 : 400,
                                        cursor: 'pointer'

                                    }}>
                                        Profit Bars
                                </div>

                                <div className="basier-p2" style={{marginRight: 6}}>|</div>

                                <div className="blue-hover basier-p2"
                                    onClick={() => this.switchScope('evol')}
                                    style={{marginRight: 12,
                                        color: this.state.scope === 'evol' ? "#0078DB" : "#6A7E93" ,
                                        fontWeight: this.state.scope === 'evol' ? 900 : 400, 
                                        cursor: 'pointer'
                                    }}>
                                        Profit % evolution
                                </div>

                            </div>

                        </div>

                        {this.state.scope === 'bars' ?
                        
                        <div>

                            <div style={{paddingLeft: 12, marginBottom: 12, fontStyle: 'italic', height: 20, display: 'flex', alignItems: 'center'}} className="basier-p4"></div>

                            <ProfitGraph realized={this.state.realized_profit} 
                                    unrealized={this.state.unrealized_profit} 
                                    coins={this.state.coins}
                                    total_profit={this.state.total_profit} />

                        </div>
                        
                        :

                        this.state.scope === 'evol' ?

                            this.props.roiData[this.props.portfolioId] !== undefined && this.props.roiData[this.props.portfolioId] !== null ? 
                                
                                <div>
                                    <div style={{paddingLeft: 12, marginBottom: 12, fontStyle: 'italic', height: 20, display: 'flex', alignItems: 'center'}} className="basier-p4">Note: Profit evolution % shown in the portfolio's native currency and may differ from ROI % shown in display currency</div>
                                    <ROIGraph overallData={this.props.portfoliosData} data={this.props.roiData} portfolioId={this.props.portfolioId} />
                                </div>
                                :

                                null

                        :

                        null}


                    </div>
                    </Hidden>

                    <Hidden mdUp>
                    {this.props.roiData[this.props.portfolioId] !== undefined && this.state.total_profit !== undefined ?
                        <ProfitGraphSmall data={this.props.roiData[this.props.portfolioId]['values']} portfolioId={this.props.portfolioId} start_date={this.props.roiData[this.props.portfolioId]['start_date']} totalData={this.props.portfoliosData} />
                        :
                        <div style={{minHeight: 200, width: '100%'}} />
                    }
                    </Hidden>

                    <Hidden mdUp>
                    <div style={{marginTop: 24}}>
                        {this.props.portfoliosData[this.props.portfolioId] !== undefined ? 
                            window.innerWidth < 400 ? 
                            <ProfitSummary data={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                            :
                            <StraightSummary data={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                        :
                        null
                        }
                    </div>
                    </Hidden>

                    <Hidden smDown>
                        <div style={{paddingLeft: 48, paddingRight: 48}}>
                        <SummaryProfit data={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                        </div>
                    </Hidden>

                    <div className="asset-wrapper-responsive" >


                        <ProfitList portfolioId={this.props.portfolioId} />


                    </div>

                    </div>
                :

                    <React.Fragment>
                        <Hidden smDown>
                            <div style={{height: 530, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 24}}>
                                <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                    <div>
                                        <img src={profitIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any transactions in your portfolios yet.</div>
                                        <div>Once you add transactions you can see your portfolio's profit details here.</div>
                                    </div>
                                </div>
                            </div>
                        </Hidden>

                        <Hidden mdUp>
                            <div style={{height: 300, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 12}}>
                                <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 24, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                    <div>
                                        <img src={profitIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any transactions in your portfolios yet. Once you add transactions you can see your portfolio's profit details here.</div>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                    </React.Fragment>

            :

            null
        )

    }
  
}

const mapStateToProps = state => {
    return {
        roiData: state.data.roiEvol
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioProfit);
