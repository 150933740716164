import React from 'react';
import CoinSearch from '../../../../../AppWide/CoinSearch';
import WalletList from './WalletList'
import AddNewWallet from '../QuestionBlocks/AddNewWallet';

class ExchangeQuestion extends React.PureComponent {


    state = {
        existing_wallet: null, 
    }

    setWallet = (existing) => {
        this.setState(state =>({...state, existing_wallet: existing}))
    }

    render() {

        console.log('wallet test')
        console.log(this.props.wallets)
        console.log(this.props.user_id)

        return (

            <div>

                <div style={{

                display: 'flex', 
                height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                alignItems: 'baseline',
                transition: 'height 0.3s'
                }}>
                
                <div className='basier-p3' style={{paddingLeft: `${this.props.firstFocus ? '0px' : '24px'}`, marginTop: 12, width: 500, marginBottom: 6, marginRight: 24, fontWeight:  `${this.props.firstFocus ? 900 : 400}`}}>{this.props.question}</div>

                {
                    
                    this.props.allowAll? 
                    
                    <WalletList 
                        noAdd={this.props.noAdd}
                        wallets={this.props.wallets[this.props.user_id]['storage']} 
                        base_currency={this.props.base_currency}
                        updateTransaction = {this.props.updateTransaction}
                        transactionId = {this.props.transactionId}
                        transaction={this.props.transaction}
                        setNewWallet = {this.setWallet}
                        
                    />

                    :
                    
                    this.props.user_id === this.props.transaction['data'][this.props.transactionId]['user_transaction'] ? 

                    <WalletList 
                        noAdd={this.props.noAdd}
                        wallets={this.props.wallets[this.props.user_id]['storage']} 
                        base_currency={this.props.base_currency}
                        updateTransaction = {this.props.updateTransaction}
                        transactionId = {this.props.transactionId}
                        transaction={this.props.transaction}
                        setNewWallet = {this.setWallet}
                    />
                    
                    :

                    <div>Wallet must be selected by owner</div>
                }

                </div>

                {
                    this.state.existing_wallet === false ? 

                            <AddNewWallet validation={this.props.validation} coin={this.props.base_currency['name']} transactionId={this.props.transactionId} simple={this.props.simple} />
        
                    : 

                        null 

                }
                

            </div>

        )
    }

}

export default ExchangeQuestion;