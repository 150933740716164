import React from "react";
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions/data";
import Hidden from '@material-ui/core/Hidden';
import LargeSettings from './LargeSettings.js'
import SmallSettings from './SmallSettings'

class Settings extends React.Component {
    
    handleInit() {
    }

    handleSocket(data) {


    }

    componentDidMount() {

        this.props.setCurrentView(291);
        console.log('mounting settings')
    }

    render() {

        return (

            <div>

                <Hidden smDown>
                    <LargeSettings selectedSetting={this.props.selectedSetting} portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                </Hidden>

                <Hidden mdUp>
                    <SmallSettings portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                </Hidden>

            </div>
            

        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        portfolio_id: state.auth.default_portfolio_id,
        user_id: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Settings)
