import React from 'react';
import { connect } from 'react-redux';
import TransactionTable from './TransactionTable';
import worker from "../../../../workers/filterWorker";
import sortWorker from "../../../../workers/sortWorker";
import extractWorker from "../../../../workers/filterExtractWorker";
import WebWorker from "../../../../workers/WebWorker";
import * as interActions from "../../../../store/actions/interactions";
import * as actions from "../../../../store/actions/data";
import TransactionFilter from './TransactionFilter';
import SelectActions from './SelectActions';
import { withRouter} from 'react-router-dom';
import queryString from 'query-string';
import { siwtchTransactionType } from '../../../../helpers/helpers';
import contentLoader from '../../../../Media/contentLoader.svg';

class TransactionOverview extends React.PureComponent {

    state = {
        page: 0,
        total: 0,
        selectAll: false, 
        checked: {},
        data: null,
        filteredData: null, 
        sort: 'desc',
        filters: {
            base_id: 'all', 
            quote_id: "all",
            date: 'all',
            user_id: "all", 
            transaction_description: 'all', 
        },
        filterValues: {}
    }

    resetPage = () => {
        this.setState(state => ({...state, page: 0}))
    }
    componentDidMount() {

        let parsed = {} 
        try {
                parsed = queryString.parse(this.props.location.search)
            } catch(e){}

            let filtered = []
            Object.keys(parsed).map(val => {

                let temp = {id: val, value: parsed[val]}
                filtered.push(temp)

            })
        
        let givenFilters = {...this.state.filters}
        filtered.map(v => {
            givenFilters[v['id']] = v['value'].split(',')
        })
        this.setState(state => ({...state, filters: givenFilters}))

        if (this.props.transactions !== null && this.props.transactions !== undefined) {
            
            this.setState(state => ({...state, filters: givenFilters, data: this.props.transactions['transactions'], filteredData: this.props.transactions['transactions'], page: 0}))
            
           // this.returnFiltered(this.props.transactions['transactions'], givenFilters, true)
            
            if (this.props.transactions['filters'] !== null) {

            
                let filterTypes = []
                this.props.transactions['filters']['types'].map(v => {
                    filterTypes.push([v, siwtchTransactionType(v)[0]])
                })
                
                this.setState(state => ({
                    ...state, 
                    filterValues: {
                        ...state.filterValues, 
                        base_id: this.props.transactions['filters']['base'],
                        quote_id: this.props.transactions['filters']['quote'],
                        user_id: this.props.transactions['filters']['users'],
                        transaction_description: filterTypes,
                    }, 
                })
                )
            }
            this.setState(state => ({...state,  total: this.props.transactions['total']}))
            //this.returnFiltersExtracted(this.props.transactions)
        }

        this.props.history.push(`/portfolio/${this.props.portfolioId}/transactions`)
    }

    componentDidUpdate(prevProps) {
        if (this.props.transactions !== prevProps.transactions && this.props.transactions !== null && this.props.transactions !== undefined) {

            let parsed = {} 
            try {
                parsed = queryString.parse(this.props.location.search)
            } catch(e){}

            let filtered = []
            Object.keys(parsed).map(val => {

                let temp = {id: val, value: parsed[val]}
                filtered.push(temp)

            })
        
            let givenFilters = {...this.state.filters}
            filtered.map(v => {
                givenFilters[v['id']] = v['value'].split(',')
            })
            this.setState(state => ({...state, filters: givenFilters, data: this.props.transactions['transactions'], filteredData: this.props.transactions['transactions'], total: this.props.transactions['total']}))
    
            //this.returnFiltered(this.props.transactions['transactions'], givenFilters, true)
            
            if (this.props.transactions['filters'] !== null) {
                let filterTypes = []
                this.props.transactions['filters']['types'].map(v => {
                    filterTypes.push([v, siwtchTransactionType(v)[0]])
                })
                
                this.setState(state => ({
                    ...state, 
                    filterValues: {
                        ...state.filterValues, 
                        base_id: this.props.transactions['filters']['base'],
                        quote_id: this.props.transactions['filters']['quote'],
                        user_id: this.props.transactions['filters']['users'],
                        transaction_description: filterTypes,
                    }

                })
                )
            }

            this.props.history.push(`/portfolio/${this.props.portfolioId}/transactions`)

        }
    }

    movePage = (page) => {
        this.setState(state => ({...state, page: page}))
        console.log('setting page to', page)
        this.props.getTransaction(page, this.state.filters)
    }
    returnSorted = (side, data) => {

        this.worker = new WebWorker(sortWorker);

        this.worker.addEventListener('message', event => {
            const data = event.data

            this.setState(state => ({...state,
                                    filteredData: data[0], 
                                    data: data[1],
                                    sort: side,
                                    }))

        });

        this.worker.postMessage([this.state.data, this.state.filteredData, side])

    }

    returnFiltersExtracted = (data) => {

        this.worker = new WebWorker(extractWorker);

        this.worker.addEventListener('message', event => {
            const data = event.data

            this.setState(state => ({...state,
                                    filterValues: data, 
                                    }))

        });

        this.worker.postMessage(data)

    }

    filterData = (newFilter, newFilterData) => {
        const newFilterCommit = {...this.state.filters, [newFilter]: newFilterData}
        this.setState(state => ({...state, filters: newFilterCommit}))
        //this.returnFiltered(null, newFilterCommit)
        this.props.getTransaction(0, newFilterCommit)
    }

    returnFiltered = (data, filters, cancelEmpty) => {

        this.worker = new WebWorker(worker);

        this.worker.addEventListener('message', event => {
            const data = event.data
            //console.log(event.data)
            if (!(cancelEmpty && data[0].length === 0)) {

                this.setState(state => ({...state, 
                    filteredData: data[0], 
                    data: data[1]
                    }))

            } else {

                this.setState(state => ({...state, 
                    filteredData: data[1], 
                    data: data[1]
                    }))

                this.props.showSnack({message: 'No transactions with current filter.', variant: "error"})
            }
            

        });
        
        const filt = filters !== undefined ? filters : this.state.filters
        console.log('filtering', filt)
        const sendData = data !== null && data !== undefined ? data : this.state.data 
        this.worker.postMessage([sendData, this.state.filteredData, filt])

    }

    selectAll = () => {

        let newChecked = {...this.state.checked}
        
        if (this.state.selectAll === false) {
            this.state.filteredData.filter(x => x.transfer_match === null).map(val => {
                if (this.state.checked[val.id] !== true) {
                    newChecked[val.id] = true
                }
            })
            
            this.setState(state => ({...state, selectAll: true, checked: newChecked }))

        } else {
            this.setState(state => ({...state, selectAll: false, checked: {} }))
        }

    }

    selectSingle = (id) => {
        let newChecked = {...this.state.checked}
        if (this.state.checked[id] === true) {
            
            newChecked[id] = false
            delete newChecked[id]
            
        } else {    
            
            newChecked[id] = true
            
        }

        this.setState(state => ({...state, checked: newChecked}))
        
        if (Object.keys(newChecked).length == this.state.filteredData.filter(x => x.transfer_match === null).length) {

            if (JSON.stringify(Object.keys(newChecked).sort()) == JSON.stringify(this.state.filteredData.filter(x => x.transfer_match === null).map(e => `${e.id}`).sort())) {
             this.setState(state => ({...state, selectAll: true }))
            } else {
             this.setState(state => ({...state, selectAll: false }))
         }
        } else {
            this.setState(state => ({...state, selectAll: false }))
        }

    }

    render () {
        
        console.log('filteredData is', this.state.filteredData)
        return (

            this.state.filteredData !== null && this.state.filteredData !== undefined ? 
            <div style={{width: '100%', marginTop: 24}}>

                <TransactionFilter resetPage={this.resetPage} filters={this.state.filters} filterValues={this.state.filterValues} sort={this.returnSorted} filterData={(l, d) => this.filterData(l, d)}/>

                <div style={{height: 50, paddingLeft: 12, display: 'flex', alignItems: 'center', marginBottom: 12}} className="basier-p4-caps">
                    <SelectActions selected={this.state.checked} allData={this.state.data} filteredData={this.state.filteredData} />
                </div>

                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    
                    {this.props.isLoading ? 
                    
                    <div style={{minHeight: 800}}>
                        <img src={contentLoader} alt=""  style={{height: 100, width: 100}} />
                    </div>
                    
                    :
                    
                    <TransactionTable getTransactions={(page) => this.movePage(page)} total={this.props.transactions['total']} page={this.state.page} getOverallData={this.props.getOverallData} data={this.state.filteredData} number_rows={10} allSelected={this.state.selectAll} selectAll={this.selectAll} selectSingle={this.selectSingle} selected={this.state.checked} selectTransaction={this.props.selectTransaction} />
                    }
                </div>
                
            </div>
            :
            null
            
        )
    }


}

const mapStateToProps = state => {
    return {
        transactions: state.data.transactions, 
        isLoading: state.loading.GET_TRANSACTION

    };
};

const mapDispatchToProps = dispatch => {
    return {
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TransactionOverview));
