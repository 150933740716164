import React from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/interactions";
import AssetDetail from './assetDetail'
import AssetExpansion from './AssetExpansion'
import Divider from '@material-ui/core/Divider';

class AssetTable extends React.PureComponent {

    handleModalOpen = (e) => {

        this.props.toggleAssetModal({open: true, coin: e.currentTarget.getAttribute('value')});
        
    }

    


    state = {
        active: [],
        exited: [], 
        small: [],
        showErrors: true,
    }
    
    render() {

        
        return (

        

        <div>
           {
               this.props.active.map((item, index) => { 

            const roi = item.total_value / (item.purchase_price * item.balance)
            const history = this.props.history === null ? undefined : this.props.history[item.coin]

            return (

                <AssetDetail status="active" currency_symbol={this.props.currency_symbol} handleModalOpen={this.handleModalOpen} key={index} index={index} item={item} history={history} roi={roi} />                
            
            )
    

            })
        }
        
        {this.props.small.length > 0 &&
        <div style={{marginTop: 24}}>
            <AssetExpansion title="Small balances">

                {
                    this.props.small.map((item, index) => { 

                    const roi = item.total_value / (item.purchase_price * item.balance)
                    const history = this.props.history === null ? undefined : this.props.history[item.coin]

                    return (

                        <AssetDetail status="small" contained={true} currency_symbol={this.props.currency_symbol} handleModalOpen={this.handleModalOpen} key={index} index={index} item={item} history={history} roi={roi} />                
                    
                    )


                    })
                }

            </AssetExpansion>
        </div>
        }
        
        
        {this.props.exited.filter(v => v.error == false).length > 0 && 
        <div style={{marginTop: 6}}>
        {this.props.small.length > 0 &&
        <Divider />}
            <AssetExpansion title="Exited & Negligeable coins">

                {
                    this.props.exited.filter(v => v.error == false).map((item, index) => { 

                    const roi = item.total_value / (item.purchase_price * item.balance)
                    const history = this.props.history === null ? undefined : this.props.history[item.coin]

                    return (

                        <AssetDetail status="exit" contained={true} currency_symbol={this.props.currency_symbol} handleModalOpen={this.handleModalOpen} key={index} index={index} item={item} history={history} roi={roi} />                
                    
                    )


                    })
                }

            </AssetExpansion>
        </div>
        }
        
        {this.props.exited.filter(v => v.error == true).length > 0 && 
        <div style={{marginTop: 6}}>

            {(this.props.exited.filter(v => v.error == false).length > 0 || this.props.small.length > 0) &&
            <Divider />
            }


            <AssetExpansion title="Errors & imprecisions" error={true} open={this.state.showErrors}>

                {
                    this.props.exited.filter(v => v.error == true).map((item, index) => { 

                    const roi = item.total_value / (item.purchase_price * item.balance)
                    const history = this.props.history === null ? undefined : this.props.history[item.coin]

                    return (

                        <AssetDetail status="exit" errors={true} contained={true} currency_symbol={this.props.currency_symbol} handleModalOpen={this.handleModalOpen} key={index} index={index} item={item} history={history} roi={roi} />                
                    
                    )


                    })
                }

            </AssetExpansion>
        </div>
        }


    </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        history: state.data.portfoliosHist
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleAssetModal: (currency, scope) => 
            dispatch(actions.toggleAssetModal(currency, scope)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetTable);