import React from 'react';
import { connect } from 'react-redux';
import GlobalAuditWrapper from './GlobalAuditWrapper'
import ProfileLarge from './AccountLarge';
import Profile from './Profile';
import Billing from './Billing';
import { withRouter } from "react-router-dom";

class LargeSettings extends React.PureComponent {

   constructor(props) {
      super(props); 
      if( this.props.viewOverRide === undefined) {
         this.props.history.push('settings/account')
      }

   }
      state = {
         selectedIndex: this.props.viewOverRide !== undefined ? this.props.viewOverRide : 'account',
      }

      changeMenu = (val) => {
         this.setState(state => ({...state, selectedIndex: val}))
         this.props.history.push(val)
      }

      render () {

        return (

            <div style={{top: '130px', width: '100%', backgroundColor: '#F8F9FA', padding: 48, paddingBottom: 12}}>
               
               <h3>Settings</h3>

               
               <div style={{minHeight: 500, width: '100%', backgroundColor: 'white', borderRadius: 20, marginTop: 24, display: 'flex'}}>
                  
                   <div className="basier-p3" style={{width: 200, minWidth: 165, height: '100%',minHeight: 500, borderRight: '1px solid #E1E5E9', paddingTop: 24, textTransform: 'uppercase'}}>

                       <div 
                        onClick={() => this.changeMenu('account')}
                        className={`${this.state.selectedIndex === 'account' ? '' : 'blue-bk-hover opacity-hover'}`}
                        style={{height: 40, marginTop: 6, marginBottom: 6, display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                           <div 
                           className='identifyer'
                           style={{
                               height: '100%', 
                               width: 8, 
                               backgroundColor: this.state.selectedIndex === 'account' ? '#0078DB' : 'white'
                            }}>
                            </div>

                           <div 
                            className={`${this.state.selectedIndex === 'account' ? '' : 'blue-hover opacity-hover'}`}
                            style={{
                               paddingLeft: 12,
                               color: this.state.selectedIndex === 'account' ? '#0078DB' : '#6A7E93',
                               fontWeight: this.state.selectedIndex === 'account' ? 900 : 400
                            }}>Account</div>
                            
                       </div>

                       <div 
                        onClick={() => this.changeMenu('profile')}
                        className={`${this.state.selectedIndex === 'profile' ? '' : 'blue-bk-hover opacity-hover'}`}
                        style={{height: 40, marginTop: 6, marginBottom: 6, display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                           <div 
                           className='identifyer'
                           style={{
                               height: '100%', 
                               width: 8, 
                               backgroundColor: this.state.selectedIndex === 'profile' ? '#0078DB' : 'white'
                            }}>
                            </div>

                           <div 
                            className={`${this.state.selectedIndex === 'profile' ? '' : 'blue-hover opacity-hover'}`}
                            style={{
                               paddingLeft: 12,
                               color: this.state.selectedIndex === 'profile' ? '#0078DB' : '#6A7E93',
                               fontWeight: this.state.selectedIndex === 'profile' ? 900 : 400
                            }}>Profile</div>
                            
                       </div>

                       <div 
                        onClick={() => this.changeMenu('audit')}
                        className={`${this.state.selectedIndex === 'audit' ? '' : 'blue-bk-hover opacity-hover'}`}
                        style={{height: 40, marginTop: 6, marginBottom: 6, display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                           <div 
                           className='identifyer'
                           style={{
                               height: '100%', 
                               width: 8, 
                               backgroundColor: this.state.selectedIndex === 'audit' ? '#0078DB' : 'white'
                            }}>
                            </div>

                           <div 
                            className={`${this.state.selectedIndex === 'audit' ? '' : 'blue-hover opacity-hover'}`}
                            style={{
                               width: '100%',
                               paddingLeft: 12,
                               color: this.state.selectedIndex === 'audit' ? '#0078DB' : '#6A7E93',
                               fontWeight: this.state.selectedIndex === 'audit' ? 900 : 400
                            }}>
                                Audit Trail
                            </div>
                            
                       </div>

                       <div 
                        onClick={() => this.changeMenu('billing')}
                        className={`${this.state.selectedIndex === 'billing' ? '' : 'blue-bk-hover opacity-hover'}`}
                        style={{height: 40, marginTop: 6, marginBottom: 6, display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                           <div 
                           className='identifyer'
                           style={{
                               height: '100%', 
                               width: 8, 
                               backgroundColor: this.state.selectedIndex === 'billing' ? '#0078DB' : 'white'
                            }}>
                            </div>

                           <div 
                            className={`${this.state.selectedIndex === 'billing' ? '' : 'blue-hover opacity-hover'}`}
                            style={{
                               width: '100%',
                               paddingLeft: 12,
                               color: this.state.selectedIndex === 'billing' ? '#0078DB' : '#6A7E93',
                               fontWeight: this.state.selectedIndex === 3 ? 900 : 400
                            }}>
                                Billing & Payment
                            </div>
                            
                       </div>

                   </div>

                   
                   <div style={{padding: '36px 48px', width: '100%'}}>
                    {this.state.selectedIndex === 'account' &&
                        <div style={{width: '100%'}}><ProfileLarge /></div>
                    }

                    {this.state.selectedIndex === 'profile' &&
                       <div style={{width: '100%'}}><Profile /></div>
                    }

                    {this.state.selectedIndex === 'audit' &&
                       <div style={{width: '100%'}}><GlobalAuditWrapper /></div>
                    }

                    {this.state.selectedIndex === 'billing' &&
                       <div style={{width: '100%'}}><Billing /></div>
                    }

                    </div>


               </div>
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LargeSettings));
