import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import BaseRouter from "./routes";
import "antd/dist/antd.css";
import * as actions from "./store/actions/auth";
import * as appwideActions from "./store/actions/appwide";
import * as dataActions from "./store/actions/data";
import * as interActions from "./store/actions/interactions";
import './App.css';
import './pullStyles.css';
import Intro from './Intro'
import './containers/AppWide/AppWide.css';
import './Globals.css';
import {Redirect,  withRouter} from 'react-router-dom'; /* MAY NEED TO REMOVE */
import ScrollToTop from './containers/OverallHOC'
import CustomLayout from "./containers/Layout/OverallLayout";
import Login from './containers/Login/Login'
import Reset from './containers/Login/Reset'
import ErrorHoc from './ErrorHoc'
import Logo from './Media/Logo.svg'
import LinearProgress from '@material-ui/core/LinearProgress';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Websocket from 'react-websocket';
import {backendUrl, strPub} from './variables'
import worker from "./workers/priceUpdateWorker";
import WebWorker from "./workers/WebWorker";
import {PullToRefresh} from "react-js-pull-to-refresh";
import {PullDownContent, ReleaseContent} from "react-js-pull-to-refresh";
import {StripeProvider} from 'react-stripe-elements';
import Cookies from 'js-cookie';

const PullRefresh = (
  <div style={{height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 12}}>
      <div className="ui-loading" style={{height: 30, width: 30, marginRight: 12}}></div>
      <div className="basier-p3">Loading...</div>
  </div>
)
const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'rgba(0, 120, 219, 0.2)',
  },
  barColorPrimary: {
    backgroundColor: '#0078DB',
  },
})(LinearProgress);

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      online: true,
      canRefreshResolve: 1,
      listLen: 0,
      hasMore: 0,
      initializing: 1,
      refreshedAt: Date.now(),
      stripe: null,
      isAuthenticated: null,
      reset: false,
    };
  }

 


  componentDidMount() {

    if (this.props.location.pathname.indexOf('reset') !== -1) {
      this.setState(state => ({...state, reset: true}))
      return
    } else {
    }
 
    this.props.authStart()
    let isOnline = navigator.onLine
    isOnline = isOnline || process.env.REACT_APP_HOST_ENV === 'dev' ? true : false

    let cookieData = null
    let bypass = false;
    try {
      cookieData = Cookies.get('transitWisly')
      const cutIndex = cookieData.indexOf('|')
      let tokenCookie = null;
      let userCookie = null;
      
      if (cutIndex !== -1) {
        bypass = true
        tokenCookie = cookieData.slice(0,cutIndex)
        userCookie = cookieData.slice(cutIndex+1,)
      }
      this.props.onTryAutoSignup(bypass, tokenCookie, userCookie);
    } catch(e) {
      this.props.onTryAutoSignup(false, null, null);
    }

    try {
      Cookies.remove('transitWisly', { domain: '.wisly.io' })
    } catch(e) {
      console.log('cannot remove cookie', e)
    }
    

    if (window.Stripe) {
      this.setState({stripe: window.Stripe(strPub)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe(strPub)});
      });
    }

    try {
    caches.keys().then(function(names) {
      for (let name of names)
          caches.delete(name);
    });
    } catch(e) {

    }

    
    

    this.setState({ online: isOnline })
    //this.props.getCrypto();
    //this.props.fiatInit();
    
    if (this.props.user !== undefined && this.props.user !== null && this.props.token !== undefined && this.props.token !== null) {
      
      this.props.getAllCoins(this.props.user, this.props.token);
      this.props.getContracts(this.props.user, this.props.token);
      this.props.getExchanges(this.props.user, this.props.token);
      this.props.getWallets(this.props.user, this.props.token);
      this.props.getOrgs(this.props.user, this.props.token);
      this.props.getNotifs(this.props.user, this.props.token);
      this.props.getInvites(this.props.user, this.props.token);
      this.props.getPlans(this.props.user, this.props.token);
      this.props.getUserLimits(this.props.user, this.props.token);
      this.props.getConnectors(this.props.user, this.props.token);
      this.props.getImports(this.props.user)
      this.props.getTasks(this.props.user)
    }
    //this.props.getWallets(this.props.user);

  }

  componentDidUpdate(prevProps) {

    if (this.props.location.pathname.indexOf('reset') !== -1) {
      //this.setState(state => ({...state, reset: true}))
      return
    }
    
    if (prevProps.user !== this.props.user && this.props.user !== null && this.props.token !== undefined && this.props.token !== null && prevProps.token !== this.props.token) {

      this.props.getAllCoins(this.props.user, this.props.token);
      this.props.getExchanges(this.props.user, this.props.token);
      this.props.getContracts();
      this.props.getWallets(this.props.user, this.props.token);
      this.props.getOrgs(this.props.user, this.props.token);
      this.props.getNotifs(this.props.user, this.props.token);
      this.props.getInvites(this.props.user, this.props.token);
      this.props.getPlans(this.props.user, this.props.token);
      this.props.getUserLimits(this.props.user, this.props.token);
      this.props.getConnectors(this.props.user, this.props.token);
      this.props.getImports(this.props.user)
      this.props.getTasks(this.props.user)
    }

  }

  handleWebsocket = (m) => {

    let new_notif = [JSON.parse(m)['message']]
    let new_num = this.props.notifs.unread + 1
    let new_unseen = this.props.notifs.unseen + 1
    const notifs = this.props.notifs.notifications
    const newPush = new_notif.concat(notifs)

    const data = {
      unread: new_num, 
      unseen: new_unseen, 
      notifications: newPush
    }
    this.props.writeNotifs(data)

  }

  handlePriceUpdate = (m) => {

    let new_prices = [JSON.parse(m)['message']]


    this.worker = new WebWorker(worker);

    this.worker.addEventListener('message', event => {
        
        const scope = event.data[1]
        const scopeData = event.data[0]
        const allPorts = event.data[2]
        const subsData = event.data[3]

        if (scope === 'portfolio') {
          const portfolioScope = this.props.menuSelected
          this.props.updateSinglePortfolio({data: scopeData, portfolio: portfolioScope})
          this.props.updateTotalPortfolioValue({data: scopeData['total_value'], portfolio_id: portfolioScope})
          const currentPortValues = this.props.portfolioBalance['data'][this.props.menuSelected]
          const balanceList = currentPortValues.values
          balanceList[balanceList.length -1] = scopeData['total_value']
          currentPortValues['values'] = balanceList
          this.props.updatePortfolioGraph({data: currentPortValues, portfolio_id: this.props.menuSelected})

        } else if (scope === 'global') {
          this.props.updateTotalBalances(scopeData)
          
          const globalValues = this.props.globalBalance
          const balanceList = globalValues.values
          balanceList[balanceList.length -1] = scopeData['total']
          globalValues['values'] = balanceList
          this.props.updateGraph(globalValues)
          this.props.updateTotalValue(scopeData['total'])
          this.props.updatePortfolio(allPorts)
          


        }

        if (scope === 'global' || scope === 'subs') {
          this.props.updateSubs(subsData)
        }

    });

    let scopeData = null;
    const currencyScope = this.props.inBitcoin === 'bitcoin' ? 'bitcoin' : this.props.currency

    if (this.props.menuSelected !== null) {

      if (this.props.menuSelected === '/') {

        scopeData = this.props.globalAssetList
        const allPorts = this.props.portfoliosData

        this.worker.postMessage([new_prices[0], scopeData, 'global', allPorts, currencyScope, this.props.subs])

      } else {
        
        try {
          scopeData = this.props.portfoliosData[this.props.menuSelected]
        } catch(e) {
          scopeData = undefined;
        }
        if (scopeData !== undefined && scopeData !== null) {
          this.worker.postMessage([new_prices[0], scopeData, 'portfolio', null, currencyScope, null])
        }

      }


    } else if (this.props.currentView == 95) {
      this.worker.postMessage([new_prices[0], null, 'subs', null, null, this.props.subs])
    }

    

    
  }

  onOpen = () => {
    console.log('Initial connection')

  }

  onPriceOpen = () => {
    console.log('Connected to price updates')
  }



  onRefresh = () => {
    
    //this.props.getCrypto();
    //this.props.fiatInit();
    
    if (this.props.user !== undefined && this.props.user !== null) {
      this.props.getAllCoins(this.props.user, this.props.token);
      this.props.getExchanges(this.props.user, this.props.token);
      this.props.getWallets(this.props.user, this.props.token);
      this.props.getContracts(this.props.user, this.props.token);
      this.props.getOrgs(this.props.user, this.props.token);
      this.props.getNotifs(this.props.user, this.props.token);
      this.props.getInvites(this.props.user, this.props.token);
      this.props.getPlans(this.props.user, this.props.token);
      this.props.getUserLimits(this.props.user, this.props.token);
      this.props.getConnectors(this.props.user, this.props.token);
      this.props.getImports(this.props.user)
      this.props.getTasks(this.props.user)

    }

    const request_data = {
      period: this.props.portfolioBalance.period,
      start: this.props.portfolioBalance.start,
      end: this.props.portfolioBalance.end,
      scope: this.props.portfolioBalance.inBitcoin
    }

    this.props.componentDataFetch('get', this.props.currentView, this.props.currency, this.props.user, this.props.menuSelected, request_data,request_data,this.props.inBitcoin, this.props.token)

    return new Promise((resolve) => {
        setTimeout(resolve, 2000);
      });

  }

  refresh = (resolve, reject) => {

    
    //this.props.getCrypto();
    //this.props.fiatInit();
    
    if (this.props.user !== undefined && this.props.user !== null) {
      this.props.getAllCoins(this.props.user);
      this.props.getExchanges(this.props.user);
      this.props.getWallets(this.props.user);
      this.props.getContracts();
      this.props.getOrgs(this.props.user);
      this.props.getNotifs(this.props.user);
      this.props.getInvites(this.props.user);
      this.props.getPlans(this.props.user);
      this.props.getUserLimits(this.props.user);
      this.props.getConnectors(this.props.user);
      this.props.getImports(this.props.user)
      this.props.getTasks(this.props.user)
      

    }

    const request_data = {
      period: this.props.portfolioBalance.period,
      start: this.props.portfolioBalance.start,
      end: this.props.portfolioBalance.end,
      scope: this.props.portfolioBalance.inBitcoin
    }

    this.props.componentDataFetch('get', this.props.currentView, this.props.currency, this.props.user, this.props.menuSelected, request_data,request_data,this.props.inBitcoin, this.props.token)

    setTimeout(() => {
      const { canRefreshResolve } = this.state;
      if (!canRefreshResolve) reject();
      else {
        resolve();
      }
    }, 2000);
  }

  cancelReset = () => {
    this.setState(state => ({...state, reset: false}))
  }

  render() {
    
    const prefix = process.env.REACT_APP_HOST_ENV === 'dev' ? 'ws' : process.env.REACT_APP_HOST_ENV === 'mobile' ? 'ws' : 'wss'

    const url = process.env.REACT_APP_HOST_ENV === 'dev' ? backendUrl.slice(6,) : process.env.REACT_APP_HOST_ENV === 'dev' ? backendUrl.slice(6,) : backendUrl.slice(7,)


    return (

        this.props.initialLoad || !this.state.online ? 


          <div style={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
            <div>
              <img src={Logo} alt=""/>
              <div style={{width: 280, marginTop: 12}}>
                  <ColorLinearProgress />
                  <div style={{marginTop: 12}}>
                    {!this.state.online ? 

                    'Your computer seems to be offline, please connect to the internet and refresh the page'
                    
                    :
                    
                    null}
                  </div>
              </div>
            </div>
          </div>


          :

          this.state.reset ? 
          
          <Router>
            <Reset pathname={this.props.location.pathname} cancelReset={this.cancelReset}>
              <BaseRouter />
            </Reset>
          </Router>

          :

          this.props.isAuthenticated ? 
          
          <Router>
            <ErrorHoc>
              <ScrollToTop>
              <Websocket url={`${prefix}://${url}/ws/notifications/${this.props.user}?t=${this.props.token}`} debug={false}
                onMessage={(m) => this.handleWebsocket(m)} onOpen={() => this.onOpen()}/>
                <Websocket url={`${prefix}://${url}/ws/prices?t=${this.props.token}`} debug={false}
                onMessage={(m) => this.handlePriceUpdate(m)} onOpen={() => this.onPriceOpen()}/>
                
                  <StripeProvider stripe={this.state.stripe} >

                    <CustomLayout {...this.props} currentView={this.props.currentView} user_id={this.props.user} limits={this.props.limits} onRefresh={this.onRefresh} active={this.props.active}>
                    
                        
                      <BaseRouter />


                    </CustomLayout>
                  </StripeProvider>
                  
              </ScrollToTop>
            </ErrorHoc>
          </Router>
      
          : 

          <Router>
            <Login>
              <BaseRouter />
            </Login>
          </Router>
    
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    token: state.auth.token, 
    user: state.auth.user_id, 
    notifs: state.appwide.notifs, 
    currentView: state.data.currentView, 
    limits: state.appwide.limits, 
    initialLoad: state.auth.initialLoad, 
    loading: state.auth.loading, 
    menuSelected: state.data.menu_selected, 
    globalAssetList: state.data.globalAssetList, 
    portfoliosData: state.data.portfoliosData, 
    portfolioBalance: state.data.portfolioBalance, 
    globalBalance: state.data.globalBalance, 
    inBitcoin: state.data.bitcoinPf, 
    currency: state.auth.currency.id, 
    subs: state.data.subscriptions,
    active: state.auth.subscription
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
    fiatInit: () => dispatch(dataActions.setFiatList()), 
    getImports: (u) => dispatch(dataActions.getImports(u)), 
    getContracts: () => dispatch(appwideActions.getContracts()), 
    getTasks: (u) => dispatch(interActions.getTasks(u)), 
    authStart: () => dispatch(actions.authStart()),
    onTryAutoSignup: (b, t, u) => dispatch(actions.authCheckState(b, t, u)),
    getAllCoins: (user, t) => dispatch(appwideActions.getAllCoins(user, t)), 
    getCrypto: () => dispatch(appwideActions.getCrypto()), 
    getExchanges: (user, t) => dispatch(appwideActions.getExchanges(user, t)), 
    getConnectors: (user, t) => dispatch(appwideActions.getConnectors(user, t)), 
    getWallets: (user, t) => dispatch(appwideActions.getWallets(user, t)), 
    getOrgs: (user, t) => dispatch(appwideActions.getOrgs(user, t)), 
    getNotifs: (user, t) => dispatch(appwideActions.getNotifs(user, t)), 
    writeNotifs: (data) => dispatch(appwideActions.updateNotifs(data)),
    getInvites: (data) => dispatch(appwideActions.getInvites(data)),
    getPlans: (user, t) => dispatch(appwideActions.getPlans(user, t)), 
    getUserLimits: (user, t) => dispatch(appwideActions.getUserLimits(user, t)), 
    updateSinglePortfolio: (data) => dispatch(dataActions.updateSinglePortfolio(data)),
    updateTotalBalances: (data) => dispatch(dataActions.updateTotalBalances(data)),
    updateTotalPortfolioValue: (data) => dispatch(dataActions.updateTotalPortfolioValue(data)),
    updatePortfolioGraph: (data) => dispatch(dataActions.updatePortfolioGraph(data)),
    updateGraph: (data) => dispatch(dataActions.updateGraph(data)),
    updateTotalValue: (data) => dispatch(dataActions.updateTotalValue(data)),
    updatePortfolio: (data) => dispatch(dataActions.updatePortfolio(data)),
    updateSubs: (data) => dispatch(dataActions.updateSubs(data)),
    componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, token) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));