import React, { type ElementConfig } from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';


const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '324px', height: 20, marginLeft: 12, fontSize: 16, border: '0px', color: 'black', cursor: 'text', dispay:'inline-block'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#F3F5F6'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.7',
    };
  },

};

const ValueContainer = ({ children, ...props }) => {
    var selectInput = React.Children.toArray(children).find((input) => input.type.name === "Input" || input.type.name === "DummyInput");
    const { getValue, hasValue } = props;
    const value = getValue();
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <components.ValueContainer {...props}>
        
        <div className="basier-p4">
          <div style={{textAlign: 'left', display: 'flex', width: '100%', alignItems: 'center'}}>
          <div style={{marginRight: 12}} className="basier">
              <img src={value[0].image} alt="" style={{height: 15, width: 15}} />
          </div>
          <div className="basier-p4">
            {value[0].name}
            {selectInput}
          </div>
        </div>
        </div>
        
  
      </components.ValueContainer>
    );
  };


const Option = props => {
    const {
        data,   
      } = props;
  return (

      <components.Option {...props}>

        <div 
         style={{textAlign: 'left', display: 'flex', width: '100%', alignItems: 'center'}}>
          <div style={{marginRight: 12}} className="basier">
              <img src={data.image} alt="" style={{height: 15, width: 15}} />
          </div>
          <div className="basier-p4">
            {data.name}
          </div>
        </div>
  

      </components.Option>

  );
};

class MenuSelector extends React.Component {

    state = {
      selectedOption: null, 
    }


  componentDidMount() {

    if (this.props.selected !== undefined && this.props.selected !== null) {
        this.setState(state => ({...state, selectedOption: this.props.selected}))
    }

    this.setState(state => ({...state, options: this.props.options}))

  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.options !== this.props.options) {

      this.setState(state => ({...state, selectedOption: null}))

        if (this.props.selected !== undefined && this.props.selected !== null) {
            this.setState(state => ({...state, selectedOption: this.props.selected}))
        }

        this.setState(state => ({...state, options: this.props.options}))

      }

    }


  handleChange = (selectedOption) => {

        this.setState(state => ({...state,  selectedOption: selectedOption }));
        this.props.handleSelect(selectedOption.ticker)
        //this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: selectedOption.id})
    
  }

  onFocus = () => {

    //this.setState(state => ({...state,  selectedOption: null }));
    //this.props.handleChange(null)

  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.state.options}
        onFocus={this.onFocus}
        onMenuOpen={this.onFocus}
        isSearchable={false}
        styles={colourStyles}
        alwaysOpen
        maxMenuHeight={120}
        minMenuHeight={50}
        components={{ ValueContainer, Option }}
        classNamePrefix="react-select-largefiat"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    
  };
};

const mapDispatchToProps = dispatch => {
  return {
   
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
