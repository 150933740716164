import React from 'react';
import * as actions from "../../../../../store/actions/interactions";
import { connect } from 'react-redux';
import currencyDict from '../../../.././../helpers/currency.json'

const downArrow = 
(
<svg width="28" height="32" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3333 8.35083L6.68421 13.9999L1 8.35083" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
<path d="M6.68457 14V0" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
</svg>
)

const upArrow = 
(
<svg width="28" height="32" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.999933 7.50329L6.64905 1.85418L12.3333 7.5033" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
<path d="M6.64868 1.85414L6.64868 15.8541" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
</svg>
)

class TransactionTypeQuote extends React.PureComponent {

    constructor(props) {

        super(props)

        this.state = {
            done: false, 
            selected_quote: null, 
    }
}   

    render() {

        const transaction_types = {
                deposit: 'up',
                withdraw: 'down',
                buy: 'up',
                sell: 'down',
                transfer: 'flat',
                mining: 'up',
                fee: 'down',
                dividend: 'up',
                income: 'up',
                gift: 'up',
                airdrop: 'up',
                fork: 'up',
                purchase: 'down',
                donation: 'down',
                lost: 'down',
             }
        
        const description = this.props.transaction['data'][this.props.transactionId]['transaction_description']        
        const directionToGo = transaction_types[description]
        const baseObject = this.props.transaction['data'][this.props.transactionId]['base_currency']
        const baseName = this.props.transaction['data'][this.props.transactionId]['base_currency'].name
        let quoteName = null;
        let quoteObject = null;
        let feeName = null;
        try { quoteName = this.props.transaction['data'][this.props.transactionId]['quote_currency'].name } catch(e) {}
        try { quoteObject = this.props.transaction['data'][this.props.transactionId]['quote_currency'] } catch(e) {}
        try { feeName = this.props.transaction['data'][this.props.transactionId]['fee_currency'].name } catch(e) {}
        
        let amountBase = this.props.transaction['data'][this.props.transactionId]['amount']; 
        let amountQuote = null; 

        let feeBase = 0;
        let feeQuote = 0;

        if (this.props.transaction['data'][this.props.transactionId]['fee'] > 0) {
            if (feeName === baseName) {
                feeBase = this.props.transaction['data'][this.props.transactionId]['fee']
            } else if (feeName === quoteName) {
                feeQuote = Number(this.props.transaction['data'][this.props.transactionId]['fee'])
            }
        }
        
        if (this.props.priceType === 'unit') {
            try { amountQuote = this.props.transaction['data'][this.props.transactionId]['amount'] * this.props.transaction['data'][this.props.transactionId]['unit_price'] } catch(e) {}
        } else {
            try { amountQuote = parseFloat(this.props.transaction['data'][this.props.transactionId]['unit_price']) } catch(e) {}
        }

        console.log(amountQuote)

        return (

            <div>

            <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
               
                <div style={{color: 'black', textAlign: 'center'}}>
                    <div className="basier-p3" style={{marginTop: 36}}>Your have entered the following transaction: </div> 

                    <div className="basier" style={{fontSize: 14, marginTop: 36, textTransform: 'uppercase', fontStyle: 'italic', color: 'black'}}>
                    <span>{description} </span>
                    <span>{this.props.transaction['data'][this.props.transactionId]['amount']} </span>
                    <span>{baseName}</span>

                    {description === 'buy' || description === 'sell' || description === 'ico' ?
                        <span>
                        <span> at a price of </span>
                        <span>{this.props.transaction['data'][this.props.transactionId]['unit_price']} </span>
                        <span>{quoteName} </span>
                        <span>each</span>

                        </span>

                    :
                    null } 

                    {this.props.transaction['data'][this.props.transactionId]['fee'] > 0 ?
                        <span>
                        <span> with </span>
                        <span>{this.props.transaction['data'][this.props.transactionId]['fee']} </span>
                        <span>{feeName} </span>
                        <span>in fees</span>

                        </span>

                    :
                    null } 

                    <span>.</span>

                    </div>
                </div>                

            </div>  

             <div>
                <div style={{color: 'black', textAlign: 'center'}}>
                    <div className="basier-p3" style={{marginTop: 36, marginBottom: 36}}>The impact on your portfolio will be: </div> 

                </div>
            
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 24}}>

                    <span style={{marginRight: 24}}>{directionToGo === 'up' ? upArrow : downArrow}</span>
                    <span className="basier" style={{fontSize: 24, textTransform: 'uppercase', marginLeft: 6, color: `${directionToGo === 'up' ? '#4CDAC1' : '#F9716B'}`}}>
                        {directionToGo === 'up' ? 'INCREASE' : 'DECREASE'}
                    </span>
                    <span className="basier" style={{fontSize: 24, marginRight: 12, marginLeft: 24}}>
                        in
                    </span>
                    {
                        baseObject.image !== "" && baseObject.image !== null  ? 
                        <span><img style={{height: 30, width: 30, marginRight: 12}} src={baseObject.image} alt=""/></span>
                        :
                        <span style={{height: 30, width: 30, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[baseObject.value] === undefined ? '20px' : currencyDict[baseObject.value]['symbol'].length > 1 ? '20px' : '30px'}` }}>
                            {currencyDict[baseObject.value] === undefined ? baseObject.value : currencyDict[baseObject.value]['symbol'].length > 1 ? currencyDict[baseObject.value]['symbol'] : currencyDict[baseObject.value]['symbol'] }
                        </span>
                        
                    }
                    <span className="basier" style={{fontSize: 24, marginRight: 12}}>{baseName}</span>
                    <span className="basier" style={{fontSize: 24,}}> of </span>
                    <span className="basier" style={{fontSize: 24,  marginLeft: 12, color: `${directionToGo === 'up' ? '#4CDAC1' : '#F9716B'}`}}> {
                        amountBase > 1 ? 
                        (amountBase - feeBase).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                        :
                        (amountBase - feeBase).toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})
                        } </span>

                </div>

                {description === 'buy' || description === 'ico' || description === 'sell' ?

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 24}}>
                    <span style={{marginRight: 24}}>{directionToGo === 'up' ? downArrow : upArrow}</span>
                    <span className="basier" style={{fontSize: 24, textTransform: 'uppercase', marginLeft: 6, color: `${directionToGo === 'up' ? '#F9716B' : '#4CDAC1'}`}}>
                        {directionToGo === 'up' ? 'DECREASE' : 'INCREASE'}
                    </span>
                    <span className="basier" style={{fontSize: 24, marginRight: 12, marginLeft: 24}}>
                        in
                    </span>
                    {
                        quoteObject.image !== "" && quoteObject.image !== null  ? 
                        <span><img style={{height: 30, width: 30, marginRight: 12}} src={quoteObject.image} alt=""/></span>
                        :
                        <span style={{height: 30, width: 30, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[quoteObject.value] === undefined ? '20px' : currencyDict[quoteObject.value]['symbol'].length > 1 ? '20px' : '30px'}` }}>
                            {currencyDict[quoteObject.value] === undefined ? quoteObject.value : currencyDict[quoteObject.value]['symbol'].length > 1 ? currencyDict[quoteObject.value]['symbol'] : currencyDict[quoteObject.value]['symbol'] }
                        </span>
                        
                    }
                    <span className="basier" style={{fontSize: 24, marginRight: 12}} >{quoteName}</span>
                    <span className="basier" style={{fontSize: 24,}}> of </span>
                    <span className="basier" style={{fontSize: 24, marginLeft: 12, color: `${directionToGo === 'up' ? '#F9716B' : '#4CDAC1'}`}}> {
                        
                        amountQuote > 1 ? 
                        (amountQuote + feeQuote).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                        :
                        (amountQuote + feeQuote).toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})
                    
                    } </span>

                </div>

                :
                null 
                }

            </div>
            
            </div>

        )
    }
}


const mapStateToProps = state => {
    return {
  
        modalPref: state.interactions.transactionAddModal,
        transaction: state.interactions.transactionAddData,
        tr_date: state.interactions.dateTransaction,
        feeCheck: state.interactions.feeCheck, 
        priceType: state.interactions.priceType
        
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(TransactionTypeQuote);