import React from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/interactions'
import { withRouter } from "react-router-dom";
import SmallAssetDetail from './SmallAssetDetail'
import Switch from '../../../../Assets/Switch'


class AssetTable extends React.PureComponent {

    state = {

        small: false, 
        exited: false, 
        errors: true, 
    }

    handleDetail = (e) => {
       this.props.history.push({pathname: '/position-detail', 
                                state: { coinId: e.currentTarget.getAttribute('value'), 
                                          portfolioId: this.props.portfolioId, 
                                          portfoliosData: this.props.portfoliosData }})
    }

    handleSmallToggle = () => {
        if (this.state.small === false) {
            this.setState(state => ({...state, small: true}));
        } else {
            this.setState(state => ({...state, small: false}));
        }
    }

    handleExitedToggle = () => {
        if (this.state.exited === false) {
            this.setState(state => ({...state, exited: true}));
        } else {
            this.setState(state => ({...state, exited: false}));
        }
    }

    handleErrorToggle = () => {
        if (this.state.errors === false) {
            this.setState(state => ({...state, errors: true}));
        } else {
            this.setState(state => ({...state, errors: false}));
        }
    }

    


    render() {

        return (

           <div>

               <div>

            {   
                this.props.active.map((item, index) => { 
                
                const roi = item.total_value / (item.purchase_price * item.balance)

                return (

                    <SmallAssetDetail status="small" currency_symbol={this.props.currency_symbol} handleDetail={this.handleDetail} key={index} index={index} item={item} roi={roi} />                

                )
    
                })

            }   

            </div>

            {this.props.small.length > 0 &&
            <div>

                <div style={{
                    display: 'flex', 
                    marginTop: 24,
                    marginBottom: 24,
                    paddingLeft: 12, 
                    paddingRight: 0, 
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>

                    <div className="basier-p2" style={{color: 'black', fontWeight: 900}}>Small Balances</div>
                    <div><Switch status={this.state.small} handleSwitchChange={this.handleSmallToggle} /></div>

                </div>


                { 
                this.state.small &&   
                this.props.small.map((item, index) => { 
                
                const roi = item.total_value / (item.purchase_price * item.balance)

                return (

                    <SmallAssetDetail status="small" currency_symbol={this.props.currency_symbol} handleDetail={this.handleDetail} key={index} index={index} item={item} roi={roi} />                

                )
    
                })

            }   


            </div>}


            {this.props.exited.filter(v => v.error === false).length > 0 &&
            <div>

                <div style={{
                    display: 'flex', 
                    marginTop: 24,
                    marginBottom: 24,
                    paddingLeft: 12, 
                    paddingRight: 0, 
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>

                    <div className="basier-p2" style={{color: 'black', fontWeight: 900}}>Exited Coins</div>
                    <div><Switch status={this.state.exited} handleSwitchChange={this.handleExitedToggle} /></div>

                </div>


                { 
                this.state.exited &&   
                this.props.exited.filter(v => v.error === false).map((item, index) => { 
                
                const roi = item.total_value / (item.purchase_price * item.balance)

                return (

                    <SmallAssetDetail status="exit" currency_symbol={this.props.currency_symbol} handleDetail={this.handleDetail} key={index} index={index} item={item} roi={roi} />                

                )
    
                })

            }   


            </div>}

            {this.props.exited.filter(v => v.error === true).length > 0 &&
            <div>

                <div style={{
                    display: 'flex', 
                    marginTop: 24,
                    marginBottom: 12,
                    paddingLeft: 12, 
                    paddingRight: 0, 
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>

                    <div className="basier-p2" style={{color: '#F9716B', fontWeight: 900}}>Errors & imprecisions</div>

                </div>

                    <React.Fragment> 
                        
                        <div style={{paddingLeft: 24, paddingRight: 24, marginBottom: 24, fontStyle: 'italic'}}>{`You have sold or used more assets than you own, and your balances for the below coins are negative. Please go to the portfolio settings to find out more about transaction errors and fix them.`}</div>

                        <div>
                        {this.props.exited.filter(v => v.error === true).map((item, index) => { 
                        
                        const roi = item.total_value / (item.purchase_price * item.balance)

                        return (

                        
                            <SmallAssetDetail status="exit" errors={true} currency_symbol={this.props.currency_symbol} handleDetail={this.handleDetail} key={index} index={index} item={item} roi={roi} />                
                            
                        )
            
                            })

                        }   


                        </div>
                    </React.Fragment>

            </div>}
        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleAssetModal: (currency, scope) => 
            dispatch(actions.toggleAssetModal(currency, scope)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AssetTable));