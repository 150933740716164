import React, { type ElementConfig } from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';
import * as actions from "../../../../../../store/actions/interactions";




class MenuSelector extends React.Component {

  constructor(props) {
    super(props)
    const textName = this.props.selection === 'unit' ? 'Unit Price' : this.props.selection === 'total' ? "Total Price" : null
    this.state = {
      selectedOption: {value: this.props.selection, label: textName}
    }
  }
  
  colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'transparent', width: this.props.scope === 'mobile' ? '130px' : '115px', height: 42, fontSize: this.props.scope === 'mobile' ? 15 : 12, paddingLeft: 6, border: '0px', cursor: 'text', dispay:'inline-block', borderRadius: '5px 0px 0px 5px'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
        color: isDisabled
          ? '#F3F5F6'
          : isSelected ? 'black' : 'black',
        opacity: isDisabled
          ? '1'
          : isSelected ? '1' : '0.4',
      };
    },
  
  };

  componentDidMount() {


  }

  keyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
        e.persist()
        //e.preventDefault()
        /* try {
          this.props.nextRef.focus({preventScroll: true})
        } catch(e) {} */
    }
  }
  handleChange = (selectedOption) => {

    if (selectedOption.value !== this.state.selectedOption.value) {

      this.setState({ selectedOption });
      this.props.changeType(selectedOption.value)


    }
    
  }

    options = [{value: "unit", label: "Unit Price"}, {value: "total", label: "Total Price"}]

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.options}
        isSearchable={true}
        ref={this.props.innerRef}
        onKeyDown={(e) => this.keyDown(e)}
        styles={this.colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        classNamePrefix={this.props.scope === 'mobile' ? 'react-select-priceType-mobile' : 'react-select-priceType'}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)), 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
