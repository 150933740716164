import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions/data";
import * as interActions from "../../../../store/actions/interactions";
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import matchSorter from 'match-sorter'
import './TransactionStyle.css'
import {DateFormatInput} from 'material-ui-next-pickers'
import AddPlus from '../../../../Media/AddPlus.svg'
import {siwtchTransactionType} from '../../../../helpers/helpers'
import Export from '../../../../Media/Export.svg'
import { backendUrl } from '../../../../variables'
import Plus from '../../../../Media/Plus.svg'

class TransactionOverview extends React.PureComponent {

    state = {
        date: null,
        data: Object.values(this.props.transactions),
        filtered: this.props.transactionFilter,
    }
    componentDidMount() {
        this.props.setCurrentView(5)
        let newValues = Object.values(this.props.transactions);
        for (let i in this.props.transactionFilter) {
            if (this.props.transactionFilter[i]['id'] == 'wallet') {
                newValues = Object.values(this.props.transactions).filter(v => v.storage == this.props.transactionFilter[i]['value']);
                
            }
        }
        this.setState(state => ({...state, data: newValues}))
        //this.props.componentDataFetch('get', 5, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)

    } 

    componentDidUpdate(prevProps) {
        if (prevProps.transactions !== this.props.transactions) {
            let newValues = Object.values(this.props.transactions);
            for (let i in this.props.transactionFilter) {
                if (this.props.transactionFilter[i]['id'] == 'wallet') {
                    newValues = Object.values(this.props.transactions).filter(v => v.storage == this.props.transactionFilter[i]['value']);
                    
                }
            }
            this.setState(state => ({...state, data: newValues}))
        }
    }
    
    toggleTxModal = () => {

        this.props.toggleModal({type: 'add_transaction', open: true, payload: {} })

    }

    handleFilter = (filter) => {

        this.setState(state => ({...state, filtered: filter}))
        this.props.setTrFilter(filter)

    }
    
    columns=[
        {   
            accessor: 'transaction_description', 
            Header: () =>  (
                <div className='basier-p4-caps'>Type</div>
            ),
            maxWidth: 150,
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["transaction_description"] }),
            filterAll: true,
            Cell: row => (
                <div>
                    <div className={`basier-p4 transaction-bubble transaction-color-${row.value}`}>
                    {siwtchTransactionType(row.value)[0]}
                    </div>  
                </div>
                
                
            ),
            Filter: ({ filter, onChange }) =>
                <input
                  placeholder="Filter type"
                  className="basier-p4"
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                />
        },
        { 
            id: 'date',
            accessor: 'date', 
            Header: () =>  (
                <div className='basier-p4-caps'>Date</div>
            ),
            maxWidth: 200,
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["date"] }),
            Filter: ({ filter, onChange }) =>
            <input
                placeholder="DDDD-MM-YY"
                className="basier-p4"
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : ""}
              />,
            filterAll: true,
            Cell: row => {
                const language = navigator.language || 'en'
                const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                const parseDate = new Date(row.value);
                const showDate = parseDate.toLocaleDateString(language,options)

                return (
                <div>
                    <div className="basier-p4">
                    {showDate}
                    </div>  
                </div>
            )
            }
        },
        { 
            accessor: 'base', 
            Header: () =>  (
                <div className='basier-p4-caps'>Base coin</div>
            ),
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["base"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) =>
              <input
                placeholder="Filter coin"
                className="basier-p4"
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : ""}
              />, 
            Cell: row => {
                
                
                return (
                <div>
                    <div className="basier-p4">
                        {row.value}
                    </div>  
                </div>
            )
            }
        },
        { 
            accessor: 'quote', 
            Header: () =>  (
                <div className='basier-p4-caps'>Quote coin</div>
            ),
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["quote"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) =>
              <input
                placeholder="Filter coin"
                className="basier-p4"
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : ""}
              />, 
            Cell: row => {
                
                
                return (
                <div>
                    <div className="basier-p4">
                        {   row.original.transaction_description === 'buy' || row.original.transaction_description === 'ico' || row.original.transaction_description === 'sell' ?
                            row.value
                        :
                        
                        null
                        }
                    </div>  
                </div>
            )
            }
        },
        { 
            accessor: 'amount', 
            Header: () =>  (
                <div className='basier-p4-caps' style={{textAlign: 'right', paddingRight: 12,}}>Amount</div>
            ),
            maxWidth: 200,
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["amount"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) =>
              <input
                placeholder="Filter amount"
                className="basier-p4"
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%", textAlign: 'right', paddingRight: 12, }}
                value={filter ? filter.value : ""}
              />,
            Cell: row => {
                
                
                return (
                <div>
                    <div className="basier-p4" style={{textAlign: 'right', paddingRight: 12,}}>
                        {row.value >= 1 ? 
                            row.value.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                            :
                            row.value === 0 ? 
                            null 
                            :
                            row.value.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                        }
                    </div>  
                </div>
            )
            }
        },
        { 
            accessor: 'unit_price', 
            Header: () =>  (
                <div className='basier-p4-caps' style={{textAlign: 'right', paddingRight: 12,}}>Unit Price</div>
            ),
            maxWidth: 200,
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["unit_price"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) =>
              <input
                placeholder="Filter price"
                className="basier-p4"
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%", textAlign: 'right', paddingRight: 12,}}
                value={filter ? filter.value : ""}
              />,
            Cell: row => {
                
                
                return (
                <div>
                    <div className="basier-p4" style={{textAlign: 'right', paddingRight: 12,}}>
                        {   row.original.transaction_description === 'buy' || row.original.transaction_description === 'ico' || row.original.transaction_description === 'sell' ?
                                row.value >= 1 ? 
                                row.value.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                                :
                                row.value.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                            :

                            null 
                            
                            }
                    </div>  
                </div>
            )
            }
               },
        { 
            accessor: 'user_first', 
            Header: () =>  (
                <div className='basier-p4-caps' style={{textAlign: 'center',}}>User</div>
            ),
            maxWidth: 100,
            filterMethod: (filter, rows) => 
                matchSorter(rows, filter.value, { keys: ["user_first"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => {

              return (
                <input
                    placeholder="Filter user"
                    className="basier-p4"
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%", textAlign: 'center',}}
                    value={filter ? filter.value : ""}
                />)}, 
            Cell: row => {
                
                const first = row.original.user_first
                const last = row.original.user_last

                return (
                <div>
                    <div className="basier-p4" style={{textAlign: 'center',}}>
                    <img 
                    style={{height: 25, width: 25}}
                    src={`https://ui-avatars.com/api/?rounded=true&size=128&background=6A7E93&color=fffff&name=${first}+${last}`} 
                    alt="" />
                    </div>  
                </div>
            )
            }
        },
        ]

    render() {

        return (

            <div style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}}>

                <div style={{marginTop: -24, marginBottom: 24, marginRight: 12, display: 'flex', justifyContent: 'space-between'}}>
                    
                    {this.props.roles[this.props.portfolioId] === 'admin' || this.props.roles[this.props.portfolioId] === 'manager' ? 
                    <span onClick={this.toggleTxModal} className="basier-p4-caps" style={{userSelect: 'none', color: 'black', marginLeft: 0, cursor: 'pointer'}}>
                        <span><img src={Plus} alt="" style={{marginRight: 12}}/></span>
                        <span>Add a Transaction</span>
                    </span>
                    :
                    <div></div> }

                    <a href={`${backendUrl}/v1/export_tr/?p=${this.props.portfolioId}`} style={{color: 'black'}} >
                        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} className="opacity-hover">
                            <div><img src={Export} alt="export" style={{height: 20, width: 20}} /></div>
                            <div style={{marginLeft: 12}} className="basier-p4-caps">Export transactions</div>
                        </div>
                    </a>
                </div>

                <ReactTable
                    
                    defaultPageSize={6}
                    filterable
                    filtered={this.state.filtered}
                    //onFilteredChange={filtered => {this.setState({ filtered });}}
                    onFilteredChange={filtered => this.handleFilter(filtered)}
                    resizable={false}
                    defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}
                    showPageSizeOptions={false}
                    data={this.state.data}
                    columns={this.columns}
                    getProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            border: '0px',
                          }
                        };
                      }}
                      getTrGroupProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            border: '0px',
                          }
                        };
                    }}
                    getTheadFilterProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            marginTop: 6,
                            border: 'none'
                          }
                        };
                      }}
                    getPaginationProps={(state, rowInfo, column) => {
                        return {
                          style: {
                           boxShadow: 'none',
                           border: 'none',
                          }
                        };
                      }}
                    getTheadProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            boxShadow: 'none', 
                          }
                        };
                      }}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                          onClick: e => {
                              this.props.selectTransaction(rowInfo.original)
                          },
                          className: `${

                            rowInfo === undefined ? 'no-hover' : 
                            rowInfo.row.transaction_description === 'buy'  || rowInfo.row.transaction_description === 'ico'  ? 
                                'buy-hover' //'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #4CDAC1'
                            : 
                            rowInfo.row.transaction_description === 'deposit' 
                            ? 
                                'deposit-hover'// 'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #C174FD'
                            : 
                            rowInfo.row.transaction_description === 'mining' || 
                            rowInfo.row.transaction_description === 'dividend' || 
                            rowInfo.row.transaction_description === 'income' || 
                            rowInfo.row.transaction_description === 'gift' || 
                            rowInfo.row.transaction_description === 'airdrop' || 
                            rowInfo.row.transaction_description === 'fork' 
                            ? 
                                'income-hover' //'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #ffc163'
                            :
                            rowInfo.row.transaction_description === 'sell' || 
                            rowInfo.row.transaction_description === 'fee' || 
                            rowInfo.row.transaction_description === 'lost' 
                            ? 
                                'spend-hover' //'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #F9716B'
                            :
                            rowInfo.row.transaction_description === 'withdraw' ? 
                                'withdraw-hover' //'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #0078DB'
                            : 
                            rowInfo.row.transaction_description === 'transfer' ||
                            rowInfo.row.transaction_description === 'purchase' ||
                            rowInfo.row.transaction_description === 'donation' 
                             ? 
                                'transfer-hover'//'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #6A7E93'
                            : 
                            'no-hover'
                              
                          }`,
                          style: {
                            display: 'flex', 
                            alignItems: 'center',
                            marginBottom: 7,
                            marginTop: 7,
                            boxShadow: rowInfo === undefined ? 'none' : '0px 10px 10px rgba(225, 229, 233, 0.3)', 
                            borderRadius: rowInfo === undefined ? 0 : 35, 
                            height: 56,
                            border: '0px',
                            background: rowInfo === undefined ? 'white' : 
                            rowInfo.row.transaction_description === 'buy' || rowInfo.row.transaction_description === 'ico' ? 
                                'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #4CDAC1'
                            : 
                            rowInfo.row.transaction_description === 'deposit' 
                            ? 
                                'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #C174FD'
                            : 
                            rowInfo.row.transaction_description === 'mining' || 
                            rowInfo.row.transaction_description === 'dividend' || 
                            rowInfo.row.transaction_description === 'income' || 
                            rowInfo.row.transaction_description === 'gift' || 
                            rowInfo.row.transaction_description === 'airdrop' || 
                            rowInfo.row.transaction_description === 'fork' 
                            ? 
                                'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #ffc163'
                            :
                            rowInfo.row.transaction_description === 'sell' || 
                            rowInfo.row.transaction_description === 'fee' || 
                            rowInfo.row.transaction_description === 'lost' 
                            ? 
                                'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #F9716B'
                            :
                            rowInfo.row.transaction_description === 'withdraw' ? 
                                'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #0078DB'
                            : 
                            rowInfo.row.transaction_description === 'transfer' ||
                            rowInfo.row.transaction_description === 'purchase' ||
                            rowInfo.row.transaction_description === 'donation' 
                             ? 
                                'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #6A7E93'
                            : 
                            'white'
                          }
                        };
                      }}
                />
            
            </div>

        )
    }
  
};

const mapStateToProps = state => {
    return {
        transactions: state.data.transactions, 
        inBitcoin: state.data.bitcoinPf, 
        roles: state.auth.roles,
        transactionFilter: state.interactions.transactionFilter,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTrFilter: (data) => 
            dispatch(interActions.setTrFilter(data)),
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionOverview);