import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CoinSmallGraph from '../../AppWide/CoinSmallGraph' 
import currencyDict from '../../../helpers/currency.json';
import axios from 'axios';
import {backendUrl } from '../../../variables'
import * as actions from "../../../store/actions/data";
import * as interActions from "../../../store/actions/interactions";
import { withRouter} from 'react-router-dom';





const assetModalContent = (props) => {

    const clearBalance = (coin, type, pId, user_id, token) => {

        let headers = { "content-type": "application/json" };
            if (token !== null && token !== undefined) {
        
                headers['Authorization'] = `Token ${token}`
        
            }
    
        const url = `${backendUrl}/v1/balances/clear`
        const data = {
            pId: pId, 
            cId: coin, 
            user_id: user_id, 
            type: type, 
        }
        axios.post(url, data, {headers: headers})
        .then(j => {
            props.componentDataFetch('update', 2, props.currency, props.user_id, props.currentPort, {}, {}, props.bitcoinPf, props.token)
            props.handleClose();
            props.showSnack({message: 'Balance successfully cleared.', variant: "success"})
        })
    
    }

    const confirm = (coin, type, pId, user_id, token) => {
        props.toggleModal(
            {
                type: 'confirmation_modal', 
                payload: {
                    message: 'Are you sure you want to clear this balance?', 
                    handleConfirm: () => clearBalance(coin, type, pId, user_id, token)
                }, 
                open: true
            }
        )
    }

    const goToBaseTr = (coin) => {
        
        props.history.push(`/portfolio/${props.currentPort}/transactions?base_id=${coin}`)
        props.setPfTab(2)
        
        props.handleClose();

    }

    const goToQuoteTr = (coin) => {
        
        props.history.push(`/portfolio/${props.currentPort}/transactions?quote_id=${coin}`)
        props.setPfTab(2)
        
        props.handleClose();

    }

    //const total_investment = (props.coin_in_scope.total_value - props.coin_in_scope.realized) 

    const roundBalance = Math.round(props.coin_in_scope.balance * 1e9)/1e9
    
    return (

        <div>
            <div style={{backgroundColor: '#F8F9FA', height: 100, display:'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 24}} id="responsive-dialog-title">
                
                <div style={{display:'flex', alignItems: 'center',}}>
                    <span style={{marginRight: 12}}>
                    {
                            
                        props.coin_in_scope.image_large !== "" && props.coin_in_scope.image_large !== null  ? 
                        <span><img style={{height: 60, width: 60, marginRight: 12}} src={props.coin_in_scope.image_large} alt=""/></span>
                        :
                        <span style={{height: 60, width: 60, border: '2px solid black', color: 'black', borderRadius: '100%', marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[props.coin_in_scope.coin_symbol] === undefined ? '16px' : currencyDict[props.coin_in_scope.coin_symbol]['symbol'].length > 1 ? '18px' : '30px'}` }}>
                            {currencyDict[props.coin_in_scope.coin_symbol] === undefined ? props.coin_in_scope.coin_symbol : currencyDict[props.coin_in_scope.coin_symbol]['symbol'].length > 1 ? currencyDict[props.coin_in_scope.coin_symbol]['symbol'] : currencyDict[props.coin_in_scope.coin_symbol]['symbol'] }
                        </span>
                        
                        } 
                    </span>
                    <div className="basier">
                        <div style={{fontSize: 28,color: 'black'}}>{props.coin_in_scope.coin_symbol}</div>
                        <div style={{fontSize: 16, color:'#6A7E93', textTransform: 'uppercase'}}>{props.coin_in_scope.short_name} </div> 
                    </div>
                </div>
                <div className="basier" style={{marginLeft: 24, marginRight: 24, color: '#6A7E93', fontSize: 20, justifySelf: 'flex-end'}}>
                    {(props.coin_in_scope.proportion * 100).toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}% of portfolio 
                </div>
            

            </div>

            <DialogContent style={{overflowY: 'hidden', height: 430, padding: 24}}>

                    <div style={{width: '100%', display: 'flex', alignItems: 'flex-start', height: '100%', justifyContent: 'space-between'}}>

                        <div style={{width: '50%', padding: 12, height: '100%',}}>

                            <div className="basier-p4-caps" style={{marginBottom: 12}}>
                                Key information
                            </div>

                            <div style={{paddingLeft: 12}}>
                                <div style={{marginTop: 24, display: 'flex', alignItems: 'center'}} className="basier-p3">

                                    <div style={{textTransform: 'uppercase', fontWeight: 'bold', color: 'black'}}>
                                        Transactions
                                    </div>
                                    <div style={{marginRight: 12, marginLeft: 12, textTransform: 'uppercase', fontWeight: 'bold', color: 'black'}}>:</div>
                                    <div>
                                        {props.coin_in_scope.transactions}
                                    </div>
                                
                                </div>

                                <div style={{marginTop: 24, display: 'flex', alignItems: 'center'}} className="basier-p3">

                                    <div style={{textTransform: 'uppercase', fontWeight: 'bold', color: 'black'}}>
                                        Cost basis
                                    </div>
                                    <div style={{marginRight: 12, marginLeft: 12, textTransform: 'uppercase', fontWeight: 'bold', color: 'black'}}>:</div>
                                    <div>
                                        <span>
                                        <span>{props.currency_symbol}&nbsp;</span>
                                        {
                                            props.inBictoin ? 
                                            props.coin_in_scope.purchase_price.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                                            :
                                            props.coin_in_scope.asset_type === 'fiat' ? 
                                            props.coin_in_scope.purchase_price.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                            :
                                            props.coin_in_scope.purchase_price.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                                        }
                                        </span>
                                    </div>
                                
                                </div>

                                <div style={{marginTop: 24, display: 'flex', alignItems: 'center'}} className="basier-p3">

                                    <div style={{textTransform: 'uppercase', fontWeight: 'bold', color: 'black'}}>
                                        Total Profit
                                    </div>
                                    <div style={{marginRight: 12, marginLeft: 12, textTransform: 'uppercase', fontWeight: 'bold', color: 'black'}}>:</div>
                                    <div>
                                        <span>{props.currency_symbol}&nbsp;</span>
                                        <span>
                                        {
                                            props.inBictoin ? 
                                            props.coin_in_scope.total_profit.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                                            :
                                            props.coin_in_scope.asset_type === 'fiat' ? 
                                            props.coin_in_scope.total_profit.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                            :
                                            props.coin_in_scope.total_profit.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                                        }
                                        </span>
                                    </div>
                                </div>

                            <div style={{marginTop: 24, display: 'flex', alignItems: 'center'}} className="basier-p3">

                                <div style={{textTransform: 'uppercase', fontWeight: 'bold', color: 'black'}}>
                                    Unrealized Profit
                                </div>
                                <div style={{marginRight: 12, marginLeft: 12, textTransform: 'uppercase', fontWeight: 'bold', color: 'black'}}>:</div>
                                <div>
                                    <span>{props.currency_symbol}&nbsp;</span>
                                    <span>
                                    {
                                        props.inBictoin ? 
                                        props.coin_in_scope.active_profit.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                                        :
                                        props.coin_in_scope.asset_type === 'fiat' ? 
                                        props.coin_in_scope.active_profit.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                        :
                                        props.coin_in_scope.active_profit.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                                    }
                                    </span>

                                </div>

                            </div>

                        </div>

                        </div>


                        <div style={{width: '50%', padding: 12, height: '100%',}}>

                            <div className="basier-p4-caps">
                                Quick actions
                            </div>

                            <div style={{paddingLeft: 12}}>
                                
                                <div className="basier-p3 opacity-hover" style={{textTransform: 'uppercase', color: '#0078DB', cursor: 'pointer', marginTop: 24}} onClick={() => goToBaseTr(props.coin_in_scope.coin)}>View related Base Transactions</div>

                                <div className="basier-p3 opacity-hover" style={{textTransform: 'uppercase', color: '#0078DB', cursor: 'pointer', marginTop: 24}} onClick={() => goToQuoteTr(props.coin_in_scope.coin)}>View related Quote Transactions</div>

                                <div className={`basier-p3 ${roundBalance > 0 ? 'opacity-hover' : ''}`} style={{textTransform: 'uppercase', color: roundBalance > 0 ? '#F9716B' : '#6A7E93', cursor: roundBalance > 0 ? 'pointer' : 'default', marginTop: 24}} onClick={roundBalance > 0 ? () => confirm(props.coin_in_scope.coin, 'fee', props.currentPort, props.user_id, props.token) : null}>Clear entire balance as fee</div>
                                
                                <div className={`basier-p3 ${roundBalance > 0 ? 'opacity-hover' : ''}`} style={{textTransform: 'uppercase', color: roundBalance > 0 ? '#F9716B' : '#6A7E93', cursor: roundBalance > 0 ? 'pointer' : 'default', marginTop: 24}} onClick={roundBalance > 0 ? () => confirm(props.coin_in_scope.coin, 'lost', props.currentPort, props.user_id, props.token) : null}>Clear entire balance as loss</div>

                            </div>

                        </div>


                    </div>
                        
            </DialogContent>
        </div>

    )
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        currency: state.auth.currency.id,
        inBictoin: state.data.bitcoinPf === 'bitcoin',
        bitcoinPf: state.data.bitcoinPf,
        currentPort: state.data.menu_selected,
        user_id: state.auth.user_id, 
        token: state.auth.token, 
        currentView: state.data.currentView,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        showSnack: (data) => dispatch(interActions.showSnack(data)),
        setPfTab: (tab) => 
            dispatch(interActions.setPfTab(tab)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(assetModalContent));
