import React from "react";
import './Login.css'
import VectorBG from '../../Media/LoginVectorFinal.svg'
import VectorSmall from '../../Media/MobileBk.svg'
import CenterVector from '../../Media/LoginCenterVector.svg'
import LoginImage from '../../Media/LoginIllu.svg'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LoginForm from './LoginForm'
import Logo from '../../Media/Logo.svg'

class loginLand extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.email = React.createRef();
        this.pw = React.createRef();
        this.state = {
            forgot: false,
        }
      }

      toggleForgot = () => {
        this.setState(state => ({...state, forgot: !state.forgot}))
        }

      handleSubmit(event) {

        event.preventDefault();
      }
    
      componentDidMount() {
        document.body.style.backgroundColor = 'white'
      }

     componentWillUnmount() {
        window.scrollTo(0, 0);
        document.body.style.backgroundColor = null
     }
     
    render () {

    return (

        <React.Fragment>
            <div className="total-login-container" style={{display: 'flex', position: 'relative', width: '100%', overflowY: 'hidden', height: '1200px' }}>

                <div className="vector-bg-large" style={{zIndex: 10}}>
                    <img className="vector-svg" src={VectorBG} alt=""/>
                </div>
                <div className="vector-bg-small" style={{zIndex: 10}}>
                    <img className="small-svg" src={VectorSmall} alt=""/>
                </div>

                <div className="centered-container">
                    
                    <div>
                        <a href="https://wisly.io">
                            <div style={{width: '100%', height: 100, display: 'flex', alignItems: 'center', marginLeft: 24, cursor: 'pointer'}}>
                                <div style={{marginRight: 12}}>
                                    <svg width="12" height="21" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 13L1 7.00244L7 1.00244" stroke="#0078DB" stroke-miterlimit="10"/>
                                    </svg>
                                </div>
                                <img src={Logo} alt="" />
                            </div>
                        </a>
                        <div style={{display: 'flex'}}>
                        <div className="image-login">
                            <img className="" src={LoginImage} alt=""/>
                        </div>

                        
                        <div className="form-overall-container">

                            <div className="form-container">
                                
                                <div style={{width: '100%'}}>

                                    <div className="login-title">
                                        <h1 style={{fontSize: 40, lineHeight: '42px'}}>
                                            {this.state.forgot ? 
                                            'Forgot Password' : 
                                            'Log in'}
                                        </h1>
                                    </div>

                                    <LoginForm toggleForgot={this.toggleForgot} forgot={this.state.forgot}/>

                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>

                <div style={{marginTop: 0, zIndex: 900, display: 'block', position: 'relative'}}>
                    <footer style={{zIndex: 900}}>
                    <div style={{maxWidth: 1280, margin: '0 auto', padding: '0 15px'}} className='basier'>
                        <div className="footer-logo" style={{textAlign: 'center', position: 'relative', marginBottom: 20, display: 'flex', alignItems: 'center'}}>
                            <div className="line-logo"/>
                            <figure style={{display: 'inline-block', position: 'relative', zIndex: 9, padding: '0 40px'}}>
                                <img src="//wisly.hestawork.com/media/_sitelogosize/wisly_logo.svg" alt="footer-logo" />
                            </figure>
                            <div className="line-logo"/>
                        </div>

                        <div style={{display: 'flex', marginBottom: 50, justifyContent: 'space-between', flexWrap: 'wrap'}}>
                            <div class="footer-menu">
                                <h6>Communication</h6>
                                <ul className="login-ul">
                                    <li className="opacity-hover" style={{cursor: 'pointer'}}><a href="https://t.me/wislyio">Telegram</a>
                                    </li>
                                </ul>
                                </div>
                                    <div class="footer-menu">
                                        <h6>Read Us</h6>
                                        <ul style={{zIndex: 900}} className="login-ul">
                                            <li className="opacity-hover" style={{cursor: 'pointer', zIndex: 900}}><a href="https://medium.com/@wisly">Medium</a></li>
                                            <li className="opacity-hover" style={{cursor: 'pointer', zIndex: 900}}><a href="https://wisly.io/blog">Blog</a></li>
                                        </ul>
                                    </div>
                                    <div class="footer-menu">
                                        <h6>Features</h6>
                                        <div class="menu footer-menu">
                                            <ul className="login-ul">
                                                <li className="opacity-hover" style={{cursor: 'pointer'}} id="menu-item-33"><span class=""><a class="" href="https://wisly.io/#smart-features">Smart Features</a></span></li>
                                                <li className="opacity-hover" style={{cursor: 'pointer'}} id="menu-item-34"><a class="" href="https://wisly.io/features/collaboration">Collaboration</a></li>
                                                <li className="opacity-hover" style={{cursor: 'pointer'}} id="menu-item-35"><a class="" href="https://wisly.io/features/reports">Reports</a></li>
                                                <li className="opacity-hover" style={{cursor: 'pointer'}} id="menu-item-36"><a class="" href="https://wisly.io/features/profit">Profits</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="footer-menu">
                                        <h6>Links</h6>
                                        <div class="menu footer-menu">
                                            <ul className="login-ul">
                                                <li className="opacity-hover" style={{cursor: 'pointer'}} id="menu-item-37"><a class="" href="https://wisly.io/disclaimer">About Us</a></li>
                                                <li className="opacity-hover" style={{cursor: 'pointer'}} id="menu-item-38"><a class="" href="https://wisly.io/privacy-policy">Privacy Policy</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="footer-menu address-menu"><h6>Based In South Bank, London UK</h6><div class="menu contact-us"></div></div></div><div class="copyright"><p>2020, All rights reserved © Wisly</p></div></div>
                    </footer>
                </div>

            </div>

                
        </div>
            

        </React.Fragment>
        
    )
}
}

export default loginLand