import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import currencyData from '../../helpers/currency.json'

const initialState = {
    initialLoad: false,
    token: null,
    user_id: null, 
    expiry: null,
    user_first_name: null,
    user_last_name: null,
    default_portfolio_id: null,
    list_portfolios: null, 
    subscription: null,
    roles: null,
    currency: {
        id: null,
        symbol: null
    },
    user_preferences: {},
    error: null,
    loading: false
};

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
    });
};

const authCheck = (state, action) => {
    return updateObject(state, {
        error: null,
        initialLoad: true,
    });
};




const updateRoles = (state, action) => {
    return updateObject(state, {
        roles: action.data,
    });
}

const updateUser = (state, action) => {

    return {
        ...state,
        ...action.data, 
    }

}

const authSuccess = (state, action) => {

    let currency_symbol = null 
    const u_preferred_currency = action.data.preferred_currency === null ? "USD" : action.data.preferred_currency;
    try { currency_symbol = currencyData[u_preferred_currency]["symbol"]} catch (e) { currency_symbol = u_preferred_currency}

    return updateObject(state, {
        token: action.data.rahat_t,
        expiry: action.data.expirationDate,
        user_id: action.data.user_id,
        email: action.data.email,
        user_preferences: action.data.user_preferences,
        user_first_name: action.data.user_first_name,
        user_last_name: action.data.user_last_name, 
        default_portfolio_id: action.data.default_portfolio,
        list_portfolios: action.data.list_portfolios, 
        subscription: action.data.subscription, 
        roles: action.data.roles,
        currency: {
            ...state.preferred_currency, 
            id: u_preferred_currency, 
            symbol: currency_symbol, 
        },
        error: null,
        loading: false, 
        initialLoad: false,
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null
    });
};

const setViewCurrency = (state, action) => {
    
    let currency_symbol = null 
    
    const u_preferred_currency = action.data.preferred_currency === null ? "USD" : action.data.preferred_currency;
    try { currency_symbol = currencyData[u_preferred_currency]["symbol"]} catch (e) { currency_symbol = u_preferred_currency}

    return updateObject(state, {
        currency: updateObject(state.currency, {
            id: u_preferred_currency, 
            symbol: currency_symbol
        })
    });
}

const setBtcSymbol = (state, action) => {
    
    return updateObject(state, {
        currency: updateObject(state.currency, {
            symbol: action.data
        })
    });
}

const updateUserPref = (state, action) => {
    
    return updateObject(state, {
        list_portfolios: action.data.portfolios, 
        user_preferences: action.data.preferences, 
        subscription: action.data.subscription
    });
}

const updatePortfolios = (state, action) => {
    
    return updateObject(state, {
        list_portfolios: action.data, 
    });
}

const updateSinglePref = (state, action) => {
    
    return updateObject(state, {
        user_preferences: action.data, 
    });
}



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_CHECK:
                return authCheck(state, action); 
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.SET_VIEW_FIAT: 
            return setViewCurrency(state, action);
        case actionTypes.USER_PREF: 
            return updateUserPref(state, action)
        case actionTypes.BTC_SYMBOL: 
            return setBtcSymbol(state, action)
        case actionTypes.UPDATE_PORTS: 
            return updatePortfolios(state, action)
         case actionTypes.UPDATE_PREFS: 
            return updateSinglePref(state, action)
        case actionTypes.UPDATE_USER: 
            return updateUser(state, action)
        case actionTypes.UPDATE_ROLES: 
            return updateRoles(state, action)
        default:
            return state;
    }
};

export default reducer;