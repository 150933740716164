import React from 'react';
import Grid from '@material-ui/core/Grid';
import seeMore from '../../../../../Media/seeMore.svg'
import warning from '../../../../../Media/warning.svg'
import currencyDict from '../../../.././../helpers/currency.json'
import Tooltip from '@material-ui/core/Tooltip';

const assetDetail = (props) => 

{

    const onClick = (e) => {
        console.log('clicking')
        e.preventDefault();
    }

    return (

    <Grid
        onContextMenu={onClick}
        container style={{ 
                        height: 60,
                        paddingLeft: 24,
                        marginTop: 8, 
                        marginBottom: 8, 
                        paddingRight: 24, 
                        boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                        borderRadius: 1035, 
                        width: '100%',
                        display: 'flex', 
                        alignItems: 'center',
                        }} 
                        index={props.index}
                        className="asset-line basier-p3">
                
                
                <Grid item style={{width: '28%', display: 'flex', alignItems: 'center'}} >
                
                {props.item.image_large !== "" && props.item.image_large !== null ? 
                <span><img style={{height: 25, width: 25, marginRight: 12}} src={props.item.image_large} alt=""/></span>
                :
                <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
                    {currencyDict[props.item.coin_symbol] === undefined ? null : currencyDict[props.item.coin_symbol]['symbol'].length > 1 ? null : currencyDict[props.item.coin_symbol]['symbol'] }
                </span>
                }
                
                <span>
                    <div style={{marginTop:2, marginBottom: 2}}>{props.item.coin_symbol}</div>
                    <div className="basier-p4-caps white-hover" style={{color: '#6A7E93'}}>{props.item.short_name}</div>
                </span>
                
                </Grid> 

                <Grid item style={{width: '13%', textAlign: 'left'}} >
                    <div className="percent-container" style={{display: 'inline-block', position: 'relative'}}>
                        <div className="percent-amount" style={{display: 'inline-block', position: 'absolute', left: 0, width: `${props.item.proportion*100}%`, }} />
                    </div>
                </Grid> 


                <Grid item style={{width: '16%', textAlign: 'right' }} >
                
                <div style={{
                            paddingTop: 4, 
                            marginBottom: 2,}}>
                    {  props.item.error  &&
                    <span>
                        <Tooltip title={`You have sold or used ${props.item.oversold > 1 ? props.item.oversold.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : props.item.oversold <= 1 ? props.item.oversold.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8}) : ''} more ${props.item.coin_symbol} than you own, your actual balance should hence be different. Please go to the portfolio settings to find out more about transaction errors and fix them.`} aria-label="read">
                            <img src={warning} alt="" style={{height: 20, marginRight: 12}} />
                        </Tooltip>
                    </span>
                    }
                    <span>
                        {
                        
                        props.errors === true ? 
                            props.item.balance == 0 ? 


                            (-1 * props.item.oversold).toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8}) 


                            :



                            props.item.balance < -1 ? 
                            props.item.balance.toLocaleString('en', {maximumFractionDigits: 4, minimumFractionDigits: 4}) 
                            :
                            props.item.balance.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8}) 

                        :   
                            props.item.balance > 1 ? 
                            props.item.balance.toLocaleString('en', {maximumFractionDigits: 4, minimumFractionDigits: 4}) : 
                            props.item.balance.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8}) 
                        }
                    </span>
                </div>

                
                </Grid> 
                
                
                <Grid item style={{width: '16%', textAlign: 'right' }} >
                <span>

                    <div style={{paddingTop: 4, 
                            marginBottom: 2,}}>
                    {props.currency_symbol} {props.item.total_value.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                    </div>

                </span>
                </Grid> 
                <Grid item style={{width: '16%', textAlign: 'right' }} >

                    <div style={{paddingTop: 4, 
                        marginBottom: 2,}}>

                    {props.currency_symbol}&nbsp;
                    {props.item.current_price > 1 ? 
                        props.item.current_price.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                        : 
                        props.item.current_price.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }

                    </div>
                    
                    <div
                    className="basier-p4 white-hover"
                    style = {{
                        width: 65, 
                        fontStyle: 'italic',
                        display: 'inline-block',
                        color: `${props.item.change_24h > 0 ? '#4CDAC1' : props.item.change_24h < 0 ? '#F9716B' : '#6A7E93'}`
                    }}>
                        {props.item.asset_type === 'crypto' ? `${props.item.change_24h > 0 ? "+" : ""}${props.item.change_24h.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}%` : '-' }
                    </div>
                    
                </Grid>

                <Grid item style={{width: '10%', textAlign: 'right' }} >
                
                    <div style={{
                        borderRadius: 35, 
                        height: 27, 
                        textAlign: 'right'
                        }}
                        className={`roi-container ${props.status === 'exit' ? 'neutral-bk' : props.roi >= 1 ? 'green-bk' : props.roi < 1 ? 'red-bk' : 'neutral-bk'}`}>
                        
                        {
                            props.status === 'exit' ?  

                            "-"
                            :

                            `${props.roi.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}x`

                        }
                        
                        </div>

                </Grid> 
                <div value={props.item.coin} onClick={props.handleModalOpen} className="see-more-icon" style={{position: 'absolute', right: `${props.contained ? '-5px' : '39px'}`}}><img src={seeMore} alt=""/></div>

            </Grid>

    )

}

export default assetDetail;


