import React from 'react';
import { connect } from 'react-redux';
import listIcon from '../../../../../Media/listIcon.svg'
import PositionTable from './PositionTableStraight';

class LargePositions extends React.PureComponent {

    state = {
        open: [], 
        closed: [],
    }
    componentDidMount() {
        if (this.props.portfoliosData[this.props.portfolioId] !== undefined && this.props.portfoliosData[this.props.portfolioId] !== null ) {
            const open = this.props.portfoliosData[this.props.portfolioId].positions.filter(v => v.is_open === true)
            const close = this.props.portfoliosData[this.props.portfolioId].positions.filter(v => v.is_open === false)

            this.setState(state => ({...state, open: open, closed: close}))
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.portfoliosData[this.props.portfolioId] !== undefined && this.props.portfoliosData[this.props.portfolioId] !== null && prevProps.portfoliosData[this.props.portfolioId] !== this.props.portfoliosData[this.props.portfolioId] ) {
            const open = this.props.portfoliosData[this.props.portfolioId].positions.filter(v => v.is_open === true)
            const close = this.props.portfoliosData[this.props.portfolioId].positions.filter(v => v.is_open === false)

            this.setState(state => ({...state, open: open, closed: close}))
        }

    }

    render () {

        return (
            <div>
                <div style={{display: 'flex', alignItems: 'flex-end', backgroundColor: 'white', 'boxShadow': 'none', height: 30, paddingBottom: 12, marginTop: 30, marginBottom: 12, borderBottom: '1px solid #E1E5E9', width: '100%'}}>
                
                    <div
                        style={{display: 'flex', alignItems: 'baseline', paddingLeft: 50, paddingRight: 50, color: '#6A7E93', width: '100%'}}
                    > 
                
                        <div style={{width: '19%' }}>
                            <div className="basier-p3"  style={{textTransform: 'uppercase'}}>
                                Contract
                            </div>
                        </div> 



                        <div style={{width: '12%', textAlign: 'left'}}>
                            <div className="basier-p3"  style={{textTransform: 'uppercase'}}>
                                Type
                            </div>
                        </div> 




                        <div style={{width: '19%', textAlign: 'right'}}>
                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Size (Base)
                            </div>

                            <div className="basier-p4-caps">
                                Size (Quote)
                            </div>
                        </div> 



                        <div  style={{width: '21%', textAlign: 'right'}}>
                            
                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Last Price
                            </div>

                            <div className="basier-p4-caps">
                                Open Price
                            </div>

                        </div> 



                        <div  style={{width: '21%', textAlign: 'right'}}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                P&L (Quote)
                            </div>
                            <div className="basier-p4-caps">
                                Inerse P&L (base)
                            </div>

                        </div> 


                        <div  style={{width: '8%', textAlign: 'right', paddingRight: 12}}>
                        
                            <div className="basier-p4-caps">
                                
                            </div>
                        
                        </div> 

                    </div>

                </div>

                <div style={{width: '100%'}}>

                    <div style={{marginLeft: 48, marginRight: 48, marginTop: 0}}>
                        
                        {this.props.portfoliosData[this.props.portfolioId] !== undefined ?

                            this.props.portfoliosData[this.props.portfolioId].positions.length > 0 ? 

                                <PositionTable open={this.state.open} closed={this.state.closed} portfolioId={this.props.portfolioId} />
                            
                            :

                            <div style={{height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12}}>
                                <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column'}}>
                                    <div>
                                        <img src={listIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any positions in your portfolios yet.</div>
                                        <div>Once you add positions you can see them here.</div>
                                    </div>
                                </div>
                            </div>

                        : 
                        null 
                        }
                    
                    </div>

                </div>
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LargePositions)
