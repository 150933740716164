import React from 'react';
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import * as actions from "../../../store/actions/interactions";
import AuditCompare from './ModalComps/AuditCompare';
import fieldValidator from '../../../helpers/validators'

const scopeTr = (scope) => {
    switch(scope) {

        case 'transaction': {
            return 'Transaction record'
        }

        case 'portfolio': {
            return 'Portfolio record'
        }

        case 'upload': {
            return 'Upload record'
        }

        case 'wallet': {
            return 'Wallet record'
        }

        case 'exchange': {
            return 'Exchange Account record'
        }

        default: {
            return 'Audit record'
        }
    }
}

class AddWalletModalView extends React.PureComponent {

    state = {
        data: {
            user_id: this.props.user_id
        },
        validation: {
            exchange: true, 
        }
    }

    updateData = (label, val) => {

        this.setState(state => ({...state, data: {...state.data, [label]: val}}) )

    }

    updateServer = () => {
       
        const exchange_val = fieldValidator(this.state.data['exchange'], ['isNotEmpty'])
        this.setState(state => ({...state, validation: {...state.validation, exchange: exchange_val}}))

        if (exchange_val) {
            console.log(this.state.data)
        } else {
            console.log('invalid form data')
        }
        

    }
    
    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
      }

    compRenderer = (scope) => {
        switch(true) {
            case (['transaction', 'portfolio', 'wallet', 'exchange'].includes(scope)): 
                return <AuditCompare 
                    data={this.props.data}
                    scope={this.props.scope}
                    handleClose={this.handleClose} />
            default:
                return null
        }

    }
    render () {

        return (

            <div>

                <BaseModalTop forceSingle={true} title={`${scopeTr(this.props.scope)}`} handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />

                <div style={{padding: 48, width: '100%', display: 'flex'}}>
                    {this.compRenderer(this.props.data.scope)}
                </div>    
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddWalletModalView)
