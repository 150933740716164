import React from 'react';
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import axios from 'axios';
import { backendUrl } from '../../../variables';
import SingleCollabList from '../../AppWide/SingleCollabList';
import RoleList from '../../Portfolio/PortfolioComponents/Settings/Large/RoleList';
import addCollabBK from "../../../Media/AddCollab.svg"
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import SelectUserDrawer from './ModalComps/SelectUserDrawer'
import {loadData} from '../../../helpers/helpers';
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router';
import Tooltip from '@material-ui/core/Tooltip';


const styles = theme => ({
    container: {
        width: '100%', 
        padding: 48, 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 12, 
        },
    },
    handheld: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 13
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 15
        },
    },
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0, paddingRight: 0, 
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 48, paddingRight: 48, 
        },
       
    },
    firstContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 24
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 48
        },
    },
    smallMargin: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 12, marginRight: 12,
        }, 
    },
    sencondCointainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
    },
    firstWidth: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 324, justifyContent: 'space-between',
        },
        [theme.breakpoints.up('md')]: {
            display: 350, height: 350, justifyContent: 'space-between',
            paddingLeft: 24, paddingRight: 24, 
        },
    },
})
const generateUserRoles = (plan, numberManagers, limit) => {

    if (numberManagers == limit) {
        return [
            {value: "observer", label: "OBSERVER"},
        ]
    }
    console.log('the plan is', plan)
    switch(true) {
        case (plan === 'pro') || (plan === 'unlimited'): {
            return [
                {value: "admin", label: "ADMIN"}, 
                {value: "manager", label: "MANAGER"},
                {value: "observer", label: "OBSERVER"},
            ]
        }
        default: {
            return [
                {value: "manager", label: "MANAGER"},
                {value: "observer", label: "OBSERVER"},
            ]
        }
    }

}


class WalletDetail extends React.PureComponent {

    state = {
        edit: false,
        newAddress: null, 
        newName: null,
        balances: null,
        address: this.props.data.address,
        name: this.props.data.name
        }
    
    componentDidMount() {
        this.onMount()
    }

    toggleEdit = () => {
        this.setState(state => ({...state, edit: !state.edit}))
    }

    updateDetails = () => {

        const address = this.state.newAddress !== null ? this.state.newAddress : undefined
        const name = this.state.newName !== null ? this.state.newName : undefined

        if(address !== undefined || name !== undefined) { 
            const postData = {
                name: name, 
                address: address, 
                user: this.props.user_id,
                id: this.props.scope,
            }

            let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {

                    headers['Authorization'] = `Token ${this.props.token}`

                }

            const url_call = `${backendUrl}/v1/wallets/`

            axios.patch(url_call, postData, {headers: headers})
                .then(json => {

                this.props.getWallets(this.props.user_id)
                if (address !== undefined) {
                    this.setState(state => ({...state, address: address}))
                } 
                if (name !== undefined) {
                    this.setState(state => ({...state, name: name}))
                }

                this.setState(state => ({...state, edit: false}))
                //this.setState(state => ({...state, loading: false, success: true}))
                    
                })
                .catch( e => {

                //this.setState(state => ({...state, loading: false, error: true}))

                })
            
            }
        }

    goToTr = (pfId, coin) => {

        if (this.props.type == 'exchange') {
            this.props.history.push(`/portfolio/${pfId}/transactions?base_id=${coin}&account=${this.props.data.account_id}`)
        } else if (this.props.type == 'storage') {
            this.props.history.push(`/portfolio/${pfId}/transactions?base_id=${coin}&wallet=${this.props.scope}`)
        }
        this.handleClose()

    }

    updateData = (label, val) => {
    }

    onMount = () => {

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const data_fetch = loadData(`${backendUrl}/v1/wallet/${this.props.scope}/balances/?u=${this.props.user_id}`, headers)
        data_fetch
            .then(json => {

                this.setState(state => ({...state, balances: json['data']}))

        })

        //this.setState(state => ({...state, userRole: this.state.managers === this.props.data.portfolio_limit ? 'observer' : 'manager'}))
    }

    deleteHandler = () => {
        if (window.confirm("Are you sure you want to delete this wallet")) {
            this.deleteTransaction()
          } 
    }

    deleteTransaction = () => {

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/wallets/${this.props.scope}`

        axios.delete(url_call, {headers: headers})
            .then(json => {

                // load new wallet list
                this.props.getWallets(this.props.user_id)
                //console.log(json['data'])

            })

        this.handleClose()
    }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
        document.body.ontouchmove = (e) => { 
            return true;
        }


      }
    

    handleDelete = () => {
        //this.setState(state => ({...state, addedUser: null}))
    }

    changeValue = (label, e) => {
        e.persist();
        this.setState(state => ({...state, [label]: e.target.value}))
    }

    goToPf = (id) => {

        this.props.history.push(`/portfolio/${id}`)
        this.handleClose()

    }
 
    render () {

        const { classes } = this.props;

        return (

            <div style={{zIndex: 99999999}}>

                <BaseModalTop forceSingle={true} title="Wallet details" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />
                <div className={classes.root} style={{paddingTop: 48, paddingBottom: 24}}>

                    <div style={{width: '100%'}}>

                        <div style={{display: 'flex', justifyContent: 'space-between', height: 120,  alignItems: 'center'}}>
                            {this.props.type === 'storage' ?
                            <div style={{display: 'flex', alignItems: 'center',}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={this.props.data.coin_image} style={{height: 50, width: 50, marginRight: 12}} alt="" />
                                    {this.state.edit ?
                                    <div style={{width: 300}}>
                                        <div style={{width: 300}}>
                                        <TextField
                                            className="custom-input custom-input-email"
                                            value={this.state.newName}
                                            onChange={(val) => this.changeValue('newName', val)}
                                            label="Name"
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        </div>
                                        <div style={{marginTop: 12, width: 300}}>
                                        <TextField
                                            className="custom-input custom-input-email"
                                            value={this.state.newAddress}
                                            onChange={(val) => this.changeValue('newAddress', val)}
                                            label="Address"
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <h4 style={{textTransform: 'uppercase', marginBottom: 0}}>{this.state.name}</h4>
                                        <div className="basier-p3">{this.state.address}</div>
                                    </div>
                                    }
                                </div>
                            </div>
                            :
                            <div style={{display: 'flex', alignItems: 'center',}}>
                                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                                    <img src={this.props.data.coin_image} style={{height: 50, width: 50, marginRight: 12}} alt="" />
                                    <div>
                                        <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 0}}>
                                            <h4 style={{textTransform: 'uppercase', margin:0}}>{this.props.data.coin_name}</h4>
                                        </div>
                                        <div className="basier-p3" style={{marginLeft: 0, textTransform: 'uppercase', fontStyle: 'italic'}}>
                                            <span>
                                                {this.props.data.account !== null && this.props.data.account !== undefined ? 
                                                this.props.data.account
                                                :
                                                null
                                                }
                                            </span>
                                            <span style={{marginLeft: 6, marginRight: 6}}>
                                                -
                                            </span>
                                            <span>
                                                {this.props.data.exchange !== null && this.props.data.exchange !== undefined ? 
                                                this.props.data.exchange
                                                :
                                                null
                                                }
                                            </span>
                                        </div>
                                        {this.state.edit ? 
                                        <div style={{marginTop: 12, width: 300}}>
                                        <TextField
                                            className="custom-input custom-input-email"
                                            value={this.state.newAddress}
                                            onChange={(val) => this.changeValue('newAddress', val)}
                                            label="Address"
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        </div>
                                        :
                                        <div className="basier-p3">{this.state.address}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            }
                            <div style={{paddingRight: 24}}>
                                {
                                    
                                    this.state.edit ? 

                                    <div className="small-center" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'wrap',}}>

                                        <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, cursor: 'pointer', marginRight: 12, marginTop: 12}} onClick={() => this.updateDetails()}>
                                        Save
                                        </button>

                                        <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, cursor: 'pointer', backgroundColor: '#F9716B', marginTop: 12, marginRight: 12,}} onClick={() => this.toggleEdit()}>
                                            Cancel
                                        </button>

                                    </div>

                                    :

                                    <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, marginTop: 12,  cursor: 'pointer', marginRight: 12,}} onClick={() => this.toggleEdit()}>
                                        Edit Details
                                    </button>

                                } 
                            </div>
                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 0}}>
                        <div style={{flex: 1, height: 300, backgroundColor: 'rgba(243, 245, 246, 0.5)', padding: 24}}>
                            <div className="basier-p4-caps" style={{color: 'black'}}>
                                Balances
                            </div>
                            
                            <div style={{width: '100%', marginTop: 12}}>
                                {this.state.balances !== undefined && this.state.balances !== null && this.state.balances.length > 0 ? 
                                    <React.Fragment>
                                        <div style={{display: 'flex', alignItems: 'basline', justifyContent: 'space-between', width: '100%', marginBottom: 0}} className="basier-p4">

                                            <div style={{flex: 4}}>Portfolio</div>
                                            <div style={{flex: 3}}>Balance</div>
                                            <div style={{flex: 2}}></div>

                                        </div>

                                        <div style={{width: '100%', height: 250, overflowY: 'scroll'}} className="basier-p4">

                                            {this.state.balances.map((val, key) => {

                                                return (

                                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 30}}>
                                                        <div style={{flex: 4}}>{val.portfolio_name}</div>
                                                        <div style={{flex: 3}}>
                                                            {
                                                                val.balance > 1 || (val.balance * 10000000) < 0.5 ? 
                                                                val.balance.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                                                :
                                                                val.balance.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8})
                                                            }
                                                        </div>
                                                        <div onClick={() => this.goToTr(val.portfolio, this.props.data.coin_ticker)} className="opacity-hover" style={{flex: 2, color: '#0078DB', cursor: 'pointer'}}>Transactions ></div>
                                                    </div>

                                                )

                                            })}
                                            
                                        

                                        </div>
                                    </React.Fragment>

                                :

                                <div className="basier-p4" style={{fontStyle: 'italic'}}>No transactions have been entered with this wallet yet. In order to update the wallet balance please enter or edit the relevant transactions specifying this wallet as its destination.</div>
                                }

                            </div>
                        </div>

                        <div style={{flex: 1, height: 300, padding: 24}}>
                            <div className="basier-p4-caps" style={{color: 'black'}}>
                                Actions
                            </div>

                            <div style={{width: '100%', height: 250, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            
                            <div>
                                {this.props.data.explorer !== null && this.props.data.explorer !== undefined && this.state.address !== null && this.state.address !== undefined && this.state.address !== '' ?
                                    <a href={`${this.props.data.explorer}${this.state.address}`} target="_blank" rel="noopener noreferrer">
                                        <button className={`opacity-hover basier-p4-caps`} style={{border: 'none', width: '100%', height: 40, borderRadius: 10, cursor: 'pointer', marginTop: 12, color: 'white', backgroundColor: '#0078DB'}}>
                                            See in Block Explorer
                                        </button>
                                    </a>
                                    :
                                    <Tooltip title={`${this.props.data.explorer === null || this.props.data.explorer === undefined? 'The explorer is not available for this coin.' : 'You need to specify a wallet address to use the explorer.'}`} aria-label="read" classes={{
                                        popper: classes.popper
                                    }}>
                                    <button className={`opacity-hover basier-p4-caps`} style={{border: 'none', width: '100%',   height: 40, borderRadius: 10, cursor: 'not-allowed', marginTop: 12, color: 'white', backgroundColor: '#E1E5E9'}}>
                                        See in Block Explorer
                                    </button>
                                    </Tooltip>
                                }
                            </div>

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                                <div style={{width: '50%', borderBottom: '1px solid #CCCCCC'}}/>
                            </div>

                            <button className={`opacity-hover basier-p4-caps`} style={{border: 'none', width: '100%', height: 40, borderRadius: 10, cursor: 'pointer', marginTop: 12, color: 'white', backgroundColor: '#F9716B'}} onClick={() => this.deleteHandler()}>
                                Delete Wallet
                            </button>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token,
        orgs: state.appwide.orgs
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(WalletDetail)));
