import React from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/auth";
import loadingSpinner from '../../Media/contentLoader.svg'
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import currencyData from '../../helpers/currency.json';
import {nFormatter, formattedNumber} from '../../helpers/helpers';
const moment = require('moment-timezone');

/* (function(H) {
    H.Pointer.prototype.reset = function() {
      return undefined;
    };
  
    H.Point.prototype.highlight = function(event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      //this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
  
    H.syncExtremes = function(e) {
      var thisChart = this.chart;
  
      if (e.trigger !== "syncExtremes") {
        // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function(chart) {
          if (chart && chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: "syncExtremes"
              });
            }
          }
        });
      }
    };
  })(Highcharts); */

const positionMarker = (series) => {

    var chart = series.chart;
    var lastPoint = series.points[series.points.length - 1];
    if (lastPoint !== undefined) {
        chart.pulseMarker.attr({
            x: lastPoint.plotX - chart.plotLeft - chart.spacing[0],
            y: lastPoint.plotY + chart.plotTop + chart.spacing[2] - 3
        }, true);
    }
}

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };

const arrowDownSvg = 
  `<svg width="24" height="24" viewBox="12 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d)">
  <rect x="10" y="0.00415039" width="24" height="24" rx="5" fill="white"/>
  </g>
  <path d="M26.2664 13.0848L21.7471 17.6041L17.1997 13.0848" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
  <path d="M21.7473 17.6042V6.40417" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
  <defs>
  <filter id="filter0_d" x="0" y="0.00415039" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
  <feOffset dy="10"/>
  <feGaussianBlur stdDeviation="5"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.882353 0 0 0 0 0.898039 0 0 0 0 0.913725 0 0 0 0.3 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
  </filter>
  </defs>
  </svg>`

const arrowUp = 
`<svg width="24" height="24" viewBox="12 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<rect x="10" width="24" height="24" rx="5" fill="white"/>
</g>
<path d="M18 10.3684L22.4861 5.88234L27 10.3684" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
<path d="M22.4854 5.88235L22.4854 17" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
<defs>
<filter id="filter0_d" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="10"/>
<feGaussianBlur stdDeviation="5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.882353 0 0 0 0 0.898039 0 0 0 0 0.913725 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>`

class BalanceGraph extends React.PureComponent {

    constructor(props) {

        super(props);
        this.state = {
            dateSelected: null,
            chart: null,
        }

    }

    handleBTCChange = e => {

            
        const new_scope = e.target.value;
        //console.log(`new scope is ${new_scope}`)
        const globalData = {
            period: this.props.timePeriod,
            start: this.props.data.start,
            end: this.props.data.end, 
            scope: new_scope
        }
        
        this.props.setPfScope(new_scope)
        this.props.updateGraph('update', this.props.currentView, this.props.currency, this.props.user, this.props.portfolio, globalData, globalData, e.target.value)
        if (new_scope === 'bitcoin') {
            this.props.setBtcSymbol('₿')
        } else {
            let currency_symbol = null;
            try { currency_symbol = currencyData[this.props.currency_name]["symbol"]} catch (e) { currency_symbol = this.props.currency_name}
            this.props.setBtcSymbol(currency_symbol)
        }
        
    }

    render () {

    const component = this
    const { classes } = this.props;
    const start_date = moment.tz(this.props.start_date, 'UTC')
    let data_date = Date.UTC(start_date.year(), start_date.month(), start_date.date())
    const symbol_to_use = this.props.coinGraph === true ? "$" : this.props.currencyScope === 'bitcoin' ? "₿" : this.props.currency_symbol

    const options = {
        chart: {
            type: 'area', 
            backgroundColor: this.props.background,  
            animation: false,
            marginRight: 74,
            events: {
                load: function() {
                    var chart = this;
                    
                    chart.pulseMarker = this.renderer.text('<div class="ring-container"><div class="ringring"></div><div class="circle"></div></div>', chart.chartWidth - 50, -208, true).add();

                    // set up the updating of the chart each second
                    var series = this.series[0];
                    // change the position of pulse marker	
                    try {		
                    positionMarker(series);
                    } catch(e) {}

                }, 
                redraw: function() {
                    var series = this.series[0];
                    // change the position of pulse marker					
                    try {		
                    positionMarker(series);
                    } catch(e) {}

                }
            }
        },
        credits: false,
        title: false,
        xAxis: {
            className: 'basier-p4-caps',    
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            type: 'datetime',
            minPadding:0,
            maxPadding:0,
            tickLength: 0,
            tickColor: 'black',
            labels: {
                padding: 30,
                style: {
                    fontSize: '12px',
                },
            }

        },
        yAxis: {
            className: 'basier-p4 graph-labels-x',
            title: {
                text: false,
            },
            align: 'right',
            minorGridLineWidth: 1,
            gridLineWidth: 1,
            gridLineColor: '#E1E5E9',
            labels: {
                x: 55, 
                style: {
                    fontSize: '12px',
                    textAlign: 'right',
                    align: 'left'
                },
                formatter: function() {
                    return nFormatter(this.value)
                }
            }
            
        },
        series: [{
            name: 'Portfolio value in USD',
            data: this.props.data,
            pointStart: data_date,
            pointInterval: 24 * 3600 * 1000,
            color: '#0078DB',
            lineWidth: 1.5,
            marker: {
                fillColor: "#fff", 
                lineColor: "#0078DB", 
                lineWidth: 2,
            }
        }],
        legend: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        exporting: {
            enabled: false,
        },
        plotOptions: {
            series: {
                animation: false, 
                marker: {
                    enabledThreshold: 3
                },
            },
            area: {
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1},
                    stops: [
                        [0, "rgba(0, 120, 219, 0.22)"],
                        [1, "rgba(0, 120, 219, 0)"]
                    ]
                },
            },
        },
        responsive: {
            rules: [{
                condition: {
                    minWidth: 400
                },
                chartOptions: {
                    chart: {
                        height: 450
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    }
                }
            }]
        },
        tooltip: {
        
        backgroundColor: '#fff',
        height: 200,
        borderRadius: 14,
        borderWidth: 0,
        shadow: true,
        padding:12,
        style: {
            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
            height: '108px', 
            width: '186px',
        },
        useHTML: true,
        formatter: function () {

            let changePrevious = 0
            try{ changePrevious= this.y / this.points[0].series.data[this.points[0].point.index -1].y } catch(e) {}
            let changeVue = null;
            if (changePrevious < 1) {
                changeVue = arrowDownSvg
            } else if (changePrevious > 1) {
                changeVue = arrowUp
            } else { 
                changeVue = ''
            }

            const val = this.y > 1 ? 2 : 6
            let pointformat = null;
            let momentDate = moment.tz(this.x, 'UTC')
            let currentDateUTC = moment.utc()
            if (
                momentDate.year() === currentDateUTC.year() && 
                momentDate.month() === currentDateUTC.month() && 
                momentDate.date() === currentDateUTC.date() 
                ) 
            {

                let hourShow = currentDateUTC.hours()
                hourShow = ("0" + hourShow).slice(-2);
                let minuteShow = currentDateUTC.minutes()
                minuteShow = ("0" + minuteShow).slice(-2);

                pointformat = `${Highcharts.dateFormat('%e %b %Y',new Date(this.x))} ${hourShow}:${minuteShow} UTC`
            } else {
                pointformat = `${Highcharts.dateFormat('%e %b %Y',new Date(this.x))} 23:59 UTC`
            }
            


            return "<span class='tooltip-wrapper'><span class='tooltip-main-text'><span class='image-tooltip'>" + changeVue + "</span><h4 class='tooltip-title'> " + symbol_to_use + " " + this.y.toLocaleString('en', {minimumFractionDigits:val, maximumFractionDigits: val}) + "</h4></span><span class='tooltip-divider'></span><br /><span class='date-tooltip'><span class='basier-p4 date-text-span'>" + pointformat + "</span></span>"
                }
        }, 
        rangeSelector: {
            enabled: true, 
            buttonPosition: {
                align: 'right', 
            }, 
            selected: 4,
            inputEnabled: false, 
            buttonSpacing: 12, 
            buttonTheme: { // styles for the buttons
                fill: 'none',
                stroke: 'none',
                'stroke-width': 0,
                r: 8,
                style: {
                    color: '#6A7E93',
                    fontSize: 16, 
                },
                states: {
                    hover: {
                        fill: 'none',
                        style: {
                            color: '#0078DB'
                        },
                    },
                    select: {
                        fill: 'none',
                        style: {
                            color: '#0078DB',
                            fontWeight: 'bold',
                        }
                    }, 
                    // disabled: { ... }
                }
            },
            labelStyle: {
                color: 'white',
                display: 'none',
            },
            scrollbar: {
                enabled: false,
            },
        },
    } 
     
        return (
        
        <div>
            { this.props.coinGraph === true ? 
            null
            :
            <Grid container 
                spacing={0}
                justify="space-between"
                alignItems="center"
            >
                    <Grid item style={{
                        marginLeft: 48,
                        display: 'flex', 
                        alignItems: 'center'
                    }}> 
                        <div style={{display: 'inline-block'}}>
                            <div 
                                className="basier-p4-caps"
                                style={{color: '#6A7E93',
                                        display: 'inline-block'}}
                                >
                                Total Portfolio Balance
                            </div><br />
                             <h2 style={{display: 'inline-block'}}>{symbol_to_use} 
                             {
                               formattedNumber(this.props.today_value)
                            } 
        </h2>
                        </div>

                        <span style={{  marginLeft: 25, 
                                        width: 170,
                                        height: 42, 
                                        paddingLeft: 10,
                                        display: 'inline-flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center',
                                        }}
                            className="white-button"
                            >
                            
                            <div className="form-check form-check-inline sp-l-12">

                                <RadioGroup
                                    name="btcScope"
                                    value={this.props.inBitcoin}
                                    onChange={this.handleBTCChange}
                                    style={{display: 'inline-block'}}

                                >                              
                                    <FormControlLabel value="fiat" 
                                                      control={<Radio classes={{
                                                          root: classes.root,
                                                          checked: classes.checked
                                                      }}/>} 
                                                      label={this.props.currency_name} />
                                    <FormControlLabel value="bitcoin" 
                                                      control={<Radio classes={{
                                                          root: classes.root,
                                                          checked: classes.checked
                                                      }} />} 
                                                      label="BTC" />

                            
                                </RadioGroup>

						    </div>
                            </span>

                    </Grid>
                </Grid>
                }

                <div style={{width: '100%', marginLeft: -10, paddingRight: 36, position: 'absolute', height: 450}}>
                { ( this.props.loadedGraph === true) ?  <div className="loader-graph"><img style={{height: 100, with: 100}} src={loadingSpinner} alt="Loading..."/></div> : null }
                </div>

                <div style={{marginLeft: this.props.coinGraph ? 0 : -10, width: '100%'}}>
                    <HighchartsReact
                            highcharts={Highcharts}
                            constructorType={'stockChart'}
                            containerProps={{ style: { height: "448px", width: '100%' } }}
                            options={options}
                            oneToOne={true}
                            style={{height: 600}}
                    />
                </div>

        </div>

            
            
        )

    }


        
};

BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf, 
        portfolioValue: state.data.totalPortValue,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setBtcSymbol: (s) =>
            dispatch(actions.setBtcSymbol(s)), 
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));


