import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import Logo from '../../Media/Logo.svg'
import Hidden from '@material-ui/core/Hidden';
import MobileMenu from './DrawerMenu'
import PortfolioSelector from './LayoutComponents/PortfolioList'
import CurrencySelector from './LayoutComponents/CurrencyList'
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import UserIcon from '../../Media/User-1.png'
import notifIcon from '../../Media/notif.svg'
import UserMenu from './LayoutComponents/UserMenu'
import SmallMenu from './LayoutComponents/ResponsiveTopLinks'
import NewSearch from '../AppWide/CoinSearch'
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import * as appwideActions from "../../store/actions/appwide";
import { withRouter} from 'react-router-dom';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Badge from '@material-ui/core/Badge';
import axios from 'axios'; 
import {backendUrl} from '../../variables';

const getter = (dict, key) => {

    if (dict !== undefined) {
        if (dict[key] !== undefined) {
            return dict[key]
        }
    } 
    return 0

}

const styles = theme => ({
    overall: {
        paddingLeft: '24px', 
        paddingTop: '38px',
        backgroundColor: '#F8F9FA',
        boxShadow: 'none',
        height: 130,
    }, 
    badge: {
        marginTop: 8, 
        marginRight: 48,
    },
    colorbadge: {
        background: '#F9716B',
    },
    appbar: {
        height: 50,
    }, 
    dropdown: {
        borderRadius: 7,
        backgroundColor: '#FFFFFF',
        height: 49, 
        width: 232,
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: '50px'

    },
    links: {
        lineHeight: '50px'
    },
    
    root: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        
    },
    search: {
        position: 'relative',
        borderRadius: '100px',
        backgroundColor: '#FFFFFF',
        '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
        marginLeft: 0,
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
        display: 'none'
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'black',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
            width: 200,
      },
    },
  },
});

function TopSection(props) {
  const { classes } = props;


  const seeNotifs = () => {

    props.toggleNotif();

    if (!props.notif && props.notifs.unseen > 0) {
        let headers = { "content-type": "application/json" };
        if (props.token !== null && props.token !== undefined) {

            headers['Authorization'] = `Token ${props.token}`

        }
        
        const url_call = `${backendUrl}/v1/see_notifs/`
        const data = {
            "user_id": props.user_id
        }

        axios.post(url_call, data, {headers: headers})
            .then(json => {

            const new_number = 0
                const notif_data = {
                    unseen: new_number,
                    unread: props.notifs.unread,
                    notifications: props.notifs.notifications
                }

                props.writeNotifs(notif_data)

            })
            .catch( e => {

                console.log(e)

            })
    }

}
    const emptyMenu = () => {
        props.setMenuPortfolio(null)
        props.setMenuPath(null)
        
    }
    const goToSettings = () => {
        props.history.push('/settings')
    }
    const goToCoin = (value) => {
        props.history.push({
            pathname: `/coins/${value.value}`, 
            state: { coin_id: value.value }
        })
    }

  return (


   
    <div className={`${classes.root} appbar-height`}>

      {/* RENDER FOR DESKTOP */}

    <Hidden smDown>
        <AppBar position="fixed" className={classes.overall}>
            <Toolbar className={classes.appbar}>

            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="baseline"
                >


                <Grid item lg={12} md={12}>
                    <Link component={RouterLink} to="/">
                        <img src={Logo} className="hvr-shrink-shadow" alt="" style={{'display': 'inline-block'}}/>
                    </Link>
                        
                    <div style={{display: 'inline-block'}} className="basier-p2"><PortfolioSelector /></div>
                    <div style={{marginLeft: '36px', display: 'inline-block'}} className="responsive-36 basier-p2"><CurrencySelector  /></div>
                    
                    <Hidden mdDown>
                    <Link component={RouterLink} to="/watchlist" onClick={emptyMenu} style={{color: props.currentView === 95 ? '#0078DB' : '#6A7E93', marginLeft: '36px', fontWeight: props.currentView === 95 ? 900 : 400, }} className="basier-p2 responsive-36" >
                        Watchlist
                    </Link>

                    <Link component={RouterLink} to="/wallets" onClick={emptyMenu} style={{color: props.currentView === 97 ? '#0078DB' : '#6A7E93', marginLeft: '36px', fontWeight: props.currentView === 97 ? 900 : 400, }} className="basier-p2 responsive-36" >
                        Wallets
                    </Link>

                    {
                        props.reconciliations !== undefined && props.reconciliations.filter(v => (v.related_transaction === null && v.include === true) || (v.reviewed === false && v.base_currency !== null)).length > 0 && 
                        <Link component={RouterLink} to="/reconcile" onClick={emptyMenu} style={{color: props.currentView === 92 ? '#0078DB' : '#F9716B', marginLeft: '36px', fontWeight: 900 }} className="basier-p2 responsive-36" >
                                <span style={{marginRight: 12}}>Review</span>
                                <span style={{marginLeft: 0, width: 28, height: 16, borderRadius: 8, backgroundColor: props.currentView === 92 ? '#0078DB' : '#F9716B', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', color: 'white', padding: 4, fontWeight: 900, fontSize: 10}} className="basier">{props.reconciliations.filter(v => (v.related_transaction === null && v.include === true) || v.reviewed === false).length}</span>
                        </Link>
                    }
                
                    </Hidden>

                    <Hidden lgUp>
                        <div className="basier-p2 responsive-36" style={{display: 'inline-block', color: "#6A7E93", marginLeft: '36px'}}>
                        <SmallMenu setMenuPortfolio={props.setMenuPortfolio} setMenuPath={props.setMenuPath} />
                        </div>
                    </Hidden>

                    <div style={{float: 'right', display: 'flex', alignItems: 'center'}}>
                        <div style={{display: 'inline-block', marginRight: '24px'}}>
                        <NewSearch handleSelect={(value) => goToCoin(value)} data_filter={props.cryptos_list}/>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <Badge classes={{
                            badge: classes.badge, 
                            colorPrimary: classes.colorbadge, 
                        }} badgeContent={getter(props.notifs, 'unseen')} color="primary" invisible={getter(props.notifs, 'unseen') === 0 ? true : false}>
                            <div onClick={seeNotifs} style={{marginRight: 24}} className="opacity-hover no-select">
                                <svg width="24px" height="29px" viewBox="0 0 24 29" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{display: 'flex', alignItems: 'center'}}>
                                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="notif" fill={`${ !props.notif ? '#6A7E93' : '#0078DB' }`} fillRule="nonzero">
                                            <path d="M12,0 C10.7388394,0 9.71428571,1.02455371 9.71428571,2.28571429 C9.71428571,3.54687486 10.7388394,4.57142857 12,4.57142857 C13.2611606,4.57142857 14.2857143,3.54687486 14.2857143,2.28571429 C14.2857143,1.02455371 13.2611606,0 12,0 Z M8.78571429,3.48214286 C6.17410714,4.60044629 4.57142857,7.11830343 4.57142857,10.2857143 C4.57142857,16.5714286 2.39955371,18.1495537 1.10714286,19.0892857 C0.533482286,19.5044643 0,19.8906251 0,20.5714286 C0,22.9754463 3.58928571,24 12,24 C20.4107143,24 24,22.9754463 24,20.5714286 C24,19.8906251 23.4665177,19.5044643 22.8928571,19.0892857 C21.6004463,18.1495537 19.4285714,16.5714286 19.4285714,10.2857143 C19.4285714,7.10937486 17.8281251,4.59821429 15.2142857,3.48214286 C14.7254463,4.78125 13.4665177,5.71428571 12,5.71428571 C10.5334823,5.71428571 9.27455371,4.77901771 8.78571429,3.48214286 Z M8.57142857,25.0714286 C8.57142857,25.09375 8.57142857,25.1205357 8.57142857,25.1428571 C8.57142857,27.0334823 10.1093749,28.5714286 12,28.5714286 C13.8906251,28.5714286 15.4285714,27.0334823 15.4285714,25.1428571 C15.4285714,25.1205357 15.4285714,25.09375 15.4285714,25.0714286 C14.3526786,25.1160714 13.2098214,25.1428571 12,25.1428571 C10.7901786,25.1428571 9.64732143,25.1160714 8.57142857,25.0714286 Z" id="Shape"></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </Badge>
                        <img 
                            onClick={props.toggleMenu}
                            style={{height: 35, width: 35}}
                            className="opacity-hover no-select"
                            src={`https://ui-avatars.com/api/?rounded=true&size=128&background=6A7E93&color=fffff&name=${props.first_name}+${props.last_name}`} 
                            alt="" />
                            <span style={{display: 'inline-block', color: 'black', paddingLeft: '8px'}}>

                            </span>
                        </div>
                        
                        
                    </div>
                    {   
                        
                            (props.active == null || props.active == undefined) ? 
                            
                            null 
                            
                            : 
                            
                            props.active.subscription_status == 0 && (props.subscription.plan.plan_id !== 'unlimited' && props.subscription.plan.plan_id !== 'free') ? 

                            <div style={{width: '100%', display: 'flex', alignItems: 'center', height: 30, marginTop: 12, color: 'black', backgroundColor: '#f5b1ae', zIndex: 12000, paddingLeft: 12, paddingRight: 12}}>
                                There was an issue with your account payment or your plan is expired. Please &nbsp; <span style={{color: 'white', cursor: 'pointer'}} className="opacity-hover" onClick={goToSettings}>go to settings</span>&nbsp; or contact support if you think it is a mistake.
                            </div>

                            :
                            
                            (props.limits !== undefined && props.limits !== null) ? 
                        
                            (props.limits.investment_limit != -1 && props.limits.investment>props.limits.investment_limit) ||
                            (props.limits.transaction_limit != -1 && props.limits.transactions>props.limits.transaction_limit) ||
                            (props.limits.portfolio_limit != -1 && props.limits.portfolios>props.limits.portfolio_limit) ? 
                            
                        
                                <div style={{width: '100%', display: 'flex', alignItems: 'center', height: 30, marginTop: 12, color: 'black', backgroundColor: '#f5b1ae', zIndex: 12000, paddingLeft: 12, paddingRight: 12}}>
                                    Your account limits have been exceeded. This can lead to <b>&nbsp;incorrect data and results</b>. We recommend you &nbsp; <span style={{color: 'white', cursor: 'pointer'}} className="opacity-hover" onClick={goToSettings}>upgrade your plan here</span>
                                </div>
                                
                                : null
                        :null }
                </Grid>

            </Grid>
            </Toolbar>
        </AppBar>
    </Hidden>
    
       {/* RENDER FOR MOBILE */}
    
    <Hidden mdUp>
        <MobileMenu location={props.location} />
    </Hidden>


    </div>
  );
}
//(props.limits.investment_limit != -1 && props.limits.investment>props.limits.investment_limit)
//props.limits.transaction_limit != -1 && props.limits.transactions>props.limits.transaction_limit
TopSection.propTypes = {
  classes: PropTypes.object.isRequired,
};
  


const mapStateToProps = state => {
    return {
        first_name: state.auth.user_first_name,
        last_name: state.auth.user_last_name,
        cryptos_list: state.appwide.crypto, 
        notifs: state.appwide.notifs, 
        currentView: state.data.currentView, 
        token: state.auth.token, 
        user_id: state.auth.user_id, 
        limits: state.appwide.limits,
        active: state.auth.subscription,
        subscription: state.auth.subscription,
        reconciliations: state.data.importedTransactions
    };
};

  const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (portfolio) =>
            dispatch(actions.setMenuPortfolio(portfolio)), 
        setMenuPath: (path) => 
             dispatch(actions.setMenuPath(path)),
        writeNotifs: (data) => dispatch(appwideActions.updateNotifs(data))
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withRouter((TopSection))));
  