import * as actionTypes from "./actionTypes";
import { loadData, api_timeout_caller } from '../../helpers/helpers';
import * as interActions from './interactions'
import { backendUrl } from '../../variables'
import { setFiatList } from './data'

export const updatePay = (data) => {
    return {
        type: actionTypes.APPWIDE_PAY, 
        data
    };
};

export const addPort = (data) => {
    return {
        type: actionTypes.APPWIDE_ADD_PORT, 
        data
    };
};

export const addTr = (data) => {
    return {
        type: actionTypes.APPWIDE_ADD_TR, 
        data
    };
};


export const getPay = (user, custId) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/pm_mt/?u=${user}&customer_id=${custId}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updatePay(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

export const updateConnectors = (data) => {
    return {
        type: actionTypes.APPWIDE_CONNECTORS, 
        data
    };
};


export const getConnectors = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } else {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/connectors/list/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateConnectors(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

export const updateContracts = (data) => {
    return {
        type: actionTypes.APPWIDE_CONTRACTS, 
        data
    };
};


export const getContracts = () => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/positions/contracts`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateContracts(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


export const updateUserLimit = (data) => {
    return {
        type: actionTypes.APPWIDE_LIMITS, 
        data
    };
};


export const getUserLimits = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } else {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/user_limits/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateUserLimit(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


export const updateAllNames = (data) => {
    return {
        type: actionTypes.APPWIDE_ACCOUNT_NAMES, 
        data
    };
};


export const getAllNames = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } else {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/wallets/allnames/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateAllNames(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


export const updatePlan = (data) => {
    return {
        type: actionTypes.APPWIDE_PLAN, 
        data
    };
};


export const getPlans = (user,tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } else {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/client_sub/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updatePlan(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


export const updateNotifs = (data) => {
    return {
        type: actionTypes.APPWIDE_NOTIFS, 
        data
    };
};


export const getNotifs = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } else {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/notifications/${user}/`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateNotifs(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

export const updateInvites = (data) => {
    return {
        type: actionTypes.APPWIDE_INVITES_SUCCESS, 
        data
    };
};


export const getInvites = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } else {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    return dispatch => {
        dispatch( {type: actionTypes.APPWIDE_INVITES_REQUEST} )
        const data_fetch = loadData(`${backendUrl}/v1/portfolio_requests/?id=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateInvites(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


const updateAllCoins = (data) => {
    return {
        type: actionTypes.APPWIDE_ALL_COINS, 
        data
    };
};


export const getAllCoins = (user, tokenProvided) => {

    console.log('GOING TO GET COINSSSSSS')
    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
        console.log('got token from ')
        
    } else {
        headers["Authorization"] = `Token ${tokenProvided}`;
        console.log('passedToken')

    }
    return dispatch => {

        console.log('going to call')
        const data_fetch = loadData(`${backendUrl}/v1/cryptos/?type=all`, headers)
        data_fetch
            .then(json => 
                
                {   
                    console.log('gotit')
                    dispatch(updateAllCoins(json['data']));
                    const crypto = json['data'].filter(v => v.asset_type === 'crypto')
                    dispatch(updateCrypto(crypto))
                    const fiat = json['data'].filter(v => v.asset_type === 'fiat')
                    let goodFiat = []
                    fiat.map(val => {
                        goodFiat.push({value: val.id, label: val.id, full_name: val.name})
                    })
                    dispatch(setFiatList(goodFiat))

                }
                
            )
            .catch(err => {
                console.log('coin error')
            })
    };


}

const updateAllPorts = (data) => {
    return {
        type: actionTypes.APPWIDE_ALL_PORTS, 
        data
    };
};

const updateFlatWallets = (data) => {
    return {
        type: actionTypes.APPWIDE_FLAT_WALLETS, 
        data
    };
};

export const getAllPortfolioList = (user_id) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/port_ownership/?u=${user_id}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateAllPorts(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

export const getFlatWalletList = (user_id) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/wallet_ownership/?u=${user_id}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateFlatWallets(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


const updateCrypto = (data) => {
    return {
        type: actionTypes.APPWIDE_CRYPTO, 
        data
    };
};


export const getCrypto = () => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/cryptos/`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateCrypto(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

const updateExchanges = (data) => {
    return {
        type: actionTypes.APPWIDE_EXCHANGES, 
        data
    };
};


export const getExchanges = (user_id, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } else {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/exchanges/?u=${user_id}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateExchanges(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


export const updateSingleWallet = (data) => {
    return {
        type: actionTypes.APPWIDE_SINGLE_WALLET, 
        data
    };
};


const updateWallets = (data) => {
    return {
        type: actionTypes.APPWIDE_WALLETS, 
        data
    };
};


export const getWallets = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } else {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }

    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/wallets/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateWallets(json['data']));
                    dispatch(getAllNames(user));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

const updateOrgs = (data) => {
    return {
        type: actionTypes.APPWIDE_ORG_LIST, 
        data
    };
};


export const getOrgs = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } else {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/organizations/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateOrgs(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}