import React, { type ElementConfig } from 'react';
import { Creatable, components }  from 'react-select';
import { connect } from 'react-redux';
import plusButton from '../../../../../../Media/Plus.svg'
import * as actions from "../../../../../../store/actions/interactions";


const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '250px', fontSize: 16, border: '0px', color: 'black', cursor: 'text', dispay:'inline-block'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#F3F5F6'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.7',
    };
  },

};

const optionDisplay = (account) => {
    return (
      <div style={{textAlign: 'left'}}>
          {account.name}
      </div>
     
    )
}

const adderDisplay = (
    <div style={{'textAlign': 'left'}}>
      <span className="icon-container-menu">
          <img src={plusButton} alt=""/>
      </span>
      <span className="basier-p4-caps">add a new Wallet</span>
  </div>
  )

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <div style={{marginLeft: '12px'}}>
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9136 1L7.95679 8L1 1" stroke="#6A7E93" stroke-width="1.5" stroke-miterlimit="10"/>
        </svg>
      </div>
    </components.DropdownIndicator>
  );
};

const menuHeaderStyle = {
    padding: '8px 12px',
    background: 'white',
    color: 'black',
    fontWeight: 900,
  };

const MenuList = props => {
    return (
      <components.MenuList {...props}>
        <div className="basier-p4-caps" style={menuHeaderStyle}>Exchange accounts</div>
        {props.children}
      </components.MenuList>
    );
  };

let options = [];

class MenuSelector extends React.Component {

  state = {
    selectedOption: null
  }
  

  componentDidMount() {
    

    let accounts = null 
    if (this.props.straight) {
      accounts = this.props.accounts[this.props.exchange]
    } else {
      accounts = this.props.accounts[this.props.exchange['value']]
    }

    options = [];

    if ( accounts !== undefined &&  accounts !== null) {

      const account_keys = Object.keys(accounts)

      this.setState(state => ({...state, selectedOption: {value: accounts[account_keys[0]]['id'], label: accounts[account_keys[0]]['name']}}))
      this.props.updateTransaction({key: this.props.transactionId, label: "account", value: accounts[account_keys[0]]['id']})

      account_keys.map( (key) => {
        let values = {id: accounts[key]['id'], value: accounts[key]['name'], label: optionDisplay(accounts[key])};
        options.push(values)

        })

    }

    console.log(options)

    this.setState(state => ({...state, options: options}))

  }

  componentDidUpdate(prevProps, prevState) {
    const currentIndex = options.findIndex(p => p.id === this.props.selected_menu)
  

    if (prevProps.portfolios !== this.props.portfolios) {

      let accounts = null 
      if (this.props.straight) {
        accounts = this.props.accounts[this.props.exchange]
      } else {
        accounts = this.props.accounts[this.props.exchange['value']]
      }

      options = [];

      if ( accounts !== undefined &&  accounts !== null) {

        const account_keys = Object.keys(accounts)

        account_keys.map( (key) => {
          let values = {id: accounts[key]['id'], value: accounts[key]['name'], label: optionDisplay(accounts[key])};
          options.push(values)
  
          })

        console.log(options)
  
      }

      this.setState(state => ({...state, options: options}))

    }
    
  }

  handleClear = () => {

    this.setState(state => ({...state,  selectedOption: null }));
    this.props.updateTransaction({key: this.props.transactionId, label: "account", value: -1})
    this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})
    this.props.removeWallets()

  }
  handleChange = (selectedOption) => {
    
    if ( selectedOption.hasOwnProperty('__isNew__') ) {
      
      const selectedTest = selectedOption['value'].toLowerCase().trim()
      let existingIndex = null; 
      for (let i = 0; i < this.state.options.length; i++) {
        if (this.state.options[i]['value'].toLowerCase().trim() == selectedTest) {
          existingIndex = i
          break;
        }
      }
      if (existingIndex === null) { 

        if (selectedOption['__isNew__'] === true) {

          this.setState(state => ({...state,  selectedOption: selectedOption }));
          this.props.updateTransaction({key: this.props.transactionId, label: "account", value: -1})
          this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: selectedOption.value})

        }
      } else {

        const actualValue = this.state.options[existingIndex]
        if (selectedOption.value !== 'adder') {
          this.setState(state => ({...state,  selectedOption: actualValue }));
          this.props.updateTransaction({key: this.props.transactionId, label: "account", value: actualValue.id})
          this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})
  
        } else if (selectedOption.value === 'adder') {
          this.props.setNewWallet(false)
        }

      }

    }
    else if (selectedOption.value !== 'adder') {
        this.setState(state => ({...state,  selectedOption: selectedOption }));
        this.props.updateTransaction({key: this.props.transactionId, label: "account", value: selectedOption.id})
        this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})

    } else if (selectedOption.value === 'adder') {
        this.props.setNewWallet(false)
    }
    
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Creatable
        value={selectedOption}
        onChange={this.handleChange}
        onMenuOpen={() => this.handleClear()}
        options={this.state.options}
        isSearchable={true}
        styles={colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        components={{ MenuList, DropdownIndicator }}
        classNamePrefix="react-select-user"
        placeholder="Select or type new..."
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeWallets: () =>
      dispatch(actions.removeWallets()), 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
