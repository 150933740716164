import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CloseIcon from '../../Media/CloseIcon.svg'
import axios from 'axios';
import { backendUrl } from '../../variables';

const generateUserRoles = (plan) => {

    switch(true) {
 
        case (plan === 'pro') || (plan === 'unlimited'): 
            return [
                {value: "admin", label: "Admin"}, 
                {value: "manager", label: "Manager"},
                {value: "observer", label: "Observer"},
            ]
        default: 
            return [
                {value: "manager", label: "Manager"},
                {value: "observer", label: "Observer"},
            ]
    }

}

function Transition(props) {
    return <Slide direction="left" {...props} />;
  }
  
  const drawerWidth = '100%';
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  
  
  const styles = theme => ({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    root: {
      backgroundColor: "#fff",
      zIndex: 1400,
      display: 'block',
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#fff"
        }, 
      },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#fff',
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#fff"
    }, 
    },
  
  });
  

class FullScreenDialog extends React.Component {

  state = {
    selectedRole: null
  }
  openModal = this.props.openModal

  componentDidMount() {
    document.body.ontouchmove = (e) => { 
      e.preventDefault(); 
    }
    if (this.props.data !== undefined && this.props.data !== null) {
        this.setState(state => ({...state, selectedRole: this.props.data.role}))
    }
  }

  componentWillUnmount() {
    document.body.ontouchmove = (e) => { 
      return true
    }
  }

  componentDidUpdate(prevProps) {
    
    if (this.props.data !== prevProps.data && this.props.data !== undefined && this.props.data !== null) {
        this.setState(state => ({...state, selectedRole: this.props.data.role}))
    }
  }

  changeRole = (val) => {
      this.setState(state => ({...state, selectedRole: val}))
  }

  handleClose = () => {
    this.setState({ open: false });
  };
  
  submitPermissions = (id, oldRole) => {

    console.log('old', oldRole)
    console.log('id', id)
    if (oldRole !== this.state.selectedRole) {

        const sendData = {
            id: id, 
            role: this.state.selectedRole,
            user_id: this.props.user_id,
        }

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/portfolio/users/`
        
        axios.patch(url_call, sendData, {headers: headers})
            .then(json => {
                
                let newUsers = [];
                this.props.allData['users'].map(val => {
                    if (val['id'] == id) {
                        val['role'] = this.state.selectedRole
                    }
                    newUsers.push(val)
                })
                const newData = {
                    ...this.props.allData, 
                    users: newUsers,
                }
                this.props.updateState(newData)

            })
            .catch(e => console.log(e))

    }
    this.props.closeModal()
}

  

  render() {
    const { classes } = this.props;
    return (
      <div>
        <SwipeableDrawer
          anchor="right"
          open={this.props.openModal}
          onClose={this.props.closeModal}
          onOpen={this.props.openModal}
          disableBackdropTransition={!iOS} 
          disableDiscovery={true}
          disableSwipeToOpen={true}
          hysteresis={0.05}
          classes={{
            paper: classes.drawerPaper,
            root: classes.root
          }}
        >
          <div
            tabIndex={0}
            role="button"
          >
            <div>
                <div style={{backgroundColor: '#F8F9FA', 'boxShadow': 'none', height: 75, justifyContent: 'space-between', display: 'flex', alignItems: 'center'}}>
            
                    <div style={{display: 'flex', alignItems: 'center', zIndex: 1500, height: '100%'}} className="over-all">
                        <img style={{marginLeft: '12px', marginTop: 20}} src={CloseIcon} alt="" onClick={this.props.closeModal}/> 
                    </div>

                    <div className="basier-p2" style={{fontWeight: 900, color: 'black', textTransform: 'uppercase'}}>
                        
                    </div>

                    <div style={{textAlign: "right"}}>
                        <div style={{marginRight: 24, color: '#F9716B', fontSize: '1.2rem'}} className="modalActionButton touchableOpacity basier" onClick={() => this.props.confirmDelete(this.props.data)}>
                          Delete User
                        </div>
                    </div>
                    
                </div>
                {this.props.data !== null &&
                <div style={{paddingLeft: 12, paddingRight: 12, paddingTop: 24}}>
                    
                    <h4>
                        {this.props.data.first_name} {this.props.data.last_name}
                    </h4>

                    <div className="basier-p4-caps" style={{marginTop: 12, marginBottom: 12}}>User Role</div>

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    {   
                        generateUserRoles(this.props.data.plan_type).map((val, key) => {

                            return (
                                <div 
                                    key={key}
                                    className={`basier-p4-caps transaction-bubble`}
                                    onClick={() => this.changeRole(val.value)}
                                    style={{
                                        marginBottom: 12, 
                                        fontSize: 20,
                                        fontWeight: 900, 
                                        marginRight: 6,
                                        marginLeft: 6,
                                        padding: '20px 2px', 
                                        minWidth: 80, 
                                        flexGrow: 1,
                                        height: 50, 
                                        borderRadius: 10,
                                        cursor: 'pointer',
                                        color: this.state.selectedRole == val.value ? 'white' : 'black',
                                        backgroundColor: this.state.selectedRole == val.value ? '#0078DB' : '#F8F9FA'
                                        }}>
                                            {val.label}
                                        </div>
                            )

                        })
                    }
                    </div>
                    {this.props.data.plan_type !== 'pro' && this.props.data.plan_type !== 'unlimited' &&
                    <div style={{marginTop: 0, textAlign: 'center'}} className="basier-p4">Only users with active Pro plans can become admins of collaborative porfolios.</div>}

                    <div style={{marginTop: 24, width: '100%', display: 'flex', justifyContent: 'center'}}>

                    <button className={`button-blue opacity-hover`} style={{height: 40, borderRadius: 35, marginTop: 12, width: 180,  cursor: 'pointer', marginRight: 12,}} onClick={() => this.submitPermissions(this.props.data['id'], this.props.data['role'])}>
                        Submit Changes
                    </button>

                    </div>
                </div>
                }
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenDialog);