import React from 'react';
import { connect } from 'react-redux';
import {siwtchTransactionType} from '../../../../../../helpers/helpers';
import CoinList from '../../../../../MobileOnly/MobileCurrency'
import TypeList from '../../../../../MobileOnly/MobileType'
import * as actions from "../../../../../../store/actions/interactions";
import * as dataActions from "../../../../../../store/actions/data";
import Switch from '../../../../../Assets/Switch';
import UserList from '../../../../../MobileOnly/UserDrawer';
import currencyDict from '../../../../../../helpers/currency.json'
import PriceType from '../../NewTransaction/QuestionBlocks/SelectPriceType';

var moment = require('moment');
require('moment/locale/en-gb');

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }

class assetModalContent extends React.PureComponent {

    

    constructor(props) {

        super(props)
        

          this.state = {

            
          }
  
      }
    
    ChangePriceType = (val) => {
    console.log(val)
    this.props.setInteraction({label: 'priceType', value: val})
    let priceSend = null;

    if(this.props.passState['transactionData']['unit_price'] !== null && this.props.passState['transactionData']['unit_price'] !== undefined && this.props.passState['transactionData']['amount'] !== null && this.props.passState['transactionData']['amount'] !== undefined && this.props.passState['transactionData']['amount'] !== 0 ) {

        if (val === 'unit') {
        
        priceSend = this.props.passState['transactionData']['unit_price'] * this.props.passState['transactionData']['amount']

        } else {
            
        
        priceSend = this.props.passState['transactionData']['unit_price'] / this.props.passState['transactionData']['amount']

        }
    }

    this.props.updatePriceType(priceSend)
    }
    
    render () {

        return (    

                    <div>

                         <div style={{marginTop: 0}}>

                             <div 
                                style={{
                                 height: 60,
                                 display: 'flex', 
                                 alignItems: 'center',
                                 justifyContent: 'space-between',
                                 paddingLeft: 24,
                                 borderBottom: '1px solid #E1E5E9',
                                 borderTop: '1px solid #E1E5E9',
                                 width: '100%',
                                 textTransform: 'uppercase',
                                 fontWeight: 900
                             }}
                                onClick={() => this.props.coinModalOpen('base_currency')}
                                className="basier-p3">

                                
                                <div style={{color: 'black', fontWeight: 900}}>
                                    Base currency
                                </div>

                                <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>

                                    <div style={{marginRight: 24, color: '#6A7E93'}}>
                                        {
                                            this.props.passState.transactionData['base_currency'] !== undefined && this.props.passState.transactionData['base_currency'] !== null ? 
                                            
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                               
                                               {
                                                    
                                                    this.props.passState.transactionData['base_currency']['image_thumb'] !== "" && this.props.passState.transactionData['base_currency']['image_thumb'] !== null  ? 
                                                        <span><img style={{height: 25, width: 25, marginRight: 12}} src={this.props.passState.transactionData['base_currency']['image_thumb']} alt=""/></span>
                                                        :
                                                        <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[this.props.passState.transactionData['base_currency']['symbol']] === undefined ? '16px' : currencyDict[this.props.passState.transactionData['base_currency']['symbol']]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                                                            {currencyDict[this.props.passState.transactionData['base_currency']['symbol']] === undefined ? this.props.passState.transactionData['base_currency']['symbol'] : currencyDict[this.props.passState.transactionData['base_currency']['symbol']]['symbol'].length > 1 ? currencyDict[this.props.passState.transactionData['base_currency']['symbol']]['symbol'] : currencyDict[this.props.passState.transactionData['base_currency']['symbol']]['symbol'] }
                                                        </span>

                                                    }
                                               
                                                <div>{this.props.passState.transactionData['base_currency']['symbol']}</div>
                                            </div>
                                            
                                            :
                                            <div>select...</div>
                                        }
                                    </div>

                                    <div>
                                        >
                                    </div>
                                </div>

                                 

                             </div>

                        </div>
                        
                        {

                            this.props.passState.transactionData['base_currency'] !== null && this.props.passState.transactionData['base_currency'] !== undefined ? 

                            <div style={{marginTop: 12, display: 'flex', alignItems: 'center', justifyContent: 'space-between',  flexWrap: 'wrap', marginLeft: 12}}>

                                    { this.props.passState.transactionData['base_currency']['asset_type'] === 'fiat' ?
                                        <React.Fragment>
                                        <div 
                                            className={`basier-p4-caps transaction-bubble ${ this.props.passState.transactionData['transaction_description'] === 'deposit' ? `transaction-color-${`deposit`}` : 'color-grey' }`}
                                            onClick={() => this.props.selectType('deposit')}
                                            style={{
                                                marginBottom: 12, 
                                                fontSize: 20,
                                                fontWeight: 900, 
                                                padding: 20, 
                                                marginRight: 12,
                                                marginLeft: 0,
                                                minWidth: 80, 
                                                flexGrow: 1,
                                                height: 50, 
                                                borderRadius: 10, 
                                                
                                                }}>

                                                {siwtchTransactionType('deposit')[0]}
                                        </div>

                                        <div 
                                            className={`basier-p4-caps transaction-bubble ${ this.props.passState.transactionData['transaction_description'] === 'withdraw' ? `transaction-color-${`withdraw`}` : 'color-grey' }`}
                                            onClick={() => this.props.selectType('withdraw')}                                    
                                            style={{
                                                marginBottom: 12, 
                                                fontSize: 20,
                                                fontWeight: 900, 
                                                padding: 20, 
                                                marginRight: 12,
                                                marginLeft: 0,
                                                minWidth: 80, 
                                                flexGrow: 1,
                                                height: 50, 
                                                borderRadius: 10
                                                
                                                }}>

                                                {siwtchTransactionType('withdraw')[0]}
                                        </div>
                                        </React.Fragment>
                                    
                                    :
                                            
                                    <React.Fragment>
                                            <div 
                                                className={`basier-p4-caps transaction-bubble ${ this.props.passState.transactionData['transaction_description'] === 'buy' || this.props.passState.transactionData['transaction_description'] === 'ico' ? `transaction-color-${`buy`}` : 'color-grey' }`}
                                                onClick={() => this.props.selectType('buy')}
                                                style={{
                                                    marginBottom: 12, 
                                                    fontSize: 20,
                                                    fontWeight: 900, 
                                                    padding: 20, 
                                                    marginRight: 12,
                                                    marginLeft: 0,
                                                    minWidth: 80, 
                                                    flexGrow: 1,
                                                    height: 50, 
                                                    borderRadius: 10, 
                                                    
                                                    }}>
    
                                                    {siwtchTransactionType('buy')[0]}
                                            </div>
    
                                            <div 
                                                className={`basier-p4-caps transaction-bubble ${ this.props.passState.transactionData['transaction_description'] === 'sell' ? `transaction-color-${`sell`}` : 'color-grey' }`}
                                                onClick={() => this.props.selectType('sell')}                                    
                                                style={{
                                                    marginBottom: 12, 
                                                    fontSize: 20,
                                                    fontWeight: 900, 
                                                    padding: 20, 
                                                    marginRight: 12,
                                                    marginLeft: 0,
                                                    minWidth: 80, 
                                                    flexGrow: 1,
                                                    height: 50, 
                                                    borderRadius: 10
                                                    
                                                    }}>
    
                                                    {siwtchTransactionType('sell')[0]}
                                            </div>
                                            </React.Fragment>


                                    }

                                    <div 
                                        className={`basier-p4-caps transaction-bubble ${ 
                                            this.props.passState.transactionData['transaction_description'] !== undefined && 
                                            this.props.passState.transactionData['transaction_description'] !== 'deposit' && 
                                            this.props.passState.transactionData['transaction_description'] !== 'withdraw' &&
                                            this.props.passState.transactionData['transaction_description'] !== 'buy' &&
                                            this.props.passState.transactionData['transaction_description'] !== 'ico' &&
                                            this.props.passState.transactionData['transaction_description'] !== 'sell'
                                            ? `transaction-color-${this.props.passState.transactionData['transaction_description']}` : 'color-grey' }`}
                                        onClick={this.props.typeModalOpen}
                                        style={{
                                            marginBottom: 12, 
                                            fontSize: 20,
                                            fontWeight: 900, 
                                            marginRight: 12,
                                            marginLeft: 0,
                                            padding: '20px 2px', 
                                            minWidth: 90, 
                                            flexGrow: 1,
                                            height: 50, 
                                            borderRadius: 10
                                            
                                            }}>

                                        {
                                            this.props.passState.transactionData['transaction_description'] !== undefined && 
                                            this.props.passState.transactionData['transaction_description'] !== 'deposit' && 
                                            this.props.passState.transactionData['transaction_description'] !== 'withdraw' &&
                                            this.props.passState.transactionData['transaction_description'] !== 'buy' &&
                                            this.props.passState.transactionData['transaction_description'] !== 'ico' &&
                                            this.props.passState.transactionData['transaction_description'] !== 'sell'
                                            ? `${siwtchTransactionType(this.props.passState.transactionData['transaction_description'])[0]} >` : 'Other >' 
                                        }
                                    </div>

                                </div>

                            :

                            null 

                        }


                        { this.props.passState.transactionData['transaction_description'] === 'buy' || this.props.passState.transactionData['transaction_description'] === 'ico' || this.props.passState.transactionData['transaction_description'] === 'sell' ?

                        <div style={{marginTop: 0, display: 'flex', alignItems: 'center',}}>

                            <div 
                                    style={{
                                    height: 60,
                                    display: 'flex', 
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingLeft: 24,
                                    borderBottom: '1px solid #E1E5E9',
                                    borderTop: '1px solid #E1E5E9',
                                    width: '100%',
                                    textTransform: 'uppercase',
                                    fontWeight: 900
                                }}
                                    onClick={() => this.props.coinModalOpen('quote_currency')}
                                    className="basier-p3">

                                    
                                    <div style={{color: 'black', fontWeight: 900}}>
                                        Quote currency
                                    </div>

                                    <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>

                                        <div style={{marginRight: 24, color: '#6A7E93'}}>
                                            {
                                                this.props.passState.transactionData['quote_currency'] !== undefined && this.props.passState.transactionData['quote_currency'] !== null ? 
                                                
                                                <div style={{display: 'flex', alignItems: 'center'}}>

                                                    {
                                                    
                                                    this.props.passState.transactionData['quote_currency']['image_thumb'] !== "" && this.props.passState.transactionData['quote_currency']['image_thumb'] !== null  ? 
                                                        <span><img style={{height: 25, width: 25, marginRight: 12}} src={this.props.passState.transactionData['quote_currency']['image_thumb']} alt=""/></span>
                                                        :
                                                        <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[this.props.passState.transactionData['quote_currency']['symbol']] === undefined ? '16px' : currencyDict[this.props.passState.transactionData['quote_currency']['symbol']]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                                                            {currencyDict[this.props.passState.transactionData['quote_currency']['symbol']] === undefined ? this.props.passState.transactionData['quote_currency']['symbol'] : currencyDict[this.props.passState.transactionData['quote_currency']['symbol']]['symbol'].length > 1 ? currencyDict[this.props.passState.transactionData['quote_currency']['symbol']]['symbol'] : currencyDict[this.props.passState.transactionData['quote_currency']['symbol']]['symbol'] }
                                                        </span>

                                                    }

                                                    <div>{this.props.passState.transactionData['quote_currency']['symbol']}</div>
                                                </div>
                                                
                                                :
                                                <div>select...</div>
                                            }
                                        </div>

                                        <div>
                                            >
                                        </div>
                                    </div>

                                    

                            </div>

                        </div>

                        :

                        null 

                        }

                        { this.props.passState.transactionData['transaction_description'] !== null && this.props.passState.transactionData['transaction_description'] !== undefined ?
                            

                                <div style={{ marginTop: 12,  marginBottom: 24}}>
                                    
                                  <div style={{paddingLeft: 12, paddingRight: 12,}}>
                                    <div style={{display: 'flex', height: 50, alignItems: 'center'}} className="basier-p3">
                                        <div style={{position:'absolute', left: 24}}>Amount</div>
                                        <input onFocus={this.onFocus} value={this.props.passState.transactionData['amount'] || ""} onChange={(e) => this.props.handleInput(e, 'amount')} style={{width: '100%', textAlign: 'right', height: 50, border: '1px solid #E1E5E9', paddingRight: 12}} type="number" pattern="[0-9]*" step="any" inputmode="decimal" className="no-outline" />
                                    </div>

                                    { this.props.passState.transactionData['transaction_description'] === "buy" || 
                                    this.props.passState.transactionData['transaction_description'] === "ico" || this.props.passState.transactionData['transaction_description'] === 'sell' ? 
                                        <React.Fragment>
                                        <div style={{display: 'flex', height: 50, alignItems: 'center', marginTop: 12}} className="basier-p3">
                                            <PriceType changeType={this.ChangePriceType} onKeyDown={(e) => this.onNextStepRef(e, 'price_type')} selection={this.props.priceType} scope="mobile" />
                                            <input value={this.props.passState.transactionData['unit_price'] || ""} onChange={(e) => this.props.handleInput(e, 'unit_price')} style={{width: '100%', textAlign: 'right',  height: 50, border: '1px solid #E1E5E9', paddingRight: 12, borderLeft: 0, borderRadius: '0px 5px 5px 0px'}} type="number" pattern="[0-9]*" step="any" inputmode="decimal" className="no-outline" />
                                        </div>
                                        <div className="basier-p4" style={{fontStyle: 'italic', color: '#0078DB', marginTop: 6}}>
                                            {this.props.priceDisplay !== null && this.props.priceDisplay !== undefined ? 

                                                this.props.priceType === 'unit' ? 


                                                `You will spend a total of ${
                                                    this.props.priceDisplay > 1 ? 
                                                    this.props.priceDisplay.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    :
                                                    this.props.priceDisplay.toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})
                                                } `

                                                :

                                                `The unit price will be ${
                                                    this.props.priceDisplay > 1 ? 
                                                    this.props.priceDisplay.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    :
                                                    this.props.priceDisplay.toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})
                                                }`
                                            
                                            :
                                            
                                                null
                                                
                                            }

                                        </div>
                                        {this.props.new && this.props.passState.transactionData['quote_currency'] !== null && this.props.passState.transactionData['quote_currency'] !== undefined ? 

                                            this.props.passState.transactionData['quote_currency']['asset_type'] === 'fiat' ?

                                            <div style={{width: '100%', display: 'flex', alignItems: 'center', marginTop: 12}}>
                                                <div 
                                                    className='basier-p4-caps transaction-bubble'
                                                    onClick={() => this.props.selectFundType('add')}
                                                    style={{
                                                        backgroundColor: `${ this.props.passState.transactionData.fund_source === 'add' ? '#0078DB' : '#E1E5E9'}`,
                                                        marginBottom: 12, 
                                                        fontSize: 20,
                                                        fontWeight: 900, 
                                                        padding: 10, 
                                                        marginRight: 12,
                                                        marginLeft: 0,
                                                        minWidth: 120, 
                                                        flexGrow: 1,
                                                        height: 50, 
                                                        borderRadius: 10, 
                                                        
                                                        }}>

                                                        Add New Money
                                                </div>

                                                <div 
                                                    className='basier-p4-caps transaction-bubble'
                                                    onClick={() => this.props.selectFundType('deduct')}                                    
                                                    style={{
                                                        backgroundColor: `${ this.props.passState.transactionData.fund_source === 'deduct' ? '#0078DB' : '#E1E5E9'}`,
                                                        marginBottom: 12, 
                                                        fontSize: 20,
                                                        fontWeight: 900, 
                                                        padding: 10, 
                                                        marginLeft: 0,
                                                        minWidth: 120, 
                                                        flexGrow: 1,
                                                        height: 50, 
                                                        borderRadius: 10
                                                        
                                                        }}>
                                                        Deduct from balance
                                                </div>
                                            </div>

                                            :

                                            null

                                        :null}
                                        </React.Fragment>
                                        :
                                        null

                                    }

                                </div>

                                </div>

                            

                        :

                        null 

                        }

                        <CoinList coins={this.props.coins} openModal={this.props.passState.coinModal} closeModal={this.coinModalClose} handleSelect={(res) => this.props.handleCoinSelect(res, this.props.passState.scopeCoin)}/>
                        
                        {this.props.passState.transactionData['base_currency'] !== undefined && this.props.passState.transactionData['base_currency'] !== null ? 
                            <TypeList scope={this.props.passState.transactionData['base_currency']['asset_type']} openModal={this.props.passState.typeModal} closeModal={this.typeModalClose} handleSelect={(res) => this.props.handleTypeSelect(res)}/>
                        :
                        null 
                        }

                        {this.props.portfolio_users !== [] && this.props.portfolio_users !== null && this.props.portfolio_users !== undefined ? 

                            <UserList users={this.props.portfolio_users} openModal={this.props.passState.userModal} closeModal={this.userModalClose} handleSelect={(res) => this.props.handleUser(res)}/>
                        
                        : 
                        
                        null 
                        
                        }

                    </div>
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        coins: state.appwide.all_coins,
        transaction: state.interactions.transactionAddData,
        portfolio_users: state.data.scopedUsers, 
        user_id: state.auth.user_id, 
        user_first_name: state.auth.user_first_name,
        user_last_name: state.auth.user_last_name,
        priceType: state.interactions.priceType
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)),
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        callUserList: (portfolio) =>
          dispatch(dataActions.callUserList(portfolio)), 
        setInteraction: (data) =>
            dispatch(actions.setInteraction(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(assetModalContent);
