import React from 'react';
import currency_dict from '../../../../../helpers/currency.json';

const symbolGet = (dict, key) => {
    
    if (dict[key] !== undefined) {
        return dict[key]["symbol"]
    } else {
        return key
    }
}

const collabCard = (props) => {
    
    return (
        <div
        onClick={(e) => props.setScopeUser(e, props.user_id)}
        className="hover-border-select"
        style={{
            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', 
            border: `${props.selectedUser === props.user_id ? '2px solid #0078DB' : 'none'}`,
            borderRadius: 20,
            width: 310,
            marginRight: 20,
            marginLeft:20, 
            paddingTop: 28,
            paddingBottom: 28, 
            paddingLeft: 12, 
            paddingRight: 12,
            marginBottom: 24
        }}>
            
            <div style={{
                display: 'flex', 
                alignItems: 'center',
            }}>
                <span>
                    <img 
                    style={{height: 35, width: 35, border: '2px solid white', borderRadius: '50%'}}
                    src={`https://ui-avatars.com/api/?rounded=true&size=128&background=6A7E93&color=fffff&name=${props.user}`} 
                    alt=""/>
                </span>
                <span className="basier-p2" style={{marginLeft: 10, color: 'black',}}>
                    {props.user}
                </span>
            </div>

            <div style={{
                display: 'flex', 
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                marginTop: 40,
            }}>
                <div style={{marginLeft: 6}}>
                    <div className="basier-p4-caps">
                        Investment
                    </div>

                    <div>
                        { 
                            Object.keys(props.deposit['deposits']).map(function(key, index) {
                                return (
                                    <div>
                                        <h5 style={{marginBottom:0, paddingBottom: 0}}>
                                            <span>{symbolGet(currency_dict, key)} </span> 
                                            <span>{props.deposit['deposits'][key].toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span> 
                                        </h5>
                                    </div>
                                )
                            })
                        
                        }
                    </div>

                </div>
                
                <div style={{marginLeft: 64}}>
                    <div className="basier-p4-caps"> 
                        Transactions
                    </div>

                    <div>
                        <h5 style={{marginBottom:0, paddingBottom: 0}}>{props.transactions}</h5>
                    </div>

                </div>
             

            
            </div>

            

        </div>
    )
}

export default(collabCard);