import React from 'react';
import { connect } from 'react-redux';

class Step1Connect extends React.PureComponent {

    render () {

        return (
            
            <div style={{maginTop: 12}}>

                <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>

                    <div style={{marginRight: 12, width: '50%', height: '100%'}}>
                        <h4>Exchange</h4>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', overflowY: 'scroll', marginTop: 12}}>
                            {this.props.connectors !== null && this.props.connectors !== undefined && this.props.connectors['exchange'].length > 0 ? 
                                this.props.connectors['exchange'].map((val, key) => {

                                    return (
                                        <div 
                                            className={val.ready === true ? 'hvr-grow-shadow' : null} 
                                            key={val.id} 
                                            style={{display: 'flex', alignItems: 'center', marginRight: 8, marginLeft: 8, marginTop: 8, marginBottom: 8, flex: 1, padding: 12, boxShadow: ' 0px 10px 10px rgba(225, 229, 233, 0.3)', backgroundColor: 'white', borderRadius: 10, height: 55, border: '1px solid #F8F9FA', cursor: val.ready === true ? 'pointer' : 'not-allowed', filter: val.ready === false ? 'grayscale(1.0)' : null, opacity: val.ready === true ? 1 : 0.5}} 
                                            onClick={val.ready === true ? () => this.props.selectExchange(val, 'exchange') : null}
                                            >
                                            <img src={val.image} alt="" style={{height: 20, width: 20, marginRight: 12}} />
                                            <div className="basier-p3" style={{fontWeight: 900}}>{val.exchange_name}</div>
                                        </div>
                                    )

                                })
                            : 

                            null
                            }
                        </div>
                    </div>

                    <div style={{marginLeft: 12,  width: '50%'}}>
                        <h4>Storage</h4>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', overflowY: 'scroll', marginTop: 12}}>
                            {this.props.connectors !== null && this.props.connectors !== undefined && this.props.connectors['storage'].length > 0 ? 
                                this.props.connectors['storage'].map((val, key) => {

                                    return (
                                        <div 
                                            className={val.ready === true ? 'hvr-grow-shadow' : null} 
                                            key={val.id} 
                                            style={{display: 'flex', alignItems: 'center', marginRight: 8, marginLeft: 8, marginTop: 8, marginBottom: 8, flex: 1, padding: 12, boxShadow: ' 0px 10px 10px rgba(225, 229, 233, 0.3)', backgroundColor: 'white', borderRadius: 10, height: 55, border: '1px solid #F8F9FA', cursor: val.ready === true ? 'pointer' : 'not-allowed', filter: val.ready === false ? 'grayscale(1.0)' : null, opacity: val.ready === true ? 1 : 0.5}} 
                                            onClick={val.ready === true ? () => this.props.selectExchange(val, 'storage') : null}
                                            >
                                            <img src={val.image} alt="" style={{height: 20, width: 20, marginRight: 12}} />
                                            <div className="basier-p3" style={{fontWeight: 900}}>{val.coin_name}</div>
                                        </div>
                                    )

                                })
                            : 

                            null
                            }
                        </div>
                    </div>

                </div>


            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Step1Connect)
