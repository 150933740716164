import React from 'react';
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import ExchangeAdd from './ModalComps/ExchangeAdd';
import fieldValidator from '../../../helpers/validators'
import axios from 'axios';
import WalletAdd from './ModalComps/WalletAdd'; 
import { backendUrl } from '../../../variables';

const scopeTr = (scope) => {
    switch(scope) {

        case 'exchange': {
            return 'Exchange Account'
        }

        case 'exchange_wallet': {
            return 'Exchange Wallet'
        }

        case 'storage': {
            return 'Storage Wallet'
        }
        

        default: {
            return null
        }
    }
}
class AddWalletModalView extends React.PureComponent {

    state = {
        loading: false, 
        eAloading: false,
        eAsuccess: false,
        eAerror: false,
        walletLoading: false, 
        walletSuccess: false, 
        walletError: false, 
        error: false, 
        success: false, 
        data: {
            
        },
        validation: {
            exchange: true, 
            coin: true,
            exchange_account: true, 
            name: true,
            isName: true,
        }
    }

    updateData = (label, val) => {

        this.setState(state => ({...state, data: {...state.data, [label]: val}}) )

    }

    updateServer = () => {
       
        let name_unique = true
        const exchange_val = fieldValidator(this.state.data['exchange'], ['isNotEmpty'])
        const cust_exchange_val = fieldValidator(this.state.data['custom_exchange'], ['isNotEmpty'])
        if (this.state.data['name'] !== null && this.state.data['name'] !== undefined) {
            name_unique = fieldValidator(this.state.data['name'], ['isNotIncluded'], this.props.accountNames)
        }
        
        this.setState(state => ({...state, validation: {...state.validation, exchange: (exchange_val || cust_exchange_val), name: name_unique}}))

        if ( (exchange_val || cust_exchange_val) && name_unique ) {
            
            this.setState(state => ({...state, eAloading: true}))
            let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {

                headers['Authorization'] = `Token ${this.props.token}`

            }

            const url_call = `${backendUrl}/v1/exchange_accounts/`

            axios.post(url_call, this.state.data, {headers: headers})
                .then(json => {

                   this.props.getWallets(this.props.user_id)
                   this.setState(state => ({...state, eAloading: false, eAsuccess: true}))
                   
                   setTimeout(() => {

                    this.handleClose()
        
                  }, 600)
                    
                })
                .catch( e => {

                this.setState(state => ({...state, eAloading: false, eAerror: true}))

                })



        } else {
            console.log('invalid form data')
        }
        

    }

    updateWalletServer = () => {


        let name_val = true
        let name_unique = true
        const coin_val = fieldValidator(this.state.data['coin'], ['isNotEmpty'])
        this.setState(state => ({...state, validation: {...state.validation, coin: coin_val}}))

        if ( this.state.data['wallet_type'] === "storage" ) {
            name_val = fieldValidator(this.state.data['name'], ['isNotEmpty'])
        }
        if (this.state.data['name'] !== null && this.state.data['name'] !== undefined) {
            name_unique = fieldValidator(this.state.data['name'], ['isNotIncluded'], this.props.accountNames)
        }
        
        
        this.setState(state => ({...state, validation: {...state.validation, coin: coin_val, name: name_unique, isName: name_val}}))
        
        
        const valid = coin_val && name_val && name_unique
        
        if (valid) {

            this.setState(state => ({...state, walletLoading: true}))

            let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {

                headers['Authorization'] = `Token ${this.props.token}`

            }

            const url_call = `${backendUrl}/v1/wallets/`
            const data_send = {
                ...this.state.data, 
                user_id: this.props.user_id
            }
            axios.post(url_call, data_send, {headers: headers})
                .then(json => {

                   this.props.getWallets(this.props.user_id)
                   this.setState(state => ({...state, walletLoading: false, walletSuccess: true}))
                   
                   setTimeout(() => {

                    this.handleClose()
        
                  }, 600)
                    
                })
                .catch( e => {

                this.setState(state => ({...state, walletLoading: false, walletError: true}))

                })


        } else {
            console.log('not valid')
        }

    }
    
    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
      }

    compRenderer = (type) => {

        switch(type) {
            case 'exchange': 
                return <ExchangeAdd 
                    loading={this.state.eAloading}
                    success={this.state.eAsuccess}
                    error={this.state.eAerror}
                    data={this.state.data}
                    handleClose={this.handleClose}
                    updateServer={() => this.updateServer()} 
                    updateData={(label, val) => this.updateData(label, val)} 
                    validation={this.state.validation} />
            case 'exchange_wallet': 
                return <WalletAdd 
                    loading={this.state.walletLoading}
                    success={this.state.walletSuccess}
                    error={this.state.walletError}
                    scope='exchange'
                    data={this.state.data}
                    handleClose={this.handleClose}
                    updateServer={() => this.updateWalletServer()} 
                    updateData={(label, val) => this.updateData(label, val)} 
                    contextData={this.props.contextData}
                    user_id={this.props.user_id}
                    validation={this.state.validation} />
            case 'storage': 
                return <WalletAdd 
                    loading={this.state.walletLoading}
                    success={this.state.walletSuccess}
                    error={this.state.walletError}
                    scope='storage'
                    data={this.state.data}
                    user_id={this.props.user_id}
                    handleClose={this.handleClose}
                    updateServer={() => this.updateWalletServer()} 
                    updateData={(label, val) => this.updateData(label, val)} 
                    validation={this.state.validation} />
            default:
                return null
        }

    }
    render () {

        return (

            <div>

                <BaseModalTop forceSingle={true} title={`New ${scopeTr(this.props.scope)}`} handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />

                <div style={{width: '100%', display: 'flex'}}>
                    {this.compRenderer(this.props.scope)}
                </div>    
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token,
        accountNames: state.appwide.accountNames
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddWalletModalView)
