
export default () => {

    const distinct = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    const getUnique = (array) => {

        return array.filter(distinct)

    }

    const siwtchTransactionType = (type) => {

        switch (type) {
      
            case 'deposit': {
                return ["Deposit", '#C174FD'];
            }
            case 'withdraw': {
                return ["Withdraw", '#0078DB'];
            }
            case 'buy': {
                return ["Buy", '#4CDAC1'];
            }
            case 'ico': {
              return ["ICO", '#4CDAC1'];
            }
            case 'sell': {
                return ["Sell", '#F9716B'];
            }
            case 'transfer': {
                return ["Transfer", '#6A7E93'];
            }
            case 'mining': {
                return ["Rewards", '#ffc163'];
            }
            case 'fee': {
                return ["Fee", '#F9716B'];
            }
            case 'dividend': {
                return ["Dividend", '#ffc163'];
            }
            case 'income': {
                return ["Income", '#ffc163'];
            }
            case 'gift': {
                return ["Gift", '#ffc163'];
            }
            case 'airdrop': {
                return ["Airdrop", '#ffc163'];
            }
            case 'fork': {
                return ["Fork", '#ffc163'];
            }
            case 'purchase': {
                return ["Purchase", '#6A7E93'];
            }
            case 'donation': {
                return ["Donation", '#6A7E93'];
            }
            case 'lost': {
                return ["Loss", '#F9716B'];
            }
            case 'pnl_loss': {
                return ["P&L Loss", '#F9716B'];
            }
            case 'pnl_profit': {
              return ["P&L Profit", '#4CDAC1'];
            }
      
            default: {
                return [null, null];
            }
      
        }
      
      }

	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		if (!e) return;

        const data = e.data
        const allTx = Object.values(data)

        const uniqueBase = getUnique(allTx.map(x => x.base_id)).filter(x => x !== null).sort()
        const uniqueQuote = getUnique(allTx.map(x => x.quote_id)).filter(x => x !== null).sort()
        const uniqueType = getUnique(allTx.map(x => x.transaction_description)).filter(x => x !== null).sort()
        const uniqueUser = getUnique(allTx.map(x => x.user_id)).filter(x => x !== null).sort()


        const baseSelect = uniqueBase.map(x => [x, `${allTx.filter(u => u.base_id == x)[0].base_ticker} -  ${allTx.filter(u => u.base_id == x)[0].base}`])
        const quoteSelect = uniqueQuote.map(x => [x, `${allTx.filter(u => u.quote_id == x)[0].quote_ticker} -  ${allTx.filter(u => u.quote_id == x)[0].quote}`])
        const typeSelect = uniqueType.map(x => [x, siwtchTransactionType(x)[0]])
        const userSelect = uniqueUser.map(x => [x, `${allTx.filter(u => u.user_id == x)[0].user_first} ${allTx.filter(u => u.user_id == x)[0].user_last}`])
        
        const startDate = new Date(Math.min.apply(null, allTx.map(function(e) {
            return new Date(e.date);
          })));

        const endDate = new Date(Math.max.apply(null, allTx.map(function(e) {
        return new Date(e.date);
        })));
        let filters = {
            base_id: baseSelect, 
            quote_id: quoteSelect, 
            transaction_description: typeSelect, 
            user_id: userSelect, 
            startDate: startDate, 
            endDate: endDate, 
        }

        postMessage(filters);


	})
}
