import React, { type ElementConfig } from 'react';
import { Creatable, components }  from 'react-select';
import { connect } from 'react-redux';



let options = [];

class MenuSelector extends React.Component {

  state = {
    selectedOption: null
  }
  

  componentDidMount() {

    options = [];

    if ( this.props.data !== undefined &&  this.props.data !== null) {

      const org_keys = Object.keys(this.props.data)

      org_keys.map( (key) => {
        let values = {id: key, value: key, label: this.props.data[key]['name']};
        options.push(values)

        })

    }

    this.setState(state => ({...state, options: options}))

  }


  handleClear = () => {

    this.setState(state => ({...state,  selectedOption: null }));
    this.props.handleChange("organization", null)
    this.props.handleChange("organization_name", null)

  }
  handleChange = (selectedOption) => {

    if ( selectedOption.hasOwnProperty('__isNew__') ) {

      if (selectedOption['__isNew__'] === true) {

        this.setState(state => ({...state,  selectedOption: selectedOption }));
        this.props.handleChange("organization", -1)
        this.props.handleChange("organization_name", selectedOption.value)

      }

    }
    else {
        
        this.setState(state => ({...state,  selectedOption: selectedOption }));
        this.props.handleChange("organization", selectedOption.value)
        this.props.handleChange("organization_name", null)
    } 
    
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Creatable
        value={selectedOption}
        onChange={this.handleChange}
        onMenuOpen={() => this.handleClear()}
        options={this.state.options}
        isSearchable={true}
        styles={
          {
            control: styles => ({ ...styles, backgroundColor: 'transparent', height: 42, fontSize: 16, border: `${!this.props.validOrg ? '2px solid red !important' : '0px'}`, color: 'black', dispay:'inline-block'}),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          
              return {
                ...styles,
                backgroundColor: isDisabled
                  ? null
                  : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
                color: isDisabled
                  ? '#F3F5F6'
                  : isSelected ? 'black' : 'black',
                opacity: isDisabled
                  ? '1'
                  : isSelected ? '1' : '0.7',
              };
            },
          
          }
        }
        maxMenuHeight={210}
        minMenuHeight={50}
        classNamePrefix="react-select-largest"
        placeholder="Select or type new..."
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.appwide.orgs
  };
};

const mapDispatchToProps = dispatch => {
  return {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
