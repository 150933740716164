import React from 'react';
import { connect } from 'react-redux';
import SFTop from './SFTop'
class SFBody extends React.PureComponent {

    state = { 
        data: undefined,
        filteredData: undefined,
    }

    componentDidMount() {
        if (this.props.values !== null && this.props.values !== undefined) {
            this.setState(state => ({...state, data: this.props.values, filteredData: this.props.values}))
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.values !== prevProps.values && this.props.values !== null && this.props.values !== undefined) {
            this.setState(state => ({...state, data: this.props.values, filteredData: this.props.values}))
        }
    }

    clearAll = () => {
        this.props.clearAll()
    }

    selectRow = (id) => {

        this.props.selectRow(id)
    }

    filterList = (val) => {
        if (val !== '' && val !== null && val !== undefined) {
            const newList = this.state.data.filter(v => v[1].toLowerCase().search(val) !== -1)
            this.setState(state => ({...state, filteredData: newList}))
        } else {
            this.setState(state => ({...state, filteredData: state.data}))
        }
    }

    render () {

        return (
            <div style={{maxHeight: 250, minHeight: 250, width: 240, backgroundColor: 'white', marginTop: 10, padding: 0, border: '1px solid #CCC', position: 'absolute', 
            right: this.props.rightOpen ? '0px' : 'inherit', zIndex: 900}}>
                <SFTop toClose={this.props.close} clearAll={this.clearAll} filterList={this.filterList} selected={this.props.selected} />
                <div style={{height: 170, overflowY: 'scroll', overflowX: 'hidden'}}>
                    {
                    this.state.filteredData !== null && this.state.filteredData !== undefined ? 
                    this.state.filteredData.length == 0 ? 

                    <div>No existing values</div>

                    :

                    this.state.filteredData.map((val, key) => {
                        return (
                            <div className="background-hover" onClick={() => this.selectRow(val[0])} key={key} style={{backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                
                                <div className="basier-p4 truncate" style={{height: 40, flex: 1, paddingLeft: 6, display: 'flex', alignItems: 'center', cursor: 'pointer', paddingRight: 12, color: this.props.selected[val[0]] == true ? 'black' : 'rgba(0,0,0,0.65)'}}>
                                    <div className="truncate noselect">{val[1]}</div>
                                </div>

                                <div style={{width: 20}}>
                                    {
                                    this.props.selected[val[0]] === true ? <span className="noselect" style={{color: '#0078DB'}}>✓</span> : null
                                    }
                                </div>

                            </div>
                        )
                    })
                    :
                    null 
                    }
                    
                </div>
                <div style={{height: 40, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderTop: '1px solid #CCC', paddingLeft: 12, paddingRight: 12}}>
                    
                    <div onClick={this.props.clearAll} style={{cursor: Object.keys(this.props.selected).length > 0 ? 'pointer' : 'default', color: Object.keys(this.props.selected).length > 0 ? '#0078DB' : '#E1E5E9'}} className={`basier-p4-caps noselect ${Object.keys(this.props.selected).length > 0 ? 'opacity-hover' : ''}`}>Clear All</div>
                    
                    <div className="basier-p4-caps noselect opacity-hover" style={{color: '#0078DB', cursor: 'pointer'}} onClick={this.props.selectAll}>Select All</div>

                </div>
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SFBody)
