import React from 'react';
import {
    Elements,
} from 'react-stripe-elements';
import PaymentForm from './PaymentForm'

const paymentWrapper = (props) => {

    return (

        <Elements locale='en'>
            <PaymentForm 
                user_id={props.user_id}
                token={props.token}
                currency={props.currency}
                customer_id={props.customer_id} 
                fullName={props.fullName} 
                setError={(m) => props.setError(m)}
                setLoading={(l, m) => props.setLoading(l, m)}
                submit={(p) => props.submit(p)} 
                setupOnly={props.setupOnly}
            />
        </Elements>

    )

}

export default paymentWrapper