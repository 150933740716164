import React from 'react';
import { connect } from 'react-redux';
import listIcon from '../../../../../Media/listIcon.svg'
import AssetTable from './AssetTableStraight';

class LargeAssetTable extends React.PureComponent {

    render () {

        return (
            <div>
                <div style={{display: 'flex', alignItems: 'flex-end', backgroundColor: 'white', 'boxShadow': 'none', height: 30, paddingBottom: 12, marginTop: 30, marginBottom: 12, borderBottom: '1px solid #E1E5E9', width: '100%'}}>
                
                    <div
                        style={{display: 'flex', alignItems: 'baseline', paddingLeft: 70, paddingRight: 70, color: '#6A7E93', width: '100%'}}
                    > 
                
                        <div className="basier-p3"  style={{width: '28%', textTransform: 'uppercase'}}>Asset</div> 
                        <div className="basier-p3"  style={{width: '13%', textAlign: 'left', textTransform: 'uppercase'}}>Proportion</div> 
                        <div  style={{width: '16%', textAlign: 'right', textTransform: 'uppercase'}}>
                            <div className="basier-p3">
                            Balance
                            </div>
                            
                        </div> 
                        <div  style={{width: '16%', textAlign: 'right'}}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                            Value
                            </div>
                            <div className="basier-p4" style={{fontStyle: 'italic'}}>
                            
                            </div>

                        </div> 
                        <div  style={{width: '16%', textAlign: 'right' }}>
                        
                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Price
                            </div>
                            <div className="basier-p4-caps">
                                24h %
                            </div>
                        
                        </div> 
                        <div  style={{width: '10%', textAlign: 'right', paddingRight: 12}}>
                        
                            <div className="basier-p3">
                                ROI
                            </div>
                            <div className="basier-p4-caps" >
                                On balance
                            </div>
                        
                        </div> 

                    </div>

                </div>

                <div style={{width: '100%'}}>

                    <div style={{marginLeft: 48, marginRight: 48, marginTop: 30}}>
                        
                        {this.props.portfoliosData[this.props.portfolioId] !== undefined ?

                            this.props.portfoliosData[this.props.portfolioId].balances.length > 0 ? 
                            
                                <AssetTable active={this.props.active} small={this.props.small} exited = {this.props.exited} items={this.props.portfoliosData[this.props.portfolioId]}  />
                            
                            :

                            <div style={{height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12}}>
                                <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column'}}>
                                    <div>
                                        <img src={listIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any transactions in your portfolios yet.</div>
                                        <div>Once you add transactions you can see your balances here.</div>
                                    </div>
                                </div>
                            </div>

                        : 
                        null 
                        }
                    
                    </div>

                </div>
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LargeAssetTable)
