import React from 'react'
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { connect } from 'react-redux';
import currencyDict from '../../../helpers/currency.json'
import listIcon from '../../../Media/listIcon.svg'


const GlobalAssetList = (props) => {
    
    const data = props.globalAssetList.balances
    console.log(typeof data)


    return (

        <div>

             {data !==  undefined ? 
                
                <div>
            
                    <Grid container
                        spacing={0}
                        alignItems="baseline"
                        style={{paddingBottom: 0}}>

                        <Grid item xl={4} lg={3} md={4} style={{textAlign: 'left'}}>
                            <h3 style={{paddingBottom: 0, marginBottom: 0}}>Assets</h3>
                        </Grid>
                        
                        <Hidden mdDown>
                            <Grid item xl={3} lg={3} style={{textAlign: 'right', paddingRight: 5}}>
                                <span className="basier-p4-caps">Quantity</span>
                            </Grid>
                        </Hidden>


                        <Grid item xl={2} lg={3} md={4} style={{textAlign: 'right', paddingRight: 5}}>
                            <span className="basier-p4-caps">Price</span>
                        </Grid>


                        <Grid item xl={3} lg={3} md={4} style={{textAlign: 'right', paddingRight: 30}}>
                            <span className="basier-p4-caps">Value</span>
                        </Grid>

                    </Grid>

                    { 
                        data.length > 0 ? 
                        data.slice(0,5).map((coin, index) => 
                        
                    <Grid container
                        spacing={0}
                        alignItems="center"
                        key={index}
                        className='coin-list-container'>
                    
                    { coin.change > 0 ? <div className='green-container'></div> : <div className='red-container'></div> }
                    
                    <Grid item xl={4} lg={3} md={4} style={{textAlign: 'left', zIndex: 1000, display:'flex', alignItems: 'center'}}>

                        {   
                            coin.image ? 
                                <span style={{paddingLeft: 15, paddingRight: 15}}><img src={coin.image} alt="" style={{height: 30, width: 30, border: 'none'}} /></span>
                            :
                                <span style={{paddingLeft: 15, paddingRight: 15}}><div style={{height: 30, width: 30, border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24}}>
                                    {currencyDict[coin.coin_symbol] === undefined ? null : currencyDict[coin.coin_symbol]['symbol'].length > 1 ? null : currencyDict[coin.coin_symbol]['symbol'] }
                                    </div></span>
                        }
                        
                        <span>
                            <div className="basier-p3">
                                {coin.coin_symbol}
                            </div >

                            <Hidden mdDown>
                            <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                                {coin.short_name}
                            </div>
                            </Hidden>
                            
                            </span>
                    </Grid>

                    <Hidden mdDown>
                        <Grid item xl={3} lg={3} style={{textAlign: 'right', paddingRight: 5, zIndex: 1000}}>
                            <span className="basier-p3">
                                {coin.balance.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}
                            </span>
                        </Grid>
                    </Hidden>
                    
                        <Grid item xl={2} lg={3} md={4} style={{textAlign: 'right', paddingRight: 5, zIndex: 1000}}>
                            <span className="basier-p3">
                            {props.currency_symbol} &nbsp;
                            {coin.price > 1 ? coin.price.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2}) : coin.price.toLocaleString('en', {minimumFractionDigits:6, maximumFractionDigits: 6})}
                            </span>
                        </Grid>
                    

                    <Grid item xl={3} lg={3} md={4} style={{textAlign: 'right', paddingRight: 30, zIndex: 1000}}>
                        <span className="basier-p3">
                            {props.currency_symbol} &nbsp;
                            {coin.value.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}
                        </span>
                    </Grid>
                
                </Grid>


                        )
                    
                    :
                    
                    <div style={{height: 382, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12}}>
                        <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                            <div>
                                <img src={listIcon} alt="" style={{height: 80}} />
                            </div>
                            <div className="basier-p2" style={{marginTop: 24}}>
                                <div>You do not have any transactions in your portfolios yet.</div>
                                <div>Once you add transactions you can see your top holdings here.</div>
                            </div>
                        </div>
                    </div>
                    
                    }

                </div>
                

                :

                null
                
                }
        </div>
    
       
    )
}


const mapStateToProps = state => {
    return {
        globalAssetList: state.data.globalAssetList,
        currency_symbol: state.auth.currency.symbol,
    };
};

export default connect(
    mapStateToProps,
    null
)(GlobalAssetList);
