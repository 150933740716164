import React from "react";
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import { withRouter} from 'react-router-dom';


// Import FilePond styles
const get = (dict, key) => {
    let value = null
    try { value = dict[key] } catch(e) {value = 'deleted'}
    return value
}

class SmallInvitations extends React.Component {

    state = {
        // Set initial files, type 'local' means this is a file
        // that has already been uploaded to the server (see docs)
        files: [], 
        progress: {},
        scope: 'pending'
    }

    handleInit() {
    }

    handleSocket(data) {

        

    }

    changeScope = (scope) => {
        this.setState(state => ({...state, scope: scope}))
    }

    componentDidMount() {

    }

    render() {

        return (
            <div>
                <div style={{marginTop: 12, marginLeft: 12}}>
                    <h3>
                        Invitations
                    </h3>
                </div>

                <div style={{display: 'flex'}}>

                    <div style={{
                        width: '50%', height: 50, display: 'flex', 
                        alignItems: 'center', justifyContent: 'center', 
                        backgroundColor: `${this.state.scope === 'pending' ? '#0078DB' : '#E1E5E9' }`, 
                        color: `${this.state.scope === 'pending' ? 'white' : 'black'}`, 
                        fontWeight: `${this.state.scope === 'pending' ? 900 : 400 }`, 
                        textTransform: 'uppercase', marginRight: 6, marginLeft: 12, borderRadius: 10}} 
                        className="basier-p3"
                        onClick={() => this.changeScope('pending')}>
                        Pending
                    </div>
                    <div 
                        style={{width: '50%', height: 50, display: 'flex', 
                        alignItems: 'center', justifyContent: 'center', 
                        backgroundColor: `${this.state.scope === 'reviewed' ? '#0078DB' : '#E1E5E9' }`, 
                        fontWeight: `${this.state.scope === 'reviewed' ? 900 : 400 }`, 
                        textTransform: 'uppercase', marginRight: 12, marginLeft: 6, borderRadius: 10, 
                        color: `${this.state.scope === 'reviewed' ? 'white' : 'black'}`, }}
                        onClick={() => this.changeScope('reviewed')}
                        className="basier-p3">
                        Reviewed
                    </div>
                    </div>

                    {
                        this.state.scope === "pending" ? 

                        <div style={{marginTop: 48, marginLeft: 12, marginRight: 12,}}>

                            {this.props.invites.filter(v => v.status === 'pending').length > 0 ? 

                            <div className="basier">

                                {this.props.invites.filter(v => v.status === 'pending').map(val => { 

                                    let date = new Date(val.created_at)
                                    const language = navigator.language || 'en'
                                    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                                    let displayDate = date.toLocaleDateString(language, options)

                                    return (

                                        <div style={{display: 'flex', flexDirection: 'column', paddingBottom: 24, paddingTop: 24, borderBottom: '1px solid #E1E5E9'}}>
                                            <div key={val.id} style={{display: 'flex', alignItems: 'center', marginLeft: 24, marginRight: 24, }}>

                                                
                                                <div style={{width: '50%', display: 'flex', flexDirection: 'column'}}>
                                                    <div className="basier-p2">{get(val.portfolio, 'name')}</div>
                                                    <div className="basier-p4-caps" style={{marginTop: 6}}>sent on {displayDate}</div>
                                                </div>  
                                            
                                            </div>


                                            <div style={{display: 'flex', alignItems: 'center', marginTop: 12, width: '100%', justifyContent: 'center'}}>
                                                <div style={{padding: 6, width: 120, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 12, marginLeft: 12, backgroundColor: '#CCEDFE', height: 50, borderRadius: 20, fontWeight: 400, color: 'black', fontSize: 16, textTransform: 'uppercase', cursor: 'pointer'}} className="basier opacity-hover" onClick={() => this.props.handleRequestResponse(val, 'approved')}>
                                                    {
                                                        this.props.loading[val.id] === true ? 

                                                        'Loading'

                                                        :

                                                        'Confirm'
                                                    }
                                                </div>
                                                <div style={{padding: 6, width: 120, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 12, marginLeft: 12, backgroundColor: '#CCEDFE', height: 50, borderRadius: 20, fontWeight: 400, color: 'black', fontSize: 16, textTransform: 'uppercase', cursor: 'pointer'}} className="basier opacity-hover" onClick={() => this.props.handleRequestResponse(val, 'declined')}>
                                                    Decline
                                                </div>
                                            </div>     
    
                                        </div>
                                    )
                                })
                                }

                            </div>

                            :

                            null 

                            }

                        </div>


                        :

                        null
                    }

{
                        this.state.scope === "reviewed" ? 

                        <div style={{marginTop: 48, marginLeft: 12, marginRight: 12,}}>

                            {this.props.invites.filter(v => (v.status === 'approved' || v.status === 'declined')).length > 0 ? 

                            <div className="basier">

                                {this.props.invites.filter(v => (v.status === 'approved' || v.status === 'declined')).map(val => { 

                                    let date = new Date(val.created_at)
                                    const language = navigator.language || 'en'
                                    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                                    let displayDate = date.toLocaleDateString(language, options)

                                    return (

                                        <div style={{display: 'flex', flexDirection: 'column', paddingBottom: 24, paddingTop: 24, borderBottom: '1px solid #E1E5E9'}}>
                                            <div key={val.id} style={{display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginLeft: 24, marginRight: 24, }}>

                                                
                                                <div style={{width: '50%', display: 'flex', flexDirection: 'column'}}>
                                                    <div className="basier-p2">{get(val.portfolio, 'name')}</div>
                                                    <div className="basier-p4-caps" style={{marginTop: 6}}>sent on {displayDate}</div>
                                                </div>  

                                                <div>

                                                <div style={{width: '50%', textTransform: 'uppercase', fontWeight: 900, color: `${val.status === 'approved' ? '#4CDAC1' : '#F9716B'}` }}>
                                                    {val.status === 'approved' ?
                                                        "Confirmed"
                                                    :
                                                        "Declined"
                                                    }
                                                </div>
                                                
                                                </div>
                                            
                                            </div>

                                            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                
                                                    {
                                                        val.status === 'approved' ? 

                                                            val.portfolio !== null ? 
                                                            
                                                                <div style={{padding: 6, width: 250, marginTop: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 12, marginLeft: 12, backgroundColor: '#CCEDFE', height: 50, borderRadius: 20, fontWeight: 400, color: 'black', fontSize: 16, textTransform: 'uppercase', cursor: 'pointer'}} className="basier opacity-hover"onClick={() => {this.props.history.push(`/portfolio/${val.portfolio.id}`)}}>
                                                                    Go to portfolio
                                                                </div>  
                                                                
                                                                :

                                                                null

                                                        :

                                                        <div style={{padding: 6, width: 250, marginTop: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 12, marginLeft: 12, backgroundColor: '#CCEDFE', height: 50, borderRadius: 20, fontWeight: 400, color: 'black', fontSize: 16, textTransform: 'uppercase', cursor: 'pointer'}} className="basier opacity-hover" onClick={() => this.props.handleRequestResponse(val, 'pending')}>
                                                            Undo decline
                                                        </div>    
                                                    }
                                            </div>    
    
                                        </div>
                                    )
                                })
                                }

                            </div>

                            :

                            null 

                            }

                        </div>


                        :

                        null
                    }

                </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        portfolio_id: state.auth.default_portfolio_id,
        user_id: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmallInvitations));
