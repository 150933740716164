
export default () => {


    const newFilter = (array, field, scope) => {

        return array.filter(data => scope.includes(data[field]))

    }

    const dateFilter = (array, start, end) => {

        return array.filter(data =>  new Date(data['date']) >= start && new Date(data['date']) <= end )

    }

	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
        if (!e) return;
        
        const data = e.data
        const allTx = data[0]
        const filteredData = data[1]
        const filters = data[2]
        let returnFilter = Object.values(allTx)

        const filterData = Object.entries(filters)
        const returnAll = Object.values(allTx)

        for (let entries of filterData) {
            const field = entries[0]
            let value = entries[1]
            if (field !== undefined) {
                if (field === 'user_id' && value !== 'all') {
                    value = value.map(x => parseInt(x))
                }
                if (field !== 'date' && value !== 'all') {
                    returnFilter = newFilter(returnFilter, field, value)
                }
                if (field === 'date' && value !== 'all') {
                    const start = value['start']
                    const end = value['end']

                    returnFilter = dateFilter(returnFilter, start, end)

                }
                
            }
        }

        postMessage([returnFilter, returnAll]);


	})
}
