import React, { type ElementConfig } from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';


const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '90px', borderRadius: '10px', color: '#6A7E93', cursor: 'text', dispay:'inline-block',}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#6A7E93'
        : isSelected ? '#6A7E93' : '#6A7E93',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.4',
    };
  },

};

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <div style={{marginLeft: '12px'}}>
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9136 1L7.95679 8L1 1" stroke="#6A7E93" stroke-width="1.5" stroke-miterlimit="10"/>
        </svg>
      </div>
    </components.DropdownIndicator>
  );
};


class MenuSelector extends React.Component {

  state = {
    selectedOption: {value: this.props.currency_used, label: this.props.currency_used}
  }

  handleChange = (selectedOption) => {

    if ((selectedOption.value !== this.state.selectedOption.value) || (this.props.inBitcoin === 'bitcoin')) {

      this.setState({ selectedOption });
      this.props.handleChange(selectedOption.value)

    }
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.props.fiatList}
        isSearchable={true}
        styles={colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        components={{ DropdownIndicator }}
        classNamePrefix="react-select-currency-settings"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#6A7E93',
            primary: '#6A7E93',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {

      fiatList: state.data.fiatList,
      currency_used: state.auth.currency.id, 
      graphData: state.data.globalBalance,
      portfolioGraphData: state.data.portfolioBalance,
      user: state.auth.user_id,
      portfolio: state.auth.default_portfolio_id,
      currentPortfolio: state.data.menu_selected, 
      currentView: state.data.currentView, 
      inBitcoin: state.data.bitcoinPf

  };
};

const mapDispatchToProps = dispatch => {
  return {

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
