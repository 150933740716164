import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop'
import DateTimePicker from 'react-datetime';
import TypeList from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/QuestionBlocks/TypeList';
import loadingSpinner from '../../../Media/loadingSpinner.gif'
import okIcon from '../../../Media/okIcon.svg'
import axios from 'axios'
import CoinSearch from '../../AppWide/CoinSearch';
import SmallExchangeQuestion from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/QuestionBlocks/SmallExchangeQuestion'
import SourceQuestion from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/QuestionBlocks/SmallSourceQuestion'
import SmallTransferFirstBlock from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/QuestionBlocks/SmallTransferFirstBlock'
import SmallTransferSecondBlock from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/QuestionBlocks/SmallTransferSecondBlock'
import { backendUrl } from '../../../variables';
import {loadData} from '../../../helpers/helpers'

var moment = require('moment');
require('moment/locale/en-gb');

class ResponsiveDialog extends React.Component {

  constructor(props) {
    
    super(props);

    this.focusOut = React.createRef();
    this.baseCurencyRef = React.createRef();
    this.quoteCurencyRef = React.createRef();
    this.feeCurrencyRef = React.createRef();
    this.firstFocusExchange = React.createRef();
    

    this.state = {
    }

  };

  componentDidMount() {

    if (this.props.data !== null) {

        this.updateState(this.props.data)

    } else {

      this.getData()

    }

  }

  updateState = (data) => {

    const now = new Date(data.date)
    const moment_date = moment(now);
    const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")

    let quote_currency = data.quote_currency
    if (data.transaction_description !== 'buy' && data.transaction_description !== 'ico' &&data.transaction_description !== 'sell') {
      quote_currency = null
    }

    this.setState(state => ({...state, 

        loading: false, 
        error: false, 
        success: false,
        formFields: {
  
          ...data, 
          date: stateDate, 
          quote_currency: quote_currency,
          account_data: data.account,
          base_id: data.base_currency.id
  
        },
        editFields: {
          
        }
  

    }))


  }

  getData = () => {
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {

        headers['Authorization'] = `Token ${this.props.token}`

    }

    const url_call = `${backendUrl}/v1/transaction_detail/${this.props.transactionId}`

    const transactionDetail = loadData(url_call, headers)
    transactionDetail
        .then(json => {

            const data = json['data']
            this.updateState(data)
            
        })

}

  updateServer = () => {
    
    //console.log(this.state)
    this.setState(state => ({...state, loading: true}))
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {

        headers['Authorization'] = `Token ${this.props.token}`

    }

    const url_call = `${backendUrl}/v1/transaction_detail/${this.props.transactionId}`

    const sendData = {
      ...this.state.editFields, 
      user_id: this.props.user_id
    }
    axios.patch(url_call, sendData, {headers: headers})
        .then(json => {
            
            this.setState(state => ({...state, loading: false, success: true}))
            
            this.props.setDetailTr(json['data']['data'])
            this.props.getData(json['data']['data'])
            this.props.getOverallData()
            this.props.getWallets(this.props.user_id)
            this.props.getUserLimits(this.props.user_id)


            setTimeout(() => {

              this.handleClose()
  
            }, 300)

        })
        .catch( e => {

          this.setState(state => ({...state, loading: false, error: true}))

        })
  }


  handleStateChange = (type, field) => {

    this.setState(state => ({...state, 
                                editFields: {...state.editFields, [type]: field}
                            }))
    


  }

  handleWalletSelect = (data) => {

    this.setState(state => ({...state, 
      editFields: {...state.editFields, [data.label]: data.value}
    }))
  
    this.setState(state => ({...state, 
      formFields: {...state.formFields, [data.label]: data.value}
    }))

  }

  handleFromSelect = (data) => {

    this.setState(state => ({...state, 
      editFields: {...state.editFields, 'from_wallet': data}
    }))
  
    this.setState(state => ({...state, 
      formFields: {...state.formFields, 'from_wallet': data}
    }))

  }

  handleDateChange = (date) => {

    const testStateDate = moment(date, "DD/MM/YYYY HH:mm", true).local()

    if (testStateDate.isValid()) {
        const stateDate = testStateDate.format("DD/MM/YYYY HH:mm")
        
        this.setState(state => ({...state, 
          formFields: {...state.formFields, date: stateDate}
        }))

        let dateRedux = moment(date);
        if (dateRedux.isValid()) { 
          
          this.setState(state => ({...state, 
            editFields: {...state.editFields, date: dateRedux.utc().format()}
          }))

        }
        
    } else {
      this.setState(state => ({...state, 
        formFields: {...state.formFields, date: date}
      }))
    }
  }

  handleDateBlur = (date) => {

    console.log('here')
    console.log(date)

    const testDate = moment(date, "DD/MM/YYYY", true).local()
    if (testDate.isValid()) {
         console.log('valid')
    } else {
        console.log('invalid')
    }

  }

 fillToday = () => {

  const now = new Date()
  const moment_date = moment(now);
  const reduxDate = moment_date.utc().format();
  const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")
  
  this.setState(state => ({...state, 
    editFields: {...state.editFields, date: reduxDate}
  }))

  this.setState(state => ({...state, 
    formFields: {...state.formFields, date: stateDate}
  }))

  }

  getRef = async (ref) => {
    return ref;
  }

  focusOutRef = (ref) => {

    const ref_needed = this.getRef(ref)
    
    ref_needed
    .then(res => {

        try {res.current.myInput.blur()} catch(e) {}
        
        })

  }



  handleClose = () => {

    this.props.removeTransaction(this.state.transaction_id);
    this.props.setTrTime(null);
    this.props.setInteraction({label: 'feeCheck', value: false});
    this.props.setInteraction({label: 'transactionAddData', value: {number: 0, data: {}}});
    this.props.toggleModal({type: null, open: false, payload: null})
   
  }

  changeCoinValue = (type, val) => {

  }
  changeValue = (type, val) => {

    if (type === 'base_currency') {

      this.setState(state => ({...state, 
        formFields: {...state.formFields, base_id: val}
      }))

      const sourceQuestions = ['mining', 'dividend', 'income', 'gift', 'airdrop', 'fork', 'purchase', 'donation', 'lost', 'fee']
    
      if ( sourceQuestions.includes(this.state.formFields.transaction_description) ) {

      this.setState(state => ({...state, 
        editFields: {...state.editFields, storage: null}
      }))

      this.setState(state => ({...state, 
        formFields: {...state.formFields, storage: null}
      }))


    }
  }

  if (type === 'exchange') {


    if (val !== null ) {
      if (val.custom === true) {

        this.setState(state => ({...state, 
          editFields: {...state.editFields, exchange: null, custom_exchange: {id: val.value, name: val.name}, account: null}
        }))
      
        this.setState(state => ({...state, 
          formFields: {...state.formFields, [type]: null, custom_exchange: {id: val.value, name: val.name}, account: null}
        }))
        return null

      } else {

        this.setState(state => ({...state, 
          editFields: {...state.editFields, exchange: null, custom_exchange: val.value}
        }))
      
        this.setState(state => ({...state, 
          formFields: {...state.formFields, [type]: null, custom_exchange: val.value}
        }))
        return null

      }
    } else {

      this.setState(state => ({...state, 
        editFields: {...state.editFields, [type]: null}
      }))
    
      this.setState(state => ({...state, 
        formFields: {...state.formFields, [type]: null}
      }))

    }

  }


    this.setState(state => ({...state, 
      editFields: {...state.editFields, [type]: val}
    }))
  
    this.setState(state => ({...state, 
      formFields: {...state.formFields, [type]: val}
    }))

  }




  walletRenderer = (type) => {

    const exchangeQuestions = ['deposit', 'withdraw', 'buy', 'sell']
    const walletQuestions = ['transfer']
    const sourceQuestions = ['ico', 'mining', 'dividend', 'income', 'gift', 'airdrop', 'fork', 'purchase', 'donation', 'lost', 'fee']
    const user_scope = this.state.formFields['user_transaction']

    console.log('user_scope', user_scope)
    let exchangeDataName = null; 
    let exchangeDataId = null; 

    if (this.state.formFields.account_data !== null ) {

      exchangeDataName = this.state.formFields.account_data.exchange !== null ? this.state.formFields.account_data.exchange : this.state.formFields.account_data.custom_exchange
      console.log(exchangeDataName)
      try { exchangeDataId = this.state.formFields.account_data.exchange.id } catch(e) { exchangeDataId = this.state.formFields.account_data.custom_exchange.toLowerCase() }
        
    }

    if ( exchangeQuestions.includes(type) ) {

      return (

        <div style={{display: 'flex', alignItems: 'baseline', marginTop: 8}}>
          <div style={{width: 250, display: 'flex', alignItems: 'baseline'}}>
            <SmallExchangeQuestion 
              data_filter={this.props.exchanges}
              dataBind={exchangeDataName}
              exchange={exchangeDataId}
              passRef={this.firstFocusExchange}
              focusIn={() => this.focusOutRef(this.firstFocusExchange)}
              type="exchange"
              allowOther={false}
              clearRedux={["exchange"]}
              clearWallets={false}
              user_id={this.state.formFields.user_transaction['id']}
              transactionId={this.props.transactionId}
              handleWalletSelect={this.handleWalletSelect}
              //removeWallets={this.props.removeWallets}
              updaterMethod={(ref, value) => this.changeValue(ref, value)}
              handleSelect={(ref, value) => this.changeValue('exchange', value)}
              masterClass="transaction-coin-search"/>
          </div>
        </div>
      )

  } else  if ( sourceQuestions.includes(type) ) {
      return (
        
        <div style={{display: 'flex', alignItems: 'baseline', marginTop: 8}}>
          <div style={{width: 250, display: 'flex', alignItems: 'baseline'}}>
            <SourceQuestion 
              exchanges={this.props.exchanges}
              data_filter={this.props.exchanges}
              dataBind={exchangeDataName}
              exchange={exchangeDataId}
              wallet={this.state.formFields.storage}
              account={this.state.formFields.account}
              passRef={this.firstFocusExchange}
              focusIn={() => this.focusOutRef(this.firstFocusExchange)}
              type="exchange"
              allowOther={false}
              clearRedux={["exchange"]}
              clearWallets={false}
              user_id={this.state.formFields.user_transaction['id']}
              transactionId={this.props.transactionId}
              handleWalletSelect={this.handleWalletSelect}
              coin={this.state.formFields.base_id}
              //removeWallets={this.props.removeWallets}
              updaterMethod={(ref, value) => this.changeValue(ref, value)}
              handleSelect={(ref, value) => this.changeValue('exchange', value)}
              masterClass="transaction-coin-search"/>
            </div>
          </div>
      )
  } else  if ( walletQuestions.includes(type) ) {
    return (

      <div style={{display: 'flex', alignItems: 'baseline',}}>
          <div style={{width: 250, display: 'flex', alignItems: 'baseline', flexDirection: 'column'}}>
      <SmallTransferFirstBlock 
          noAdd={true}
          transactionId = {this.props.transactionId}
          getExchangeAddress = {this.getExchangeAddress}
          selectExchange = {this.props.selectExchange}
          onKeyDownNext = {this.props.onKeyDownNext}
          secondFocusExchange = {this.secondFocusExchange}
          selectedBase={this.state.formFields.base_currency}
          selectedQuote={this.state.formFields.quote_currency}
          //wallets={this.props.wallets}
          user_id={user_scope}
          user_first={this.props.user_first}
          user_last={this.props.user_last}
          handleSelect={(o) => this.handleFromSelect(o)}
      />

      {(this.state.formFields['from_wallet'] !== null && 
       this.state.formFields['from_wallet'] !== undefined) ?
      
       <SmallTransferSecondBlock 
          noAdd={true}
          transactionId = {this.props.transactionId}
          getExchangeAddress = {this.getExchangeAddress}
          selectExchange = {this.props.selectExchange}
          onKeyDownNext = {this.props.onKeyDownNext}
          secondFocusExchange = {this.secondFocusExchange}
          selectedBase={this.state.formFields.base_currency}
          selectedQuote={this.state.formFields.quote_currency}
          dataPass={this.state.formFields}
          updateTransaction={(data) => this.handleWalletSelect(data)}
          formData={this.state.formFields}
          handleWalletSelect={this.handleWalletSelect}
          firstFocusExchange={this.firstFocusExchange}
          //wallets={this.props.wallets}
          user_id={user_scope}
          user_first={this.props.user_first}
          user_last={this.props.user_last}
          handleSelect={(data) => this.handleWalletSelect(data)}
          exchangeDataName={exchangeDataName}
          exchangeDataId={exchangeDataId}
          focusOutRef={() => this.focusOutRef(this.firstFocusExchange)}
          changeValue={(ref, value) => this.changeValue('exchange', value)}
          />

       :

       null 
      }

      
          </div>
        </div>
    )
  }

  }
  render() {

    
    return (

      this.state.formFields !== null && this.state.formFields !== undefined ?

      <div>
        
          <BaseModalTop forceSingle={true} title="Edit transaction" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />


          {
            this.state.loading ? 

          <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 0.6, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
              <img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/>
          </div>


          :

          this.state.success ? 

            <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
                <img style={{height: 64, with: 64}} src={okIcon} alt="OK!"/>
                <div style={{marginTop: 24}} className="basier-p2">
                  Edit successful!
                </div>
            </div>

          :

          null 

          }


          <div style={{padding: 48, width: '100%', display: 'flex'}}>



            <div style={{width: '48%'}}>

              <div style={{display: 'flex', alignItems: 'baseline'}}>

                <div style={{marginLeft: 12, width: 140}} className='basier-p3'>Transaction Type : </div>
                <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 24}}>

                    <TypeList selected={this.state.formFields.transaction_description} changeType={(val) => this.changeValue('transaction_description', val) } />
                
                </div>
              
                </div>
              
              <div style={{display: 'flex', marginTop: 24}}>

                <div style={{marginLeft: 12, width: 140}} className='basier-p3'>Date : </div>
                <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 24}}>

                  <DateTimePicker 
                      onChange={(date) => this.handleDateChange(date)} 
                      value={this.state.formFields.date} 
                      locale="en-gb"
                      closeOnSelect={true}
                      strictParsing={true}
                      inputProps={{className: "select_datepicker basier-p3", placeholder: 'DD/MM/YYYY', onBlur: this.handleDateBlur}}
                  />

                  <div className="basier-p4-caps link-hover"  style={{marginLeft: 24, width: 60,}} onClick={this.fillToday}>Now</div>
                  </div>
                
                </div>

                <div style={{display: 'flex', alignItems: 'baseline', marginTop: 24}}>

                <div style={{marginLeft: 12, width: 140}} className='basier-p3'>Base currency : </div>
                <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 24}}>

                <CoinSearch 
                  data_filter={this.props.all_coins} 
                  dataBind={this.state.formFields.base_currency}
                  ref={this.baseCurencyRef}
                  width={250}
                  keepInput={true}
                  overrideClass={true} 
                  focusIn={(ref) => this.focusOutRef(this.baseCurencyRef)}
                  clearRedux='base_currency'
                  updaterMethod={(ref, value) => this.changeValue(ref, value)}
                  handleSelect={(value) => this.changeValue("base_currency", value.value)}
                  masterClass="transaction-coin-search"/>

                
                  </div>
                </div>

                { 

                  this.state.formFields.transaction_description === 'buy' || this.state.formFields.transaction_description === 'ico' ||this.state.formFields.transaction_description === 'sell' ?

                    <div style={{display: 'flex', alignItems: 'baseline', marginTop: 24}}>

                    <div style={{marginLeft: 12, width: 140}} className='basier-p3'>Quote currency : </div>
                    <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 24}}>

                    <CoinSearch 
                      data_filter={this.props.all_coins} 
                      dataBind={this.state.formFields.quote_currency}
                      ref={this.quoteCurencyRef}
                      width={250}
                      keepInput={true}
                      overrideClass={true} 
                      focusIn={(ref) => this.focusOutRef(this.quoteCurencyRef)}
                      clearRedux='quote_currency'
                      updaterMethod={(ref, value) => this.changeValue(ref, value)}
                      handleSelect={(value) => this.changeValue("quote_currency", value.value)}
                      masterClass="transaction-coin-search"/>

                    
                      </div>
                    </div>

                    :

                    null

                }

                <div style={{display: 'flex', alignItems: 'baseline', marginTop: 24}}>

                    <div style={{marginLeft: 12, width: 140}} className='basier-p3'>Amount : </div>
                    <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 24}}>
                      <input onChange={(e) => this.changeValue('amount', e.target.value)} className="transaction-coin-search" value={this.state.formFields.amount} />
                    </div>

                </div>

                {this.state.formFields['transaction_description'] !== 'transfer' ? 
                    
                    <div style={{display: 'flex', alignItems: 'baseline', marginTop: 24}}>

                      <div style={{marginLeft: 12, width: 140}} className='basier-p3'>Unit Price : </div>
                      <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 24}}>
                        <input onChange={(e) => this.changeValue('unit_price', e.target.value)} className="transaction-coin-search" value={this.state.formFields.unit_price} />
                      </div>

                    </div>

                    :
                    
                    null 

                  }


                <div style={{display: 'flex', alignItems: 'baseline', marginTop: 24}}>

                    <div style={{marginLeft: 12, width: 140}} className='basier-p3'>Fee : </div>
                    <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 24}}>
                      <input onChange={(e) => this.changeValue('fee', e.target.value)} className="transaction-coin-search" value={this.state.formFields.fee} />
                    </div>

                </div>

                {this.state.formFields['transaction_description'] === 'transfer' ? 
                    <div style={{display: 'flex', alignItems: 'baseline', marginTop: 24}}>

                    <div style={{marginLeft: 12, width: 140}} className='basier-p3'>Fee currency : </div>
                      <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 24}}>
                    
                        <CoinSearch 
                          data_filter={this.props.all_coins} 
                          dataBind={this.state.formFields.fee_currency}
                          ref={this.feeCurrencyRef}
                          width={250}
                          keepInput={true}
                          overrideClass={true} 
                          focusIn={(ref) => this.focusOutRef(this.feeCurrencyRef)}
                          clearRedux='fee_currency'
                          updaterMethod={(ref, value) => this.changeValue(ref, value)}
                          handleSelect={(value) => this.changeValue("fee_currency", value.value)}
                          masterClass="transaction-coin-search"/>

                      </div>
                    </div>

                    :
                    
                    null 

                    }

                </div>

                <div style={{width: '48%'}}>

                  {this.state.formFields['transaction_description'] !== 'transfer' ? 
                    <div style={{display: 'flex', alignItems: 'baseline'}}>

                    <div style={{marginLeft: 36, width: 140}} className='basier-p3'>Fee currency : </div>
                      <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 24}}>
                    
                        <CoinSearch 
                          data_filter={this.props.all_coins} 
                          dataBind={this.state.formFields.fee_currency}
                          ref={this.feeCurrencyRef}
                          width={250}
                          keepInput={true}
                          overrideClass={true} 
                          focusIn={(ref) => this.focusOutRef(this.feeCurrencyRef)}
                          clearRedux='fee_currency'
                          updaterMethod={(ref, value) => this.changeValue(ref, value)}
                          handleSelect={(value) => this.changeValue("fee_currency", value.value)}
                          masterClass="transaction-coin-search"/>

                      </div>
                    </div>

                    :
                    
                    null 

                    }
                  
                          {this.walletRenderer(this.state.formFields.transaction_description)}


                    <div style={{
                      position: 'absolute', 
                      display: 'flex',
                      width: '48%',
                      bottom: 64
                    }}>

                    <div style={{width: '100%', paddingLeft: 36, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                      <div className='basier' onClick={this.handleClose} 
                          style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          Cancel
                      </div>

                      <div className='button-next-blue' onClick={() => this.updateServer()} 
                          style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer'}}>
                          Submit
                      </div>
                    </div> 

                    </div>
                  


                </div>

                











                </div>

      </div>

      :

      null

    )
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        modalPref: state.interactions.transactionAddModal,
        portfoliosData: state.data.portfoliosData,
        transaction: state.interactions.transactionAddData,
        all_coins: state.appwide.all_coins, 
        exchangeCheck: state.interactions.exchangeCheck, 
        exchanges: state.appwide.exchanges, 
        wallets: state.appwide.wallets, 
        user_id: state.auth.user_id,
        user_first: state.auth.user_first_name,
        user_last: state.auth.user_last_name, 
        token: state.auth.token
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
       toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(ResponsiveDialog));
