import React from "react";
import { FixedSizeList as List } from "react-window";
import Plus from '../../../../../Media/Plus.svg'
import trIcon from '../../../../../Media/trIcon.svg'
import Grid from '@material-ui/core/Grid';
import {siwtchTransactionType} from '../../../../../helpers/helpers'
import ShowMore from '@tedconf/react-show-more';
import SmallTransactionEditDetail from './SmallTransactionEditDetail'
import { withRouter } from "react-router-dom";

class TransactionOverview extends React.PureComponent {

    state = {
        initialItems: [], 
        items: [], 
        detail: false,
        selectedItem: null
    }

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }

      getListSize = () => {
          return this.state.items.length
      }
      
      getSuggestions = (event) => {
        const value = event.target.value

        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
           
          this.setState(state => ({...state, items: this.state.initialItems}))
          return [];
        }
      
        const regex = new RegExp('\\b' + escapedValue, 'i');
        
        const filteredList = this.state.initialItems.filter(coin => regex.test(this.getSuggestionValue(coin)));
        this.setState(state => ({...state, items: filteredList})) 

      }

      getSuggestionValue  = (suggestion) => {
        return `${suggestion.name}`;
      }

      selectCoin = (evt) => {
        console.log(evt.target.value)
      }
    
      componentDidMount() {
        this.props.setCurrentView(5)
        if (this.props.transactions !== null) {
            this.setState(state => ( {...state, initialItems: this.props.transactions, items: this.props.transactions } ))
        }



      }

      componentDidUpdate(prevProps) {
        if (prevProps.transactions !== this.props.transactions && this.props.transactions !== null ) {
          console.log('items',this.props.transactions)
          this.setState(state => ( {...state, initialItems: this.props.transactions, items: this.props.transactions } ))
        }
        document.body.ontouchmove = (e) => { 
          return true;
        }
      
      }

    componentDidUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
      }


      clearInput = () => {
          document.getElementById('coin-search').value = '';
          this.setState(state => ( {...state, items: this.state.initialItems}))
      }

      seeDetail = (itemInfo) => {

          this.props.history.push({pathname: '/transaction_detail', 
                                   state: { item: itemInfo, portfolioId: this.props.portfolioId}}
                                  )
                                  
          this.setState(state => ( {...state, detail: true, selectedItem:itemInfo}))

      }

      deleteDeltail = () => {

        this.setState(state => ( {...state, detail: false, selectedItem:null}))

      }

      render() {
        
        const heightVitrual = window.innerHeight - `120`; 
        const widthVirtual = window.innerWidth;
        const itemHeight = heightVitrual/8

        return (

          this.state.items !== null ?

            this.state.detail ? 

            <div style={{height: '100%'}}>

              <SmallTransactionEditDetail item={this.state.selectedItem} backAction={this.deleteDeltail}/>

            </div>

            :

            <div style={{height: '100%'}}>

                <div style={{height: 120, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{paddingLeft: 24}}>
                        <div 
                            className="basier-p4-caps"
                            style={{color: '#6A7E93',
                                    display: 'inline-block'}}
                            >
                            Total Transactions
                        </div><br />
                        {this.props.portfoliosData[this.props.portfolioId] !== undefined ? 
                        <h2 style={{display: 'inline-block'}}>{this.props.portfoliosData[this.props.portfolioId]['number_transactions']}</h2>
                        : null }
                    </div>

                    <div  style={{paddingRight: 24, display: 'flex', alignItems: 'center'}} onClick={this.props.addNew}>
                      <img src={Plus} style={{height: 50, width: 50}} alt=""/>
                      <div className="basier" style={{fontSize: 16, textTransform: 'uppercase', marginLeft: 12}}>Add</div>
                    </div>
                </div>
                <div className="filter-list">

                <ShowMore items={this.state.items} by={10}>
                  {({ current, onMore }) => {
                    return (
                    <div>
                      <div>{current.map(item => 

                        <div style={{ 
                        paddingLeft: 12, 
                        paddingRight: 12, 
                        }} 
                        value={item.id}
                        onClick={() => this.seeDetail(item)}
                        >
                          <div style={{
                              display: 'flex', 
                              alignItems: 'center',
                              marginBottom: 7,
                              paddingLeft: 12,
                              marginTop: 7,
                              boxShadow: item === undefined ? 'none' : '0px 10px 10px rgba(225, 229, 233, 0.3)', 
                              borderRadius: item === undefined ? 0 : 35, 
                              height: 70,
                              border: '0px',
                              background: item === undefined ? 'white' : 
                              item.transaction_description === 'buy' || item.transaction_description === 'ico' ? 
                                  'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #4CDAC1'
                              : 
                              item.transaction_description === 'deposit' 
                              ? 
                                  'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #C174FD'
                              : 
                              item.transaction_description === 'mining' || 
                              item.transaction_description === 'dividend' || 
                              item.transaction_description === 'income' || 
                              item.transaction_description === 'gift' || 
                              item.transaction_description === 'airdrop' || 
                              item.transaction_description === 'fork' 
                              ? 
                                  'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #ffc163'
                              :
                              item.transaction_description === 'sell' || 
                              item.transaction_description === 'fee' || 
                              item.transaction_description === 'lost' 
                              ? 
                                  'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #F9716B'
                              :
                              item.transaction_description === 'withdraw' ? 
                                  'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #0078DB'
                              : 
                              item.transaction_description === 'transfer' ||
                              item.transaction_description === 'purchase' ||
                              item.transaction_description === 'donation' 
                               ? 
                                  'linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #6A7E93'
                              : 
                              'white'
                            
                            }}>
                              
                              <div style={{width: 106,}}>
                                  <div 
                                      className={`basier-p4 transaction-bubble transaction-color-${item.transaction_description}`}
                                      style={{
                                          textTransform: 'uppercase', 
                                          fontWeight: 900, 
                                          marginLeft: 0,
                                      }}
                                  
                                      >
                                      {siwtchTransactionType(item.transaction_description)[0]}
                                  </div>
                              </div>

                              <div>
                                      
                                      <div>
                                        <span>
                                          { item.amount === null || item.amount === undefined ? null :
                                          item.amount.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}
                                        </span>
                                        <span style={{marginLeft: 6}}>
                                          {item.base_ticker}
                                        </span>

                                          { item.quote !== null && item.unit_price !== 0 ? 
                                            
                                            <span>
                                              <span style={{marginLeft: 6}}>
                                                x
                                              </span>
                                              <span style={{marginLeft: 6}}>
                                                  {item.quote_ticker} 
                                              </span>
                                            </span>
                                            :
                                            null
                                          }

                                      </div>

                                      { item.quote !== null && item.unit_price !== 0 ? 
                                      <div>
                                         <span>
                                                @
                                        </span>
                                        <span style={{marginLeft: 3}}>
                                        { item.unit_price === null || item.unit_price === undefined ? null :
                                        
                                        item.unit_price >= 1 ? 

                                            item.unit_price.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                                            :
                                            item.unit_price !== 0 ?
                                              item.unit_price.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                                            :
                                            null

                                        }
                                        </span>
                                        <span style={{marginLeft: 6, fontStyle: 'italic'}}>
                                          per unit
                                        </span>
                                      </div>
                                      :
                                      null
                                      }


                              </div>
                          
                          </div>

                          </div>

                      
                      )}</div>
                      { this.props.portfoliosData[this.props.portfolioId] !== undefined ? 
                        this.props.portfoliosData[this.props.portfolioId]['number_transactions'] > 10 && 
                          <div
                            style={{
                              marginLeft: 12, 
                              marginRight: 12, 
                              height: 50, 
                              marginBottom: 72,
                              borderRadius: 35,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: '#6A7E93',
                              paddingLeft: 15,
                              paddingRight: 15,
                              border: '1px solid #6A7E93',
                              marginTop: 24,
                            }}
                            disabled={!onMore}
                            onClick={() => {
                              if (!!onMore) onMore();
                            }}
                          >
                            See more
                          </div>
                        :
                          null
                      }
                      {
                        this.props.portfoliosData[this.props.portfolioId] !== undefined ? 
                          this.props.portfoliosData[this.props.portfolioId]['number_transactions'] == 0 &&
                          <div style={{height: 280, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 12}}>
                              <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 24, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                  <div>
                                      <img src={trIcon} alt="" style={{height: 80}} />
                                  </div>
                                  <div className="basier-p2" style={{marginTop: 24}}>
                                      <div>You do not have any transactions in your portfolios. Once you add transactions you will see them here.</div>
                                  </div>
                              </div>
                          </div>
                          :
                          null
                      }
                    </div>
                    
                    )}
                    }
                </ShowMore>
                
                </div>
            </div>

          :

          null 

        )
      }

}

export default withRouter(TransactionOverview);