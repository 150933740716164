import React from 'react';
import CoinSearch from '../../../../../AppWide/CoinSearch';
import ToOriginList from './ToOriginList'
import ExchangeQuestion from './SmallExchangeQuestion'
import * as actions from "../../../../../../store/actions/interactions";
import { connect } from 'react-redux';
import WalletQuestion from './WalletQuestion'
import SelectSource from './SelectSource'
import UserList from '../UserList'
import DetailWalletList from '../QuestionBlocks/DetailWalletList'
import OriginList from './OriginList'
import SourceQuestion from './SmallSourceQuestion'

class TransferSecondBlock extends React.PureComponent {


    state = {
        originSelected: this.props.currentPortfolio, 
        userSend: this.props.user_id['id'], 
        scopeUser: undefined, 
        toOriginType: 'exchange',
    }

    componentDidMount() {
        
        this.props.updateTransaction({key: this.props.transactionId, label: "user_wallet_transfer", value: this.props.user_id})

        if (this.props.scopedPortfolios !== null && this.props.scopedPortfolios !== undefined) {
            this.props.updateTransaction({key: this.props.transactionId, label: "destination_portfolio", value: this.props.currentPortfolio})
            this.setState(state => ({...state, scopeUser: this.props.scopedPortfolios[this.props.currentPortfolio]['user']}))
        }

    }


    changeOrigin = (origin) => {

        this.props.updateTransaction({key: this.props.transactionId, label: "to_wallet", value: origin})

    }

    changeToOrigin = (origin) => {

        this.setState(state => ({...state, toOriginType: origin}))
        this.props.updateTransaction({key: this.props.transactionId, label: "exchange", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "exchange_address", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "account", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage_name", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})

    }

    changePortOrigin = (origin) => {

        this.props.updateTransaction({key: this.props.transactionId, label: "exchange", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "exchange_address", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "account", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage_name", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "destination_portfolio", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "user_wallet_transfer", value: null})

        if (origin === null) {
           this.setState(state => ({...state, originSelected: origin, scopeUser: null}))  
        } else {
            const scopeUser = this.props.scopedPortfolios[origin]['user']
            this.setState(state => ({...state, originSelected: origin, scopeUser: scopeUser}))
            this.props.updateTransaction({key: this.props.transactionId, label: "user_wallet_transfer", value: this.props.user_id})
            this.props.updateTransaction({key: this.props.transactionId, label: "destination_portfolio", value: origin})

        }

    }

    handleDestinationSameChange = (val) => {

        if (val !== this.state.userSend) {

            this.props.updateTransaction({key: this.props.transactionId, label: "exchange", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "exchange_address", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "account", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "storage_name", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "user_wallet_transfer", value: val})
            this.setState(state => ({...state, userSend: val}))
            this.changeOrigin(null)

        }
        

        
    }

    render() {

        console.log('state at render', this.state)
        return (

            <div>
                
                {(this.props.dataPass['from_wallet'] !== null && 
                 this.props.dataPass['from_wallet'] !== undefined) ? 
                <div style={{

                display: 'flex', 
                height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                alignItems: 'baseline',
                transition: 'height 0.3s', 
                marginTop: 24,
                }}>
                    

                    <div style={{marginLeft: 24, paddingLeft: 12, width: 140}} className='basier-p3'>
                        On Portfolio : 
                    </div>
                    <ToOriginList changeOrigin={this.changePortOrigin} data={this.props.scopedPortfolios} currentPort={this.props.currentPortfolio} />
                    
                </div>

                : 

                null
                
                }

                <div>

                    {
                        this.state.originSelected !== null && this.state.originSelected !== undefined ? 

                        <div>

                            <div style={{

                                display: 'flex', 
                                height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                                alignItems: 'baseline',
                                transition: 'height 0.3s'
                                }}>
                                    

                                    <div style={{marginLeft: 24, paddingLeft: 12, width: 140}} className='basier-p3'>
                                        To User : 
                                    </div>
                                    <UserList 
                                                transactionId={this.props.transactionId} 
                                                portfolioId={this.state.originSelected} 
                                                userProvided={this.state.scopeUser}
                                                prov={true}
                                                propagate={true}
                                                handleChange={
                                                    (o) => this.handleDestinationSameChange(o)
                                                } 
                                    />
                                    
                                </div>

                                <SourceQuestion 
                                    exchanges={this.props.exchanges}
                                    data_filter={this.props.exchanges}
                                    dataBind={this.props.exchangeDataName}
                                    exchange={this.props.exchangeDataId}
                                    wallet={this.props.formData.wallet}
                                    account={this.props.formData.account}
                                    passRef={this.props.firstFocusExchange}
                                    focusIn={() => this.props.focusOutRef(this.firstFocusExchange)}
                                    type="exchange"
                                    allowOther={false}
                                    clearRedux={["exchange"]}
                                    clearWallets={false}
                                    user_id={this.state.userSend}
                                    transactionId={this.props.transactionId}
                                    handleWalletSelect={this.props.handleWalletSelect}
                                    coin={this.props.formData.base_id}
                                    //removeWallets={this.props.removeWallets}
                                    updaterMethod={(ref, value) => this.props.changeValue(ref, value)}
                                    handleSelect={(ref, value) => this.props.changeValue('exchange', value)}
                                    masterClass="transaction-coin-search"/>



                    </div>


                        : 

                        null
                    }
                    

                </div>

            </div>

        )
    }

}


const mapStateToProps = state => {
    return {
  
        modalPref: state.interactions.transactionAddModal,
        transaction: state.interactions.transactionAddData,
        tr_date: state.interactions.dateTransaction,
        exchangeCheck: state.interactions.exchangeCheck, 
        originSelected: state.interactions.originSelected,
        exchanges: state.appwide.exchanges,
        wallets: state.appwide.wallets,
        currentPortfolio: state.data.menu_selected, 
        scopedPortfolios: state.appwide.all_portfolios,
        scopedWallets: state.appwide.flat_wallets
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(TransferSecondBlock);
