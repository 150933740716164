import React from 'react';
import { relative } from 'path';
import Grid from '@material-ui/core/Grid';
import FlexibleLargeGraph from '../AppWide/FlexibleLargeGraph';
import SmallGraph from '../AppWide/FlexibleSmallGraph';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import * as interactionActions from "../../store/actions/interactions";
import PortfolioList from './OverviewComponents/PortfolioLists'
import LargePortfolioList from './OverviewComponents/LargePortfolioList'
import {createLoadingSelector} from '../../store/reducers/selectors'
import GlobalAssets from './OverviewComponents/GlobalAssets'
import WatchListGlobal from './OverviewComponents/WatchListGlobal'
import './Overview.css'
import Hidden from '@material-ui/core/Hidden';
import Plus from '../../Media/Plus.svg'
import FirstStepUp from './OverviewComponents/NoPortfolios'
import chartIcon from '../../Media/chartIcon.svg'
class OverviewContainer extends React.PureComponent {
    
    componentDidMount() {

        const request_data = {
            period: this.props.graphData.period,
            start: this.props.graphData.start,
            end: this.props.graphData.end, 
            scope: this.props.graphData.inBitcoin,
        }  

        this.props.setCurrentView(1);
        this.props.setMenuPortfolio('/');
        this.props.componentDataFetch('get', 1, this.props.currency, this.props.user, null, request_data, null, this.props.inBitcoin, this.props.token)
        
    }

    changeTimePeriod = (newPeriod) => {
        this.setState(state => ({...state, timeperiod: newPeriod}))
    }

    togglePfModal = () => {

        this.props.toggleModal({type: 'new_portfolio', open: true, payload: {} })

    }


    render() {

        const page_render = (

            <div style={{
                width: '100vw',
                backgroundColor: '#F8F9FA', 
            }}>
                
                {
                this.props.graphData.start_date !== null ? 
                    this.props.graphData.values.length > 0 ? 
                    <div style={{width: '100%'}}>
                        <Hidden smDown>
                            <FlexibleLargeGraph 
                                data={this.props.graphData.values}
                                start_date={this.props.graphData.start_date}
                                today_value={this.props.graphData.today_value}
                                user={this.props.user}
                                currencyScope={this.props.graphData.inBitcoin}
                                currency={this.props.currency}
                                setPfScope={this.props.setPfScope}
                                updateGraph={(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) => this.props.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)}
                                updateTime={this.props.updateTime}
                                setNewScope={this.props.setGraphScope}
                                timePeriod={this.props.timePeriod}
                                loadedGraph={this.props.loadedGraph}
                                background="#F8F9FA"
                                scope="global" />
                        </Hidden>

                        <Hidden mdUp>
                            <SmallGraph 
                                    data={this.props.graphData.values}
                                    start_date={this.props.graphData.start_date}
                                    today_value={this.props.graphData.today_value}
                                    user={this.props.user}
                                    currencyScope={this.props.graphData.inBitcoin}
                                    currency={this.props.currency}
                                    setPfScope={this.props.setPfScope}
                                    updateGraph={(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) => this.props.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)}
                                    updateTime={this.props.updateTime}
                                    setNewScope={this.props.setGraphScope}
                                    timePeriod={this.props.timePeriod}
                                    loadedGraph={this.props.loadedGraph}
                                    background="#F8F9FA"
                                    scope="global" />
                        </Hidden>
                    </div>

                    :
                    
                    <React.Fragment>
                        <Hidden smDown>
                            <div style={{height: 530, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 24}}>
                                <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                    <div>
                                        <img src={chartIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any transactions in your portfolios yet.</div>
                                        <div>Once you add transactions you can see your investments' historical evolution here.</div>
                                    </div>
                                </div>
                            </div>
                        </Hidden>

                        <Hidden mdUp>
                            <div style={{height: 300, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 12}}>
                                <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 24, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                    <div>
                                        <img src={chartIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any transactions in your portfolios yet. Once you add transactions you can see your investments' historical evolution here.</div>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                    </React.Fragment>
                
                :
                
                <div>

                    <Hidden smDown>
                        
                        <div style={{backgroundColor: 'white', height: 533, width: '100%', paddingTop: 48}}>

                            <div style={{marginLeft: 48, height: 83, width: 450, display: 'flex', alignItems: 'center'}}>
                                <div className="pulse-skeleton" style={{width: 83, height: 83, borderRadius: 100, }}></div>

                                <div style={{marginLeft: 24, display: 'flex', flexDirection: 'column'}}>
                                    <div className="pulse-skeleton" style={{width: 325, height: 45, borderRadius: 10, marginRight: 0}}></div>
                                    <div className="pulse-skeleton" style={{width: 325, height: 26, marginTop: 12, borderRadius: 10, marginRight: 0}}></div>

                                
                                </div>
                                

                            </div>
                            <div style={{marginLeft: 0, height: 450}}>
                                <div style={{marginTop: 48}}></div>
                                <div style={{width: '100%', height: 402}} className="pulse-skeleton"></div>
                            </div>

                        </div>
                    
                    </Hidden>
                
                    <Hidden mdUp>

                        <div style={{backgroundColor: 'white', height: 417, width: '100%'}}>

                        <div style={{marginLeft: 12, height: 83, width: '100%', display: 'flex', alignItems: 'center'}}>
                                <div className="pulse-skeleton" style={{width: 75, height: 75, borderRadius: 100, }}></div>

                                <div style={{marginLeft: 24, display: 'flex', flexDirection: 'column'}}>
                                    <div className="pulse-skeleton" style={{width: 200, height: 30, borderRadius: 10, marginRight: 0}}></div>
                                    <div className="pulse-skeleton" style={{width: 200, height: 18, marginTop: 12, borderRadius: 10, marginRight: 0}}></div>
                                </div>
                            </div>

                            <div style={{marginLeft: 0, height: 334}}>
                                <div style={{marginTop: 12}}></div>
                                <div style={{width: '100%', height: 322}} className="pulse-skeleton"></div>
                            </div>

                        </div>

                    </Hidden>

                </div>
                        
                
                }




                <div className="overview-container-responsive">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h3 style={{marginBottom: 6}}>
                            Portfolios
                        </h3>
                        <span onClick={this.togglePfModal} className="basier-p4-caps" style={{userSelect: 'none', color: 'black', marginLeft: 24, cursor: 'pointer'}}>
                            <span><img src={Plus} alt="" style={{marginRight: 12}}/></span>
                            <span>Add a Portfolio</span>
                        </span> 
                        </div>
                        
                    </div>
                <div className="portfolio-wrapper">

                    <Hidden smDown>
                        <LargePortfolioList />
                    </Hidden>

                    <Hidden mdUp>
                        <PortfolioList />
                    </Hidden>

                </div>


                <div style={{marginLeft: 48, marginRight: 48, paddingTop: 12, paddingBottom: 80}}>
                    
                    <Hidden smDown>

                        <Grid container 
                            spacing={2}
                            justify="space-between"
                            alignItems="flex-start"
                        >

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{paddingRight: 24}}>
                                <GlobalAssets />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{paddingLeft: 24}}>
                                <WatchListGlobal />
                            </Grid>


                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{paddingLeft: 24}}>
                                
                            </Grid>
                        
                        </Grid>
                    
                    </Hidden>

                </div>

            </div>
        )
            

        return (

            this.props.list_portfolio.length > 0 ?
            <div>{page_render}</div> 
            :
            <FirstStepUp newPortModal={this.togglePfModal} />

            
        )
    }

}

const graphLoadingSelector = createLoadingSelector(['UPDATE_GRAPH']);
const graphPortfolioSelector = createLoadingSelector(['UPDATE_ALL_PORTFOLIOS']);

const mapStateToProps = state => {
    return {
        graphData: state.data.globalBalance,
        currency: state.auth.currency.id,
        globalAssetList: state.data.globalAssetList,
        user: state.auth.user_id, 
        list_portfolio: state.auth.list_portfolios,
        loading_graph: graphLoadingSelector(state),
        loading_port: graphPortfolioSelector(state),
        timePeriod: state.data.globalBalance.period,
        loadedGraph: graphLoadingSelector(state),
        last_updated: state.interactions.overviewUpdated,
        inBitcoin: state.data.bitcoinPf,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        updateTime: (time) => 
            dispatch(actions.setGlobalTime(time)), 
        updateRequestTime: (time) => 
            dispatch(interactionActions.updateRequestTime(time)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        setGraphScope: (currency, scope) => 
            dispatch(actions.setGraphScope(currency, scope)), 
        setPfScope: (currency) => 
            dispatch(actions.setPfScope(currency)), 
        toggleModal: (data) => 
            dispatch(interactionActions.toggleModal(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OverviewContainer);
