import React from 'react';
import { connect } from 'react-redux';
import {siwtchTransactionType,loadData } from '../../../../../../helpers/helpers';
import BackIcon from "../../../../../../Media/BackIcon.svg";
import SmallTransactionEditDetail from '../SmallTransactionEditDetail'
import axios from 'axios';
import * as dataActions from "../../../../../../store/actions/data";
import { backendUrl } from '../../../../../../variables'
import currencyDict from '../../../../../../helpers/currency.json';


class TransactionViewMobile extends React.PureComponent {

    state = {
        open: false,
        coin_in_scope: null, 
        graph_data: [], 
        graph_start: null,
        edit: false,
    }

    componentDidMount() {
        this.props.setCurrentView(51)
        console.log(this.props.location.state.item)
        const transaction_id = this.props.location.state.item.id
        this.getData(transaction_id)
        
      }
    

    getData = (transaction_id) => {
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {

        headers['Authorization'] = `Token ${this.props.token}`

    }
    
    const url_call = `${backendUrl}/v1/transaction_detail/${transaction_id}`

    const transactionDetail = loadData(url_call, headers)
    transactionDetail
        .then(json => {

            this.setState(state => ({...state, item: json['data']}))
            console.log(json['data'])

        })

    }

    editHandler = () => {
        this.setState(state => ({...state, edit: true}))
    }

    deleteHandler = () => {
        if (window.confirm("Are you sure you want to delete this transction")) {
            this.deleteTransaction()
          } 
    }

    deleteTransaction = () => {

        let headers = { "content-type": "application/json" };
        headers['x-user'] = this.props.user_id
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/transaction_detail/${this.props.location.state.item.id}`

        axios.delete(url_call, {headers: headers})
            .then(json => {
                console.log('calling')
                this.props.componentDataFetch('get', 5, this.props.currency_id, null, this.props.location.state.portfolioId, null, null, this.props.inBitcoin)
                this.props.history.goBack()
                //console.log(json['data'])

            })


    }

    cancelEdit = () => {
        this.setState(state => ({...state, edit: false}))
    }
    
    backHandler = () => {
        this.props.backAction()
    }
    
    render () {

        const { item } = this.state;
        let displayDate = null
        try {
            let date = new Date(item.date)
            const language = navigator.language || 'en'
            const options = {year: '2-digit', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            displayDate = date.toLocaleDateString(language, options)
        } catch(e) {}
            

        return ( 
            
            this.state.edit ? 
            
            <SmallTransactionEditDetail edit={true} new={false} passedData={item} getTrData={(id) => this.getData(id)} cancelAdd={this.cancelEdit} />

            :
            
            <div style={{position: 'absolute', top: 0, zIndex: 999999, width: '100%'}}>

                <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed', justifyContent: 'space-between'}} id="responsive-dialog-title">
                    
                        <div onClick={() => this.props.history.goBack()}>
                            <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                        </div>

                        <div>
                            <div className="touchableOpacity basier"  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '1.2rem'}}>
                                
                                <div className="modalActionButton"  onClick={this.deleteHandler} style={{marginRight: 48, color: '#F9716B'}}>Delete</div>

                                 <div className="modalActionButton"  onClick={this.editHandler} style={{marginRight: 24, color: '#0078DB'}}>Edit</div>
                               
                            </div> 
                        </div>

                    </div>

                { item === null || item === undefined ? 
                    
                    null 


                :
    
                    <div style={{marginTop: 75, marginBottom: 75, backgroundColor: 'white', paddingTop: 0, width: '100%'}}>
                        <div style={{padding: 24}}>


                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <div 
                                    className={`basier transaction-bubble transaction-color-${item.transaction_description}`}
                                    style={{
                                        marginBottom: 12, 
                                        fontSize: 20,
                                        fontWeight: 900, 
                                        padding: 20, 
                                        marginLeft: 0,
                                        
                                        }}>

                                        {siwtchTransactionType(item.transaction_description)[0]}
                                </div>

                                <div className="basier-p3" style={{marginLeft: 12}}>
                                    {displayDate}
                                </div>

                            </div>

                            <div style={{marginTop: 12}}>

                            

                            </div>



                            <div style={{marginTop: 24,}}>

                                <div className="basier-p4-caps">
                                    <div>
                                        Base currency
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        
                                        {
                                                    
                                            item.base_currency.image_thumb !== "" && item.base_currency.image_thumb !== null  ? 
                                                <span><img style={{height: 30, width: 30, marginRight: 12}} src={item.base_currency.image_thumb} alt=""/></span>
                                                :
                                                <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[item.base_currency.symbol] === undefined ? '16px' : currencyDict[item.base_currency.symbol]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                                                    {currencyDict[item.base_currency.symbol] === undefined ? item.base_currency.symbol : currencyDict[item.base_currency.symbol]['symbol'].length > 1 ? currencyDict[item.base_currency.symbol]['symbol'] : currencyDict[item.base_currency.symbol]['symbol'] }
                                                </span>


                                            }


                                        <h4 style={{marginTop: 8}}>
                                            {item.base_currency.name}
                                        </h4>
                                    </div>
                                </div>

                            </div>
                            
                            { item.transaction_description === "buy" || item.transaction_description === "ico" || item.transaction_description === "sell" ? 

                                <div style={{marginTop: 24}}>

                                    <div className="basier-p4-caps">
                                        <div>
                                            Quote currency
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center'}}>

                                            {
                                                    
                                                item.quote_currency.image_thumb !== "" && item.quote_currency.image_thumb !== null  ? 
                                                    <span><img style={{height: 30, width: 30, marginRight: 12}} src={item.quote_currency.image_thumb} alt=""/></span>
                                                    :
                                                    <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[item.quote_currency.symbol] === undefined ? '16px' : currencyDict[item.quote_currency.symbol]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                                                        {currencyDict[item.quote_currency.symbol] === undefined ? item.quote_currency.symbol : currencyDict[item.quote_currency.symbol]['symbol'].length > 1 ? currencyDict[item.quote_currency.symbol]['symbol'] : currencyDict[item.quote_currency.symbol]['symbol'] }
                                                    </span>
    
    
                                                }

                                            <h4 style={{marginTop: 8}}>
                                                {item.quote_currency.name}
                                            </h4>
                                        </div>
                                    </div>

                                </div>
                            
                        
                            :
                            
                                null 

                            }
                            
                            <div style={{marginTop: 24, display: 'flex', alignItems: 'center'}}>

                                <div className="basier-p4-caps" style={{width: '48%'}}>
                                    <div>
                                        Amount
                                    </div>
                                    <h4 style={{marginTop: 8}}>
                                        {item.amount >= 1 ? 
                                            item.amount.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                                        :
                                            item.amount.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                                        }
                                    </h4>
                                </div>
                            </div>


                                { item.transaction_description === "buy" || item.transaction_description === "ico" || item.transaction_description === "sell" ? 

                                <div style={{marginTop: 24}}>

                                    <div className="basier-p4-caps" style={{width: '49%'}}>
                                        <div>
                                            Unit Price
                                        </div>
                                            <h4 style={{marginTop: 8}}>
                                            {item.unit_price >= 1 ? 
                                                item.unit_price.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                                            :
                                                item.unit_price.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                                            }
                                            </h4>
                                    </div>

                                </div>
                            
                        
                            :
                            
                                null 

                            }

                                

                                
                            { item.fee !== undefined && item.fee !== null && item.fee !== 0 ?

                                <div style={{marginTop: 24}}>

                                <div className="basier-p4-caps">
                                    Fees
                                </div>

                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                    <h4 style={{marginTop: 8}}>
                                        {item.fee >= 1 ? 
                                                item.fee.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                                            :
                                                item.fee.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                                            }
                                    </h4>
                                    {

                                        item.fee_currency !== null && item.fee_currency !== undefined ?
                                        
                                        <div className="basier-p3" style={{marginLeft: 8}}>
                                            in {item.fee_currency.name}
                                        </div>
                                        
                                        :
                                        
                                        null
                                        
                                    }
                                </div>

                            </div>
                                
                            :
                            

                            null
                        
                        
                            }
                        
                        {    
                            item.account !== null && item.account !== undefined ? 
                                <div style={{marginTop: 24}}>
                                    <div className="basier-p4-caps">
                                        <div>
                                            Wallet
                                        </div>
                                    </div>
                                        <div style={{display: 'flex', alignItems: 'center'}}>

                                            { item.account.exchange !== null ? 
                                            <img src={item.account.exchange.image} alt="" style={{height: 30, width: 30, marginRight: 12}} />
                                            :
                                            null
                                            }
                                            <h4 style={{marginTop: 8}}>
                                                {item.account.name}
                                            </h4>

                                                { item.account.exchange !== null ? 

                                                <div  className="basier-p2" style={{marginLeft: 6}}>
                                                    <span style={{fontStyle: 'italic'}}>({item.account.exchange.name})</span>
                                                </div>

                                                :

                                                null
                                                }
                                            
                                    </div>
                                </div>
                                        
                                    : 
                                    
                                    item.wallet !== null && item.wallet !== undefined ?

                                    <div style={{marginTop: 24}}>

                                        <div className="basier-p4-caps">
                                            <div>
                                                Wallet
                                            </div>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                                <h4 style={{marginTop: 8}}>
                                                    {item.wallet.name}
                                                </h4>
                                                <div className="basier-p2" style={{fontStyle: 'italic'}}>(Storage wallet)</div>
                                            </div>
                                        </div>


                                    : 
                                    
                                        <div style={{marginTop: 24}}>
                                            <div className="basier-p4-caps">
                                                    <div>
                                                        Wallet
                                                    </div>
                                            </div>

                                            <div style={{fontStyle: 'italic'}}>Not specified</div>
                                        </div>
                                    
                            }

                            { item.comments !== null && item.comments !== undefined ? 


                                <div style={{marginTop: 24}}>

                                <div>
                                    <div>
                                        Comments
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 8}}>
                                        {item.comments}
                                    </div>
                                </div>

                                </div>

                                : 

                                null 
                            
                            }
                            
                        
                        </div>
                    </div>

            }

            </div>
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        currency_id: state.auth.currency.id,
        inBitcoin: state.data.bitcoinPf, 
        user_id: state.auth.user_id, 
        token: state.auth.token,

        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        setCurrentView: (view) => 
            dispatch(dataActions.setCurrentView(view)),
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionViewMobile);
