import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import * as interactionActions from "../../store/actions/interactions";
import PortfolioTabs from './PortfolioComponents/PortfolioTabs'
import Settings from './PortfolioComponents/Settings/SettingsOverview'
import PortfolioOverview from './PortfolioComponents/Overview/PortfolioOverview'
import PortfolioProfit from './PortfolioComponents/Profit/PortfolioProfit'
import TransactionOverview from './PortfolioComponents/Transactions/TransactionOverview'
import Hidden from '@material-ui/core/Hidden';
import { withRouter } from "react-router-dom";
import './Portfolio.css'
import Upload from '../../Other'
import CollaboratorOverview from './PortfolioComponents/Collaborators/CollaboratorWrapper'
import AnalysisOverview from './PortfolioComponents/Analysis/AnalysisOverview'
import TransactionWrapper from './PortfolioComponents/Transactions/TransactionPicker'
import noAccess from '../../Media/noAccess.svg';
import Warning from '../../Media/warning.svg';
import ReportOverview from './PortfolioComponents/Reports/ReportOverview'

function TabContainer(props) {
    return (
        <div>
            {props.children}
        </div>
    );
  }

const switchTabs = (tab) => {

    switch(tab) {
        case 'overview': 
            return 0
        case 'profit': 
            return 1
        case 'transactions': 
            return 2
        case 'report': 
            return 3
        case 'analysis': 
            return 4
        case 'balances': 
            return 5
        case 'collaborators': 
            return 5
        case 'settings': 
            return 6
        default: 
            return 0
    }

}

const switchInts = (tab) => {

    switch(tab) {
        case 0: 
            return 'overview'
        case 1: 
            return 'profit'
        case 2: 
            return 'transactions'
        case 3: 
            return 'report'
        case 5: 
            return 'balances'
        case 6: 
            return 'settings'
        default: 
            return 'overview'
    }

}


class PortfolioContainer extends React.Component {

    constructor(props) {

        super(props);
        this.portfolio_id = this.props.match.params.portfolio
        this.section = undefined
        this.subsection = undefined
        try { this.section = this.props.match.params.section } catch(e) { }
        if (this.section === undefined) {
            this.section = 'overview';
            this.props.history.push(`/portfolio/${this.portfolio_id}/overview`)
        }

        try { this.subsection = this.props.match.params.subsection } catch(e) {}
        this.props.setPfTab(switchTabs(this.section))
        this.state = {
            allowed: null, 
        }
    }

    redirect = () => {
        this.props.showSnack({message: 'You do not have access to this portfolio.', variant: "error"})
        this.props.history.push('/')
    }
    
    componentDidMount() 
    {
        console.log('mounting 2')

        this.props.setInteraction({label: 'pin', value: true})

        const myPorts = this.props.list_portfolios.filter(v => v.id == this.props.match.params.portfolio)
        const isAllowed = myPorts.length > 0
        this.setState({allowed: isAllowed})

        const request_data = {
            period: this.props.portfoliosBalance.period,
            start: this.props.portfoliosBalance.start,
            end: this.props.portfoliosBalance.end,
            scope: this.props.portfoliosBalance.inBitcoin
        }
        if (isAllowed) {
            this.props.setMenuPortfolio(this.portfolio_id)
            console.log('calling at mount 2')
            
            this.props.componentDataFetch('get', 2, this.props.currency, this.props.user, this.portfolio_id, null, request_data, this.props.inBitcoin)
        }
    }

    componentDidUpdate(prevProps) 
    {  
        if (this.props.match.params.portfolio !== this.portfolio_id) {

            let isAllowed = false
            if (this.props.list_portfolios !== undefined && prevProps.list_portfolios !== this.props.list_portfolios) {
                const myPorts = this.props.list_portfolios.filter(v => v.id == this.props.match.params.portfolio)
                isAllowed = myPorts.length > 0
                this.setState({allowed: isAllowed})
            }
            this.portfolio_id = this.props.match.params.portfolio
            
            if (isAllowed) {
                const request_data = {
                    period: this.props.portfoliosBalance.period,
                    start: this.props.portfoliosBalance.start,
                    end: this.props.portfoliosBalance.end,
                    inBitcoin: this.props.portfoliosBalance.inBitcoin
                }
                console.log('calling at update 2')
                this.props.componentDataFetch('update', 2, this.props.currency, this.props.user, this.portfolio_id, null, request_data, this.props.inBitcoin)
            }

        }
    
    }

    handleTabChange = (event, val) => {
        this.props.history.push(`/portfolio/${this.portfolio_id}/${switchInts(val)}`)
        this.props.setPfTab(val)
    }

    render() {

        return (

            this.state.allowed !== null ? 

            this.state.allowed === true ? 

                
                <div style={{minWidth:'100%'}}>

                    {this.props.subscription !== undefined && this.props.list_portfolios.filter(v=> v.id == this.props.match.params.portfolio).length > 0 ? 
                    <PortfolioTabs handleChange={this.handleTabChange} value={this.props.tab} plan={this.props.subscription.plan} portfolioType={this.props.list_portfolios.filter(v=> v.id == this.props.match.params.portfolio)[0]['portfolio_ownership']}/>
                    :
                    null
                    }

                    <div className="portfolio-content">
                    
                    
                    { this.props.portfoliosData[this.portfolio_id] !== undefined ?  
                    ( this.props.portfoliosData[this.portfolio_id]['limited']  &&
                    <div style={{marginLeft: 48, display: 'flex', alignItems: 'center', marginBottom: 12}}>
                        <img src={Warning} alt="" style={{height: 30, width: 30, marginRight: 12}} />
                        <div>This portfolio may display incorrect data as its owner no longer has an active Pro plan. Please change the portfolio Owner to an account with an active Pro plan or reactivate the Pro plan of the current owner. Adding new transactions has also been disabled.</div>
                    </div>
                    )
                    :
                    null
                    }

                    {this.props.tab === 0 && 
                    this.props.portfoliosData[this.portfolio_id] !== undefined &&
                        <div>
                            <TabContainer>
                                <PortfolioOverview  portfolioId={this.portfolio_id}/>
                            </TabContainer>
                        </div>
                    
                    }
                    
                    
                    
                    {this.props.tab === 1 && 


                        <TabContainer><PortfolioProfit portfolioId={this.portfolio_id} portfoliosData={this.props.portfoliosData}/></TabContainer>
                    
                    }
                    {this.props.tab === 2 && <TabContainer><TransactionWrapper portfoliosData={this.props.portfoliosData} portfolioId={this.portfolio_id} /></TabContainer>}
                    {this.props.tab === 3 && <TabContainer><ReportOverview portfolioId={this.portfolio_id} /></TabContainer>}
                    {this.props.tab === 5 && <TabContainer><div><CollaboratorOverview portfolioId={this.portfolio_id} portfoliosData={this.props.portfoliosData}/></div></TabContainer>}

                   
                    {this.props.tab === 6 && <TabContainer>
                            <Settings selectedSetting={this.subsection} portfolioId={this.portfolio_id} portfoliosData={this.props.portfoliosData} />
                        </TabContainer>}
                   

                    </div>
                </div>
            :
            <div style={{padding: 48, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexWrap: 'wrap'}}>
                {this.redirect()}
                <img src={noAccess} alt='no access' style={{width: 500}} />
                <div style={{width: 200, paddingTop: 60}}>
                    <h1>Sorry!</h1>
                    <div className="basier-p2">
                        You do not seem to have access to this portfolio. Please contact the portfolio's owner to request access.
                    </div>
                </div>
                
            </div>
            :
            null
            
        )
    }

}

const mapStateToProps = state => {
    return {
        portfoliosBalance: state.data.portfolioBalance,
        tab: state.interactions.portfolioTab,
        portfoliosData: state.data.portfoliosData,
        currency: state.auth.currency.id,
        globalAssetList: state.data.globalAssetList,
        user: state.auth.user_id, 
        list_portfolios: state.auth.list_portfolios, 
        inBitcoin: state.data.bitcoinPf, 
        subscription: state.auth.subscription, 
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setPfTab: (tab) => 
            dispatch(interactionActions.setPfTab(tab)), 
        setMenuPortfolio: (portfolio) =>
            dispatch(actions.setMenuPortfolio(portfolio)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        setInteraction: (data) => 
            dispatch(interactionActions.setInteraction(data)), 
        showSnack: (data) => dispatch(interactionActions.showSnack(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PortfolioContainer));
