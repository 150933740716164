import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../store/actions/data";
import * as interActions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import '../../Portfolio/PortfolioComponents/Transactions/TransactionStyle.css'
import {loadData, siwtchTransactionType, dateParser, formattedNumber} from '../../../helpers/helpers'
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { backendUrl } from '../../../variables'
import Tooltip from '@material-ui/core/Tooltip';
import TypeSelector from '../../AppWide/TableTypeSelector';
import arrowRound from '../../../Media/arrowRound.svg';

class MatchedTransfer extends React.PureComponent {

    state = {

        transactionId: this.props.transactionId,
        date: null,
        data: null,
        editConnected: false,
        connectedEdit: {
            unit_price: null
        }
    }

    
    componentDidMount() {

        this.getData(this.props.transactionId)
        //this.props.setCurrentView(5)
       //this.props.componentDataFetch('get', 5, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)

    } 

    getData = (transaction_id) => {
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/matched_tx/?u=${this.props.user_id}&id=${this.props.transactionId}`

        const transactionDetail = loadData(url_call, headers)
        transactionDetail
            .then(json => {

                this.setState(state => ({...state, data: json['data']}) )

            })

    }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
    }


    renderTransaction = (data) => {
        
        return (
            <div style={{width: '100%', padding: 12}}>
                
                <div style={{
                    fontWeight: 900, 
                    width: 100,
                    height: 30, 
                    padding: '0px 12px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    borderRadius: 20, 
                    textTransform: 'uppercase', 
                    color: 'white', 
                    backgroundColor: siwtchTransactionType(data.transaction_description)[1]
                    }} 
                    className="basier-p4-caps">
                        {siwtchTransactionType(data.transaction_description)[0]}
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 4, }}>
                    <div className="basier-p4" style={{paddingLeft: 0}}>
                        { dateParser(data.date) }
                    </div >
                </div>
                <div className="basier-p3" style={{marginTop: 12,}}>
                    <span style={{fontWeight: 900, color: 'black'}}>Amount: </span>
                    <span style={{fontWeight: 400}}>{formattedNumber(data.amount, 8)}</span>

                </div>
                <div className="basier-p3" style={{marginTop: 6, }}>
                        
                        <span style={{fontWeight: 900, color: 'black'}}>{
                            data.transaction_description === 'deposit' ? 'Sent to: ' : 'Sent from: '
                        }</span>

                        {
                            data.storage !== null ? 

                                <span style={{fontWeight: 400}}>{data.storage.name}</span>


                            : 

                            data.account !== null ? 

                                <span style={{fontWeight: 400}}>{data.account.name}</span>

                            : 
                            
                            'Not specified'
                        }
                        
                    </div>
                    
                    <div className="basier-p3" style={{marginTop: 6, }}>
                        
                        <span style={{fontWeight: 900, color: 'black'}}>Address: </span>

                        {
                            data.connection !== null ? 

                                data.transaction_type === 'deposit' ? 
                                    <span className="basier-p4 truncate" style={{fontWeight: 400}}>{this.state.data['withdraw']['address_hash']}</span>
                                    
                                :   
                                    <span className="basier-p4 truncate" style={{fontWeight: 400}}>{this.state.data['deposit']['address_hash']}</span>

                            :

                                data.storage !== null ? 

                                    <span className="basier-p4 truncate" style={{fontWeight: 400}}>{data.storage.address}</span>


                                : 

                                data.account !== null ? 

                                    <span className="basier-p4 truncate" style={{fontWeight: 400}}>{data.account.address}</span>

                                : 
                                
                                'Not specified'
                            


                        }
                        
                    </div>

            </div>
        )

    }

    render() {

        const { data }  = this.state;

        return (

            this.state.data !== null && this.state.data !== undefined ? 
            <div>
                
                <BaseModalTop forceSingle={true} sub={this.state.data.connection !== null ? <div style={{color: '#4CDAC1', fontWeight: 900}}>Connected Transaction</div> : null} title="Matched Transfer" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />

                <div style={{padding: '24px 48px', width: '100%'}}>

                    <div className="basier-p4" style={{width: '100%', fontStyle: 'italic', textAlign: 'justify', marginBottom: 24}}>
                        Matched transfers are created from a deposit and a withdrawal within your portfolio, when these two transactions occur between your (or your collaborators) accounts. This allows us to only take fees into considerarion, and not calculate withdrawal profit. You can find more information here.
                    </div>

                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingBottom: 0, marginBottom: 24, borderBottom: 'none'}}>
                    
                            
                        <div style={{
                            fontWeight: 900, 
                            width: 210,
                            height: 40, 
                            padding: '0px 12px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            borderRadius: 20, 
                            textTransform: 'uppercase', 
                            color: 'white', 
                            backgroundColor: siwtchTransactionType('transfer')[1]
                            }} 
                            className="basier-p3">
                                Matched Transfer
                        </div>

                        <div className="basier-p3 opacity-hover" style={{textTransform: 'uppercase', color: this.state.data.deposit.connection !== null ? '#E1E5E9' : '#0078DB', cursor: this.state.data.deposit.connection !== null ? 'not-allowed' : 'pointer', }}>
                            Unmatch
                        </div>
                            

                    </div>
                    
                    <div className="basier-p4-caps" style={{marginBottom: 6, paddingLeft: 6}}>Match Details</div>

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', minHeight: 75, borderRadius: 20}}>
                            
                        <div style={{width: '50%'}}>
                            {this.renderTransaction(this.state.data['withdraw'])}
                        </div>

                        <div style={{width: '50%'}}>
                            {this.renderTransaction(this.state.data['deposit'])}
                        </div>

                    </div>

                    <div className="basier-p4-caps" style={{marginBottom: 6, marginTop: 24, paddingLeft: 6}}>Transfer Details</div>
                    
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', minHeight: 75, borderRadius: 20}}>

                        <div style={{width: '100%', padding: 12}}>
                            <div className="basier-p3" style={{marginTop: 12,}}>
                                <span style={{fontWeight: 900, color: 'black'}}>Coin: </span>
                                <span style={{fontWeight: 400}}>{this.state.data['deposit']['base_currency']['name']}</span>
                            </div>

                            <div className="basier-p3" style={{marginTop: 12,}}>
                                <span style={{fontWeight: 900, color: 'black'}}>Fee: </span>
                                <span style={{fontWeight: 400}}>{formattedNumber(this.state.data['withdraw']['fee'], 8)} {this.state.data['deposit']['base_currency']['symbol']}</span>
                            </div>

                            <div style={{marginTop: 12,}}>
                                <span className="basier-p3"  style={{fontWeight: 900, color: 'black'}}>Tx Hash: </span>
                                <span className="basier-p4"  style={{fontWeight: 400}}>{this.state.data['deposit']['tid']}</span>
                            </div>

                        </div>

                    </div>

                        
                </div>

            </div>
           :

           null

        )
    }
  
};

const mapStateToProps = state => {
    return {
        inBitcoin: state.data.bitcoinPf, 
        token: state.auth.token,
        user_id: state.auth.user_id,
        roles: state.auth.roles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        getFlatWallets: (user_id) => 
            dispatch(appwideActions.getFlatWalletList(user_id)), 
        getAllPortfolioList: (user_id) => 
            dispatch(appwideActions.getAllPortfolioList(user_id)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        getImports: (data) => dispatch(actions.getImports(data)), 
        
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MatchedTransfer));