import React from 'react';
import { connect } from 'react-redux';
import { backendUrl } from '../../../../variables'
import 'filepond/dist/filepond.min.css';
import * as appwideActions from "../../../../store/actions/appwide";
import * as interActions from "../../../../store/actions/interactions";
import * as dataActions from "../../../../store/actions/data";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import loadingSpinner from '../../../../Media/contentLoader.svg'
import whiteLoader from '../../../../Media/whiteloader.gif'
import okIcon from '../../../../Media/okIcon.svg'
import errorIcon from '../../../../Media/errorIcon.svg'
import { withStyles } from '@material-ui/core/styles';
import Step1Connect from './Step1Connect';
import Step2Connect from './Step2Connect';
import axios from 'axios';

const styles = theme => ({
    container: {
        width: '100%', 
        padding: 48, 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 12, 
        },
    },
    handheld: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 13
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 15
        },
    },
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0, paddingRight: 0, 
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 48, paddingRight: 48, 
        },
       
    },
    firstContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 24
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 48
        },
    },
    smallMargin: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 12, marginRight: 12,
        }, 
    },
    sencondCointainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
    },
    firstWidth: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 324, justifyContent: 'space-between',
        },
        [theme.breakpoints.up('md')]: {
            display: 350, height: 350, justifyContent: 'space-between',
            paddingLeft: 24, paddingRight: 24, 
        },
    },
})


class ConnectAccount extends React.PureComponent {

    state = {
        activeStep: 0,
        data: {
            scope: 'all'
        }, 
        selected: {},
        connection_type: null, 
        loading: false, 
        error: null,
      };


    componentDidMount() {
        
      

    }

    submitServer = () => {

        this.setState(state => ({...state, loading: true}))

        let submitData = {
            ...this.state.data, 
            user_id: this.props.user_id, 

        }

        if ( submitData.scope === 'all' ) {
            submitData['portfolio'] = this.props.overridePort === true ? this.props.overridePortId : this.props.currentPortfolio
        }

        let headers = { 
            "content-type": "application/json",     
          };
          headers["Authorization"] = `Token ${this.props.token}`;

        
        let url = null 
        if (this.state.connection_type === 'exchange') {
            url = `${backendUrl}/v1/connectors/setup/`
        } else if (this.state.connection_type === 'storage') {
            url = `${backendUrl}/v1/wallets/setup/`
        }
        axios
        .post(url,
            submitData
        , { headers: headers } )
        .then(res => { 
            const accountId = res['data']['data']['id']
            const currentTasks = this.props.tasks
            let newTasks = [...currentTasks]
            if (this.state.connection_type=== 'exchange') {
                newTasks = [{description: `Exchange Connection`, sub: `${this.state.selected.exchange_name}`, status: 'pending', type: 'connection', task_id: accountId}, ...currentTasks]
            } else if (this.state.connection_type === 'storage') {
                newTasks = [{description: `Wallet Connection`, sub: `${this.state.selected.coin_name}`, status: 'pending', type: 'connection', task_id: accountId}, ...currentTasks]
            }
            
            this.props.setInteraction({label: "tasks", value: newTasks})


            //this.connectForUpdate();
            this.props.getWallets(this.props.user_id, this.props.token);
            this.props.getUserLimits(this.props.user_id)
            this.props.handleClose();

         })
         .catch(err => {
            let error = "Unable to connect at this time, please try again."
            
            try {
                error = err.response['data']['data']
            } catch(e) {}

            this.setState(state => ({...state, error: error, loading: false }))

         })
    }

    handleInit() {
    }

    connectForUpdate = () => {

        const prefix = process.env.REACT_APP_HOST_ENV === 'dev' ? 'ws' : process.env.REACT_APP_HOST_ENV === 'mobile' ? 'ws' : 'wss'

        const url = process.env.REACT_APP_HOST_ENV === 'dev' ? backendUrl.slice(6,) : process.env.REACT_APP_HOST_ENV === 'dev' ? backendUrl.slice(6,) : backendUrl.slice(7,)
        
        const allUrl = `${prefix}://${url}/ws/tasks/${this.props.user_id}?t=${this.props.token}`
        const ws = new WebSocket(allUrl);

        ws.onopen = function () {
        };

        ws.onmessage = (evt) => {
            
            const message = JSON.parse(evt.data)['message']
            const tasks = this.props.tasks
            
            const newTasks = []

            tasks.map(val => {
                console.log('val', val)
                if (val.id == message.id && val.type == message.type) {
                    val.status = message.status
                    newTasks.push(val)
                } else {
                    newTasks.push(val)
                }

            })
            this.props.setInteraction({label: "tasks", value: newTasks})
            ws.close()

            const request_data = {
                period: null,
                start: null,
                end: null
              }

            if (this.props.overridePort === true) {

                this.props.componentDataFetch('update', 1, this.props.currency_id, this.props.user_id, this.props.currentPortfolio, this.props.graphSettings, null, this.props.inBitcoin);
              } else {

                this.props.componentDataFetch('update', this.props.currentView, this.props.currency_id, this.props.user_id, this.props.currentPortfolio, null, request_data, this.props.inBitcoin);
                
            }

            this.props.getImports(this.props.user_id)

        };

        ws.onclose = function () {
            // websocket is closed.
        };
    
    }

    handleBack = () => {
        this.setState(state => ({
        activeStep: state.activeStep - 1,
        }));
    };

    getSteps() {
        return ['Select Account', 'Enter details'];
    }

    getStepContent(stepIndex) {
        switch (stepIndex) {
        case 0:
            return this.step1();
        case 1:
            return this.step2();
        default:
            return 'Unknown stepIndex';
        }
    }

    inputData = (e, label) => {
        e.persist();
        this.setState(state => ({...state, data: {...state.data, [label]: e.target.value}}))
    }

    selectExchange = (object, connection_type) => {
        if (connection_type === 'exchange') {
            this.setState(state => ({...state, selected: object, connection_type: connection_type, data: {...state.data, exchange_id: object.exchange_id}, activeStep: 1}))
        } else {
            this.setState(state => ({...state, selected: object, connection_type: connection_type, data: {...state.data, coin_id: object.coin_id}, activeStep: 1}))
        }
    }

    changeType = e => {
        e.persist();
        console.log(e.target.value)
        this.setState(state => ({...state, data: {...state.data, scope: e.target.value}}))
    }

    step1 = () => {
        return (
          
          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
              
                <Step1Connect connectors={this.props.connectors} selectExchange={(val, connection_type) => this.selectExchange(val, connection_type)} />
  
            </div>
          </div>
        )
      }
  
      step2 = () => {
         
          return (
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-start'}}>
  
                  <Step2Connect error={this.state.error} inputData={(e, label) => this.inputData(e, label)} data={this.state.selected} scope={this.state.data.scope} connection_type={this.state.connection_type} changeType={this.changeType} portfolioId={this.props.overridePort === true ? this.props.overridePortId : this.props.currentPortfolio}  />
                </div>
          )
      }



    render () {
        const { classes } = this.props;
        const steps = this.getSteps();
        const { activeStep } = this.state;

        return (
            
        <div className={classes.root} style={{height: '100%'}}>
            
            <div style={{height: '15%'}}>
            <Stepper activeStep={activeStep}>
            {steps.map(label => (
                <Step key={label} >
                <StepLabel StepIconProps={{ classes: {active: 'step-icon-active', completed: 'step-icon-completed'}}} >{label}</StepLabel>
                </Step>
            ))}
            </Stepper>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '73%'}}>
            {this.state.activeStep === steps.length ? (
                <div style={{width: '100%',}}>
                </div>
            ) : (
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                <div style={{width: '100%'}} className={classes.instructions}>{this.getStepContent(activeStep)}</div>
                
                </div>
            )}
            </div>
                
            <div style={{display: 'flex', justifyContent: 'center', height: '15%'}}>

                {activeStep === 0 ? null : 

                this.state.completed ? 
                <div style={{display: 'flex'}}>

                    <div
                    disabled={activeStep === 0}
                    onClick={this.props.onClose}
                    className='button-next'
                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                    >
                        Close
                    </div>

                    <div
                    disabled={activeStep === 0}
                    onClick={this.handleReset}
                    className='button-next-blue'
                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                    >
                        Add another
                    </div> 
                    
                </div>
                :
                <div
                disabled={activeStep === 0}
                onClick={this.handleBack}
                className='button-next'
                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                >
                    Back
                </div>
                }

                {
                activeStep === 1? 
                
                
                <div className='button-blue opacity-hover' onClick={() => this.submitServer()} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                    {this.state.loading ? 

                        <span>
                            <img src={whiteLoader} alt="" style={{height: 20, width: 20, marginRight: 12}}/>
                            <span>Connecting</span>
                        </span>
                    
                    :

                        <span>Submit</span>
                    
                    }
                </div>

                    : null
                }

                </div>
            
        </div>


        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        connectors: state.appwide.connectors,
        inBitcoin: state.data.bitcoinPf, 
        currency_id: state.auth.currency.id, 
        currentPortfolio: state.data.menu_selected, 
        tasks: state.interactions.tasks,
        currentView: state.data.currentView, 
        graphSettings: state.data.globalBalance,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getImports: (u) => dispatch(dataActions.getImports(u)), 
        getWallets: (user, t) => dispatch(appwideActions.getWallets(user, t)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        setInteraction: (data) => dispatch(interActions.setInteraction(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConnectAccount))
