import React, { type ElementConfig } from 'react';
import { Creatable, components }  from 'react-select';
import { connect } from 'react-redux';
import plusButton from '../../../../../../Media/Plus.svg'
import * as actions from "../../../../../../store/actions/interactions";
import {noAddErc20} from '../../../../../../helpers/noERC20'

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '250px', fontSize: 16, border: '0px', color: 'black', cursor: 'text', dispay:'inline-block'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#F3F5F6'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.7',
    };
  },

};

const optionDisplay = (account) => {
    return (
      <div style={{textAlign: 'left'}}>
          {account.name}
      </div>
     
    )
}

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <div style={{marginLeft: '12px'}}>
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9136 1L7.95679 8L1 1" stroke="#6A7E93" stroke-width="1.5" stroke-miterlimit="10"/>
        </svg>
      </div>
    </components.DropdownIndicator>
  );
};

const menuHeaderStyle = {
    padding: '8px 12px',
    background: 'white',
    color: 'black',
    fontWeight: 900,
  };

const MenuList = props => {
    return (
      <components.MenuList {...props}>
        <div className="basier-p4-caps" style={menuHeaderStyle}>Storage wallets</div>
        {props.children}
      </components.MenuList>
    );
  };

let options = [];

class MenuSelector extends React.Component {

  state = {
    selectedOption: null
  }
  

  componentDidMount() {
    
    console.log(this.props.wallet)
    let accounts = null 
    accounts = this.props.accounts[this.props.coin]
    const needErc20 = !noAddErc20.includes(this.props.coin)
    const accountsErc20 = this.props.accounts['ethereum']
    const jsonVer = JSON.stringify(accountsErc20)
    let displayErc20 = JSON.parse(jsonVer)

    displayErc20 = displayErc20.map(val => {
        console.log('pass', val.name)
        val.name = `${val.name} (ERC20)`
        return val
    } )

    if (needErc20 && accounts !== undefined && accounts !== null && noAddErc20 !== null && noAddErc20 !== undefined) {
      accounts = [...accounts, ...displayErc20]
    } else if (needErc20 && noAddErc20 !== null && noAddErc20 !== undefined) {
      accounts = displayErc20
    }

    options = [];
    
    if (accounts !== undefined) {
      accounts.map( (value) => {
        let values = {id: value['id'], value: value['name'], label: optionDisplay(value)};
        options.push(values)

        if (this.props.wallet !== null && this.props.wallet !== undefined) {
          if (value['id'] === this.props.wallet.id) {

            this.setState(state => ({...state, selectedOption: {value: value.id, label: value.name}}))
            this.props.updateTransaction({key: this.props.transactionId, label: "wallet", value: values.id})

          }
        }

          })
    }

    this.setState(state => ({...state, options: options}))

  }

  componentDidUpdate(prevProps) {

    if (prevProps.coin !== this.props.coin) {

        let accounts = null 
        accounts = this.props.accounts[this.props.coin]

        const needErc20 = !noAddErc20.includes(this.props.coin)
        const accountsErc20 = this.props.accounts['ethereum']
        const jsonVer = JSON.stringify(accountsErc20)
        let displayErc20 = JSON.parse(jsonVer)

        displayErc20 = displayErc20.map(val => {
            console.log('pass', val.name)
            val.name = `${val.name} (ERC20)`
            return val
        } )

        if (needErc20 && accounts !== undefined && accounts !== null && noAddErc20 !== null && noAddErc20 !== undefined) {
          accounts = [...accounts, ...displayErc20]
        } else if (needErc20 && noAddErc20 !== null && noAddErc20 !== undefined) {
          accounts = displayErc20
        }

        options = [];

        if (accounts !== undefined ) {
          accounts.map( (value) => {
            let values = {id: value['id'], value: value['name'], label: optionDisplay(value)};
            options.push(values)

            if (value['id'] === this.props.wallet.id) {

              this.setState(state => ({...state, selectedOption: {value: value.id, label: value.name}}))
              this.props.updateTransaction({key: this.props.transactionId, label: "wallet", value: values.id})

            }

            })
          }

        this.setState(state => ({...state, options: options}))

    }



  }


  handleClear = () => {

    this.setState(state => ({...state,  selectedOption: null }));
    this.props.updateTransaction({key: this.props.transactionId, label: "wallet", value: -1})
    this.props.updateTransaction({key: this.props.transactionId, label: "storage_name", value: null})
    //this.props.removeWallets()

  }
  handleChange = (selectedOption) => {

    console.log(selectedOption)

    if ( selectedOption.hasOwnProperty('__isNew__') ) {

      if (selectedOption['__isNew__'] === true) {

        this.setState(state => ({...state,  selectedOption: selectedOption }));
        this.props.updateTransaction({key: this.props.transactionId, label: "wallet", value: -1})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage_name", value: selectedOption.value})

      }

    }

    else {
        this.setState(state => ({...state,  selectedOption: selectedOption }));
        this.props.updateTransaction({key: this.props.transactionId, label: "wallet", value: selectedOption.id})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage_name", value: null})
    
  }
}

  render() {
    const { selectedOption } = this.state;

    return (
      <Creatable
        value={selectedOption}
        onChange={this.handleChange}
        onMenuOpen={() => this.handleClear()}
        options={this.state.options}
        isSearchable={true}
        styles={colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        components={{ MenuList, DropdownIndicator }}
        classNamePrefix="react-select-user"
        placeholder="Select or type new..."
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeWallets: () =>
      dispatch(actions.removeWallets()), 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
