import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';


const ProfitGraph = (props) => {

    const options = {
        chart: {
            type: 'column', 
            height: 520,
        },

        credits: false, 
        title: false,
        xAxis: {
            categories: props.coins, 
            minorGridLineWidth: 0, 
            gridLineWidth: 0,
        },
        yAxis: [{
            title: {
                text: false,
            }, 
            minorGridLineWidth: 1,
            gridLineWidth: 1,
            gridLineColor: '#E1E5E9',
            plotLines: [{
                color: '#ddd',
                width: 1,
                value: 0
        }],
        }, {
            visible: false,
            opposite: true
        }],
        series: [{
            name: 'Realized profit',
            data: props.realized,
            color: 'transparent',
            stacking: 'normal', 
            yAxis: 1,
            states: {
                hover: {
                    enabled: false      // prevent the highlight circle on hover
                }, 
                select: {
                    enabled: false
                }
            }
           
        }, { name: 'Active profit',
            data: props.unrealized,
            stacking: 'normal', 
            color: 'transparent',
            yAxis: 1,
            states: {
                hover: {
                    enabled: false      // prevent the highlight circle on hover
                }, 
                select: {
                    enabled: false
                }
            }
        }, 
        { name: 'Total profit',
        data: props.total_profit,
        stacking: 'normal', 
        borderRadius: 5,
        yAxis: 0,
        color: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1},
            stops: [
                [0, "rgba(76, 218, 193, 0.4)"],
                [1, "rgba(76, 218, 193, 0.9)"]
            ]
        },
        negativeColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1},
            stops: [
                [0, "rgba(249, 113, 107, 0.9)"],
                [1, "rgba(249, 113, 107, 0.4)"]
            ]
        },
    }],

    legend: {
        enabled: false
    },

        tooltip: {
        followPointer: false,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderRadius: 14,
        borderWidth: 0,
        shadow: true,
        padding:12,
        style: {
            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
            height: '108px', 
            width: '186px',
        },
        useHTML: true,
        shared: true,
        formatter: function () {

            const coin = this.points[0].x;
            let realized = 0;
            let unrealized = 0;
            let total = 0;

            try { realized = this.points[0].y } catch(e) { realized = 0 };
            try { unrealized = this.points[1].y } catch(e) { unrealized = 0 };
            try { total = this.points[2].y } catch(e) { total = 0 };

            return (

                `
                <span class='tooltip-wrapper-profit'>
                    <span class='profit-tooltip-main-text'>
                        <span class="basier-p4-caps"><b>${coin}</b></span>
                        <span class="basier-p4-caps">Total profit</span>

                        <div>
                        <h4 class='tooltip-title'>
                            ${props.currency_symbol} ${total.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits:2})}
                        </h4>
                        </div>

                    </span>
                    <span class='profit-tooltip-divider'></span>
                    <br />
                    <span class='date-tooltip'>
                        <div class='basier-p2 profit-text'>
                            <b>Un-realized profit:</b> ${props.currency_symbol} ${unrealized.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits:2})}
                        </div>
                        <div class='basier-p2 profit-text'>
                            <b>Realized profit:</b> ${props.currency_symbol} ${realized.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits:2})}
                        </div>
                    </span>
                `
               
            )


            },

        },
    }


    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            oneToOne={true}
        />
    )
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
    };
};

export default connect(
    mapStateToProps,
    null
)(ProfitGraph);

