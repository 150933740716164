import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import * as actions from "../../../../../store/actions/interactions";
import { connect } from 'react-redux';
import questionMark from '../../../../../Media/questionMark.svg'



const styles = {
  root: {
    color: '#E1E5E9',
    '&$checked': {
      color: '#0078DB',
    },
  },
  checked: {},
};

class RadioButtons extends React.Component {
  state = {
    selectedValue: '',
    currentIndex: 0,
  };

  handleChange = event => {
    this.setState({ selectedValue: event.target.value });
  };

  selectTransactionType = (value) => {

    if (['deposit', 'income', 'gift', 'dividend'].includes(value) && ( (this.props.limits.investment >= this.props.limits.investment_limit) && (this.props.limits.investment_limit != -1))) {
      console.log('here in return')
      return
    }
    this.props.selectType(value);
    this.props.focusIn(value);
    //console.log(this.props.nextInput)
  }

  handleKeyDown = (e) => {


    if (e.keyCode === 13 || e.keyCode === 9 || e.keyCode === 32) {
      
      e.preventDefault();
      e.target.checked = true
      this.selectTransactionType(e.target.value)
      //this.props.selectType(e.target.value)

    }
      

  }
  submitRadios = (e) => {


      e.preventDefault();
      
      if ( ( (e.clientX !== 0) && (e.clientY !== 0) ) || ( (e.pageX !== 0) && (e.pageY !== 0) ) ) {
        this.selectTransactionType(e.currentTarget.getAttribute('value'));
      }
      else {
        e.stopPropagation()
      }
      

  }


  render() {
    

    const transaction_types = {
      "fiat": [
          {ix: 0, value: 'deposit', label: 'Deposit', helper: 'Any fiat money you send into your portfolio, usually through a cryptocurrency excahnge.', enabled: ((this.props.limits.investment < this.props.limits.investment_limit) || (this.props.limits.investment_limit == -1 )) },
          {ix: 1, value: 'withdraw', label: 'Withdraw', helper: 'Any fiat money you take out of your portfolio.', enabled: true},
          {ix: 4, value: 'fee', label: 'Fee', helper: 'Use this to enter any fees you may have paid. This could be for example if you forgot to add deposit fees or if your bank took transfer fees.', enabled: true},
          {ix: 5, value: 'lost', label: 'Loss', helper: 'Use this if you lost fiat or got hacked. This will decrease your crypto balance and show as a net loss.', enabled: true},
      ], 
      "crypto" : [
          {ix: 0, value: 'deposit', label: 'Deposit', helper: 'Any fiat money you send into your portfolio, usually through a cryptocurrency excahnge.', enabled: ((this.props.limits.investment < this.props.limits.investment_limit) || (this.props.limits.investment_limit == -1 )) },
          {ix: 1, value: 'withdraw', label: 'Withdraw', helper: 'Any fiat money you take out of your portfolio.', enabled: true},
          {ix: 2, value: 'buy', label: 'Buy', helper: 'Use this for buying crypto. This will increase the bought crypto balance.', enabled: true},
          {ix: 3, value: 'sell', label: 'Sell', helper: 'Use this for selling crypto. This will decrease the sold crypto balance.', enabled: true},
          {ix: 4, value: 'ico', label: 'ICO', helper: 'Use this for ICO purchases', enabled: true},
          {ix: 5, value: 'transfer', label: 'Transfer', helper: 'Use this for transfer of crypto - usually to and from wallets or between exchanges. Overall balance will not change, but any transfer fees will be taken into account.', enabled: true},
          {ix: 6, value: 'mining', label: 'Rewards', helper: 'Use this to enter any crypto rewards gained through mining or staking. You can also input the fees linked to the activity.', enabled: true},
          {ix: 7, value: 'fee', label: 'Fee', helper: 'Use this to enter any fees you may have paid. This could be for example for mining, or if you forgot to add trading fees or transfer fees at any point.', enabled: true},
          {ix: 8, value: 'dividend', label: 'Dividend', helper: 'Use this to enter any crypto gained through dividend schemes.', enabled: ((this.props.limits.investment < this.props.limits.investment_limit) || (this.props.limits.investment_limit == -1 ))},
          {ix: 9, value: 'income', label: 'Income', helper: 'Use this to enter any crypto received as income.', enabled: ((this.props.limits.investment < this.props.limits.investment_limit) || (this.props.limits.investment_limit == -1 ))},
          {ix: 10, value: 'gift', label: 'Gift', helper: 'Use this to enter any crypto received as a gift.', enabled: ((this.props.limits.investment < this.props.limits.investment_limit) || (this.props.limits.investment_limit == -1 ))},
          {ix: 11, value: 'airdrop', label: 'Airdrop', helper: 'Use this to enter any crypto received as airdrops.', enabled: true},
          {ix: 12, value: 'fork', label: 'Fork', helper: 'Use this to enter any crypto received through a fork.', enabled: true},
          {ix: 13, value: 'purchase', label: 'Purchase', helper: 'Use this for spending crypto on non-crypto/fiat purchases (e.g. buying a pizza with crypto). This will decrease your crypto balance.', enabled: true},
          {ix: 14, value: 'donation', label: 'Donation', helper: 'Use this if you donated crypto. This will decrease your crypto balance.', enabled: true},
          {ix: 15, value: 'lost', label: 'Loss', helper: 'Use this if you lost crypto or got hacked. This will decrease your crypto balance and show as a net loss.', enabled: true},
      ]
  } 

    const breaker_1 = this.props.assetType === 'fiat' ? 3 : 5
    const breaker_2 = 10

    return (

      this.props.assetType === null || this.props.assetType === undefined ? 

      null 

      :

      <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>


          <div style={{textAlign: 'left'}} >


          {
              transaction_types[this.props.assetType.coinType].slice(0,breaker_1).map((val, key) => {

                  return (

                    val['enabled'] ? 

                    <div  onClick={this.submitRadios} onKeyDown={(e) => e.stopPropagation()} style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer', marginBottom: 24}} value={val.value} key={key}>
                      
                        <input style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',}} id={`input_radio_1_${key}`} name="group1" type="radio" ref={transaction_types[this.props.assetType.coinType][0]['enabled'] === true ? val['ix'] === 0 ? (ip) => this.myInput= ip : null : val['ix'] === 1 ? (ip) => this.myInput= ip : null } value={val.value} key={key} onKeyDown={(e) => this.handleKeyDown(e)} disabled={!val['enabled']}  />
                    
                    
                    <label onClick={this.submitRadios} for={`input_radio_1_${key}`} value={val.value} className="basier-p4-caps" style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',marginLeft: 12, marginRight: 6, color: `${this.props.transaction['data'][this.props.transactionId]['transaction_description']=== val.value ? '#0078DB' : 'rgba(0, 0, 0, 0.87)'}`}}>{val.label}</label>
                    <span style={{marginRight: 48}}>
                      <Tooltip title={val.helper} placement="right">
                        <img style={{height: 20, width: 20}} src={questionMark} alt=""/>
                      </Tooltip>
                    </span>
                    
                    </div>

                    :
                    
                    <Tooltip title="Not allowed. Account limits reached. Please upgrade plan." placement="top">
                      <div  onClick={this.submitRadios} onKeyDown={(e) => e.stopPropagation()} style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer', marginBottom: 24}} value={val.value} key={key}>
                        
                          <input style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',}} id={`input_radio_1_${key}`} name="group1" type="radio" value={val.value} key={key} onKeyDown={(e) => this.handleKeyDown(e)} disabled={!val['enabled']} />
                      
                      
                      <label onClick={this.submitRadios} for={`input_radio_1_${key}`} value={val.value} className="basier-p4-caps" style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',marginLeft: 12, marginRight: 6, color: `${ !val['enabled'] ? 'rgba(0, 0, 0, 0.4)' : this.props.transaction['data'][this.props.transactionId]['transaction_description']=== val.value ? '#0078DB' : 'rgba(0, 0, 0, 0.87)'}`}}>{val.label}</label>
                      <span style={{marginRight: 48}}>
                          <img style={{height: 20, width: 20}} src={questionMark} alt=""/>
                      </span>
                      
                      </div>
                    </Tooltip>



              )
                  
              
              })
            }

          </div>

          <div style={{textAlign: 'left'}}>

          {
              transaction_types[this.props.assetType.coinType].slice(breaker_1,breaker_2).map((val, key) => {

                  return (

                    val['enabled'] ? 

                    <div  onClick={this.submitRadios} onKeyDown={(e) => e.stopPropagation()} style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer', marginBottom: 24}} value={val.value} key={key}>
                      
                        <input style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',}} id={`input_radio_1_${key}`} name="group1" type="radio" value={val.value} key={key} onKeyDown={(e) => this.handleKeyDown(e)} disabled={!val['enabled']} />
                    
                    
                    <label onClick={this.submitRadios} for={`input_radio_1_${key}`} value={val.value} className="basier-p4-caps" style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',marginLeft: 12, marginRight: 6, color: `${this.props.transaction['data'][this.props.transactionId]['transaction_description']=== val.value ? '#0078DB' : 'rgba(0, 0, 0, 0.87)'}`}}>{val.label}</label>
                    <span style={{marginRight: 48}}>
                      <Tooltip title={val.helper} placement="right">
                        <img style={{height: 20, width: 20}} src={questionMark} alt=""/>
                      </Tooltip>
                    </span>
                    
                    </div>

                    :
                    
                    <Tooltip title="Not allowed. Account limits reached. Please upgrade plan." placement="top">
                      <div  onClick={this.submitRadios} onKeyDown={(e) => e.stopPropagation()} style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer', marginBottom: 24}} value={val.value} key={key}>
                        
                          <input style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',}} id={`input_radio_1_${key}`} name="group1" type="radio" value={val.value} key={key} onKeyDown={(e) => this.handleKeyDown(e)} disabled={!val['enabled']} />
                      
                      
                      <label onClick={this.submitRadios} for={`input_radio_1_${key}`} value={val.value} className="basier-p4-caps" style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',marginLeft: 12, marginRight: 6, color: `${ !val['enabled'] ? 'rgba(0, 0, 0, 0.4)' : this.props.transaction['data'][this.props.transactionId]['transaction_description']=== val.value ? '#0078DB' : 'rgba(0, 0, 0, 0.87)'}`}}>{val.label}</label>
                      <span style={{marginRight: 48}}>
                          <img style={{height: 20, width: 20}} src={questionMark} alt=""/>
                      </span>
                      
                      </div>
                    </Tooltip>


              )
                  
              
              })
            }

          </div>

         
          { 
            this.props.assetType.coinType === 'crypto' ? 

            <div style={{textAlign: 'left'}}>

              {transaction_types[this.props.assetType.coinType].slice(breaker_2,).map((val, key) => {

                  return (

                    val['enabled'] ? 

                    <div  onClick={this.submitRadios} onKeyDown={(e) => e.stopPropagation()} style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer', marginBottom: 24}} value={val.value} key={key}>
                      
                        <input style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',}} id={`input_radio_1_${key}`} name="group1" type="radio" value={val.value} key={key} onKeyDown={(e) => this.handleKeyDown(e)} disabled={!val['enabled']} />
                    
                    
                    <label onClick={this.submitRadios} for={`input_radio_1_${key}`} value={val.value} className="basier-p4-caps" style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',marginLeft: 12, marginRight: 6, color: `${this.props.transaction['data'][this.props.transactionId]['transaction_description']=== val.value ? '#0078DB' : 'rgba(0, 0, 0, 0.87)'}`}}>{val.label}</label>
                    <span style={{marginRight: 48}}>
                      <Tooltip title={val.helper} placement="right">
                        <img style={{height: 20, width: 20}} src={questionMark} alt=""/>
                      </Tooltip>
                    </span>
                    
                    </div>

                    :
                    
                    <Tooltip title="Not allowed. Account limits reached. Please upgrade plan." placement="top">
                      <div  onClick={this.submitRadios} onKeyDown={(e) => e.stopPropagation()} style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer', marginBottom: 24}} value={val.value} key={key}>
                        
                          <input style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',}} id={`input_radio_1_${key}`} name="group1" type="radio" value={val.value} key={key} onKeyDown={(e) => this.handleKeyDown(e)} disabled={!val['enabled']} />
                      
                      
                      <label onClick={this.submitRadios} for={`input_radio_1_${key}`} value={val.value} className="basier-p4-caps" style={{cursor: !val['enabled'] ? 'not-allowed' : 'pointer',marginLeft: 12, marginRight: 6, color: `${ !val['enabled'] ? 'rgba(0, 0, 0, 0.4)' : this.props.transaction['data'][this.props.transactionId]['transaction_description']=== val.value ? '#0078DB' : 'rgba(0, 0, 0, 0.87)'}`}}>{val.label}</label>
                      <span style={{marginRight: 48}}>
                          <img style={{height: 20, width: 20}} src={questionMark} alt=""/>
                      </span>
                      
                      </div>
                    </Tooltip>


                    

              )
              
                  
              
              })
            }

                </div>

              : 

              null 

            }
          
          </div>

    );
  }
}

RadioButtons.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {

      modalPref: state.interactions.transactionAddModal,
      transaction: state.interactions.transactionAddData,
      limits: state.appwide.limits,
      
  };
};

const mapDispatchToProps = dispatch => {
  return {
      addTransaction: (data) =>
          dispatch(actions.addTransaction(data)),
      updateTransaction: (data) =>
          dispatch(actions.updateTransaction(data))
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps, 
  null, 
  {forwardRef: true}
)(RadioButtons);
