import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../../../store/actions/interactions";
import { connect } from 'react-redux';
import TransactionModalSingle from './SignleAddContent'
import UploadView from '../../../../Modals/ModalViews/UploadView'
import PositionEntry from '../../../../Modals/ModalViews/AddPosition'
import './TransactionAddStyle.css'
import wizardIcon from '../../../../../Media/wizard-blue.svg'
import quickIcon from '../../../../../Media/quickadd-blue.svg'
import uploadIcon from '../../../../../Media/upload-blue.svg'
import positionIcon from '../../../../../Media/positionIcon.svg'
import BaseModalTop from '../BaseModalTop'
import Oops from '../../../../../Media/oops.svg';
import { withRouter} from 'react-router-dom';
import ConnectAccount from '../../../../Modals/ModalViews/ModalComps/ConnectAccounts'

const getValue = (dictVal, key) => {

  try {
    return dictVal[key]
  } catch(e) {
    return undefined
  }

}
class ResponsiveDialog extends React.Component {

  constructor(props) {
    
    super(props);

    this.state = {
        open: false,
        coin_in_scope: null, 
        graph_data: [], 
        graph_start: null,
        welcome: true, 
        type_selected: null,
    }
    

  };

  handleClose = () => {

    this.setState(state => ({...state, welcome: true, type_selected: null}));
    this.props.removeTransaction(this.state.transaction_id);
    this.props.setTrTime(null);
    this.props.setInteraction({label: 'feeCheck', value: false});
    this.props.setInteraction({label: 'transactionAddData', value: {number: 0, data: {}}});
    this.props.toggleModal({type: null, open: false, payload: null})
   
  };

  componentDidMount() {

  }

  backToWelcome = () => {
    this.setState(state => ({...state, welcome: true, type_selected: null}))
  }

  rendererSelector = (type) => {

    if (type === 'wizard') {
      return <TransactionModalSingle onClose={this.handleClose} overridePort={this.props.overridePort} overridePortId={this.props.overridePortId} />
    }
    else if (type === 'quick') {
      return <ConnectAccount overridePort={this.props.overridePort} overridePortId={this.props.overridePortId} handleClose={this.handleClose}/>
    } 
    else if ( type === 'upload' ) {
      return <UploadView overridePort={this.props.overridePort} overridePortId={this.props.overridePortId} />
    } 
    else if ( type === 'position') {
      return <PositionEntry existingData={null} />
    }
    else {
      return null
    }

  }

  goToAccountSettings = () => {

      this.props.history.push('/settings')
      this.handleClose()

  }

  render() {

    const currentLimits = this.props.limits
    const txLimit = currentLimits.transactions
    const investLimit = currentLimits.investment
    return (

      this.props.roles[this.props.currentPortfolio] === 'admin' || this.props.roles[this.props.currentPortfolio] === 'manager' || this.props.roles[this.props.overridePortId] === 'admin' || this.props.roles[this.props.overridePortId] === 'manager' ? 
    
      <div>

          <BaseModalTop handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />

          {
          
          this.props.portfoliosData[this.props.currentPortfolio] !== undefined && this.props.portfoliosData[this.props.currentPortfolio]['limited'] === true ? 

            <div style={{paddingLeft: 48, paddingRight: 48, height: 520, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'center'}}>
                <img src={Oops} alt="" style={{height: 100, marginTop: 120}} />
                <h3 style={{marginTop: 12, fontWeight: 900, color: '#0078DB'}}>
                    Cannot add transaction
                </h3>
                <div style={{marginTop: 24, fontWeight: 400,}} className="basier-p3">
                    This portfolio is currently in Limited Mode as its owner does not have an active Pro plan. If you are a portfolio Admin, you can change the owner to another user who has a Pro plan, or contact the owner so that they reactivate their subscription.
                </div>
            </div>
          

          :
          currentLimits !== undefined && currentLimits !== null ?
            
          (currentLimits.transaction_limit != -1 && txLimit > currentLimits.transaction_limit) || (currentLimits.invest_limit != -1 && investLimit > currentLimits.invest_limit) && getValue(Object.values(this.props.transaction.data)[0], 'amount') === undefined ?

            <div style={{paddingLeft: 48, paddingRight: 48, height: 520, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'center'}}>
              <img src={Oops} alt="" style={{height: 100, marginTop: 120}} />
              <h3 style={{marginTop: 12, fontWeight: 900, color: '#0078DB'}}>
                  Transaction Limit Reached
              </h3>
              <div style={{marginTop: 24, fontWeight: 400,}} className="basier-p3">
                  It looks like you have reached the limit of number of transactions permitted with your current plan. If you wish to add more transactions, you can upgrade your plan by going to your <span onClick={this.goToAccountSettings}className="opacity-hover" style={{cursor: 'pointer', color: '#0078DB'}}>Account Settings</span>
              </div>
            </div>

          :
          
          this.state.welcome ? 
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: 48, width: 750}}>


                <div 
                  onClick={() => this.setState(state => ({...state, type_selected: 'wizard', welcome: false}))}
                  className="multiple-tr"
                  style={{width: 200, height: 300, borderRadius: 20, 
                          boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.6)', 
                          marginRight: 24, marginLeft: 24, 
                          display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>

                    <div style={{width: '100%'}}>


                      <div style={{width: '100%', textAlign: 'center', marginTop: 36}}>
                          <p className="basier" style={{fontSize: 18, textTransform: 'uppercase', color: 'black'}}>Cryptocurrency</p>
                      </div>

                      <div style={{width: '100%', textAlign: 'center', marginTop: 36}}>
                        <img src={wizardIcon} style={{height: 80, width: 80, textAlign: 'center'}} alt=""/>
                      </div>

                      <div className="basier-p4" style={{width: '100%', textAlign: 'center', paddingLeft: 12, paddingRight: 12, marginTop: 36}}>
                        <p>Movements and transactions on spot or OTC cryptocurrencies.</p>
                      </div>


                    </div>

                </div>

                <div style={{textAlign: 'center', marginTop: 12}}></div>
              
              <div 
                onClick={() => this.setState(state => ({...state, type_selected: 'position', welcome: false}))}
                className="multiple-tr"
                style={{width: 200, height: 300, borderRadius: 20, 
                        boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.6)', 
                        marginRight: 24, marginLeft: 24, 
                        display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>

                  <div style={{width: '100%'}}>

                    <div style={{width: '100%', textAlign: 'center', marginTop: 36}}>
                      <p className="basier" style={{fontSize: 18, textTransform: 'uppercase', color: 'black'}}>Derivatives</p>
                    </div>

                    <div style={{width: '100%', textAlign: 'center', marginTop: 36}}>
                      <img src={positionIcon} style={{height: 80, width: 80, textAlign: 'center'}} alt=""/>
                    </div>

                    <div className="basier-p4" style={{width: '100%', textAlign: 'center', paddingLeft: 12, paddingRight: 12, marginTop: 36}}>
                      <p>Position taken in a derivatives contract.</p>
                    </div>
                    
                    
                  
                  </div>
                  

              </div>


              <div 
                onClick={() => this.setState(state => ({...state, type_selected: 'upload', welcome: false}))}
                className="multiple-tr"
                style={{width: 200, height: 300, borderRadius: 20, 
                        boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.6)', 
                        marginRight: 24, marginLeft: 24, 
                        display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>

                  <div style={{width: '100%'}}>

                    <div style={{width: '100%', textAlign: 'center', marginTop: 36}}>
                      <p className="basier" style={{fontSize: 18, textTransform: 'uppercase', color: 'black'}}>CSV Import</p>
                    </div>

                    <div style={{width: '100%', textAlign: 'center', marginTop: 36}}>
                      <img src={uploadIcon} style={{height: 80, width: 80, textAlign: 'center'}} alt=""/>
                    </div>

                    <div className="basier-p4" style={{width: '100%', textAlign: 'center', paddingLeft: 12, paddingRight: 12, marginTop: 36}}>
                      <p>Upload multiple transactions at once.</p>
                    </div>
                    
                    
                  
                  </div>
                  

              </div>

              
            
            </div>

            <div>
              <div>
                <div 
                  //onClick={() => this.setState(state => ({...state, type_selected: 'quick', welcome: false}))}
                  className="multiple-tr"
                  onClick={() => this.setState(state => ({...state, type_selected: 'quick', welcome: false}))}
                  style={{width: 702, height: 110, borderRadius: 20, 
                          boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.6)', 
                          marginTop: 24,
                          padding: 12,
                          display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>


                      <div style={{textAlign: 'center', marginRight: 12}}>
                        <img src={quickIcon} style={{height: 80, width: 80, textAlign: 'center'}} alt=""/>
                      </div>
                      
                      <div>
                          <div className="basier" style={{fontSize: 18, textTransform: 'uppercase', color: 'black'}}>Connect Exchanges</div>
                          <div className="basier-p4" style={{textAlign: 'left'}}>Connect your cryptocurrency wallets and exchanges for automatic imports.</div>
                      </div>

                </div>
              </div>
              </div>
            </div>

            :
            
            <div style={{height: 520}}>
              {this.rendererSelector(this.state.type_selected)}
            </div>
          
          :null
          
              }
           
      </div>

      :
        
        <div>
          <BaseModalTop handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />
          <div style={{paddingLeft: 48, paddingRight: 48, height: 520, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'center'}}>
              <img src={Oops} alt="" style={{height: 100, marginTop: 120}} />
              <h3 style={{marginTop: 12, fontWeight: 900, color: '#0078DB'}}>
                  Insufficient Permissions 
              </h3>
              <div style={{marginTop: 24, fontWeight: 400,}} className="basier-p3">
                  You do not have the required permissions to add transactions to this portfolio. Please contact one of the portfolio administratiors.
              </div>
            </div>
        </div>

      

     

    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        modalPref: state.interactions.transactionAddModal,
        portfoliosData: state.data.portfoliosData,
        transaction: state.interactions.transactionAddData,
        subscription: state.auth.subscription, 
        limits: state.appwide.limits,
        roles: state.auth.roles,
        currentPortfolio: state.data.menu_selected, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
       toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(withRouter(ResponsiveDialog)));
