
export default () => {


    const newFilter = (array, field, scope) => {

        console.log(array)
        return array.filter(data => scope.includes(data[field]))

    }

    const dateFilter = (array, start, end) => {

        console.log('filtering', [start, end])
        return array.filter(data =>  new Date(data['date']) >= start && new Date(data['date']) <= end )


    }

	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		if (!e) return;

        const data = e.data
        const allTx = data[0]
        const filteredData = data[1]
        const side = data[2]

        console.log('allTx', allTx)
        console.log('filteredData', filteredData)
        let returnFilter = null;
        let returnAll = null; 
        if (side === 'asc') {
            returnFilter = filteredData.sort(function compare(a, b) {
                var dateA = new Date(a.date);
                var dateB = new Date(b.date);
                return dateA - dateB;
            })
            
            returnAll = allTx.sort(function compare(a, b) {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return dateA - dateB;
            })
        } else {

            returnFilter = filteredData.sort(function compare(a, b) {
                var dateA = new Date(a.date);
                var dateB = new Date(b.date);
                return dateB - dateA;
              })
            
            returnAll = allTx.sort(function compare(a, b) {
                var dateA = new Date(a.date);
                var dateB = new Date(b.date);
                return dateB - dateA;
            })
        }
        
        postMessage([returnFilter, returnAll]);


	})
}
