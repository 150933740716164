import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/data";
import * as interActions from "../../../../../store/actions/interactions";
import { stat } from 'fs';
import Plus from '../../../../../Media/Plus.svg'
import balanceIcon from '../../../../../Media/balanceIcon.svg'
import CollaboratorCard from './CollaboratorCard'
import WalletBalance from './WalletBalance';
import StorageBalance from './StorageBalance';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

const styles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      minHeight: 100, 
      maxHeight: 130,
      overflow: 'hidden'
    },
    tabsRoot: {
      flexGrow: 1,
    },
    tabsIndicator: {
      left: 0, 
      width: 160, 
      top: 48,
    },
    scrollable: {
      paddingBottom: 60,
    },
    scrollButtons: {
        width: 48, 
    }
  });

class CollabOverview extends React.PureComponent {


    state = {
        selectedUser: this.props.user_id, 

    }

    componentDidMount() {
        this.props.setCurrentView(6)
        this.getData()
    }

    getData = () => {
        this.props.componentDataFetch('get', 6, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)
        this.setState(state => ({...state, selectedUser: this.props.user_id}))
        this.props.componentDataFetch('get', 291, this.props.currency_name, this.props.user_id, this.props.portfolioId, {}, {}, this.props.inBitcoin)
        this.setState(state => ({...state, selectedUser: this.props.user_id}))
    }

    componentDidUpdate(prevProps) {
        if (this.props.portfolioId !== prevProps.portfolioId) {
            this.setState(state => ({...state, selectedUser: this.props.user_id}))  
        }
    }

    setScopeUser = (e, scope) => {

        if (window.getSelection().toString() === "") {
            this.setState(state => ({...state, selectedUser: scope}))
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    addCollab = () => {
        this.props.toggleModal({type: 'add_collab', open: true, payload: {data: {
            portfolio_limit: this.props.portfoliosData[this.props.portfolioId]['user_limit'] || '10',
            users: this.props.portfoliosData[this.props.portfolioId]['users'],
            org: this.props.portfoliosData[this.props.portfolioId]['organization'],
            portfolioId:this.props.portfolioId,
        },
        getData: this.getData,
    }})
    }
    
    render() {
        const { classes } = this.props;
        
        return (
            this.props.portfoliosData === undefined || this.props.deposits === null || this.props.portfolioId === undefined || Object.keys(this.props.portfoliosData).length === 0 || this.props.deposits.user_deposits[this.state.selectedUser] === undefined ? 
            null 
            : 
            <div>
                
                { this.props.subscription.plan.plan_id === 'pro' || this.props.subscription.plan.plan_id === 'unlimited' ?

                <React.Fragment>
                <Grid container 
                spacing={0}
                justify="space-between"
                alignItems="center"
                >
                    <Grid item style={{
                        marginLeft: 48,
                        display: 'flex', 
                        alignItems: 'center'
                    }}> 
                        <div style={{display: 'inline-block'}}>
                            <div 
                                className="basier-p4-caps"
                                style={{color: '#6A7E93',
                                        display: 'inline-block'}}
                                >
                                Total Investment
                            </div><br />
                             <h2 style={{display: 'inline-block'}}>{this.props.currency_symbol} {this.props.portfoliosData[this.props.portfolioId].deposit_check.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</h2>
                        </div>

                    </Grid>

                    {this.props.roles[this.props.portfolioId] === 'admin' ? 
                    <Grid item style={{
                        marginRight: 56,
                        display: 'flex', 
                        alignItems: 'center'
                    }}>
                        <span style={{  marginLeft: 25, 
                                        height: 42, 
                                        display: 'flex',
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)'}}
                            className="white-button"
                            >

                                
                            <span style={{alignSelf: 'center', color: '#6A7E93'}}>
                                    <span className='basier-p4' style={{paddingRight: 12}}>{this.props.deposits.user_list.length}/{this.props.portfoliosData[this.props.portfolioId]['user_limit'] || '10'} managers{this.props.deposits.user_list.length > 1? 's': null}</span>
                                    {   
                                        this.props.deposits.user_list.filter(v => v === this.props.user_id).concat(this.props.deposits.user_list.filter(v => v !== this.props.user_id)).slice(0,3).map((user, index) => {
                                            return (
                                                <span>
                                                    <img 
                                                        style={{height: 24, width: 24, marginLeft: -5, border: '2px solid white', borderRadius: '50%'}}
                                                        src={`https://ui-avatars.com/api/?rounded=true&size=128&background=6A7E93&color=fffff&name=${this.props.deposits.user_deposits[user]['name']}`} 
                                                        alt=""/>
                                                </span>
                                            )
                                        })
                                    }
                            </span>

                            
                        </span>

                        <span className="basier-p4-caps" onClick={this.addCollab} style={{userSelect: 'none', color: 'black', marginLeft: 24}}>
                            <span><img src={Plus} alt="" style={{marginRight: 12}}/></span>
                            <span>Add a collaborator</span>
                        </span>
                        
                    </Grid>

                    :

                        null
                    }

                   
                    </Grid>

                    <Grid   container 
                            spacing={0}
                            style={{paddingLeft: 0, paddingRight: 0, marginTop: 42, minHeight: 145, maxHeight: 175}}
                            justify="flex-start"
                            alignItems="flex-start">
                        <Tabs
                            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator, scroller: classes.scrollable, scrollButtons: classes.scrollButtons}}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="collaborators"
                            >

                        {
                            this.props.deposits.user_list.filter(v => v === this.props.user_id).concat(this.props.deposits.user_list.filter(v => v !== this.props.user_id)).map((user, index) => {
                                return (
                                    <CollaboratorCard   
                                        key={index} 
                                        user={this.props.deposits.user_deposits[user]['name']}
                                        user_id={user}
                                        selectedUser={this.state.selectedUser}
                                        deposit={this.props.deposits.user_deposits[user]} 
                                        transactions={this.props.deposits.user_transactions[user]}
                                        setScopeUser={(e, scope) => this.setScopeUser(e, scope)} />
                                )
                            })
                        }

                        </Tabs>

                    </Grid>
                    </React.Fragment>
                    :

                    null 
                    }

                    { this.props.portfoliosData[this.props.portfolioId] !== undefined ? 
                    
                        this.props.portfoliosData[this.props.portfolioId].number_transactions > 0 ? 
                            <React.Fragment>
                                <div style={{paddingLeft: 48, paddingRight: 48, marginBottom: 48, width: '100%'}}>
                                    <div
                                        style={{
                                            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                                            borderRadius: 14, 
                                            position: 'relative',
                                            width: '100%',
                                            paddingBottom: 24,}}
                                    >
                                        <div className='title-asset-sticky' style={{width: '100%', marginTop: 48, zIndex: 999}}>
                                            <div style={{paddingTop: 48, paddingBottom: 12, paddingLeft: 40, display: 'flex', alignItems: 'center', borderBottom: '1px solid rgb(225, 229, 233)'}}>
                                                <h3 style={{marginBottom: 0}}>Exchange Balances | </h3>
                                                <div className="basier-p2" style={{textTransform: 'uppercase'}}>&nbsp;{this.props.deposits.user_deposits[this.state.selectedUser]['name']}</div>  
                                            </div>                     
                                        </div>
                                    <div style={{paddingTop: 48, paddingLeft: 40,}}>
                                        <WalletBalance user_id={this.state.selectedUser} />
                                    </div>
                                    </div>

                                </div>

                                <div style={{paddingLeft: 48, paddingRight: 48, marginBottom: 48, width: '100%'}}>
                                    <div
                                        style={{
                                            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                                            borderRadius: 14, 
                                            position: 'relative',
                                            width: '100%',
                                            paddingBottom: 24,}}
                                    >
                                        <div className='title-asset-sticky' style={{width: '100%', marginTop: 48, zIndex: 999}}>
                                            <div style={{paddingTop: 48, paddingBottom: 12, paddingLeft: 40, display: 'flex', alignItems: 'center', borderBottom: '1px solid rgb(225, 229, 233)'}}>
                                                <h3 style={{marginBottom: 0}}>Storage Wallet Balances | </h3>
                                                <div className="basier-p2" style={{textTransform: 'uppercase'}}>&nbsp;{this.props.deposits.user_deposits[this.state.selectedUser]['name']}</div>  
                                            </div>                     
                                        </div>
                                    <div style={{paddingTop: 48, paddingLeft: 40,}}>
                                        <StorageBalance user_id={this.state.selectedUser} />
                                    </div>
                                    </div>

                                </div>
                            </React.Fragment>
                        
                        :
                        <div style={{height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 72}}>
                            <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                <div>
                                    <img src={balanceIcon} alt="" style={{height: 80}} />
                                </div>
                                <div className="basier-p2" style={{marginTop: 24}}>
                                    <div>You do not have any transactions in your portfolios yet.</div>
                                    <div>Once you add transactions you will your exchange and storage balances here.</div>
                                </div>
                            </div>
                        </div>
                    
                    :

                       null

                    }

                </div>
            
            
        )


    }   
};
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        deposits: state.data.deposits, 
        inBitcoin: state.data.bitcoinPf, 
        user_id: state.auth.user_id,
        subscription: state.auth.subscription,
        roles: state.auth.roles,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CollabOverview));


