import React from 'react';
import Grid from '@material-ui/core/Grid';
import backIconSingle from '../../../../Media/backArrowSingle.svg';
import closeIconSingle from '../../../../Media/closeIconSingle.svg';
import Hidden from '@material-ui/core/Hidden';

const baseModalTop = (props) => {


    return (

        
        <div>
        <Hidden smDown>
            <div style={{height: 90, display: 'flex', alignItems: 'center', backgroundColor: 'rgba(243, 245, 246, 0.5)'}}>
            <div style={{ width: '25%', display: 'flex', alignItems: 'center'}}>

                {   props.forceSingle ? 

                    null 

                    :
                    
                    !props.welcome ? 
                    <div  style={{width: '100%',}}>
                     <div 
                     style={{marginBottom: 0, paddingLeft:24, cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                     className="basier-p2"
                     onClick={props.onClick}> 

                         <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', 
                                height: 42, width: 42, borderRadius: '100%',  marginRight: 12,
                                backgroundColor: 'white', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)'}}>

                            <img src={backIconSingle} alt="" /> 
                         </div>

                        <div> Entry method</div>

                    </div>
                 </div>
                :   
                
                null 
                }
               
            
            </div>


            <div style={{flexDirection: 'column',  width: '50%'}}>
                
                {   props.forceSingle ? 
                    props.sub !== undefined ? 
                    <div className='basier' style={{textAlign: 'center', fontSize: 16, textTransform: 'uppercase', paddingTop: 12}}>{props.sub}</div>
                    :
                    null

                :
                <div className='basier' style={{textAlign: 'center', fontSize: 16, textTransform: 'uppercase', paddingTop: 12}}>new transaction</div>
                }

                <h3 style={{textAlign: 'center', marginTop: 6}}>
                {   props.forceSingle ? 
                
                    props.title

                    : 


                    props.entryType === 'wizard' ? 

                    "Entry Wizard"

                    : 

                    props.entryType === 'quick' ? 

                    "Connect Exchanges/Wallets"

                    : 

                    props.entryType === 'upload' ? 

                    "CSV Import"

                    : 

                    "Select transaction entry method"
                }
                </h3>

            </div>

            <div  style={{width: '25%', }}>

            <div  style={{width: '100%',}}>
                     <div 
                     style={{marginBottom: 0, paddingRight:24, cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                     className="basier-p2"
                     onClick={props.handleClose}> <span>Close </span>
                    
                        <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', 
                                height: 42, width: 42, borderRadius: '100%',  marginLeft: 12,
                                backgroundColor: 'white', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)'}}>

                            <img src={closeIconSingle} alt="" /> 
                         </div>

                    </div>
            </div>


            </div>
            </div>
            </Hidden>

            <Hidden mdUp>
                
                <div style={{height: 90, width: '100%', display: 'flex', position: 'fixed', top: 0, marginBottom: 90, alignItems: 'center', backgroundColor: '#F8F9FA', zIndex: 99999999}}>
                    <div style={{width: '100%', paddingLeft: 12, paddingRight: 12,  display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                
                    <div className="basier-p3" style={{textAlign: 'center', marginTop: 6, textTransform: 'uppercase', color: 'black', fontWeight: 900}}>
                    {   props.forceSingle ? 
                    
                        props.title

                        : 


                        props.entryType === 'wizard' ? 

                        "Entry Wizard"

                        : 

                        props.entryType === 'quick' ? 

                        "Connect Exchanges/Wallets"

                        : 

                        props.entryType === 'upload' ? 

                        "CSV Import"

                        : 

                        "Select transaction entry method"
                    }
                    </div>
                    <div 
                     style={{marginBottom: 0, paddingRight:12, cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                     className="basier-p3"
                     onClick={props.handleClose}> 
                        <div style={{
                            display: 'flex', justifyContent: 'center', alignItems: 'center', 
                            height: 42, width: 42, borderRadius: '100%',  marginLeft: 6,
                            backgroundColor: 'white', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)'}}>

                        <img src={closeIconSingle}alt="close"/> 
                        </div>
                    </div>
                
                </div>
                </div>
                <div style={{height: 92, width: '100%'}} />


            </Hidden>
            
        </div>
    )
}

export default baseModalTop;