import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/set'
import 'core-js/es/map'
import 'filepond-polyfill';
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import NoSupport from './NoSupport';
import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import profitReducer from './store/reducers/profit'
import appwideReducer from './store/reducers/appwide'
import authReducer from './store/reducers/auth'
import dataReducer from './store/reducers/data'
import interactionReducer from './store/reducers/interactions'
import loadingReducer from './store/reducers/loading'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { BrowserRouter } from "react-router-dom";
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

function get_browser() {
  var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: (tem[1] || '') };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/)
    if (tem != null) { return { name: 'Opera', version: tem[1] }; }
  }
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    tem = ua.match(/\Edge\/(\d+)/)
    if (tem != null) { return { name: 'Edge', version: tem[1] }; }      
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
  return {
    name: M[0],
    version: +M[1]
  };
}

function isSupported(browser) {
  var supported = false;
  if (browser.name === "Chrome" && browser.version >= 48) {
    supported = true;
  } else if (browser.name === "Edge") {
    supported = true;
  } else if (browser.name === "Safari" && browser.version >= 11) {
    supported = true;
  } else if (browser.name === "Firefox" && browser.version >= 52) {
    supported = true;
  } else if (browser.name === "Opera" && browser.version >= 28) {
    supported = true;
  }
  return supported;
}

const browser = get_browser()
const isSupportedBrowser = isSupported(browser);
console.log(browser)

const appReducer = combineReducers({
    profit: profitReducer,
    appwide: appwideReducer,
    auth: authReducer,
    data: dataReducer,
    loading: loadingReducer,
    interactions: interactionReducer,
});


const rootReducer = (state, action) => {

    if (action.type === 'AUTH_LOGOUT') {
        state = undefined
      }

    return appReducer(state, action)
  }


const composerEnhancer = composeWithDevTools({
  name: `Redux`,
  realtime: true,
  trace: true,
  traceLimit: 25
});

let store = null;
if (process.env.REACT_APP_HOST_ENV === 'dev') {
  store = createStore(rootReducer, composerEnhancer(applyMiddleware(thunk)));
} else {
  store = createStore(rootReducer, applyMiddleware(thunk));
}

if (!isSupportedBrowser) {

  ReactDOM.render(
        <NoSupport browser={browser} />,
  document.getElementById('root'))

} else {

  ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>,
  document.getElementById('root'))

}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
