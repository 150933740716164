import React from 'react';
import { connect } from 'react-redux';
import TableRow from './TransactionTableRow';
import Oops from '../../../../Media/oops.svg';


class TableBody extends React.PureComponent {

    render () {

        return (

            <div>
                {
                this.props.data.length > 0 ? 
                this.props.data.map(v => {
                    return (
                        <TableRow getOverallData={this.props.getOverallData} selectTransaction={this.props.selectTransaction} data={v} selectAll={this.props.selectAll} selectSingle={this.props.selectSingle} selected={this.props.selected} allSelected={this.props.allSelected} />
                    )
                })
                : 
                <div style={{width: '100%', display: 'flex',flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', marginTop: 12}}>
                    <img src={Oops} alt="" style={{height: 100, marginTop: 120}} />
                    <div className="basier-p3">No records found</div>
                </div>
            }
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(TableBody)
