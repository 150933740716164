import React, { type ElementConfig } from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/data';
import * as interactionActions from '../../../../../store/actions/interactions';
import { Thumbs } from 'react-responsive-carousel';



const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '250px', fontSize: 16, color: 'black', cursor: 'text', dispay:'inline-block'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#F3F5F6'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.4',
    };
  },

};

class MenuSelector extends React.Component {

  state = {
    selectedOption: null,
    options: [{label: 'Deduct from balance', value: 'deduct'}, {label: 'Add new money', value: 'add'}]
  }

  componentDidMount() {

    let startValue = {label: 'Add new money', value: 'add'}
    if (this.props.balances !== undefined && this.props.balances !== null) {
        if (this.props.balances[this.props.portfolio] !== undefined && this.props.balances[this.props.portfolio] !== null) {

            if (this.props.balances[this.props.portfolio][this.props.coin] !== undefined && this.props.balances[this.props.portfolio][this.props.coin] !== null && this.props.balances[this.props.portfolio][this.props.coin] > 0 ) {
                startValue = {label: 'Deduct from balance', value: 'deduct'}
            }

        }
    } 
    this.setState(state => ({...state, selectedOption: startValue}))
    this.handleChange(startValue)

  }

  componentDidUpdate(prevProps) {

  }


  handleChange = (selectedOption) => {
    
    if (selectedOption === null) {
      this.setState({ selectedOption });
      this.props.handleSelect(selectedOption.value)

    }
    else if (selectedOption !== this.state.selectedOption) {
      this.setState({ selectedOption });
      this.props.handleSelect(selectedOption.value)

    }
    
  }

  onFocus = () => {
    if (this.props.propagate === true) {
      this.handleChange(null)
    }
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.state.options}
        isSearchable={true}
        styles={colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        onFocus={this.onFocus}
        onMenuOpen={this.onFocus}
        classNamePrefix="react-select-user"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
