import React, { type ElementConfig } from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';
import * as actions from "../../../../../../store/actions/interactions";



const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '250px', fontSize: 16, border: '0px', color: 'black', cursor: 'text', dispay:'inline-block'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#F3F5F6'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.4',
    };
  },

};

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <div style={{marginLeft: '12px'}}>
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9136 1L7.95679 8L1 1" stroke="#6A7E93" stroke-width="1.5" stroke-miterlimit="10"/>
        </svg>
      </div>
    </components.DropdownIndicator>
  );
};


class MenuSelector extends React.Component {

  constructor(props) {
    super(props)
    const textName = this.props.selection === 'exchange' ? 'Exchange' : this.props.selection === 'wallet' ? "Storage Wallet" : null
    this.state = {
      selectedOption: {value: this.props.selection, label: textName}
    }
  }
  

  componentDidMount() {


  }


  handleChange = (selectedOption) => {

    if (selectedOption.value !== this.state.selectedOption.value) {

      this.setState({ selectedOption });
      this.props.changeOrigin(selectedOption.value)


    }
    
  }

    options = [{value: "exchange", label: "Exchange"}, {value: "wallet", label: "Storage Wallet"}]

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.options}
        isSearchable={true}
        styles={colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        components={{ DropdownIndicator }}
        classNamePrefix="react-select-user"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)), 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
