import React from 'react';
import { connect } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };

class Step2Connect extends React.PureComponent {

    render () {
        const { classes } = this.props;
        const portName = this.props.portfolioList.filter(v => v.id == this.props.portfolioId)

        let portMessage = null;
        if (portName.length > 0) {
            portMessage = `Import all transactions from this account to ${portName[0].name}`
        } else {
            portMessage = `Import all transactions from this account to a single portfolio.`
        }

        return (
            
            <div style={{padding: '0px 24px', display: 'flex', justifyContent: 'space-between', width: '100%'}}>

                <div style={{flex: 1,  paddingRight: 12, paddingLeft: 12}}>
                    <h4>
                        Connection details
                    </h4> 
                    <div style={{marginTop: 24}}> 

                        <div style={{marginTop: 12, marginBottom: 12}}>
                            <div style={{marginBottom: 4}} className="basier-p3">
                                Account Name
                            </div>
                            <input onChange={(e) => this.props.inputData(e, 'name')} type="text" className='light-input' style={{width: '90%'}} />
                        </div>

                        {this.props.data.fields[0].map((val, key) => {
                        
                        return (
                        <div style={{marginTop: 12, marginBottom: 12}}>
                            <div style={{marginBottom: 4}} className="basier-p3">
                                {val[1]}
                            </div>
                            <input onChange={(e) => this.props.inputData(e, val[0])} type="text" className={this.props.error ? 'light-input error-input' : 'light-input'} style={{width: '90%'}} />
                        </div>
                        )

                        })}

                    </div> 
                    {this.props.error !== null && 
                        <div style={{color: 'red'}} className="basier-p4">{this.props.error}</div>
                    }
                </div>    

                <div style={{flex: 1, paddingRight: 12, paddingLeft: 12}}>
                    <h4>
                        Input settings
                    </h4> 

                    <div style={{marginTop: 24}}>
                        <div className="basier-p3" style={{fontWeight: 900}}>
                            How should we import your transactions?
                        </div>

                        <div style={{marginTop: 24}}>
                            <RadioGroup
                                    name="btcScope"
                                    value={this.props.scope}
                                    onChange={this.props.changeType}
                                    //style={{display: 'inline-block'}}

                                >   

                                 <FormControlLabel value="all" 
                                                    control={<Radio classes={{
                                                        root: classes.root,
                                                        checked: classes.checked
                                                    }} />} 
                                                    label={portMessage} />

                                <FormControlLabel value="choose" 
                                                    control={<Radio classes={{
                                                        root: classes.root,
                                                        checked: classes.checked
                                                    }}/>} 
                                                    label="Let me choose to which portfolio each transaction should be imported." />

                                
                            </RadioGroup>
                            
                            {this.props.scope === 'choose' &&
                                <div className="basier-p4" style={{fontStyle: 'italic', color: '#0078DB'}}>Once we have imported all your transactions you will be able to manually match transactions to portfolios</div>
                            }

                        </div>
                    </div>
                </div>

            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        portfolioList: state.auth.list_portfolios
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Step2Connect))
